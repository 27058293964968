//对 axios 进行二次封装
import axios from "axios";

//引入 进度条
import nprogress from "nprogress"
import "nprogress/nprogress.css"

const requests = axios.create({
    baseURL: '/api',
    timeout: 60000
})

//请求拦截器：发请求前触发
requests.interceptors.request.use((config) => {
    //config：配置对象，对象中有一个属性很重要，headers请求头
    nprogress.start()
    return config
})

//响应拦截器：
requests.interceptors.response.use((res) => {
    //响应成功
    nprogress.done()
    return res.data
}, (error) => {
    //响应失败
    return Promise.reject(error)
})


export default requests;
