<template>

    <div class="container" v-loading="loading">


        <el-form ref="paperElecInvoiceForm" :model="form" :rules="rules" label-width="80px">

            <el-form-item label="发票号码" required prop="number">
                <el-input v-model="form.number" clearable></el-input>
            </el-form-item>

            <el-form-item label="开票金额" required prop="amount">
                <el-input-number v-model="form.amount" style="width: 50%"
                                 :precision="2" :step="0.01" :min="0">
                </el-input-number>
            </el-form-item>

            <el-form-item label="开票内容" required prop="content">
                <el-input style="width: 100%"
                          type="textarea"
                          :rows="3"
                          placeholder="请输入开票内容"
                          v-model="form.content">
                </el-input>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" @click="Submit">保存</el-button>
            </el-form-item>

        </el-form>

    </div>

</template>

<script>
    import {GetElecInvoiceInfo, InsertOperationRecord, InsertPaperElecInvoice} from "../../api";

    export default {
        name: "MakeElecInvoiceIssued",
        props: {
            clientId: {
                type: String,
                required: true
            },
            billMonth: {
                type: String,
                required: true
            },
            payType: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                unionid: null,
                loading: false,
                form: {
                    number: null,
                    amount: null,
                    content: null,
                },
                rules: {
                    number: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    amount: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    content: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            Submit() {
                this.$refs.paperElecInvoiceForm.validate((e) => {
                    if (e) {
                        this.loading = true
                        InsertPaperElecInvoice(this.clientId, this.form.amount, this.form.content, this.billMonth, this.form.number, this.unionid).then((res) => {
                            if (res === 1) {
                                InsertOperationRecord(this.unionid, 'checkElecInvoice', this.clientId + ',' + this.billMonth).then((res) => {
                                    if (res === 1) {
                                        this.loading = false
                                        this.$emit('ElecPaperInvoiceAdd')
                                    }
                                })
                            }
                        })
                    } else {
                        this.$message.error('有未填项！');
                    }

                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')
            GetElecInvoiceInfo(this.payType, this.clientId, this.billMonth).then((res) => {
                this.form.amount = res.amount
                this.form.content = res.content
            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
        white-space: pre-line
    }

</style>
