<template>

    <div class="container">

        <div v-if="paymentDetail != null">

            <el-descriptions title="客户信息"
                             labelClassName="label"
                             class="description"
                             :column="2"
                             border
                             style="margin-bottom: 20px">
                <el-descriptions-item label="所属项目" :span="2">{{paymentDetail.user.projName}}</el-descriptions-item>
                <el-descriptions-item label="客户名称" :span="2">{{paymentDetail.user.clientName}}</el-descriptions-item>
                <el-descriptions-item label="客户编号">{{paymentDetail.user.clientId}}</el-descriptions-item>
                <el-descriptions-item label="微信绑定手机号">{{paymentDetail.user.telephone}}</el-descriptions-item>
                <el-descriptions-item label="用户名称">{{paymentDetail.userInfo.name}}</el-descriptions-item>
                <el-descriptions-item label="联系电话">{{paymentDetail.userInfo.phone}}</el-descriptions-item>
            </el-descriptions>

            <el-descriptions title="支付信息"
                             labelClassName="label"
                             class="description"
                             :column="1"
                             border
                             style="margin-bottom: 20px">

                <el-descriptions-item label="账单月份">{{paymentDetail.elecPayment.billMonth}}</el-descriptions-item>
                <el-descriptions-item label="支付金额">{{MoneyFormat(paymentDetail.elecPayment.amount)}}
                </el-descriptions-item>
                <el-descriptions-item label="手续费">{{MoneyFormat(paymentDetail.adapay.payments[0].fee_amt)}}
                </el-descriptions-item>
                <el-descriptions-item label="订单号">{{paymentDetail.elecPayment.orderNo}}</el-descriptions-item>
                <el-descriptions-item label="支付流水号">{{paymentDetail.elecPayment.orderId}}</el-descriptions-item>
                <el-descriptions-item label="第三方订单号">{{paymentDetail.adapay.payments[0].party_order_id}}
                </el-descriptions-item>
                <el-descriptions-item label="微信订单号">{{paymentDetail.adapay.payments[0].out_trans_id}}
                </el-descriptions-item>
                <el-descriptions-item label="时间">{{paymentDetail.elecPayment.createTime}}</el-descriptions-item>
            </el-descriptions>

        </div>

    </div>

</template>

<script>
    import {GetElecPaymentDetail} from "../../api";
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "ElecPaymentDetail",
        props: {
            id: {
                type: Number,
                required: true
            },
        },
        data() {
            return {
                paymentDetail: null
            }
        },
        methods: {

            MoneyFormat(value) {
                return formatMoney(value)
            }

        },
        created() {

            GetElecPaymentDetail(this.id).then((res) => {
                this.paymentDetail = res
            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

</style>
