<template>
    <div v-loading="loading">

        <div v-if="!authority">
            <el-result style="height: -webkit-calc(100vh - 250px)"
                       icon="warning" title="无权限"
                       subTitle="抱歉，无权限操作">
            </el-result>
        </div>

        <FolderFormTable v-else>

            <template slot="leftContent">
                <!--查询表单-->
                <el-form :model="searchObj" label-width="80px" size="small">

                    <el-form-item label="所属项目">
                        <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                            <el-option v-for="project in projects" :key="project.abbreviation"
                                       :value="project.projName"
                                       :label="project.projName"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="消息类型">
                        <el-select v-model="searchObj.type" placeholder="消息类型" @change="submit">
                            <el-option value="billMsg" label="账单通知"></el-option>
                            <el-option value="urgeMsg" label="催缴通知"></el-option>
                            <el-option value="invoiceMsg" label="发票领取通知"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="账单月份" v-if="searchObj.type === 'billMsg'">
                        <el-date-picker style="width: 215px" @change="submit"
                                        type="months"
                                        value-format="yyyy-MM"
                                        v-model="searchObj.months"
                                        placeholder="选择一个或多个月">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="欠费客户" v-if="searchObj.type === 'urgeMsg'">
                        <el-select v-model="searchObj.clientId" placeholder="欠费客户" filterable @change="GetUnpaidBills">
                            <el-option v-for="client in clientList" :key="client.clientId"
                                       :value="client.clientId"
                                       :label="client.clientName"></el-option>
                        </el-select>
                    </el-form-item>

                </el-form>
            </template>


            <template slot="rightContent">
                <!-- 账单通知 -->
                <div v-if="searchObj.type === 'billMsg'" v-loading="sendLoading" element-loading-text="消息推送时间较长，请勿关闭窗口">
                    <el-tag style="width: 100%;margin-bottom: 10px" type="warning">
                        <i class="el-icon-warning-outline" style="margin-right: 5px"></i>
                        <span>{{searchObj.months == null?'':searchObj.months.join(',')}} 请选择要推送的客户</span>
                    </el-tag>
                    <div style="height: -webkit-calc(100vh - 205px)">
                        <vxe-grid
                                ref="xTable"
                                class="msgSendClientsTable"
                                v-bind="msgSendClientsTable"
                                @checkbox-change="CheckboxChange"
                                @checkbox-all="CheckboxChange"
                                @toolbar-tool-click="toolbarToolClickEvent">
                        </vxe-grid>
                    </div>
                </div>
                <!-- 缴费通知 -->
                <div v-if="searchObj.type === 'urgeMsg'" v-loading="sendLoading" element-loading-text="消息推送时间较长，请勿关闭窗口">
                    <el-tag style="width: 100%;margin-bottom: 10px" type="warning">
                        <i class="el-icon-warning-outline" style="margin-right: 5px"></i>
                        <span>欠费账单</span>
                    </el-tag>
                    <div style="height: -webkit-calc(100vh - 205px)">
                        <vxe-grid
                                ref="xTable2"
                                class="msgSendBillsTable"
                                v-bind="msgSendBillsTable"
                                @checkbox-change="CheckboxChange2"
                                @checkbox-all="CheckboxChange2"
                                @toolbar-tool-click="toolbarToolClickEvent2">

                            <template #payNotice="{ row }">
                                <el-tag v-if="row.payNoticeInfo != null" type="success" size="small">已推送</el-tag>
                                <el-tag v-else type="danger" size="small">未推送</el-tag>
                            </template>
                        </vxe-grid>
                    </div>
                </div>
                <!-- 发票通知 -->
                <div v-if="searchObj.type === 'invoiceMsg'" v-loading="sendLoading"
                     element-loading-text="消息推送时间较长，请勿关闭窗口">
                    <el-tag style="width: 100%;margin-bottom: 10px" type="warning">
                        <i class="el-icon-warning-outline" style="margin-right: 5px"></i>
                        <span>请选择要通知发票领取的客户</span>
                    </el-tag>
                    <div style="height: -webkit-calc(100vh - 205px)">
                        <vxe-grid
                                ref="xTable3"
                                class="msgSendInvoicesTable"
                                v-bind="msgSendInvoicesTable"
                                @checkbox-change="CheckboxChange3"
                                @checkbox-all="CheckboxChange3"
                                @toolbar-tool-click="toolbarToolClickEvent3">
                        </vxe-grid>
                    </div>
                </div>

            </template>

        </FolderFormTable>
    </div>
</template>

<script>
    import FolderFormTable from "../utils/FolderFormTable"
    import XEUtils from 'xe-utils'
    import {
        GetBoundClientWithUnpaidBills,
        GetBoundClientWuyeBillCount,
        GetProjectsWithoutAll, GetValidClientsByProjName,
        GetWuyeAuthority, GetWuyeClientUnpaidBills,
        WxSendBillNotice, WxSendInvoiceNotice, WxSendUrgeNotice
    } from "../../api";

    export default {
        name: "WyMpMsgManagement",
        components: {FolderFormTable},
        data() {
            return {
                loading: false,
                authority: false,
                unionid: null,
                projects: [],
                searchObj: {
                    type: 'billMsg',
                    projName: '稻谷国际中心',
                    months: [XEUtils.toDateString(XEUtils.getWhatMonth(new Date(), -1), 'yyyy-MM'), XEUtils.toDateString(XEUtils.getWhatMonth(new Date()), 'yyyy-MM')],
                    clientId: null
                },
                sendLoading: false,
                //账单通知
                clients: [],
                selectClientIds: [],
                //催缴通知
                clientList: [],
                bills: [],
                selectBillIds: [],
                //发票通知
                invoiceClients: [],
                selectInvoiceClientIds: []
            }
        },
        computed: {
            msgSendClientsTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        size: 'medium',
                        tools: [
                            {
                                code: 'send',
                                name: '推送账单',
                                status: 'primary',
                                disabled: this.selectClientIds.length === 0
                            },
                        ]
                    },
                    rowConfig: {
                        isHover: true
                    },
                    data: this.clients,
                    checkboxConfig: {
                        strict: true,
                        highlight: true
                    },
                    columns: [
                        {type: 'checkbox', field: 'check', title: '', width: 40},
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '客户编号', field: 'clientId'},
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {resizable: true, title: '可推送账单数', field: 'billCounts'},
                        {resizable: true, title: '账单月份', field: 'billMonths'},
                        {resizable: true, title: '相关房源', field: 'houseNames'},
                        {resizable: true, title: '收费项目', field: 'fees'}
                    ]
                }
            },
            msgSendBillsTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        size: 'medium',
                        tools: [
                            {
                                code: 'send',
                                name: '推送缴费通知',
                                status: 'primary',
                                disabled: this.selectBillIds.length === 0
                            },
                        ]
                    },
                    rowConfig: {
                        isHover: true
                    },
                    data: this.bills,
                    checkboxConfig: {
                        strict: true,
                        highlight: true
                    },
                    columns: [
                        {type: 'checkbox', field: 'check', title: '', width: 40},
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '账单月份', field: 'billMonth'},
                        {resizable: true, title: '最晚缴款日', field: 'dueDate', className: 'font-blue'},
                        {resizable: true, title: '账单周期', field: 'chargeContent'},
                        {resizable: true, title: '收费项目', field: 'u8fee'},
                        {resizable: true, title: '账单金额', field: 'amount', formatter: 'FormatMoney'},
                        {resizable: true, title: '减免金额', field: 'reliefAmount', formatter: 'FormatMoney'},
                        {
                            resizable: true, title: '实际应收', field: 'receivedAmount', formatter: 'FormatMoney',
                            className: 'font-blue'
                        },
                        {resizable: true, title: '账单推送', field: 'payNoticeInfo', slots: {default: 'payNotice'}},
                    ]
                }
            },
            msgSendInvoicesTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        size: 'medium',
                        tools: [
                            {
                                code: 'send',
                                name: '推送发票领取通知',
                                status: 'primary',
                                disabled: this.selectInvoiceClientIds.length === 0
                            },
                        ]
                    },
                    rowConfig: {
                        isHover: true
                    },
                    data: this.invoiceClients,
                    checkboxConfig: {
                        strict: true,
                        highlight: true
                    },
                    columns: [
                        {type: 'checkbox', field: 'check', title: '', width: 40},
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '客户编号', field: 'clientId'},
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'}
                    ]
                }
            }
        },
        methods: {
            submit() {
                this.clients = []
                this.selectClientIds = []
                this.selectBillIds = []
                this.selectInvoiceClientIds = []


                switch (this.searchObj.type) {
                    case 'billMsg':
                        if (this.searchObj.months != null && this.searchObj.months.length > 0) {
                            this.loading = true
                            GetBoundClientWuyeBillCount(this.searchObj).then((res) => {
                                this.clients = res
                                this.loading = false
                            })
                        }
                        break
                    case 'urgeMsg':
                        this.loading = true
                        GetBoundClientWithUnpaidBills(this.searchObj.projName).then((res) => {
                            this.clientList = res
                            this.loading = false
                        })
                        break
                    case  'invoiceMsg':
                        this.loading = true
                        GetValidClientsByProjName(this.searchObj.projName).then((res) => {
                            this.invoiceClients = res
                            this.loading = false
                        })
                        break
                }

            },
            GetUnpaidBills() {
                this.selectBillIds = []
                this.loading = true
                GetWuyeClientUnpaidBills(this.searchObj.clientId).then((res) => {
                    this.bills = res
                    this.loading = false
                })
            },
            /* 账单通知 */
            CheckboxChange() {
                var a = this.$refs.xTable.getCheckboxRecords(true)

                this.selectClientIds = []

                for (var i = 0; i < a.length; i++) {
                    this.selectClientIds.push(a[i].clientId)
                }
            },
            toolbarToolClickEvent({code}) {
                switch (code) {
                    case 'send':
                        this.$alert('是否确定推送？', '账单推送通知', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            showCancelButton: true,
                            callback: action => {
                                if (action === 'confirm') {
                                    this.sendLoading = true
                                    WxSendBillNotice(this.searchObj.projName, this.searchObj.months.join(","), this.selectClientIds.join(","), this.unionid).then((res) => {
                                        this.submit()
                                        this.sendLoading = false
                                        this.$notify({
                                            title: '推送成功',
                                            type: 'success',
                                            message: '共推送了 ' + res + ' 位用户',
                                            duration: 0
                                        });
                                    })
                                }
                            }
                        });
                        break
                }
            },
            /* 缴费通知 */
            CheckboxChange2() {
                var a = this.$refs.xTable2.getCheckboxRecords(true)
                this.selectBillIds = []

                for (var i = 0; i < a.length; i++) {
                    this.selectBillIds.push(a[i].id)
                }
            },
            toolbarToolClickEvent2({code}) {
                switch (code) {
                    case 'send':
                        this.$alert('是否确定推送缴费通知？', '缴费通知', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            showCancelButton: true,
                            callback: action => {
                                if (action === 'confirm') {
                                    this.sendLoading = true
                                    WxSendUrgeNotice(this.selectBillIds.join(","), this.searchObj.clientId).then((res) => {
                                        this.sendLoading = false
                                        this.$notify({
                                            title: '推送成功',
                                            type: 'success',
                                            message: '共推送了 ' + res + ' 位用户',
                                            duration: 0
                                        });
                                    })
                                }
                            }
                        });
                        break
                }
            },
            /* 发票通知 */
            CheckboxChange3() {
                var a = this.$refs.xTable3.getCheckboxRecords(true)
                this.selectInvoiceClientIds = []

                for (var i = 0; i < a.length; i++) {
                    this.selectInvoiceClientIds.push(a[i].clientId)
                }
            },
            toolbarToolClickEvent3({code}) {
                switch (code) {
                    case 'send':
                        this.$alert('是否确定推送？', '发票领取通知', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            showCancelButton: true,
                            callback: action => {
                                if (action === 'confirm') {
                                    this.sendLoading = true
                                    WxSendInvoiceNotice(this.selectInvoiceClientIds.join(",")).then((res) => {
                                        this.submit()
                                        this.sendLoading = false
                                        this.$notify({
                                            title: '推送成功',
                                            type: 'success',
                                            message: '共推送了 ' + res + ' 位用户',
                                            duration: 0
                                        });
                                    })
                                }
                            }
                        });
                        break
                }
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeAuthority(this.unionid).then((res) => {

                /* 权限控制 */
                if (res.authorities.indexOf('mpMsgSend') != -1) {
                    this.authority = true
                } else {
                    this.authority = false
                }

                this.searchObj.projName = '稻谷国际中心'
                if (res.projName === 'ALL') {
                    GetProjectsWithoutAll().then((res) => {
                        this.projects = res
                    })
                } else {
                    this.projects = []
                    var arr = res.projName.split(',')

                    this.searchObj.projName = arr[0]

                    for (var i = 0; i <= arr.length - 1; i++) {
                        this.projects.push({
                            projName: arr[i]
                        })
                    }
                }

                this.submit()

            })

        }
    }
</script>

<style scoped>

    .msgSendClientsTable >>> .font-blue {
        color: #45aaf2;
    }

    .msgSendBillsTable >>> .font-blue {
        color: #45aaf2;
    }

    .msgSendInvoicesTable >>> .font-blue {
        color: #45aaf2;
    }

</style>
