<template>
    <div>
        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
            <el-collapse-item name="collapseItem" class="form-container">
                <template slot="title">
                    <el-tag type="success" style="width: 100%;padding-left: 15px">
                        <i class="el-icon-search" style="margin-right: 5px"></i>
                        <span>{{title}}</span>
                    </el-tag>
                </template>

                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px" style="padding-left: 15px">

                    <el-form-item label="查询月份">
                        <el-date-picker style="width: 200px" @change="intiSubmit" :clearable="false"
                                        v-model="searchObj.month"
                                        value-format="yyyy-MM"
                                        type="month" :picker-options="pickerOptions" placeholder="选择月">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="所属项目:">
                        <el-select v-model="searchObj.projName" placeholder="所属项目" @change="intiSubmit">
                            <el-option v-for="project in projects" :key="project.abbreviation"
                                       :value="project.abbreviation"
                                       :label="project.projName"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="报表类别:">
                        <el-select v-model="searchObj.kind" placeholder="报表类别" @change="submit">
                            <el-option v-for="kind in reportsKinds"
                                       :key="kind.reportKind"
                                       :value="kind.reportKind"
                                       :label="kind.reportName">
                                <span style="float: left">{{ kind.reportName }}</span>
                                <span style="float: right; color: #8492a6; font-size: 13px">{{ kind.exist?'&nbsp(已审核)':'' }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="计费单价:" v-if="searchObj.kind === 'elecReport_05'">
                        <el-input-number style="width: 200px" :disabled="currentReportExist"
                                         v-model="searchObj.unitPrice"
                                         :precision="1" :step="0.1" :max="10" @change="submit"
                                         controls-position="right">
                        </el-input-number>
                    </el-form-item>

                </el-form>
            </el-collapse-item>
        </el-collapse>


        <div :style="tableContainerStyle">
            <vxe-grid ref="xTable"
                      id="reportTable"
                      class="reportTable"
                      v-bind="reportTable"
                      @toolbar-button-click="toolbarButtonClickEvent"
                      @toolbar-tool-click="toolbarToolClickEvent"
                      @cell-click="cellClick">

                <template #bottom>
                    <div class="alert-message">
                        <span>
                            <el-tag effect="plain" v-if="currentReportExist">审核人：{{reviewerName}}</el-tag>
                            <el-tag type="danger" effect="plain" v-else>未审核</el-tag>
                        </span>
                    </div>
                </template>

            </vxe-grid>
        </div>

        <!--抽屉-->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <TerminalMeterRecords v-if="searchObj.kind === 'elecReport_01'"
                                  :abbreviation="searchObj.projName"
                                  :accountNum="accountNum"
                                  :month="searchObj.month"></TerminalMeterRecords>
            <ElecRecordDetail v-else :elecId="elecId" :month="searchObj.month"
                              :projName="searchObj.projName"></ElecRecordDetail>
        </el-drawer>

        <!--upload抽屉-->
        <el-drawer :visible.sync="uploadDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="uploadDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <UploadOfficialUsage :abbreviation="searchObj.projName" :month="searchObj.month"></UploadOfficialUsage>
        </el-drawer>

        <!--弹出确认框-->
        <!--<el-dialog title="报表审核" :visible.sync="dialog">
            <h3>是否确认无误？</h3>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="small" @click="confirmSubmit">确认</el-button>
            </div>
        </el-dialog>-->


    </div>
</template>

<script>
    import XEUtils from 'xe-utils'
    import formatMoney from "../../utils/formatMoney";
    import ElecRecordDetail from "./ElecRecordDetail";
    import UploadOfficialUsage from "./UploadOfficialUsage"
    import TerminalMeterRecords from "./TerminalMeterRecords"
    import {
        GetAuthority,
        GetElecReport_01, GetElecReport_02,
        GetElecReport_03,
        GetElecReport_04,
        GetElecReport_05,
        GetElecReportKinds, GetElecReportReviewer,
        GetProjectsWithoutAll, GetTransferSituation, InsertElecReports, InsertOperationRecord
    } from "../../api";

    export default {
        name: "ElecReportManagement",
        components: {ElecRecordDetail, TerminalMeterRecords, UploadOfficialUsage},
        data() {
            return {
                unionid: null,
                title: '',
                drawer: false,
                drawerTitle: '',
                dialog: false,
                uploadDrawer: false,
                uploadDrawerTitle: '',
                uploadAuthority: false,
                checkAuthority: false,
                projects: [],
                reportsKinds: [],
                currentReportExist: false,
                reviewerName: '',
                searchObj: {
                    projName: 'GJZX',
                    month: XEUtils.toDateString(XEUtils.getWhatMonth(new Date(), -1), 'yyyy-MM'),
                    kind: 'elecReport_01',
                    unitPrice: 0.7
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > XEUtils.getWhatMonth(new Date(), -1);
                    },
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                reports: [],
                columns: [],
                tools: [],
                footerShow: false,
                elecId: null,
                accountNum: null
            }
        },
        computed: {
            reportTable() {
                return {
                    height: 'auto',
                    showOverflow: true,
                    align: 'center',
                    border: true,
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'print', name: '打 印', icon: 'vxe-icon--print'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'}
                        ],
                        tools: this.tools,
                        size: 'medium'
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    printConfig: {
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.reports,
                    columns: this.columns,
                    showFooter: this.footerShow,
                    footerRowClassName: 'footer-row',
                    footerMethod({columns, data}) {
                        return [
                            columns.map((column, columnIndex) => {
                                if (columnIndex === 0) {
                                    return '合计：'
                                }
                                if (['elecUsage'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return count.toFixed(2)
                                }

                                if (['amount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return formatMoney(count)
                                }

                                return ''
                            }),
                        ]
                    }
                }
            }
        },
        watch: {
            uploadDrawer: function () {
                this.kindSearch(this.searchObj.kind)
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            intiSubmit() {
                this.searchObj.kind = 'elecReport_01'
                this.kindSearch(this.searchObj.kind)
            },
            submit() {
                this.kindSearch(this.searchObj.kind)
            },
            kindSearch(value) {

                /*check权限*/
                GetAuthority('checkElecReport', this.unionid).then((res) => {
                    this.checkAuthority = res
                })
                /*上传权限*/
                GetAuthority('uploadOfficialUsage', this.unionid).then((res) => {
                    this.uploadAuthority = res
                })

                switch (value) {
                    case 'elecReport_01':

                        GetElecReport_01(this.searchObj).then((res) => {
                            this.reports = res
                            this.columns = [
                                {
                                    resizable: true, title: '总线路损耗表分类汇总表' + ' (' + this.searchObj.month + ')',
                                    children: [
                                        {resizable: true, title: '户号', field: 'accountNum'},
                                        {
                                            resizable: true,
                                            title: '左邻抄表用量',
                                            field: 'zuolin_Usage',
                                            formatter: 'FormatNum'
                                        },
                                        {
                                            resizable: true,
                                            title: '供电局用量',
                                            field: 'official_Usage',
                                            formatter: 'FormatNum'
                                        },
                                        {resizable: true, title: '损耗量', field: 'lossRate', formatter: 'FormatPercent'},
                                        {
                                            resizable: true,
                                            title: '供电局账单',
                                            field: 'official_Amount',
                                            formatter: 'FormatMoney'
                                        },
                                    ]
                                }
                            ]
                            this.footerShow = false

                            GetElecReportKinds(this.searchObj).then((res) => {
                                this.reportsKinds = res
                                this.title = '总线路损耗表分类汇总表'

                                var reportsKind = this.reportsKinds.filter(function (reportKind) {
                                    return reportKind.reportKind === 'elecReport_01'
                                })
                                this.currentReportExist = reportsKind[0].exist

                                if (this.currentReportExist) {
                                    GetElecReportReviewer(this.searchObj).then((res) => {
                                        this.reviewerName = res
                                    })
                                    this.title = this.title + ' (已审核)'

                                    this.tools = [
                                        {
                                            code: 'uploadUsage',
                                            name: '用量上报',
                                            status: 'success',
                                            disabled: this.uploadAuthority ? false : true
                                        }
                                    ]
                                } else {
                                    this.tools = [
                                        {
                                            code: 'uploadUsage',
                                            name: '用量上报',
                                            status: 'success',
                                            disabled: this.uploadAuthority ? false : true
                                        },
                                        {
                                            code: 'check',
                                            name: '审核',
                                            status: 'primary',
                                            disabled: this.checkAuthority ? false : true
                                        },
                                    ]
                                }
                            })

                        })
                        break
                    case 'elecReport_02':
                        GetElecReport_02(this.searchObj).then((res) => {
                            this.reports = res
                            this.columns = [
                                {
                                    resizable: true, title: '月度电耗分类汇总表' + ' (' + this.searchObj.month + ')',
                                    children: [
                                        {resizable: true, title: '户号', field: 'accountNum'},
                                        {
                                            resizable: true,
                                            title: '左邻抄表用量',
                                            field: 'zuolin_Usage',
                                            formatter: 'FormatNum'
                                        },
                                        {
                                            resizable: true,
                                            title: '其中：公摊用量',
                                            field: 'shared_Usage',
                                            formatter: 'FormatNum',
                                            className: 'font-blue'
                                        },
                                        {
                                            resizable: true,
                                            title: '公摊含：商业区中央空调',
                                            field: 'centralAC_Usage',
                                            formatter: 'FormatNum',
                                            className: 'font-blue'
                                        },
                                        {
                                            resizable: true,
                                            title: '实际公摊',
                                            field: 'actualShared_Usage',
                                            formatter: 'FormatNum'
                                        },
                                        {
                                            resizable: true,
                                            title: '空置房用电',
                                            field: 'emptyHouse_Usage',
                                            formatter: 'FormatNum'
                                        },
                                        {
                                            resizable: true,
                                            title: '抄表计费用电量',
                                            field: 'elecBill_Usage',
                                            formatter: 'FormatNum'
                                        },
                                        {
                                            resizable: true,
                                            title: '抄表计费',
                                            field: 'elecBill_Amount',
                                            formatter: 'FormatMoney'
                                        },
                                    ]
                                }
                            ]
                            this.footerShow = false

                            GetElecReportKinds(this.searchObj).then((res) => {
                                this.reportsKinds = res
                                this.title = '月度电耗分类汇总表'

                                var reportsKind = this.reportsKinds.filter(function (reportKind) {
                                    return reportKind.reportKind === 'elecReport_02'
                                })
                                this.currentReportExist = reportsKind[0].exist

                                if (this.currentReportExist) {
                                    GetElecReportReviewer(this.searchObj).then((res) => {
                                        this.reviewerName = res
                                    })
                                    this.title = this.title + ' (已审核)'
                                    this.tools = []
                                } else {
                                    this.tools = [
                                        {
                                            code: 'check',
                                            name: '审核',
                                            status: 'primary',
                                            disabled: this.checkAuthority ? false : true
                                        },
                                    ]
                                }
                            })
                        })

                        break
                    case 'elecReport_03':

                        GetElecReport_03(this.searchObj).then((res) => {
                            this.reports = res
                            this.columns = [
                                {
                                    resizable: true, title: '大用量公摊表同比和环比分析表' + ' (' + this.searchObj.month + ')',
                                    children: [
                                        {resizable: true, title: '表计名称', field: 'meterName'},
                                        {resizable: true, title: '表计编号', field: 'meterId'},
                                        {resizable: true, title: '表计属性', field: 'meterAttribute'},
                                        {resizable: true, title: '用电量', field: 'elecUsage', formatter: 'FormatNum'},
                                        {
                                            resizable: true,
                                            title: '去年同比',
                                            field: 'lastYear_rate',
                                            formatter: 'FormatPercent'
                                        },
                                        {
                                            resizable: true,
                                            title: '上月环比',
                                            field: 'lastMonth_rate',
                                            formatter: 'FormatPercent'
                                        },
                                        {resizable: true, title: '本期备注', field: 'remark'},
                                        {resizable: true, title: '表计负载', field: 'meterLoad'},
                                    ]
                                }
                            ]
                            this.footerShow = false

                            GetElecReportKinds(this.searchObj).then((res) => {
                                this.reportsKinds = res
                                this.title = '大用量公摊表同比和环比分析表'

                                var reportsKind = this.reportsKinds.filter(function (reportKind) {
                                    return reportKind.reportKind === 'elecReport_03'
                                })
                                this.currentReportExist = reportsKind[0].exist

                                if (this.currentReportExist) {
                                    GetElecReportReviewer(this.searchObj).then((res) => {
                                        this.reviewerName = res
                                    })
                                    this.title = this.title + ' (已审核)'
                                    this.tools = []
                                } else {
                                    this.tools = [
                                        {
                                            code: 'check',
                                            name: '审核',
                                            status: 'primary',
                                            disabled: this.checkAuthority ? false : true
                                        },
                                    ]
                                }
                            })
                        })
                        break
                    case 'elecReport_04':
                        GetElecReport_04(this.searchObj).then((res) => {
                            this.reports = res
                            this.columns = [
                                {
                                    resizable: true, title: '用户及房源空置用电分析表' + ' (' + this.searchObj.month + ')',
                                    children: [
                                        {resizable: true, title: '表计属性', field: 'meterAttribute'},
                                        {resizable: true, title: '表计名称', field: 'meterName'},
                                        {resizable: true, title: '表计编号', field: 'meterId'},
                                        {resizable: true, title: '房源', field: 'houseName'},
                                        {resizable: true, title: '房源状态', field: 'houseState'},
                                        {resizable: true, title: '客户名称', field: 'clientName'},
                                        {resizable: true, title: '用电量', field: 'elecUsage', formatter: 'FormatNum'},
                                        {resizable: true, title: '合同开始时间', field: 'contractStartDate'},
                                        {resizable: true, title: '合同结束时间', field: 'contractEndDate'},
                                        {resizable: true, title: '本期备注', field: 'remark'},
                                    ]
                                }
                            ]
                            this.footerShow = true

                            GetElecReportKinds(this.searchObj).then((res) => {
                                this.reportsKinds = res
                                this.title = '用户及房源空置用电分析表'

                                var reportsKind = this.reportsKinds.filter(function (reportKind) {
                                    return reportKind.reportKind === 'elecReport_04'
                                })
                                this.currentReportExist = reportsKind[0].exist

                                if (this.currentReportExist) {
                                    GetElecReportReviewer(this.searchObj).then((res) => {
                                        this.reviewerName = res
                                    })
                                    this.title = this.title + ' (已审核)'
                                    this.tools = []
                                } else {
                                    this.tools = [
                                        {
                                            code: 'check',
                                            name: '审核',
                                            status: 'primary',
                                            disabled: this.checkAuthority ? false : true
                                        },
                                    ]
                                }
                            })
                        })
                        break
                    case 'elecReport_05':
                        GetElecReport_05(this.searchObj).then((res) => {
                            this.reports = res
                            this.columns = [
                                {
                                    resizable: true, title: '商业区中央空调耗电核算分析' + ' (' + this.searchObj.month + ')',
                                    children: [
                                        {resizable: true, title: '表计属性', field: 'meterAttribute'},
                                        {resizable: true, title: '表计名称', field: 'meterName'},
                                        {resizable: true, title: '表计编号', field: 'meterId'},
                                        {resizable: true, title: '用电量', field: 'elecUsage', formatter: 'FormatNum'},
                                        {resizable: true, title: '计费单价', field: 'unitPrice', formatter: 'FormatMoney'},
                                        {resizable: true, title: '计费金额', field: 'amount', formatter: 'FormatMoney'},
                                        {resizable: true, title: '电表负载', field: 'meterLoad'},
                                    ]
                                }
                            ]
                            this.footerShow = true

                            GetElecReportKinds(this.searchObj).then((res) => {
                                this.reportsKinds = res
                                this.title = '商业区中央空调耗电核算分析'

                                var reportsKind = this.reportsKinds.filter(function (reportKind) {
                                    return reportKind.reportKind === 'elecReport_05'
                                })
                                this.currentReportExist = reportsKind[0].exist

                                if (this.currentReportExist) {
                                    GetElecReportReviewer(this.searchObj).then((res) => {
                                        this.reviewerName = res
                                    })
                                    this.title = this.title + ' (已审核)'
                                    this.tools = []
                                } else {
                                    this.tools = [
                                        {
                                            code: 'check',
                                            name: '审核',
                                            status: 'primary',
                                            disabled: this.checkAuthority ? false : true
                                        },
                                    ]
                                }
                            })
                        })
                        break
                }
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.kindSearch(this.searchObj.kind)
                        break
                    case 'export-plus':
                        this.$refs.xTable.openExport()
                        break
                    case 'print':
                        this.$refs.xTable.openPrint()
                        break
                    case 'zoom':
                        this.$refs.xTable.zoom()
                        break
                }
            },
            toolbarToolClickEvent({code}) {
                switch (code) {
                    case 'uploadUsage':
                        this.uploadDrawerTitle = this.searchObj.month + ' - 用量上报'
                        this.uploadDrawer = true
                        break
                    case 'check':
                        /*this.dialog = true*/
                        this.$alert('是否确认无误？', '报表审核', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            showCancelButton: true,
                            callback: action => {
                                if (action === 'confirm') {
                                    InsertElecReports(this.unionid, this.searchObj).then((res) => {
                                        if (res === 1) {
                                            var detail = this.searchObj.projName + ',' + this.searchObj.kind + ',' + this.searchObj.month
                                            InsertOperationRecord(this.unionid, 'insertElecReport', detail).then((res) => {
                                                if (res === 1) {
                                                    this.kindSearch(this.searchObj.kind)
                                                    this.dialog = false
                                                }
                                            })
                                        }
                                    })
                                }
                            }
                        });
                        break
                }
            },
            /*confirmSubmit() {

            },*/
            cellClick({row}) {
                switch (this.searchObj.kind) {
                    case 'elecReport_01':
                        this.accountNum = row.accountNum
                        this.drawerTitle = row.accountNum + ' - 终端表计'
                        this.drawer = true
                        break
                    case 'elecReport_02':
                        break
                    case 'elecReport_03':
                        this.elecId = row.elecId
                        this.drawerTitle = row.meterAttribute + ' - ' + row.meterName + ' - ' + row.meterId
                        this.drawer = true
                        break
                    case 'elecReport_04':
                        this.elecId = row.elecId
                        this.drawerTitle = row.meterAttribute + ' - ' + row.meterName + ' - ' + row.meterId
                        this.drawer = true
                        break
                    case 'elecReport_05':
                        this.elecId = row.elecId
                        this.drawerTitle = row.meterAttribute + ' - ' + row.meterName + ' - ' + row.meterId
                        this.drawer = true
                        break
                }
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetProjectsWithoutAll().then((res) => {
                this.projects = res
            })

            this.kindSearch(this.searchObj.kind)
        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .reportTable >>> .font-blue {
        color: #3dc1d3;
    }

    .reportTable >>> .footer-row {
        background-color: #F8F8F9;
    }

    .reportTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .alert-message {
        padding-right: 10px;
        border: 1px solid #E9EAEC;
        border-top: none;
        background-color: #F8F8F9;
        height: 40px;
        line-height: 40px;
        text-align: right;
    }

</style>
