<template>
    <div id="app">

        <router-view></router-view>

    </div>
</template>

<script>
    import TopNav from './components/TopNav.vue'
    import Home from './views/Home.vue'

    export default {
        name: 'app',
        components: {TopNav, Home}
    }
</script>

<style>

    #app {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    }

    .reject {
        color: white !important;
        background-color: #F56C6C !important;
    }

</style>
