<template>
    <div v-loading="loading">
        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
            <el-collapse-item name="collapseItem" class="form-container">
                <template slot="title">
                    <el-tag type="success" style="width: 100%;padding-left: 15px">
                        <i class="el-icon-search" style="margin-right: 5px"></i>
                        <span>共查询到 {{total}} 条数据</span>
                    </el-tag>
                </template>

                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px" style="padding-left: 15px">

                    <el-form-item label="所属项目:">
                        <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                            <el-option v-for="project in projects" :key="project.abbreviation"
                                       :value="project.projName"
                                       :label="project.projName"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="当前阶段:">
                        <el-select v-model="searchObj.currentStage" placeholder="当前阶段" @change="submit">
                            <el-option v-for="(item,index) in currentStages" :key="index"
                                       :value="item.value"
                                       :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="商机名称:">
                        <el-input v-model="searchObj.businessName" placeholder="商机名称" clearable
                                  @change="submit"></el-input>
                    </el-form-item>

                </el-form>
            </el-collapse-item>
        </el-collapse>

        <!-- 表格 -->
        <div :style="tableContainerStyle">
            <vxe-grid ref="xTable"
                      id="businessTable"
                      class="businessTable"
                      v-bind="businessTable"
                      @toolbar-button-click="toolbarButtonClickEvent"
                      @toolbar-tool-click="toolbarToolClickEvent"
                      @cell-click="cellClick"
                      @page-change="PageChange">
                <template #operate="{ row }">
                    <el-button type="primary" size="mini" plain @click="editClick(row)">编辑</el-button>
                    <el-button type="warning" size="mini" plain @click="followUpClick(row)">跟进</el-button>
                </template>
            </vxe-grid>
        </div>

        <!-- detail 抽屉 -->
        <el-drawer :visible.sync="detailDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="detailDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <BusinessInfo :id="id"></BusinessInfo>
        </el-drawer>

        <!-- edit 抽屉 -->
        <el-drawer :visible.sync="editDrawer" size="50%" destroy-on-close
                   :close-on-press-escape="false"
                   :wrapperClosable="false">
            <template slot="title">
                <el-alert
                        :title="editDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <BusinessEdit :id="id" @businessEdit="businessEdit"></BusinessEdit>
        </el-drawer>

        <!-- followUp 抽屉 -->
        <el-drawer :visible.sync="followUpDrawer" size="50%" destroy-on-close
                   :close-on-press-escape="false"
                   :wrapperClosable="false">
            <template slot="title">
                <el-alert
                        :title="followUpDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <BusinessFollowUp :id="id" @businessFollowUp="businessFollowUp"></BusinessFollowUp>
        </el-drawer>

        <!-- add 抽屉 -->
        <el-drawer :visible.sync="addDrawer" size="50%" destroy-on-close
                   :close-on-press-escape="false"
                   :wrapperClosable="false">
            <template slot="title">
                <el-alert
                        title="新增商机"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <BusinessInfoAdd @businessInfoAdd="businessInfoAdd"></BusinessInfoAdd>
        </el-drawer>


    </div>
</template>

<script>
    import {GetCurrentStages, GetMyBusinessList, GetMyBusinessListCount, GetProjects} from "../../api";
    import BusinessInfo from './BusinessInfo'
    import BusinessEdit from './BusinessEdit'
    import BusinessFollowUp from './BusinessFollowUp'
    import BusinessInfoAdd from './BusinessInfoAdd'
    import XEUtils from 'xe-utils'

    export default {
        name: "BusinessManagement",
        components: {BusinessInfo, BusinessEdit, BusinessFollowUp, BusinessInfoAdd},
        data() {
            return {
                loading: false,
                unionid: null,
                projects: [],
                detailDrawer: false,
                detailDrawerTitle: '',
                editDrawer: false,
                editDrawerTitle: '',
                followUpDrawer: false,
                followUpDrawerTitle: '',
                addDrawer: false,
                searchObj: {
                    projName: '全部',
                    currentStage: '全部',
                    businessName: ''
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                businesses: [],
                currentStages: [],
                id: null,
                currentPage: 1,
                pageSize: 50,
                total: 0
            }
        },
        watch: {
            detailDrawer: function (val, oldVal) {
                if (!val) {
                    this.GetInfo()
                }
            }
        },
        computed: {
            businessTable() {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'print', name: '打 印', icon: 'vxe-icon--print'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'},
                        ],
                        tools: [
                            {code: 'add', name: '新增商机', status: 'success'},
                        ],
                        size: 'medium',
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    printConfig: {
                        modes: ['current'],
                        useStyle: true
                    },
                    pagerConfig: {
                        layouts: ['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Sizes', 'Total'],
                        pageSizes: [10, 20, 50, 100],
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        total: this.total
                    },
                    data: this.businesses,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {
                            resizable: true, title: '创建时间', field: 'createTime', formatter: function ({cellValue}) {
                                return XEUtils.toDateString(cellValue, 'yyyy-MM-dd')
                            }
                        },
                        {resizable: true, title: '项目', field: 'projName'},
                        {resizable: true, title: '类别', field: 'kind', width: '150'},
                        {resizable: true, title: '商机名称', field: 'businessName', className: 'font-blue'},
                        {resizable: true, title: '当前阶段', field: 'currentStage'},
                        {resizable: true, title: '最近跟进时间', field: 'followUpDate'},
                        {
                            resizable: true, title: '距今', formatter: function ({row}) {
                                return XEUtils.getDateDiff(new Date(row.followUpDate).getTime(), new Date().getTime()).dd == undefined ?
                                    '' : (XEUtils.getDateDiff(new Date(row.followUpDate).getTime(), new Date().getTime()).time / 86400000).toFixed(0) + ' 天前'
                            }
                        },
                        {title: '操作', fixed: 'right', width: 150, slots: {default: 'operate'}}
                    ]
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            GetInfo() {
                this.loading = true
                GetMyBusinessListCount(this.unionid, this.searchObj).then((res) => {
                    this.total = res
                    GetMyBusinessList(this.unionid, this.searchObj, this.pageSize, this.currentPage).then((res) => {
                        this.businesses = res
                        this.loading = false
                    })
                })
            },
            submit() {
                this.currentPage = 1
                this.pageSize = 50
                this.GetInfo()
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        this.$refs.xTable.openExport()
                        break
                    case 'print':
                        this.$refs.xTable.openPrint()
                        break
                    case 'zoom':
                        this.$refs.xTable.zoom()
                        break

                }
            },
            toolbarToolClickEvent({code}) {
                switch (code) {
                    case 'add':
                        this.addDrawer = true
                        break
                }
            },
            cellClick({row, column}) {
                if (column.field == null) {

                } else {
                    this.id = row.id
                    this.detailDrawerTitle = row.businessName + ' - 详情'
                    this.detailDrawer = true
                }
            },
            editClick(row) {
                this.id = row.id
                this.editDrawerTitle = row.businessName + ' - 编辑'
                this.editDrawer = true
            },
            followUpClick(row) {
                this.id = row.id
                this.followUpDrawerTitle = row.businessName + ' - 跟进'
                this.followUpDrawer = true
            },
            businessEdit() {
                this.GetInfo()
                this.editDrawer = false
            },
            businessFollowUp() {
                this.GetInfo()
                this.followUpDrawer = false
            },
            businessInfoAdd() {
                this.submit()
                this.addDrawer = false
            },
            PageChange({currentPage, pageSize}) {
                this.currentPage = currentPage
                this.pageSize = pageSize
                this.GetInfo()
            },
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetProjects().then((res) => {
                this.projects = res
            })

            GetCurrentStages().then((res) => {
                this.currentStages.push({
                    label: '全部',
                    value: '全部'
                })

                for (var i = 0; i < res.length; i++) {
                    this.currentStages.push({
                        label: res[i].name,
                        value: res[i].name
                    })
                }
            })

            this.submit()
        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .businessTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .businessTable >>> .font-blue {
        color: #45aaf2;
    }

</style>
