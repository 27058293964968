<template>
    <div class="container" v-loading="loading">

        <div v-if="payment != null">
            <el-descriptions title="支付信息"
                             labelClassName="label"
                             class="description"
                             :column="2"
                             border
                             style="margin-bottom: 20px">
                <el-descriptions-item label="客户名称">{{payment.user.clientName}}</el-descriptions-item>
                <el-descriptions-item label="所属项目">{{payment.user.projName}}</el-descriptions-item>
                <el-descriptions-item label="客户编号">{{payment.user.clientId}}</el-descriptions-item>
                <el-descriptions-item label="微信绑定手机号">{{payment.user.telephone}}</el-descriptions-item>
                <el-descriptions-item label="用户名称">{{payment.userInfo.name}}</el-descriptions-item>
                <el-descriptions-item label="联系电话">{{payment.userInfo.phone}}</el-descriptions-item>

                <el-descriptions-item label="账单月份">{{payment.elecPayment.billMonth}}</el-descriptions-item>
                <el-descriptions-item label="支付金额">
                    <el-tag size="small">{{MoneyFormat(payment.elecPayment.amount)}}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="手续费">
                    <el-tag type="warning" size="small">{{MoneyFormat(payment.adapay.payments[0].fee_amt)}}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="订单号">{{payment.elecPayment.orderNo}}</el-descriptions-item>
                <el-descriptions-item label="支付流水号">{{payment.elecPayment.orderId}}</el-descriptions-item>
                <el-descriptions-item label="第三方订单号">{{payment.adapay.payments[0].party_order_id}}
                </el-descriptions-item>
                <el-descriptions-item label="微信订单号">{{payment.adapay.payments[0].out_trans_id}}</el-descriptions-item>
                <el-descriptions-item label="时间">{{payment.elecPayment.createTime}}</el-descriptions-item>
            </el-descriptions>

            <el-descriptions title="关联账单"
                             labelClassName="label"
                             class="description"
                             :column="2"
                             border
                             style="margin-bottom: 20px" v-if="detail != null">

                <el-descriptions-item label="账单月份">{{detail.billMonth}}</el-descriptions-item>
                <el-descriptions-item label="账单码">{{detail.billCode}}</el-descriptions-item>

                <el-descriptions-item label="账单类型">{{detail.u8fee}}</el-descriptions-item>
                <el-descriptions-item label="房源">{{detail.houseName}}</el-descriptions-item>

                <el-descriptions-item label="原金额">{{MoneyFormat(detail.amount)}}</el-descriptions-item>
                <el-descriptions-item label="减免金额">{{MoneyFormat(detail.reliefAmount)}}</el-descriptions-item>

                <el-descriptions-item label="应收金额">{{MoneyFormat(detail.amount - detail.reliefAmount)}}
                </el-descriptions-item>
                <el-descriptions-item label="实际收款">
                    <el-tag v-if="detail.collectionCheck == null" size="small" type="danger">未收款</el-tag>
                    <el-tag v-else size="small">{{MoneyFormat(detail.receivedAmount)}}</el-tag>
                </el-descriptions-item>

                <el-descriptions-item label="收费周期">{{detail.chargeContent}}</el-descriptions-item>
                <el-descriptions-item label="应收日期">{{detail.dueDate}}</el-descriptions-item>

                <el-descriptions-item label="制单人">{{detail.maker}}</el-descriptions-item>
                <el-descriptions-item label="审核人">{{detail.reviewer}}</el-descriptions-item>

                <el-descriptions-item label="制单时间">{{detail.createTime}}</el-descriptions-item>
                <el-descriptions-item label="最近修改">{{detail.changeTime}}</el-descriptions-item>

                <el-descriptions-item label="结算方式">
                    <el-tag v-if="detail.collectionCheck == null && detail.paymentMethod=='2'" size="small" type="danger">
                        未收款
                    </el-tag>
                    <el-tag v-if="detail.collectionCheck == null && detail.paymentMethod!='2'" size="small" type="danger">
                        {{detail.paymentMethod=='1'?'未收款（江苏稻谷物业管理有限公司苏州分公司）':'未收款（苏州步步高物业管理有限公司）'}}
                    </el-tag>
                    <el-tag v-if="detail.collectionCheck != null && detail.paymentMethod!='2'" size="small">
                        {{detail.paymentMethod=='1'?'江苏稻谷物业管理有限公司苏州分公司':'苏州步步高物业管理有限公司'}}
                    </el-tag>
                    <el-tag v-if="detail.collectionCheck != null && detail.paymentMethod=='2'" size="small" type="info">现金
                    </el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="收款人">
                    <el-tag v-if="detail.collectionCheck == null" size="small" type="danger">未收款</el-tag>
                    <el-tag v-else size="small">{{detail.collectionCheck + ' ' + detail.collectionCheckDate}}</el-tag>
                </el-descriptions-item>

                <el-descriptions-item label="开票信息" :span="2">
                    <el-tag v-if="detail.paymentMethod == '2'" size="small" type="info">不可开票</el-tag>
                    <el-tag v-if="detail.invoiceInfo == 'empty' " size="small" type="warning">无需开票</el-tag>
                    <el-tag v-if="detail.invoiceInfo == null && detail.paymentMethod == '1'" size="small" type="danger">
                        未开票
                    </el-tag>
                    <el-tag v-if="detail.invoiceInfo != null && detail.invoiceInfo != 'empty'" size="small">
                        {{detail.invoiceInfo}}
                    </el-tag>
                </el-descriptions-item>

            </el-descriptions>
        </div>
    </div>
</template>

<script>
    import {GetElecPaymentDetail, GetWuyeBillDetail} from "../../api";
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "WyMpPaymentDetail",
        props: {
            id: {
                type: Number,
                required: true
            },
            billId: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                loading: false,
                payment: null,
                detail: null
            }
        },
        methods: {
            MoneyFormat(value) {
                return formatMoney(value)
            }
        },
        created() {

            this.loading = true
            GetElecPaymentDetail(this.id).then((res) => {

                console.log(res)

                this.payment = res

                if (this.payment.user == null) {
                    this.payment.user = {}
                }
                if (this.payment.userInfo == null) {
                    this.payment.userInfo = {}
                }

                if (this.billId != undefined && this.billId != null) {
                    GetWuyeBillDetail(this.billId).then((res) => {
                        this.detail = res
                        this.loading = false
                    })
                } else {
                    this.loading = false
                }
            })
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

</style>
