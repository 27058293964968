<template>

    <div class="container">
        {{projName}}
        {{billMonth}}
    </div>

</template>

<script>
    export default {
        name: "WuyeBillSend",
        props: {
            projName: {
                type: String,
                required: true
            },
            billMonth: {
                type: String,
                required: true
            }
        },
        data() {
            return {}
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

</style>
