<template>
    <div class="container" v-loading="loading">

        <el-descriptions title="用户信息"
                         labelClassName="label"
                         class="description"
                         :column="1"
                         border
                         style="margin-bottom: 20px">

            <el-descriptions-item label="姓名">{{userInfo.name}}</el-descriptions-item>
            <el-descriptions-item label="性别">{{userInfo.sex?'女':'男'}}</el-descriptions-item>
            <el-descriptions-item label="电话">{{userInfo.phone}}</el-descriptions-item>
            <el-descriptions-item label="邮箱">{{userInfo.email}}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{userInfo.time}}</el-descriptions-item>

        </el-descriptions>

        <el-divider content-position="left">客户关系</el-divider>

        <vxe-grid ref="xTable"
                  id="wuyeUserLinkedClientTable"
                  class="wuyeUserLinkedClientTable"
                  v-bind="wuyeUserLinkedClientTable"
                  @cell-click="cellClick">

            <template #state="{ row }">
                <el-tag v-if="row.state" type="success" size="small">生效中</el-tag>
                <el-tag v-if="!row.state && row.await" type="primary" size="small">待审核</el-tag>
                <el-tag v-if="!row.state && !row.await" type="danger" size="small">已解绑</el-tag>
            </template>


        </vxe-grid>


    </div>

</template>

<script>
    import {GetWuyeUserDetail} from "../../api";

    export default {
        name: "WyMpUserDetail",
        props: {
            userUnionid: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                userInfo: {},
                clients: []
            }
        },
        computed: {
            wuyeUserLinkedClientTable: function () {
                return {
                    height: '540',
                    showOverflow: true,
                    size: 'small',
                    rowConfig: {
                        isHover: true
                    },
                    data: this.clients,
                    columns: [
                        {resizable: true, title: '项目', field: 'projName'},
                        {resizable: true, title: '客户名称', field: 'clientName'},
                        {resizable: true, title: '绑定手机号', field: 'telephone'},
                        {resizable: true, title: '当前状态', field: 'state', slots: {default: 'state'}}
                    ]
                }
            }
        },
        methods: {
            cellClick({row}) {

            }
        },
        created() {
            this.loading = true
            GetWuyeUserDetail(this.userUnionid).then((res) => {
                this.userInfo = res.userInfo
                this.clients = res.clients
                this.loading = false
            })
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

</style>
