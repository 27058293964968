<template>
    <div class="container">

        <el-form :model="addForm" label-width="80px" size="medium">

            <el-form-item label="客户名称">
                <el-input v-model="addForm.clientName" disabled></el-input>
            </el-form-item>

            <el-form-item label="账单月份">
                <el-input v-model="addForm.month" disabled></el-input>
            </el-form-item>

            <el-form-item label="电费类型">
                <el-select v-model="addForm.kind" placeholder="请选择电费类型" style="width: 100%" @change="checkAble">
                    <el-option label="空调电费" value="空调电费"></el-option>
                    <el-option label="电费" value="电费"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="账单金额">
                <el-input-number v-model="addForm.amount"
                                 :precision="2" :step="0.01" :min="0" @change="checkAble">
                </el-input-number>
            </el-form-item>

            <el-form-item label="备注" required>
                <el-input type="textarea" v-model="addForm.remark" clearable @input="checkAble"></el-input>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" @click="submit" :disabled="!able">提交</el-button>
            </el-form-item>

        </el-form>

    </div>
</template>

<script>
    import {InsertOperationRecord, InsertOtherBill, UpdateElecBillAmount} from "../../api";

    export default {
        name: "OtherElecBillAdd",
        data() {
            return {
                unionid: null,
                addForm: {
                    billType: 'elec',
                    clientName: null,
                    clientId: null,
                    month: null,
                    kind: null,
                    amount: null,
                    remark: null
                },
                able: false
            }
        },
        props: {
            clientId: {
                type: String,
                required: true
            },
            month: {
                type: String,
                required: true
            },
            clientName: {
                type: String,
                required: true
            },
            amount: {
                type: Number,
                required: true
            }
        },
        methods: {
            checkAble() {
                var obj = this.addForm
                if (obj.clientId == null
                    || obj.kind == null
                    || obj.amount === 0
                    || obj.remark == null
                    || String(obj.remark).trim() === '') {
                    this.able = false
                } else {
                    this.able = true
                }
            },
            submit() {
                /*先 insert => update => operationRecord */
                InsertOtherBill(this.addForm).then((res) => {
                    if (res === 1) {
                        var newAmount = this.amount + this.addForm.amount
                        UpdateElecBillAmount(this.clientId, this.month, newAmount, null).then((res) => {
                            if (res === 1) {
                                var detail = this.clientId + ',' + this.month
                                InsertOperationRecord(this.unionid, 'insertOtherBill', detail).then((res) => {
                                    if (res === 1) {
                                        /*关闭当前窗口*/
                                        this.$emit('OtherElecBillAdd')
                                    }
                                })
                            }
                        })
                    }
                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')
            this.addForm.month = this.month
            this.addForm.clientId = this.clientId
            this.addForm.clientName = this.clientName
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

</style>
