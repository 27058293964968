<template>

    <div>

        <div v-loading="loading" v-if="authority">

            <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
                <el-collapse-item name="collapseItem" class="form-container">
                    <template slot="title">
                        <el-tag type="success" style="width: 100%;padding-left: 15px">
                            <i class="el-icon-search" style="margin-right: 5px"></i>
                            <span v-if="searchObj.type === '车位档案'">共查询到 {{parkings.length}} 条记录</span>
                            <span v-else>共查询到 {{total}} 条记录</span>
                        </el-tag>
                    </template>

                    <el-form :inline="true"
                             :model="searchObj"
                             class="form"
                             size="small"
                             label-width="72px">

                        <el-form-item>
                            <el-radio-group v-model="searchObj.type" size="small" @change="submit">
                                <el-radio-button label="车位档案"></el-radio-button>
                                <el-radio-button label="车位记录"></el-radio-button>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="所属项目:" style="margin-left: 10px">
                            <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                                <el-option v-for="project in projects" :key="project.abbreviation"
                                           :value="project.projName"
                                           :label="project.projName"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="车位编号:">
                            <el-input v-model="searchObj.parkingNo" placeholder="车位编号" clearable
                                      @change="submit"></el-input>
                        </el-form-item>

                        <el-form-item label="客户名称:">
                            <el-input v-model="searchObj.clientName" placeholder="客户名称" clearable
                                      @change="submit"></el-input>
                        </el-form-item>

                    </el-form>
                </el-collapse-item>
            </el-collapse>

            <!-- 表格 -->
            <div :style="tableContainerStyle">

                <vxe-grid ref="xTable" v-if="searchObj.type === '车位档案'"
                          id="parkingTable"
                          class="parkingTable"
                          v-bind="parkingTable"
                          @toolbar-button-click="toolbarButtonClickEvent"
                          @cell-click="cellClick">
                </vxe-grid>


                <vxe-grid ref="xTable2" v-else
                          id="parkingRecordTable"
                          class="parkingRecordTable"
                          v-bind="parkingRecordTable"
                          @toolbar-button-click="toolbarButtonClickEvent2"
                          @toolbar-tool-click="toolbarToolClickEvent"
                          @cell-click="cellClick2"
                          @checkbox-change="CheckboxChange"
                          @page-change="PageChange">

                    <template #generate="{ row }">
                        <el-tag v-if="row.generate" type="success" size="small">已同步至账单</el-tag>
                        <el-tag v-else type="danger" size="small">未同步至账单</el-tag>
                    </template>

                </vxe-grid>

            </div>

        </div>

        <div v-else>
            <el-result style="height: -webkit-calc(100vh - 250px)"
                       icon="warning" title="无权限"
                       subTitle="抱歉，无权限操作">
            </el-result>
        </div>


        <!-- 车位 详情-->
        <el-drawer :visible.sync="detailDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="detailDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <ParkingDetail :parkingNo="parkingNo" :projName="searchObj.projName"></ParkingDetail>
        </el-drawer>

        <!-- 记录详情 -->
        <el-drawer :visible.sync="recordDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="recordDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <ParkingRecordDetail :recordId="recordId"></ParkingRecordDetail>
        </el-drawer>

        <!-- 车位登记 -->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close
                   :close-on-press-escape="false"
                   :wrapperClosable="false">
            <template slot="title">
                <el-alert
                        title="车位登记"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <ParkingRegister :projName="searchObj.projName"
                             @ParkingRecordAddFormClose="InsertParkingRecord"></ParkingRegister>
        </el-drawer>

        <!-- 同步账单 -->
        <el-drawer :visible.sync="generateDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="generateDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <ParkingGenerate :ids="selectIds.join(',')"
                             @ParkingRecordGenerate="ParkingRecordGenerate"></ParkingGenerate>
        </el-drawer>

    </div>

</template>

<script>
    import {
        GetParkingList,
        GetParkingRecordCount,
        GetParkingRecordList,
        GetProjectsWithoutAll,
        GetWuyeAuthority
    } from "../../api";
    import ParkingDetail from "./ParkingDetail"
    import ParkingRecordDetail from "./ParkingRecordDetail"
    import ParkingRegister from "./ParkingRegister"
    import ParkingGenerate from "./ParkingGenerate"

    export default {
        name: "ParkingManagement",
        components: {ParkingDetail, ParkingRecordDetail, ParkingRegister, ParkingGenerate},
        data() {
            return {
                loading: false,
                unionid: null,
                authority: true,
                projects: [],
                searchObj: {
                    type: '车位记录',
                    projName: '稻谷国际中心',
                    parkingNo: '',
                    clientName: ''
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                parkings: [],
                attributeKinds: [
                    {label: '产权车位', value: '产权车位'},
                    {label: '专用车位', value: '专用车位'},
                    {label: '公用车位', value: '公用车位'},
                    {label: '其他', value: '其他'}
                ],
                detailDrawer: false,
                detailDrawerTitle: '',
                parkingNo: null,
                currentPage: 1,
                pageSize: 50,
                total: 0,
                parkingRecords: [],
                recordDrawer: false,
                recordDrawerTitle: '',
                recordId: null,
                drawer: false,
                generateDrawer: false,
                generateDrawerTitle: '',
                selectIds: []
            }
        },
        watch: {
            detailDrawer: function (val, oldVal) {
                if (!val) {
                    this.submit()
                }
            }
        },
        computed: {
            parkingTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'}
                        ],
                        size: 'medium'
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.parkings,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '位置', field: 'position'},
                        {resizable: true, width: '80', title: '区域', field: 'region'},
                        {resizable: true, width: '80', title: '结构', field: 'kind'},
                        {resizable: true, width: '100', title: '编号', field: 'parkingNo', className: 'font-blue'},
                        {
                            resizable: true, title: '属性', field: 'parkingAttribute',
                            filters: this.attributeKinds,
                            filterMethod: this.AttributeFilter
                        },
                        {resizable: true, title: '月租金', field: 'rentPrice', formatter: 'FormatMoney'},
                        {resizable: true, title: '月管理费', field: 'managementPrice', formatter: 'FormatMoney'},
                        {resizable: true, title: '当前客户', field: 'nowClient', className: 'font-blue'},
                        {resizable: true, title: '到期时间', field: 'endDate'}
                    ]
                }
            },
            parkingRecordTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'}
                        ],
                        size: 'medium',
                        tools: [
                            {code: 'add', name: '车位登记', status: 'success'},
                            {code: 'generate', name: '账单同步', status: 'primary', disabled: this.selectIds.length == 0},
                        ]
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    pagerConfig: {
                        layouts: ['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Sizes', 'Total'],
                        pageSizes: [10, 20, 50, 100],
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        total: this.total
                    },
                    checkboxConfig: {
                        showHeader: false,
                        visibleMethod: this.CheckMethod
                    },
                    data: this.parkingRecords,
                    columns: [
                        {type: 'checkbox', field: 'check', title: '', width: 40},
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '车位号', field: 'parkingNo', width: '100', className: 'font-blue'},
                        {resizable: true, title: '开始时间', field: 'startDate'},
                        {resizable: true, title: '结束时间', field: 'endDate'},
                        {resizable: true, title: '月租金', field: 'rentPrice', formatter: 'FormatMoney'},
                        {resizable: true, title: '月管理费', field: 'managementPrice', formatter: 'FormatMoney'},
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {resizable: true, title: '月份', field: 'months'},
                        {resizable: true, title: '金额', field: 'amount', formatter: 'FormatMoney'},
                        {resizable: true, title: '是否生成账单', field: 'generate', slots: {default: 'generate'}}
                    ]
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            AttributeFilter({value, row, column}) {
                if (value === '产权车位' || value === '专用车位' || value === '公用车位') {
                    return value === row.parkingAttribute
                } else {
                    return (row.parkingAttribute != '产权车位' && row.parkingAttribute != '专用车位' && row.parkingAttribute != '公用车位')
                }
            },
            submit() {

                if (this.searchObj.type === '车位档案') {
                    this.loading = true
                    GetParkingList(this.searchObj).then((res) => {
                        this.parkings = res
                        this.loading = false
                    })

                } else {
                    this.loading = true
                    GetParkingRecordCount(this.searchObj.projName).then((res) => {
                        this.currentPage = 1
                        this.pageSize = 50
                        this.total = res

                        GetParkingRecordList(this.searchObj, this.pageSize, this.currentPage).then((res) => {
                            this.parkingRecords = res
                            this.loading = false
                        })
                    })
                }
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        this.$refs.xTable.openExport()
                        break
                }
            },
            toolbarButtonClickEvent2({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        this.$refs.xTable2.openExport()
                        break
                }
            },
            toolbarToolClickEvent({code}) {
                switch (code) {
                    case 'add':
                        this.drawer = true
                        break
                    case 'generate':
                        this.generateDrawer = true
                        this.generateDrawerTitle = '同步至账单'

                }
            },
            cellClick({row}) {
                this.detailDrawer = true
                this.detailDrawerTitle = row.parkingNo + ' - 车位详情'
                this.parkingNo = row.parkingNo
            },
            cellClick2({column, row}) {

                if (column.field != 'check') {
                    this.recordDrawer = true
                    this.recordDrawerTitle = row.parkingNo + ' - 车位记录'
                    this.recordId = row.id
                }
            },
            PageChange({currentPage, pageSize}) {
                this.currentPage = currentPage
                this.pageSize = pageSize

                this.loading = true
                GetParkingRecordList(this.searchObj, this.pageSize, this.currentPage).then((res) => {
                    this.parkingRecords = res
                    this.loading = false
                })

            },
            InsertParkingRecord() {
                this.drawer = false
                this.submit()
            },
            ParkingRecordGenerate() {
                this.generateDrawer = false
                this.submit()
            },
            CheckMethod({row}) {
                if (row.generate) {
                    return false
                } else {
                    return true
                }
            },
            CheckboxChange() {
                var a = this.$refs.xTable2.getCheckboxRecords(true)

                this.selectIds = []

                for (var i = 0; i < a.length; i++) {
                    this.selectIds.push(a[i].id)
                }
            }
        },
        created() {


            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeAuthority(this.unionid).then((res) => {

                /* 权限控制 */
                if (res.authorities.indexOf('parkingMGMT') != -1) {
                    this.authority = true
                } else {
                    this.authority = false
                }

                this.searchObj.projName = '稻谷国际中心'
                if (res.projName === 'ALL') {
                    GetProjectsWithoutAll().then((res) => {
                        this.projects = res
                    })
                } else {
                    this.projects = []
                    var arr = res.projName.split(',')

                    this.searchObj.projName = arr[0]

                    for (var i = 0; i <= arr.length - 1; i++) {
                        this.projects.push({
                            projName: arr[i]
                        })
                    }
                }

                this.submit()
            })
        },
        mounted() {
            this.$nextTick(function () {
                if (this.authority) {
                    this.bigHeight = this.$refs.collapse.$el.offsetHeight
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                }
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .parkingTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .parkingTable >>> .font-blue {
        color: #45aaf2;
    }

    .parkingRecordTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .parkingRecordTable >>> .font-blue {
        color: #45aaf2;
    }

</style>
