<template>
    <div class="container">

        <el-tabs v-model="tabName">
            <el-tab-pane label="基本信息" name="first">

                <div v-if="!client.state">

                    <el-row>
                        <el-col :span="24">
                            <el-input v-model="clientCode" placeholder="请输入U8客户编码，保存后生效" clearable>

                                <el-button type="success" slot="append" icon="el-icon-upload" @click="SaveClientCode"
                                           :disabled="btnDisabled">保存
                                </el-button>

                            </el-input>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                </div>

                <transition name="el-zoom-in-top">
                    <div v-show="formShow">
                        <el-form ref="wuyeClientDetailForm" :model="form" :rules="rules" label-width="100px"
                                 size="medium">

                            <el-form-item style="text-align: right">
                                <el-button size="small" plain @click="Cancel" :disabled="btnDisabled">取消</el-button>
                                <el-button size="small" type="success" @click="Save" :disabled="btnDisabled">保存
                                </el-button>
                            </el-form-item>

                            <el-divider content-position="left">收款公司</el-divider>

                            <el-form-item label="收款公司" required prop="paymentMethod">
                                <el-select v-model="form.paymentMethod"
                                           placeholder="选择收款公司" style="width: 100%">
                                    <el-option value="1" label="江苏稻谷物业管理有限公司苏州分公司"></el-option>
                                    <el-option value="3" label="苏州步步高物业管理有限公司"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-divider content-position="left">客户信息</el-divider>

                            <el-form-item label="客户属性" required prop="attribute">
                                <el-select v-model="form.attribute"
                                           clearable multiple filterable
                                           placeholder="选择客户属性" style="width: 100%">

                                    <el-option v-for="attribute in attributes" :key="attribute.clientAttributeCode"
                                               :value="attribute.clientAttribute"
                                               :label="attribute.clientAttribute"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="联系人" required prop="contactPerson">
                                <el-input v-model="form.contactPerson" placeholder="联系人" clearable></el-input>
                            </el-form-item>

                            <el-form-item label="联系电话" required prop="contactPhone">
                                <el-input v-model="form.contactPhone" placeholder="联系人电话" clearable></el-input>
                            </el-form-item>

                            <el-form-item label="进场时间" required prop="entryDate">
                                <el-date-picker style="width: 100%"
                                                v-model="form.entryDate"
                                                value-format="yyyy-MM-dd"
                                                type="date"
                                                placeholder="选择进场时间" @change="check">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="退场时间" prop="exitDate">
                                <el-date-picker style="width: 100%"
                                                v-model="form.exitDate"
                                                type="date"
                                                value-format="yyyy-MM-dd"
                                                placeholder="选择退场时间" @change="check">
                                </el-date-picker>
                            </el-form-item>

                            <el-divider content-position="left">企业信息</el-divider>

                            <el-form-item label="企业代码">
                                <el-input v-model="form.enterpriseCode" placeholder="企业代码" clearable></el-input>
                            </el-form-item>

                            <el-form-item label="税号/身份证号">
                                <el-input v-model="form.taxNumber" placeholder="税号" clearable></el-input>
                            </el-form-item>

                            <el-form-item label="开户行">
                                <el-input v-model="form.bank" placeholder="开户行" clearable></el-input>
                            </el-form-item>

                            <el-form-item label="银行账号">
                                <el-input v-model="form.bankAccount" placeholder="银行账号" clearable></el-input>
                            </el-form-item>

                            <el-form-item label="地址">
                                <el-input v-model="form.address" placeholder="地址" clearable></el-input>
                            </el-form-item>

                            <el-form-item label="电话">
                                <el-input v-model="form.telephone" placeholder="电话" clearable></el-input>
                            </el-form-item>

                            <el-divider content-position="left">其他</el-divider>

                            <el-form-item label="备注">
                                <el-input
                                        type="textarea"
                                        :autosize="{ minRows: 4}"
                                        placeholder="备注"
                                        v-model="form.remark">
                                </el-input>
                            </el-form-item>



                        </el-form>
                    </div>
                </transition>

                <transition name="el-zoom-in-top">
                    <div v-show="!formShow" class="transition-box">

                        <el-descriptions title="收款公司"
                                         labelClassName="label"
                                         class="description"
                                         :column="1"
                                         border
                                         style="margin-bottom: 20px">

                            <template slot="extra">
                                <el-button type="danger" size="small" plain @click="formShow = true"
                                           :disabled="btnDisabled">编辑
                                </el-button>
                            </template>

                            <el-descriptions-item label="收款公司">
                                {{client.paymentMethod === '1'?'江苏稻谷物业管理有限公司苏州分公司':'苏州步步高物业管理有限公司'}}
                            </el-descriptions-item>

                        </el-descriptions>


                        <el-descriptions title="客户信息"
                                         labelClassName="label"
                                         class="description"
                                         :column="1"
                                         border
                                         style="margin-bottom: 20px">


                            <el-descriptions-item label="项目">{{client.projName}}</el-descriptions-item>
                            <el-descriptions-item label="客户编号">{{client.clientId}}</el-descriptions-item>
                            <el-descriptions-item label="编号缩写">{{client.abbreviation}}</el-descriptions-item>
                            <el-descriptions-item label="客户名称">{{client.clientName}}</el-descriptions-item>
                            <el-descriptions-item label="客户类型">{{client.type===1?'公司客户':'个人客户'}}</el-descriptions-item>
                            <el-descriptions-item label="客户属性">{{client.attribute}}</el-descriptions-item>
                            <el-descriptions-item label="联系人">{{client.contactPerson}}</el-descriptions-item>
                            <el-descriptions-item label="联系电话">{{client.contactPhone}}</el-descriptions-item>
                            <el-descriptions-item label="入场时间">{{client.entryDate}}</el-descriptions-item>
                            <el-descriptions-item label="退场时间">{{client.exitDate}}</el-descriptions-item>
                            <el-descriptions-item label="U8客户代码">{{client.u8clientCode}}</el-descriptions-item>
                        </el-descriptions>

                        <el-descriptions title="企业信息"
                                         labelClassName="label"
                                         class="description"
                                         :column="1"
                                         border
                                         style="margin-bottom: 20px">
                            <el-descriptions-item label="企业代码">{{client.enterpriseCode}}</el-descriptions-item>
                            <el-descriptions-item label="税号/身份证号">{{client.taxNumber}}</el-descriptions-item>
                            <el-descriptions-item label="开户行">{{client.bank}}</el-descriptions-item>
                            <el-descriptions-item label="银行账号">{{client.bankAccount}}</el-descriptions-item>
                            <el-descriptions-item label="地址">{{client.address}}</el-descriptions-item>
                            <el-descriptions-item label="电话">{{client.telephone}}</el-descriptions-item>
                        </el-descriptions>

                        <el-descriptions title="备注"
                                         labelClassName="label"
                                         class="description"
                                         :column="1"
                                         border
                                         style="margin-bottom: 20px">
                            <el-descriptions-item label="备注">{{client.remark}}</el-descriptions-item>
                        </el-descriptions>

                    </div>
                </transition>


            </el-tab-pane>

            <el-tab-pane label="物业合同" name="second">

                <div style="height: -webkit-calc(100vh - 160px)">
                    <vxe-grid ref="xTable"
                              id="wuyeContractTable"
                              class="wuyeContractTable"
                              v-bind="wuyeContractTable"
                              @toolbar-tool-click="toolbarToolClickEvent"
                              @cell-click="cellClick">
                    </vxe-grid>
                </div>

            </el-tab-pane>
        </el-tabs>


        <el-drawer :visible.sync="drawer" size="45%"
                   destroy-on-close
                   :append-to-body="true"
                   :close-on-press-escape="false"
                   :wrapperClosable="false">
            <template slot="title">
                <el-alert
                        title="新增物业合同"
                        type="success"
                        :closable="false">
                </el-alert>
            </template>
            <WuyeContractAdd :projName="client.projName"
                             :projCode="client.u8projCode"
                             :clientId="client.clientId"
                             :clientName="client.clientName" :clientCode="client.u8clientCode"
                             @WuyeContractInsert="WuyeContractInsert"></WuyeContractAdd>
        </el-drawer>

        <el-drawer :visible.sync="detailDrawer" size="45%"
                   destroy-on-close
                   :append-to-body="true">
            <template slot="title">
                <el-alert
                        :title="detailDrawerTitle"
                        type="success"
                        :closable="false">
                </el-alert>
            </template>

            <WuyeContractDetail :projName="client.projName" :contractNo="contractNo"
                                @WuyeContractUpdate="WuyeContractUpdate"></WuyeContractDetail>
        </el-drawer>


    </div>
</template>

<script>
    import {
        GetWuyeAuthority,
        GetWuyeClientAttributes,
        GetWuyeClientDetail,
        GetWuyeContract,
        TakeEffectWuyeClient,
        UpdateWuyeClient
    } from "../../api";
    import WuyeContractAdd from './WuyeContractAdd'
    import WuyeContractDetail from './WuyeContractDetail'

    export default {
        name: "WuyeClientDetail",
        components: {WuyeContractAdd, WuyeContractDetail},
        props: {
            clientId: {
                type: String,
                required: true
            }
        },
        data() {

            const dateCheck1 = (rule, value, callback) => {

                var start = this.form.entryDate == null ? null : new Date(this.form.entryDate)
                var end = this.form.exitDate == null ? null : new Date(this.form.exitDate)

                if (!value) {
                    return callback(new Error('开始时间不能为空'))
                }

                if (end != null) {
                    if (start > end) {
                        return callback(new Error('开始时间>结束时间'))
                    } else {
                        return callback()
                    }
                } else {
                    return callback()
                }

            }

            const dateCheck2 = (rule, value, callback) => {

                var start = this.form.entryDate == null ? null : new Date(this.form.entryDate)
                var end = this.form.exitDate == null ? null : new Date(this.form.exitDate)

                if (start == null && end != null) {
                    return callback(new Error('开始时间不能为空'))
                } else {

                    if (end != null) {
                        if (start > end) {
                            return callback(new Error('开始时间 > 结束时间'))
                        } else {
                            return callback()
                        }
                    } else {
                        return callback()
                    }

                }
            }

            return {
                unionid: null,
                drawer: false,
                detailDrawer: false,
                detailDrawerTitle: '',
                tabName: 'first',
                formShow: false,
                client: {},
                attributes: [],
                clientCode: null,
                form: {
                    paymentMethod: '',
                    attribute: [],
                    contactPerson: '',
                    contactPhone: '',
                    enterpriseCode: '',
                    taxNumber: '',
                    bank: '',
                    bankAccount: '',
                    address: '',
                    telephone: '',
                    entryDate: null,
                    exitDate: null,
                    remark: ''
                },
                rules: {
                    paymentMethod: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    attribute: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    contactPerson: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    contactPhone: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    entryDate: [
                        {validator: dateCheck1, trigger: 'change'}
                    ],
                    exitDate: [
                        {validator: dateCheck2, trigger: 'change'}
                    ]
                },
                contracts: [],
                contractNo: null,
                btnDisabled: true
            }
        },
        computed: {

            wuyeContractTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        size: 'medium',
                        tools: [
                            {code: 'add', name: '新增合同', status: 'success', disabled: !this.client.state},
                        ]
                    },
                    rowConfig: {
                        isHover: true
                    },
                    data: this.contracts,
                    columns: [
                        {resizable: true, title: '合同编号', field: 'contractNo', className: 'font-blue'},
                        {resizable: true, title: '签订日期', field: 'signDate'},
                        {resizable: true, title: '房源', field: 'houseNames'},
                        {resizable: true, title: '开始日期', field: 'startDate'},
                        {resizable: true, title: '结束日期', field: 'endDate'},
                        {resizable: true, title: '备注', field: 'remark'}
                    ]
                }
            }

        },
        methods: {
            SaveClientCode() {

                if (this.clientCode == null || this.clientCode === '' || this.clientCode.length > 10) {
                    this.$message.error('U8客户编码不能为空或格式不正确！');
                } else {

                    TakeEffectWuyeClient(this.clientId, this.clientCode).then((res) => {
                        if (res === 1) {
                            GetWuyeClientDetail(this.clientId).then((res) => {
                                this.client = res

                                this.form.paymentMethod = res.paymentMethod
                                this.form.attribute = res.attribute == null ? res.attribute : res.attribute.split(',')
                                this.form.contactPerson = res.contactPerson
                                this.form.contactPhone = res.contactPhone
                                this.form.enterpriseCode = res.enterpriseCode
                                this.form.taxNumber = res.taxNumber
                                this.form.bank = res.bank
                                this.form.bankAccount = res.bankAccount
                                this.form.address = res.address
                                this.form.telephone = res.telephone
                                this.form.entryDate = res.entryDate
                                this.form.exitDate = res.exitDate
                                this.form.remark = res.remark
                            })
                        }
                    })

                }
            },
            check() {
                this.$refs.wuyeClientDetailForm.validateField(['entryDate', 'exitDate'], (e) => {
                })
            },
            Cancel() {
                this.form.paymentMethod = this.client.paymentMethod
                this.form.attribute = this.client.attribute.split(',')
                this.form.contactPerson = this.client.contactPerson
                this.form.contactPhone = this.client.contactPhone
                this.form.enterpriseCode = this.client.enterpriseCode
                this.form.taxNumber = this.client.taxNumber
                this.form.bank = this.client.bank
                this.form.bankAccount = this.client.bankAccount
                this.form.address = this.client.address
                this.form.telephone = this.client.telephone
                this.form.entryDate = this.client.entryDate
                this.form.exitDate = this.client.exitDate
                this.form.remark = this.client.remark
                this.formShow = false
            },
            Save() {

                this.$refs.wuyeClientDetailForm.validate((e) => {

                    if (e) {
                        UpdateWuyeClient(this.clientId, this.form).then((res) => {

                            if (res === 1) {
                                GetWuyeClientDetail(this.clientId).then((res) => {
                                    this.client = res

                                    this.form.paymentMethod = res.paymentMethod
                                    this.form.attribute = res.attribute.split(',')
                                    this.form.contactPerson = res.contactPerson
                                    this.form.contactPhone = res.contactPhone
                                    this.form.enterpriseCode = res.enterpriseCode
                                    this.form.taxNumber = res.taxNumber
                                    this.form.bank = res.bank
                                    this.form.bankAccount = res.bankAccount
                                    this.form.address = res.address
                                    this.form.telephone = res.telephone
                                    this.form.entryDate = res.entryDate
                                    this.form.exitDate = res.exitDate
                                    this.form.remark = res.remark

                                    this.formShow = false
                                })
                            }

                        })
                    } else {
                        this.$message.error('有未填项！');
                    }

                })

            },
            toolbarToolClickEvent({code}) {

                if (this.btnDisabled) {
                    this.$message.error('无权限！');
                } else {
                    switch (code) {
                        case 'add':
                            this.drawer = true
                            break
                    }
                }


            },
            cellClick({row}) {
                this.detailDrawerTitle = row.contractNo + ' - 详情'
                this.contractNo = row.contractNo
                this.detailDrawer = true
            },
            WuyeContractUpdate() {
                GetWuyeContract(this.clientId).then((res) => {
                    this.contracts = res
                    this.detailDrawer = false
                })
            },
            WuyeContractInsert() {
                GetWuyeContract(this.clientId).then((res) => {
                    this.contracts = res
                    this.drawer = false
                })
            }
        },
        created() {

            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeAuthority(this.unionid).then((res) => {
                if (res === '' || res == null) {

                } else {
                    /* 权限控制 */
                    if (res.authorities.indexOf('editClient') != -1) {
                        this.btnDisabled = false
                    } else {
                        this.btnDisabled = true
                    }
                }
            })

            GetWuyeClientDetail(this.clientId).then((res) => {

                this.client = res

                this.form.paymentMethod = res.paymentMethod
                this.form.attribute = res.attribute.split(',')
                this.form.contactPerson = res.contactPerson
                this.form.contactPhone = res.contactPhone
                this.form.enterpriseCode = res.enterpriseCode
                this.form.taxNumber = res.taxNumber
                this.form.bank = res.bank
                this.form.bankAccount = res.bankAccount
                this.form.address = res.address
                this.form.telephone = res.telephone
                this.form.entryDate = res.entryDate
                this.form.exitDate = res.exitDate
                this.form.remark = res.remark

            })

            GetWuyeContract(this.clientId).then((res) => {
                this.contracts = res
            })

            GetWuyeClientAttributes().then((res) => {
                this.attributes = res
            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .wuyeContractTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .wuyeContractTable >>> .font-blue {
        color: #45aaf2;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

</style>
