import { render, staticRenderFns } from "./FollowUpEdit.vue?vue&type=template&id=3389f42e&scoped=true&"
import script from "./FollowUpEdit.vue?vue&type=script&lang=js&"
export * from "./FollowUpEdit.vue?vue&type=script&lang=js&"
import style0 from "./FollowUpEdit.vue?vue&type=style&index=0&id=3389f42e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3389f42e",
  null
  
)

export default component.exports