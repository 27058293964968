<template>
    <div class="container" v-loading="loading">

        <el-tabs v-model="tabName" @tab-click="handleClick">

            <el-tab-pane label="拜访情况" name="first">

                <!-- 修改 -->
                <div v-show="formShow">

                    <el-form :model="editForm" label-width="80px" size="medium">

                        <el-form-item label="公司名称">
                            <el-input v-model="dailyReport.companyName" disabled></el-input>
                        </el-form-item>

                        <el-form-item label="类型" required prop="kind">
                            <el-select v-model="editForm.kind" placeholder="是否为客户" style="width: 100%">
                                <el-option label="客户" :value="true"></el-option>
                                <el-option label="非客户" :value="false"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="主营业务" required prop="mainBusiness">
                            <el-input v-model="editForm.mainBusiness" clearable></el-input>
                        </el-form-item>

                        <el-form-item label="地址" required prop="address">
                            <el-input v-model="editForm.address" clearable></el-input>
                        </el-form-item>

                        <el-form-item label="接待人" required prop="receptionist">
                            <el-input v-model="editForm.receptionist" clearable></el-input>
                        </el-form-item>

                        <el-form-item label="电话" required prop="contactInfo">
                            <el-input v-model="editForm.contactInfo" clearable></el-input>
                        </el-form-item>

                        <el-form-item label="微信" required prop="wechat">
                            <el-input v-model="editForm.wechat" clearable></el-input>
                        </el-form-item>

                        <el-divider></el-divider>

                        <el-form-item label="拜访时间" required prop="visitTime">
                            <el-date-picker style="width: 100%"
                                            v-model="editForm.visitTime"
                                            type="datetime"
                                            :clearable="false"
                                            value-format="yyyy-MM-dd hh:mm"
                                            placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="当前阶段" required prop="currentStage">
                            <el-input v-model="editForm.currentStage" clearable></el-input>
                        </el-form-item>

                        <el-form-item label="拜访内容" required prop="visitContent">
                            <el-input
                                    type="textarea"
                                    :autosize="{ minRows: 4}"
                                    placeholder="拜访内容"
                                    v-model="editForm.visitContent">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="跟进人">
                            <el-input v-model="unionName" disabled></el-input>
                        </el-form-item>

                        <el-form-item label="协同人">
                            <el-select v-model="editForm.coordinator"
                                       clearable multiple filterable placeholder="请选择协同人" style="width: 100%">
                                <el-option
                                        v-for="item in employees"
                                        :key="item.unionid"
                                        :label="item.name"
                                        :value="item.unionid">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item style="text-align: right">
                            <el-button type="success" size="small" @click="EditDailyReport">保存</el-button>
                        </el-form-item>

                    </el-form>

                </div>

                <!-- 详情 -->
                <div v-show="!formShow">

                    <el-tag type="success" v-if="dailyReport.haveRead != null" style="width: 100%;margin-bottom: 10px">
                        {{dailyReport.haveRead}} 已读
                    </el-tag>

                    <el-descriptions title="客户信息"
                                     labelClassName="label"
                                     class="description"
                                     :column="1"
                                     border
                                     style="margin-bottom: 20px">
                        <el-descriptions-item label="公司名称">{{dailyReport.companyName}}</el-descriptions-item>
                        <el-descriptions-item label="类型">{{dailyReport.kind?'客户':'非客户'}}</el-descriptions-item>
                        <el-descriptions-item label="主营业务">{{dailyReport.mainBusiness}}</el-descriptions-item>
                        <el-descriptions-item label="地址">{{dailyReport.address}}</el-descriptions-item>
                        <el-descriptions-item label="接待人">{{dailyReport.receptionist}}</el-descriptions-item>
                        <el-descriptions-item label="电话">{{dailyReport.contactInfo}}</el-descriptions-item>
                        <el-descriptions-item label="微信">{{dailyReport.wechat}}</el-descriptions-item>
                    </el-descriptions>

                    <el-descriptions title="拜访情况"
                                     labelClassName="label"
                                     class="description"
                                     :column="1"
                                     border
                                     style="margin-bottom: 20px">
                        <el-descriptions-item label="拜访时间">{{dailyReport.visitTime}}</el-descriptions-item>
                        <el-descriptions-item label="当前阶段">{{dailyReport.currentStage}}</el-descriptions-item>
                        <el-descriptions-item label="拜访内容">{{dailyReport.visitContent}}</el-descriptions-item>
                        <el-descriptions-item label="跟进人">{{unionName}}</el-descriptions-item>
                        <el-descriptions-item label="协同人">{{dailyReport.coordinator}}</el-descriptions-item>
                    </el-descriptions>

                    <div style="text-align: right">
                        <el-button v-if="editable" type="primary" size="small" @click="formShow = true">修改</el-button>
                    </div>

                </div>

            </el-tab-pane>

            <el-tab-pane label="评论" name="second">

                <el-divider content-position="left">发表评论</el-divider>

                <el-input
                        type="textarea"
                        :autosize="{ minRows: 4}"
                        placeholder="请输入评论"
                        v-model="comment.comment" style="margin-bottom: 10px" @input="checkComment">
                </el-input>

                <div style="text-align: right">
                    <el-button size="small" type="primary" @click="submit" :disabled="!commentAble">评论</el-button>
                </div>

                <el-divider content-position="left">评论</el-divider>

                <el-timeline style="padding-left: 2px!important;">
                    <el-timeline-item
                            v-for="commentList in commentLists"
                            :key="commentList.id"
                            :color="commentList.readState?'':'#D77070'"
                            :timestamp="commentList.time" placement="top">
                        <p v-if="commentList.kind === 'comment'">
                            <el-tag size="small" type="danger">{{commentList.senderName}}</el-tag>
                            <span style="color: #b3b1b1"> 评论了：</span>
                        </p>
                        <p v-else>
                            <el-tag size="small" type="danger">{{commentList.senderName}}</el-tag>
                            <span style="color: #b3b1b1"> 回复 </span>
                            <el-tag size="small" type="danger">{{commentList.replyName}}</el-tag>
                            <span style="color: #b3b1b1">：</span>
                        </p>
                        <p>
                            <span>{{commentList.comment}}</span>
                        </p>

                        <DailyReportReply :replayUnionid="commentList.sender"
                                          :id="id" @dailyReportReply="dailyReportReply"></DailyReportReply>

                        <el-divider></el-divider>

                    </el-timeline-item>
                </el-timeline>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import {
        EditDailyReport,
        GetDailyReportComments,
        GetDailyReportDetail,
        GetEmployeeName, GetEmployees,
        InsertDailyReportComment,
        UpdateDailyReportCommentHaveRead, UpdateDailyReportRead
    } from "../../api";
    import DailyReportReply from './DailyReportReply'
    import XEUtils from 'xe-utils'

    export default {
        name: "DailyReportDetail",
        components: {DailyReportReply},
        data() {
            return {
                loading: false,
                formShow: false,
                tabName: null,
                unionid: null,
                unionName: null,
                dailyReport: {},
                comment: {
                    unionid: null,
                    dailyReportId: null,
                    replyUnionid: null,
                    kind: 'comment',
                    comment: null
                },
                commentAble: false,
                commentLists: [],
                editable: false,
                editForm: {
                    visitTime: null,
                    kind: false,
                    mainBusiness: null,
                    address: null,
                    receptionist: null,
                    contactInfo: null,
                    wechat: null,
                    currentStage: null,
                    visitContent: null,
                    coordinator: null
                },
                employees: []
            }
        },
        props: {
            id: {
                type: Number,
                required: true
            },
            followUpId: {
                type: Number,
                required: false,
                default: null
            },
            tab: {
                type: String,
                required: false,
                default: 'first'
            }
        },
        methods: {
            checkComment() {
                if (this.comment.comment != null && String(this.comment.comment).trim() != '') {
                    this.commentAble = true
                } else {
                    this.commentAble = false
                }
            },
            submit() {
                InsertDailyReportComment(this.comment).then((res) => {
                    /*刷新评论*/
                    this.comment.comment = null
                    GetDailyReportComments(this.id, this.unionid).then((res) => {
                        this.commentLists = res
                    })
                })
            },
            dailyReportReply() {
                GetDailyReportComments(this.id, this.unionid).then((res) => {
                    this.commentLists = res
                })
            },
            handleClick(tab, event) {
                if (tab.paneName === 'second') {
                    UpdateDailyReportCommentHaveRead(this.unionid, this.id).then((res) => {

                    })
                }
            },
            EditDailyReport() {

                this.loading = true
                EditDailyReport(this.id, this.editForm).then((res) => {

                    if (res === 1) {
                        GetDailyReportDetail(this.unionid, this.id).then((res) => {


                            this.editable = res.editable

                            this.editForm.visitTime = res.dailyReport.visitTime
                            this.editForm.kind = res.dailyReport.kind
                            this.editForm.mainBusiness = res.dailyReport.mainBusiness
                            this.editForm.address = res.dailyReport.address
                            this.editForm.receptionist = res.dailyReport.receptionist
                            this.editForm.contactInfo = res.dailyReport.contactInfo
                            this.editForm.wechat = res.dailyReport.wechat
                            this.editForm.currentStage = res.dailyReport.currentStage
                            this.editForm.visitContent = res.dailyReport.visitContent
                            this.editForm.coordinator = res.coordinators != null ? res.coordinators.split(',') : null

                            this.dailyReport = res.dailyReport
                            this.comment.replyUnionid = res.dailyReport.unionid

                            this.unionName = res.name

                            /*GetEmployeeName(res.dailyReport.unionid).then((res)=>{
                                this.unionName = res
                            })*/

                            GetEmployees('招商部', res.dailyReport.unionid).then((res) => {
                                this.employees = res
                            })

                            this.formShow = false
                            this.loading = false
                        })
                    }
                })

            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')
            this.tabName = this.tab

            if (this.tab === 'second') {
                UpdateDailyReportCommentHaveRead(this.unionid, this.id).then((res) => {

                })
            }

            this.comment.dailyReportId = this.id
            this.comment.unionid = sessionStorage.getItem('unionid')

            GetDailyReportDetail(this.unionid, this.id).then((res) => {

                this.editable = res.editable

                this.editForm.visitTime = res.dailyReport.visitTime
                this.editForm.kind = res.dailyReport.kind
                this.editForm.mainBusiness = res.dailyReport.mainBusiness
                this.editForm.address = res.dailyReport.address
                this.editForm.receptionist = res.dailyReport.receptionist
                this.editForm.contactInfo = res.dailyReport.contactInfo
                this.editForm.wechat = res.dailyReport.wechat
                this.editForm.currentStage = res.dailyReport.currentStage
                this.editForm.visitContent = res.dailyReport.visitContent
                this.editForm.coordinator = res.coordinators != null ? res.coordinators.split(',') : null

                this.dailyReport = res.dailyReport
                this.comment.replyUnionid = res.dailyReport.unionid

                this.unionName = res.name

                /*GetEmployeeName(res.dailyReport.unionid).then((res)=>{
                    this.unionName = res
                })*/

                if (this.followUpId == null) {

                } else {
                    UpdateDailyReportRead(this.unionid, this.followUpId).then((res) => {

                    })
                }

                GetEmployees('招商部', res.dailyReport.unionid).then((res) => {
                    this.employees = res
                })
            })

            GetDailyReportComments(this.id, this.unionid).then((res) => {
                this.commentLists = res
            })
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

</style>
