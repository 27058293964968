<template>
    <div class="container">

        <!--表单-->
        <div v-if="formShow">
            <el-form ref="formClientHouse" :model="formObj" :rules="rules" label-width="80px" size="medium">

                <el-alert type="info" :closable="false" style="margin-bottom: 20px"
                          title="合同信息（注：“开始时间” 必填，否则房源不可选。“结束时间” 选填）">
                </el-alert>
                <el-form-item label="合同编号">
                    <el-input v-model="formObj.contractId"></el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="开始时间" prop="contractDate">
                            <el-date-picker style="width: 100%"
                                            v-model="formObj.contractStartDate"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            placeholder="合同开始时间" @change="contractDateChange">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="结束时间" prop="contractDate">
                            <el-date-picker style="width: 100%"
                                            v-model="formObj.contractEndDate"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            placeholder="合同结束时间" @change="contractDateChange">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-alert type="info" :closable="false" style="margin-bottom: 20px"
                          title="基础信息">
                </el-alert>

                <el-form-item label="客户名称">
                    <el-input v-model="formObj.clientName" disabled></el-input>
                </el-form-item>

                <el-form-item label="房源">
                    <el-input v-model="formObj.houseId" disabled v-if="houseClientInfo"></el-input>

                    <el-select v-model="formObj.houseId"
                               clearable multiple filterable placeholder="请选择房源" style="width: 100%"
                               @change="infoCheck" v-else>
                        <el-option
                                v-for="item in vacantHouses"
                                :key="item.houseId"
                                :label="item.houseName"
                                :value="item.houseId">
                            <span style="float: left">{{ item.houseName }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.building }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="客户属性">
                    <el-select v-model="formObj.clientAttribute" placeholder="请选择客户属性" style="width: 100%"
                               @change="infoCheck">
                        <el-option-group label="业务属性">
                            <el-option label="业主" value="业主"></el-option>
                            <el-option label="租客" value="租客"></el-option>
                            <el-option label="第三方租客" value="第三方租客"></el-option>
                        </el-option-group>
                        <el-option-group label="自用属性">
                            <el-option label="物业用" value="物业用"></el-option>
                            <el-option label="置业招商用" value="置业招商用"></el-option>
                            <el-option label="置业工程用" value="置业工程用"></el-option>
                        </el-option-group>
                    </el-select>
                </el-form-item>
                <el-form-item label="房源状态">
                    <el-select v-model="formObj.houseState" placeholder="请选择房源状态" style="width: 100%"
                               @change="infoCheck">
                        <el-option label="已售" value="已售"></el-option>
                        <el-option label="出租" value="出租"></el-option>
                        <el-option label="其它（合作协议的租借房源）" value="其它"></el-option>
                        <el-option label="自用（自己公司占用办公的房源）" value="自用"></el-option>
                        <el-option label="已预订（已签约交付、客户未付款的房源）" value="已预订"></el-option>
                    </el-select>
                </el-form-item>

                <el-alert type="info" :closable="false" style="margin-bottom: 20px"
                          title="使用时间">
                </el-alert>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="入场时间" prop="actualDate">
                            <el-date-picker style="width: 100%"
                                            v-model="formObj.actualStartDate"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            placeholder="入场时间" @change="checkDateRange">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="退场时间" prop="actualDate">
                            <el-date-picker style="width: 100%"
                                            v-model="formObj.actualEndDate"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            placeholder="退场时间" @change="checkDateRange">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-alert type="info" :closable="false" style="margin-bottom: 20px"
                          title="物业费期限">
                </el-alert>
                <el-form-item label="计费周期">
                    <el-select v-model="formObj.wyfGgnhfBillingCycle" clearable placeholder="请选择计费周期"
                               style="width: 100%">
                        <el-option label="1个月" :value="1"></el-option>
                        <el-option label="3个月" :value="3"></el-option>
                        <el-option label="6个月" :value="6"></el-option>
                        <el-option label="12个月" :value="12"></el-option>
                    </el-select>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="开始时间" prop="wyfDate">
                            <el-date-picker style="width: 100%"
                                            v-model="formObj.wyfStartDate"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            placeholder="物业费开始时间" @change="checkDateRange">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="结束时间" prop="wyfDate">
                            <el-date-picker style="width: 100%"
                                            v-model="formObj.wyfEndDate"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            placeholder="物业费结束时间" @change="checkDateRange">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-alert type="info" :closable="false" style="margin-bottom: 20px"
                          title="公共能耗费期限">
                </el-alert>
                <el-form-item label="计费周期">
                    <el-select v-model="formObj.wyfGgnhfBillingCycle" clearable placeholder="请选择计费周期"
                               style="width: 100%">
                        <el-option label="1个月" :value="1"></el-option>
                        <el-option label="3个月" :value="3"></el-option>
                        <el-option label="6个月" :value="6"></el-option>
                        <el-option label="12个月" :value="12"></el-option>
                    </el-select>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="开始时间" prop="ggnhfDate">
                            <el-date-picker style="width: 100%"
                                            v-model="formObj.ggnhfStartDate"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            placeholder="公共能耗费开始时间" @change="checkDateRange">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="结束时间" prop="ggnhfDate">
                            <el-date-picker style="width: 100%"
                                            v-model="formObj.ggnhfEndDate"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            placeholder="公共能耗费结束时间" @change="checkDateRange">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item style="margin-bottom: 20px;text-align: right">
                    <el-button size="small" plain @click="formShow = false" v-if="houseClientInfo">取消</el-button>
                    <el-button size="small" plain type="success" @click="submitUpdate" :disabled="!submitCheck"
                               v-if="houseClientInfo">保存
                    </el-button>
                    <el-button size="small" plain type="success" @click="submitInsert" :disabled="!submitCheck" v-else>
                        新增
                    </el-button>
                </el-form-item>

            </el-form>
        </div>

        <!--描述列表-->
        <div v-else>
            <el-descriptions
                    labelClassName="label"
                    class="description"
                    title="基础信息"
                    :column="2"
                    size="medium"
                    border>
                <template slot="extra">
                    <el-button v-if="authority" type="danger" size="small" plain @click="editClick">编辑</el-button>
                </template>
                <el-descriptions-item label="房源ID">{{houseClientInfo.houseId}}</el-descriptions-item>
                <el-descriptions-item label="房源">{{houseClientInfo.houseName}}</el-descriptions-item>
                <el-descriptions-item label="客户属性">{{houseClientInfo.clientAttribute}}</el-descriptions-item>
                <el-descriptions-item label="房源状态">
                    <el-tag size="small" v-if="houseClientInfo.houseState">{{houseClientInfo.houseState}}</el-tag>
                </el-descriptions-item>
            </el-descriptions>

            <el-descriptions
                    labelClassName="label"
                    class="description"
                    title="合同信息"
                    :column="2"
                    size="medium"
                    border>
                <el-descriptions-item label="合同编号" :span="2">{{houseClientInfo.contractId}}</el-descriptions-item>
                <el-descriptions-item label="开始时间" :span="1">{{houseClientInfo.contractStartDate}}
                </el-descriptions-item>
                <el-descriptions-item label="结束时间" :span="1" contentClassName="content">
                    {{houseClientInfo.contractEndDate}}
                </el-descriptions-item>
            </el-descriptions>

            <el-descriptions
                    labelClassName="label"
                    class="description"
                    title="使用时间"
                    :column="2"
                    size="medium"
                    border>
                <el-descriptions-item label="入场时间" :span="1">{{houseClientInfo.actualStartDate}}</el-descriptions-item>
                <el-descriptions-item label="退场时间" :span="1">{{houseClientInfo.actualEndDate}}</el-descriptions-item>
            </el-descriptions>

            <el-descriptions
                    labelClassName="label"
                    class="description"
                    title="物业费周期"
                    :column="2"
                    size="medium"
                    border>
                <el-descriptions-item label="计费周期" :span="2">
                    {{houseClientInfo.wyfGgnhfBillingCycle}}
                    <span v-if="houseClientInfo.wyfGgnhfBillingCycle">个月</span>
                </el-descriptions-item>
                <el-descriptions-item label="开始时间" :span="1">{{houseClientInfo.wyfStartDate}}</el-descriptions-item>
                <el-descriptions-item label="结束时间" :span="1">{{houseClientInfo.wyfEndDate}}</el-descriptions-item>
            </el-descriptions>

            <el-descriptions
                    labelClassName="label"
                    class="description"
                    title="公共能耗费周期"
                    :column="2"
                    size="medium"
                    border>
                <el-descriptions-item label="计费周期" :span="2">
                    {{houseClientInfo.wyfGgnhfBillingCycle}}
                    <span v-if="houseClientInfo.wyfGgnhfBillingCycle">个月</span>
                </el-descriptions-item>
                <el-descriptions-item label="开始时间" :span="1">{{houseClientInfo.ggnhfStartDate}}</el-descriptions-item>
                <el-descriptions-item label="结束时间" :span="1">{{houseClientInfo.ggnhfEndDate}}</el-descriptions-item>
            </el-descriptions>
        </div>
    </div>
</template>

<script>
    import {GetAuthority, UpdateClientHouseInfo, InsertOperationRecord, GetHouseClient} from '../../api/index'
    import {GetVacantHousesByTime, InsertClientHouseInfos} from "../../api";

    export default {
        name: "HouseClientForm",
        data() {

            var contractDate = (rule, value, callback) => {

                var start = this.formObj.contractStartDate == null ? null : new Date(this.formObj.contractStartDate)
                var end = this.formObj.contractEndDate == null ? null : new Date(this.formObj.contractEndDate)

                this.infoCheck()
                this.contractDateCheck = true
                if (start == null) {
                    this.submitCheck = false
                    this.contractDateCheck = false
                    return callback(new Error('开始时间不能为空'))
                }
                if (start > end && end != null) {
                    this.submitCheck = false
                    this.contractDateCheck = false
                    return callback(new Error('开始时间 > 结束时间'))
                }
            }

            var actualDate = (rule, value, callback) => {

                var start = this.formObj.actualStartDate == null ? null : new Date(this.formObj.actualStartDate)
                var end = this.formObj.actualEndDate == null ? null : new Date(this.formObj.actualEndDate)

                this.infoCheck()
                if (start == null && end != null) {
                    this.submitCheck = false
                    return callback(new Error('开始时间不能为空'))
                }
                if (start > end && end != null) {
                    this.submitCheck = false
                    return callback(new Error('开始时间 > 结束时间'))
                }
            }

            var wyfDate = (rule, value, callback) => {

                var start = this.formObj.wyfStartDate == null ? null : new Date(this.formObj.wyfStartDate)
                var end = this.formObj.wyfEndDate == null ? null : new Date(this.formObj.wyfEndDate)

                this.infoCheck()
                if (start == null && end != null) {
                    this.submitCheck = false
                    return callback(new Error('开始时间不能为空'))
                }
                if (start > end && end != null) {
                    this.submitCheck = false
                    return callback(new Error('开始时间 > 结束时间'))
                }
            }

            var ggnhfDate = (rule, value, callback) => {

                var start = this.formObj.ggnhfStartDate == null ? null : new Date(this.formObj.ggnhfStartDate)
                var end = this.formObj.ggnhfEndDate == null ? null : new Date(this.formObj.ggnhfEndDate)

                this.infoCheck()
                if (start == null && end != null) {
                    this.submitCheck = false
                    return callback(new Error('开始时间不能为空'))
                }
                if (start > end && end != null) {
                    this.submitCheck = false
                    return callback(new Error('开始时间 > 结束时间'))
                }
            }

            return {
                unionid: null,
                authority: false,
                formShow: this.showForm,
                editable: true,
                contractDateCheck: true,
                submitCheck: false,
                projName: null,
                vacantHouses: [],
                formObj: {
                    id: null,
                    clientId: this.houseClientInfo ? null : this.clientId,
                    clientName: null,
                    clientAttribute: null,
                    houseId: null,
                    houseName: null,
                    houseState: null,
                    contractId: null,
                    contractStartDate: null,
                    contractEndDate: null,
                    actualStartDate: null,
                    actualEndDate: null,
                    wyfGgnhfBillingCycle: null,
                    wyfStartDate: null,
                    wyfEndDate: null,
                    ggnhfStartDate: null,
                    ggnhfEndDate: null
                },
                rules: {
                    contractDate: [
                        {validator: contractDate, trigger: 'change'}
                    ],
                    actualDate: [
                        {validator: actualDate, trigger: 'change'}
                    ],
                    wyfDate: [
                        {validator: wyfDate, trigger: 'change'}
                    ],
                    ggnhfDate: [
                        {validator: ggnhfDate, trigger: 'change'}
                    ]
                }
            }
        },
        props: {
            showForm: {
                type: Boolean,
                required: true
            },
            houseClientInfo: {
                type: Object,
                required: false
            },
            clientId: {
                type: String,
                required: false
            }
        },
        methods: {
            editClick() {
                this.formObj = JSON.parse(JSON.stringify(this.houseClientInfo));
                this.editable = false
                this.formShow = true
            },
            submitUpdate() {
                delete this.formObj['_X_ID']
                UpdateClientHouseInfo(this.formObj).then((res) => {
                    if (res === 1) {
                        InsertOperationRecord(this.unionid, 'updateClientHouseInfo', this.formObj.id).then((res) => {
                            if (res === 1) {
                                this.$emit('UpdateInfo')
                            }
                        })
                    }
                })
            },
            submitInsert() {
                InsertClientHouseInfos(this.formObj).then((res) => {
                    if (res === 1) {
                        var detail = this.formObj.clientId + ',' + this.formObj.houseId.join(',')
                        InsertOperationRecord(this.unionid, 'insertClientHouseInfo', detail).then((res) => {
                            this.$emit('UpdateInfo')
                        })
                    }
                })
            },
            contractDateChange() {
                /*表单执行校验规则*/
                this.$refs.formClientHouse.validate().catch((e) => {
                })

                if (this.contractDateCheck) {
                    GetVacantHousesByTime(this.projName, this.formObj.contractStartDate, this.formObj.contractEndDate).then((res) => {
                        this.vacantHouses = res
                    })
                }
            },
            checkDateRange() {
                /*表单执行校验规则*/
                this.$refs.formClientHouse.validate().catch((e) => {
                })
            },
            infoCheck() {
                if (this.formObj.houseId != null && this.formObj.clientAttribute != null && this.formObj.houseState != null) {
                    this.submitCheck = true
                } else {
                    this.submitCheck = false
                }
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetAuthority('clientHouseEdit', this.unionid).then((res) => {
                this.authority = res
            })

            GetHouseClient(this.clientId).then((res) => {
                this.formObj.clientName = res.clientName
                this.projName = res.projName
            })

        }
    }
</script>

<style scoped>

    .description {
        margin-bottom: 20px;
    }

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

    .span {
        color: #40a9ff;
    }

</style>
