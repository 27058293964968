<template>

    <div class="container">

        <el-form ref="wuyeInvoiceRegisterForm" :rules="rules" :model="form" label-width="100px" size="medium">

            <el-form-item label="所属项目">
                <el-input v-model="projName" disabled></el-input>
            </el-form-item>

            <el-form-item label="选择客户" prop="clientId">
                <el-select v-model="form.clientId" filterable @change="ChangeClient"
                           placeholder="请选择客户" style="width: 100%">
                    <el-option
                            v-for="item in clients"
                            :key="item.clientId"
                            :label="item.clientName"
                            :value="item.clientId">
                        <span style="float: left">{{ item.clientName }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.clientId }}</span>
                    </el-option>
                </el-select>
            </el-form-item>

            <div v-if="client != null">
                <el-divider content-position="left">发票抬头</el-divider>
                <el-descriptions v-if="client.type === 1"
                                 labelClassName="label"
                                 class="description"
                                 :column="2"
                                 border
                                 style="margin-bottom: 20px">
                    <el-descriptions-item label="公司名称">{{client.clientName}}</el-descriptions-item>
                    <el-descriptions-item label="税号">{{client.taxNumber}}</el-descriptions-item>
                    <el-descriptions-item label="开户行及账号">
                        {{ (client.bank != null && client.bankAccount != null)?client.bank + ' ' +
                        client.bankAccount:null}}
                    </el-descriptions-item>
                    <el-descriptions-item label="地址及电话">
                        {{ (client.address != null && client.telephone != null)?client.address + ' ' +
                        client.telephone:null}}
                    </el-descriptions-item>
                </el-descriptions>

                <el-descriptions v-else
                                 labelClassName="label"
                                 class="description"
                                 :column="2"
                                 border
                                 style="margin-bottom: 20px">
                    <el-descriptions-item label="姓名">{{client.clientName}}</el-descriptions-item>
                    <el-descriptions-item label="身份证号">{{client.taxNumber}}</el-descriptions-item>
                </el-descriptions>
            </div>

            <div v-loading="loading">
                <el-divider content-position="left">可开票账单</el-divider>
                <vxe-grid ref="xTable"
                          id="WuyeInvoicableBillTable"
                          class="WuyeInvoicableBillTable"
                          v-bind="WuyeInvoicableBillTable"
                          @checkbox-change="CheckboxChange"
                          @checkbox-all="CheckboxChange"
                          @cell-click="cellClick">

                    <!-- 收款信息、开票信息、支付方式 -->
                    <template #collection="{ row }">
                        <el-tag v-if="row.collectionCode != null" type="success" size="small">已收款</el-tag>
                        <el-tag v-else type="danger" size="small">未收款</el-tag>
                    </template>
                </vxe-grid>
            </div>


            <el-divider content-position="left">开票情况</el-divider>

            <el-form-item label="先票后款">
                <el-switch v-model="form.value1"
                           active-color="#13ce66">
                </el-switch>
            </el-form-item>

            <el-form-item label="内容预览">
                <el-input
                        type="textarea"
                        :autosize="{ minRows: 4}"
                        placeholder="开票内容预览"
                        v-model="form.content">
                </el-input>
            </el-form-item>

            <el-form-item label="发票金额">
                <el-input v-model="form.amount" readonly></el-input>
            </el-form-item>

            <el-form-item label="发票流水号" prop="number" required>
                <el-input v-model="form.number"></el-input>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="info" :disabled="selectIds.length == 0?true:false" @click="MarkInvoice">
                    无需开票
                </el-button>
                <el-button size="small" type="primary" :disabled="selectIds.length == 0?true:false" @click="submit">登记
                </el-button>
            </el-form-item>

        </el-form>


        <!--详情-->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close :append-to-body="true">
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <WuyeBillDetail :id="billId" @WuyeBillDetailClose="WuyeBillDetailClose"></WuyeBillDetail>
        </el-drawer>

    </div>

</template>

<script>
    import {
        GetAllWuyeClients,
        GetInvoicableWuyeBills,
        GetProjNameDetail,
        GetWuyeClientDetail, GetWuyeClientsValidCode,
        GetWuyeClientsWithInvoicableBills, MarkWuyeInvoice, RegisterWuyeInvoice
    } from "../../api";
    import WuyeBillDetail from "./WuyeBillDetail"

    export default {
        name: "WuyeInvoiceRegister",
        components: {WuyeBillDetail},
        props: {
            projName: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                unionid: null,
                loading: false,
                projNameDetail: null,
                clients: [],
                form: {
                    projName: null,
                    billMonth: null,
                    clientId: null,
                    value1: false,
                    content: null,
                    amount: null,
                    number: null
                },
                rules: {
                    number: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ]
                },
                client: null,
                bills: [],
                height: 0,
                drawer: false,
                drawerTitle: '',
                billId: null,
                selectIds: []
            }
        },
        computed: {
            WuyeInvoicableBillTable: function () {
                return {
                    height: this.height,
                    showOverflow: true,
                    size: 'small',
                    rowConfig: {
                        isHover: true
                    },
                    checkboxConfig: {
                        checkMethod: this.checkMethod,
                        highlight: true
                    },
                    data: this.bills,
                    columns: [
                        {type: 'checkbox', field: 'check', title: '', width: 40},
                        {resizable: true, width: 80, title: '月份', field: 'billMonth'},
                        {resizable: true, title: '账单周期', field: 'chargeContent'},
                        {resizable: true, title: '房源', field: 'houseName'},
                        {resizable: true, title: '收费项目', field: 'u8fee'},
                        {
                            resizable: true,
                            title: '实际收款',
                            field: 'receivedAmount',
                            formatter: 'FormatMoney',
                            className: 'font-blue'
                        },
                        {resizable: true, title: '收款', field: 'collectionCode', slots: {default: 'collection'}},
                        {resizable: true, title: '备注', field: 'remark'},
                    ]
                }
            }
        },
        methods: {
            checkMethod({row}) {
                if (row.paymentMethod == '2') {
                    return false
                } else {
                    if (row.paymentMethod != '2' && row.invoiceInfo != null) {
                        return false
                    }
                    if (row.paymentMethod != '2' && row.invoiceInfo == null) {
                        return true
                    }
                }

            },
            ChangeClient() {
                this.form.content = null
                this.form.value1 = false
                this.form.amount = null
                this.selectIds = []

                this.loading = true
                GetInvoicableWuyeBills(this.form.clientId).then((res) => {
                    this.bills = res
                    this.height = this.bills.length * 40 + 80

                    GetWuyeClientDetail(this.form.clientId).then((res) => {
                        this.client = res
                        this.loading = false
                    })
                })
            },
            CheckboxChange() {
                var a = this.$refs.xTable.getCheckboxRecords(true)

                this.form.content = null
                this.form.value1 = false
                this.form.amount = null
                this.selectIds = []

                if (a.length > 0) {

                    var houseName = []
                    var detail = ''
                    var total = 0

                    for (var i = 0; i < a.length; i++) {
                        if (a[i].collectionCode == null) {
                            this.form.value1 = true
                        }
                        if (a[i].houseName != null && a[i].houseName != '') {

                            var houses = a[i].houseName.split(',')

                            for (var j = 0; j < houses.length; j++) {
                                houseName.push(houses[j])
                            }
                        }

                        total = total + a[i].receivedAmount
                        detail = detail + '\n' + a[i].u8fee + '：' + a[i].receivedAmount + '元' + '，' + a[i].chargeContent
                        this.selectIds.push(a[i].id)
                    }

                    if (houseName.length == 0) {
                        this.form.content = this.projNameDetail + '？？？' + detail
                    } else {
                        this.form.content = this.projNameDetail + [...new Set(houseName)].join(',') + detail
                    }

                    this.form.amount = total.toFixed(2) + ' 元'
                }
            },
            cellClick({row, column}) {

                if (column.field === 'check') {

                } else {
                    this.drawerTitle = row.billMonth + " " + row.clientName + " " + row.u8fee
                    this.billId = row.id
                    this.drawer = true
                }
            },
            submit() {

                if (this.selectIds.length > 0) {
                    this.$refs.wuyeInvoiceRegisterForm.validate((e) => {
                        if (e) {
                            this.loading = true
                            RegisterWuyeInvoice(this.selectIds.join(","), this.form.number, this.form.value1, this.unionid).then((res) => {
                                if (res === 1) {
                                    this.loading = false
                                    this.$emit('RegisterWuyeInvoice')
                                }
                            })
                        } else {
                            this.$message.error('发票号码必填！');
                        }
                    })
                } else {
                    this.$message.error('请选择账单！');
                }
            },
            MarkInvoice() {

                this.$alert('是否将选中账单标记为无需开票？', '无需开票', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    showCancelButton: true,
                    callback: action => {
                        if (action === 'confirm') {
                            this.loading = true
                            MarkWuyeInvoice(this.selectIds.join(",")).then((res) => {
                                if (res === 1) {
                                    this.loading = false
                                    this.$emit('RegisterWuyeInvoice')
                                }
                            })
                        }
                    }
                });
            },
            WuyeBillDetailClose() {
                this.drawer = false
                this.ChangeClient()
            }
        },
        created() {

            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeClientsWithInvoicableBills(this.projName).then((res) => {
                this.clients = res
            })

            GetProjNameDetail(this.projName).then((res) => {
                this.projNameDetail = res
            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

    .WuyeInvoicableBillTable >>> .vxe-body--row {
        cursor: pointer;
    }

</style>
