<template>

    <div class="container" v-loading="loading">

        <el-descriptions title="客户信息"
                         labelClassName="label"
                         class="description"
                         :column="2"
                         border
                         style="margin-bottom: 20px">

            <el-descriptions-item label="项目" :span="2">{{detail.projName}}</el-descriptions-item>
            <el-descriptions-item label="客户名称" :span="2">{{detail.clientName}}</el-descriptions-item>
            <el-descriptions-item label="客户编号">{{detail.clientId}}</el-descriptions-item>
            <el-descriptions-item label="U8客户编号">{{detail.u8clientCode}}</el-descriptions-item>
        </el-descriptions>

        <el-descriptions :title="'账单详情 - 账单ID:' + detail.id"
                         labelClassName="label"
                         class="description"
                         :column="2"
                         border
                         style="margin-bottom: 20px">

            <el-descriptions-item label="账单月份">{{detail.billMonth}}</el-descriptions-item>
            <el-descriptions-item label="账单码">{{detail.billCode}}</el-descriptions-item>

            <el-descriptions-item label="账单类型">{{detail.u8fee}}</el-descriptions-item>
            <el-descriptions-item label="房源">{{detail.houseName}}</el-descriptions-item>

            <el-descriptions-item label="原金额">{{MoneyFormat(detail.amount)}}</el-descriptions-item>
            <el-descriptions-item label="减免金额">{{MoneyFormat(detail.reliefAmount)}}</el-descriptions-item>

            <el-descriptions-item label="应收金额">{{MoneyFormat(detail.amount - detail.reliefAmount)}}
            </el-descriptions-item>
            <el-descriptions-item label="实际收款">
                <el-tag v-if="detail.collectionCheck == null" size="small" type="danger">未收款</el-tag>
                <el-tag v-else size="small">{{MoneyFormat(detail.receivedAmount)}}</el-tag>
            </el-descriptions-item>

            <el-descriptions-item label="收费周期">{{detail.chargeContent}}</el-descriptions-item>
            <el-descriptions-item label="应收日期">{{detail.dueDate}}</el-descriptions-item>

            <el-descriptions-item label="制单人">{{detail.maker}}</el-descriptions-item>
            <el-descriptions-item label="审核人">{{detail.reviewer}}</el-descriptions-item>

            <el-descriptions-item label="制单时间">{{detail.createTime}}</el-descriptions-item>
            <el-descriptions-item label="最近修改">{{detail.changeTime}}</el-descriptions-item>

            <el-descriptions-item label="结算方式">
                <el-tag v-if="detail.collectionCheck == null && detail.paymentMethod=='2'" size="small" type="danger">
                    未收款
                </el-tag>
                <el-tag v-if="detail.collectionCheck == null && detail.paymentMethod!='2'" size="small" type="danger">
                    {{detail.paymentMethod=='1'?'未收款（江苏稻谷物业管理有限公司苏州分公司）':'未收款（苏州步步高物业管理有限公司）'}}
                </el-tag>
                <el-tag v-if="detail.collectionCheck != null && detail.paymentMethod!='2'" size="small">
                    {{detail.paymentMethod=='1'?'江苏稻谷物业管理有限公司苏州分公司':'苏州步步高物业管理有限公司'}}
                </el-tag>
                <el-tag v-if="detail.collectionCheck != null && detail.paymentMethod=='2'" size="small" type="info">现金
                </el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="收款人">
                <el-tag v-if="detail.collectionCheck == null" size="small" type="danger">未收款</el-tag>
                <el-tag v-else size="small">{{detail.collectionCheck + ' ' + detail.collectionCheckDate}}</el-tag>
            </el-descriptions-item>

            <el-descriptions-item label="开票信息" :span="2">
                <el-tag v-if="detail.paymentMethod == '2'" size="small" type="info">不可开票</el-tag>
                <el-tag v-if="detail.invoiceInfo == 'empty' " size="small" type="warning">无需开票</el-tag>
                <el-tag v-if="detail.invoiceInfo == null && detail.paymentMethod != '2' " size="small" type="danger">
                    未开票
                </el-tag>
                <el-tag v-if="detail.invoiceInfo != null && detail.invoiceInfo != 'empty'" size="small">
                    {{detail.invoiceInfo}}
                </el-tag>
            </el-descriptions-item>


        </el-descriptions>


        <el-form :model="form" label-width="120px" size="medium" label-position="top">

            <el-divider content-position="left">减免金额</el-divider>

            <el-form-item>
                <el-input-number v-model="form.reliefAmount"
                                 :precision="2" :step="0.01" :max="detail.amount" :disabled="editBillAmount?this.form.collection:true">
                </el-input-number>
            </el-form-item>

            <div v-if="form.paymentMethod != '2'">
                <el-divider content-position="left">收款公司</el-divider>
                <el-form-item v-if="editPayMethod">
                    <el-select v-model="form.paymentMethod"
                               placeholder="选择收款公司" style="width: 100%">
                        <el-option value="1" label="江苏稻谷物业管理有限公司苏州分公司"></el-option>
                        <el-option value="3" label="苏州步步高物业管理有限公司"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item v-else>
                    <el-select v-model="form.paymentMethod"
                               placeholder="选择收款公司" style="width: 100%" :disabled="btnDisabled == false?this.form.collection:true">
                        <el-option value="1" label="江苏稻谷物业管理有限公司苏州分公司"></el-option>
                        <el-option value="3" label="苏州步步高物业管理有限公司"></el-option>
                    </el-select>
                </el-form-item>
            </div>




            <el-divider content-position="left">备注</el-divider>

            <el-form-item>
                <el-input
                        type="textarea"
                        :autosize="{ minRows: 4}"
                        placeholder="填写备注"
                        v-model="form.remark">
                </el-input>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" @click="submit" :disabled="btnDisabled">保 存</el-button>
            </el-form-item>

        </el-form>


    </div>

</template>

<script>
    import {GetWuyeAuthority, GetWuyeBillDetail, UpdateWuyeBillDetail} from "../../api";
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "WuyeBillDetail",
        props: {
            id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                unionid: null,
                loading: false,
                detail: {},
                form: {
                    collection: false,
                    paymentMethod:null,
                    reliefAmount: 0,
                    remark: null
                },
                btnDisabled: true,
                editBillAmount: false,
                editPayMethod: false
            }
        },
        methods: {
            MoneyFormat(value) {
                return formatMoney(value)
            },
            submit() {

                if (!this.form.collection) {
                    this.$alert('请确认减免金额无误！', '确认减免金额', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        showCancelButton: true,
                        callback: action => {
                            if (action === 'confirm') {
                                this.loading = true
                                UpdateWuyeBillDetail(this.id, this.form.paymentMethod,this.form.collection, this.form.reliefAmount, this.form.remark).then((res) => {
                                    if (res === 1) {
                                        this.loading = false
                                        this.$emit('WuyeBillDetailClose')
                                    }
                                })
                            }
                        }
                    });
                } else {
                    UpdateWuyeBillDetail(this.id, this.form.paymentMethod,this.form.collection, this.form.reliefAmount, this.form.remark).then((res) => {
                        this.$emit('WuyeBillDetailClose')
                    })
                }
            }
        },
        created() {

            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeAuthority(this.unionid).then((res) => {
                if (res === '' || res == null) {

                } else {
                    /* 权限控制 */
                    if (res.authorities.indexOf('editBillOnly') != -1) {
                        this.btnDisabled = false
                    } else {
                        this.btnDisabled = true
                    }

                    if (res.authorities.indexOf('editBillAmount') != -1) {
                        this.editBillAmount = true
                    } else {
                        this.editBillAmount = false
                    }

                    //已收款账单 - 收款公司修改
                    if (res.authorities.indexOf('editPayMethod') != -1) {
                        this.editPayMethod = true
                    }else{
                        this.editPayMethod = false
                    }


                }
            })


            GetWuyeBillDetail(this.id).then((res) => {
                this.detail = res
                this.form.collection = res.collectionCheck != null ? true : false
                this.form.paymentMethod = res.paymentMethod
                this.form.reliefAmount = res.reliefAmount
                this.form.remark = res.remark
            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
        white-space: pre-line
    }

</style>
