<template>

    <div id="canvasBox">

        <p style="color: #ec132b;text-align: center" v-if="msg != null">{{msg}}</p>

        <wxlogin
                :appid="appid"
                :scope="scope"
                :redirect_uri="redirect_uri"
                :theme="theme">
        </wxlogin>


        <div style="text-align: center;font-size: 14px;margin-top: 50px">

            <p style="text-align: center">Copyright@稻谷(苏州)互联网产业园</p>
            <p style="text-align: center">Dao Valley © 2016</p>

            <p style="text-align: center">
                <a href="https://beian.miit.gov.cn/" target="_blank" style="color: black;text-decoration:none;">苏ICP备2021055036号</a>
            </p>

            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32050902101345"
               style="text-decoration:none;">
                <img src="../../public/beian.png" style="margin-right: 5px"/>
                <span style="color:#939393;">苏公网安备 32050902101345号</span>
            </a>
        </div>
    </div>

</template>

<script>
    import wxlogin from 'vue-wxlogin'

    export default {
        name: "Login",
        components: {wxlogin},
        data() {
            return {
                appid: "wx2793de04b1147634",
                scope: "snsapi_login",
                redirect_uri: "http%3A%2F%2Fwww.dao-valley.cn%2F%23%2Fhome",
                theme: "black",
                msg: null
            }
        },
        created() {
            if (this.$route.query.msg) {
                this.msg = this.$route.query.msg
            }
        },
    }
</script>

<style scoped>

    #canvasBox {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

</style>
