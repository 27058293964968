<template>

    <div>

        <div v-loading="loading" v-if="authority">
            <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
                <el-collapse-item name="collapseItem" class="form-container">

                    <template slot="title">
                        <el-tag type="success" style="width: 100%;padding-left: 15px">
                            <i class="el-icon-search" style="margin-right: 5px"></i>
                            <span>内部用电报表</span>
                        </el-tag>
                    </template>

                    <el-form :inline="true"
                             :model="searchObj"
                             class="form"
                             size="small"
                             label-width="72px" style="padding-left: 15px">

                        <el-form-item label="查询月份">
                            <el-date-picker style="width: 200px" @change="Submit" :clearable="false"
                                            v-model="searchObj.month"
                                            value-format="yyyy-MM"
                                            type="month" :picker-options="pickerOptions" placeholder="选择月">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="所属项目:">
                            <el-select v-model="searchObj.projName" placeholder="所属项目" @change="Submit">
                                <el-option v-for="project in projects" :key="project.abbreviation"
                                           :value="project.projName"
                                           :label="project.projName"></el-option>
                            </el-select>
                        </el-form-item>

                    </el-form>
                </el-collapse-item>
            </el-collapse>

            <div :style="tableContainerStyle">

                <el-container style="height: 100%;">

                    <el-main style="padding: 5px;overflow-x: hidden">
                        <el-row :gutter="12">
                            <el-col :span="12" v-for="(report,index) in reports" style="margin-bottom: 10px">
                                <el-card shadow="hover" :body-style="{ padding: '15px'}" :key="index">

                                    <!-- 头 -->
                                    <div slot="header">
                                        <!--<span>用电部门：</span>-->
                                        <span style="font-weight: bolder">{{report.clientName}}</span>
                                        <el-button style="float: right; padding: 3px 0" type="text" @click="openBill(report.bill)">关联账单</el-button>
                                    </div>

                                    <!-- main -->
                                    <div>

                                        <el-table size="small"
                                                  :data="report.items"
                                                  border
                                                  show-summary
                                                  :summary-method="getSummaries"
                                                  height="200px"
                                                  style="width: 100%">
                                            <el-table-column prop="houseName" label="房源"></el-table-column>
                                            <el-table-column prop="kind" label="费用"></el-table-column>
                                            <el-table-column prop="elecUsage" label="用量"></el-table-column>
                                            <el-table-column prop="unitPrice" label="单价">
                                                <template slot-scope="scope">
                                                    <span style="margin-left: 10px">{{ FormatMoney(scope.row.unitPrice) }}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="bill" label="金额">
                                                <template slot-scope="scope">
                                                    <span style="margin-left: 10px">{{ FormatMoney(scope.row.bill) }}</span>
                                                </template>
                                            </el-table-column>
                                        </el-table>

                                        <el-row style="margin-top: 10px;font-size: 14px;margin-bottom: 10px">
                                            <el-col :span="12">
                                                <span>上月环比：</span>
                                                <span v-if="report.lastMonthAmount == null">/</span>
                                                <span v-else :style="report.amount.amount <= report.lastMonthAmount.amount ?'color:#67C23A':'color:#F56C6C'">
                                                {{computeRate(report.amount.amount,report.lastMonthAmount.amount)}}
                                                {{'(' + computeBalance(report.amount.amount,report.lastMonthAmount.amount) + '元)'}}
                                            </span>
                                            </el-col>
                                            <el-col :span="12">
                                                <span>去年同比：</span>
                                                <span v-if="report.lastYearAmount == null">/</span>
                                                <span v-else :style="report.amount.amount <= report.lastYearAmount.amount ?'color:#67C23A':'color:#F56C6C'">
                                                {{computeRate(report.amount.amount,report.lastYearAmount.amount)}}
                                                {{'(' + computeBalance(report.amount.amount,report.lastYearAmount.amount) + '元)'}}
                                            </span>
                                            </el-col>
                                        </el-row>

                                        <el-row :gutter="12">
                                            <el-col :span="24" style="margin-bottom: 10px">
                                                <el-tag v-if="report.report != null" style="width: 100%;padding-left: 15px">
                                                    <span >{{report.report.checkerName}} 已审核</span>
                                                </el-tag>

                                                <el-tag v-else type="info" style="width: 100%;padding-left: 15px">
                                                    <span>未审核</span>
                                                </el-tag>
                                            </el-col>
                                            <el-col :span="24" style="text-align: right">

                                                <div v-if="report.report != null">
                                                    <!-- 无需审核 -->
                                                    <el-button key="1" v-if="report.reviewers.indexOf(unionid) == -1" type="info" size="small" disabled>无需审核</el-button>
                                                    <!-- 已审核 -->
                                                    <el-button key="2" v-if="(report.reviewers.indexOf(unionid) != -1) && (report.report.checker.indexOf(unionid) != -1)" type="success" size="small" disabled>已审核</el-button>
                                                    <!-- 待审核 -->
                                                    <el-button key="3" v-if="(report.reviewers.indexOf(unionid) != -1) && (report.report.checker.indexOf(unionid) == -1)" type="danger" size="small" @click="Check(report.bill)">审 核</el-button>
                                                </div>
                                                <div v-else>
                                                    <!-- 无需审核 -->
                                                    <el-button key="4" v-if="report.reviewers.indexOf(unionid) == -1" type="info" size="small" disabled>无需审核</el-button>
                                                    <!-- 待审核 -->
                                                    <el-button key="5" v-else type="danger" size="small" @click="Check(report.bill)">审 核</el-button>

                                                </div>

                                            </el-col>

                                        </el-row>
                                    </div>

                                    <!-- 尾 -->

                                </el-card>
                            </el-col>
                        </el-row>


                    </el-main>
                </el-container>
            </div>
        </div>


        <div v-else>
            <el-result style="height: -webkit-calc(100vh - 250px)"
                       icon="warning" title="无权限"
                       subTitle="抱歉，无权限操作">
            </el-result>
        </div>

        <!--详情-->
        <el-drawer :visible.sync="detailDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="detailDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <WuyeBillDetail :id="billId"></WuyeBillDetail>
        </el-drawer>

    </div>


</template>

<script>
    import XEUtils from 'xe-utils'
    import {
        CheckInternalElecReport,
        GetAuthority,
        GetInternalElecReport,
        GetProjectsWithoutAll,
        GetWuyeAuthority
    } from "../../api";
    import WuyeBillDetail from "../property/WuyeBillDetail"
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "InternalElecReportManagement",
        components: {WuyeBillDetail},
        data() {
            return {
                unionid: null,
                authority: true,
                loading: false,
                projects: [],
                searchObj: {
                    projName: '稻谷国际中心',
                    month: XEUtils.toDateString(XEUtils.getWhatMonth(new Date(), -1), 'yyyy-MM'),
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > XEUtils.getWhatMonth(new Date(), -1);
                    },
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                reports: [],
                detailDrawer: false,
                detailDrawerTitle: '',
                billId: null
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            Submit() {
                this.loading = true
                GetInternalElecReport(this.searchObj.projName,this.searchObj.month).then((res) => {
                    this.reports = res
                    this.loading = false
                })
            },
            FormatMoney(value) {
                return formatMoney(value)
            },
            getSummaries(param) {

                const { columns, data } = param;

                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    if (index === 1 || index === 3) {
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);

                        sums[index] = sums[index].toFixed(2)

                        if (index === 4) {
                            sums[index] = this.FormatMoney(sums[index]);
                        }
                    } else {
                        return;
                    }
                });

                return sums;

            },
            computeRate(now,last) {
                var rate = (now - last)/last
                if (rate >= 0) {
                    return '+' + (rate*100).toFixed(2) + '%'
                }else{
                    return (rate*100).toFixed(2) + '%'
                }
            },
            computeBalance(now,last) {
                var balance = (now - last).toFixed(2)
                if(balance >= 0) {
                    return '+' + balance
                }else{
                    return balance
                }
            },
            openBill(obj) {
                if (obj != null) {
                    this.detailDrawerTitle = obj.billMonth + " " + obj.clientName + " " + obj.u8fee
                    this.billId = obj.id
                    this.detailDrawer = true
                }else{
                    this.$alert('无关联账单！', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        callback: action => {
                            if (action === 'confirm') {

                            }
                        }
                    });
                }
            },
            Check(obj) {
                this.$alert('确认审核？', '报表审核', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    showCancelButton: true,
                    callback: action => {
                        if (action === 'confirm') {
                            this.loading = true
                            CheckInternalElecReport(obj,this.unionid).then((res) => {
                                this.Submit()
                            })
                        }
                    }
                });
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            /*check权限*/
            // GetAuthority('checkInternalElecReport', this.unionid).then((res) => {
            //     this.authority = res
            // })

            GetWuyeAuthority(this.unionid).then((res) => {

                /* 权限控制 */
                if (res.authorities.indexOf('checkInternalElecReport') != -1) {
                    this.authority = true
                } else {
                    this.authority = false
                }

                this.searchObj.projName = '稻谷国际中心'
                if (res.projName === 'ALL') {
                    GetProjectsWithoutAll().then((res) => {
                        this.projects = res
                    })
                } else {
                    this.projects = []
                    var arr = res.projName.split(',')

                    this.searchObj.projName = arr[0]

                    for (var i = 0; i <= arr.length - 1; i++) {
                        this.projects.push({
                            projName: arr[i]
                        })
                    }
                }

                this.Submit()
            })

            // GetProjectsWithoutAll().then((res) => {
            //     this.projects = res
            //     this.Submit()
            // })
        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>
    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }
</style>
