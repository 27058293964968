<template>
    <div class="container">

        <el-tabs value="first">
            <el-tab-pane label="金额详情" name="first">

                <el-card style="margin-bottom: 10px" shadow="hover">
                    <el-form :model="amountForm" label-width="80px" v-if="formShow">

                        <el-form-item label="应收金额">
                            <el-input-number v-model="amountForm.amount" style="width: 100%"
                                             :precision="2" :step="0.01" :min="0">
                            </el-input-number>
                        </el-form-item>

                        <el-form-item label="备注" required>
                            <el-input type="textarea" v-model="amountForm.remark" clearable
                                      @input="changeCheck"></el-input>
                        </el-form-item>

                        <el-form-item style="text-align: right">
                            <el-button size="small" type="info" plain @click="formShow = false">取消</el-button>
                            <el-button size="small" type="primary" @click="changeAmount" :disabled="!changeAble">修改
                            </el-button>
                        </el-form-item>

                    </el-form>


                    <el-descriptions title="应收" v-else
                                     labelClassName="label"
                                     class="description"
                                     :column="1"
                                     border
                                     size="medium"
                                     style="margin-bottom: 20px">
                        <template slot="extra">
                            <el-button type="danger" plain size="small" @click="showForm">修改应收金额</el-button>
                        </template>
                        <el-descriptions-item label="应收金额">{{MoneyFormat(monthBill.amount)}}</el-descriptions-item>
                        <el-descriptions-item label="备注">{{monthBill.remark}}</el-descriptions-item>
                    </el-descriptions>
                </el-card>

                <el-card style="margin-bottom: 10px" shadow="hover">
                    <h4>明细</h4>
                    <vxe-grid
                            ref="xTable1"
                            id="elecBillTable"
                            class="elecBillTable"
                            v-bind="elecBillTable">
                    </vxe-grid>
                </el-card>

                <el-card style="margin-bottom: 10px" shadow="hover">
                    <h4>
                        其它电费
                        <span><el-button
                                type="warning" plain size="small"
                                @click="drawer = true"
                                style="float: right">新增其它电费</el-button></span>
                    </h4>
                    <vxe-grid
                            ref="xTable2"
                            id="otherBillTable"
                            class="otherBillTable"
                            v-bind="otherBillTable">
                    </vxe-grid>
                </el-card>
            </el-tab-pane>

            <el-tab-pane label="记录" name="second" v-if="changeRecords.length > 0 || paymentRecords.length > 0">
                <el-card style="margin-bottom: 10px" shadow="hover" v-if="changeRecords.length > 0">
                    <h4>应收金额修改记录</h4>
                    <el-timeline style="padding-left: 0!important;">
                        <el-timeline-item
                                v-for="changeRecord in changeRecords"
                                :key="changeRecord.id"
                                :timestamp="changeRecord.time" placement="top">
                            <p>
                                <span style="font-weight: bolder">操作人：</span>
                                <span>{{changeRecord.unionid}}</span>
                            </p>
                            <p>
                                <span style="font-weight: bolder">修改金额：</span>
                                <span>{{MoneyFormat(changeRecord.operationType)}}</span>
                            </p>
                            <p>
                                <span style="font-weight: bolder">备注：</span>
                                <span>{{changeRecord.detail}}</span>
                            </p>
                        </el-timeline-item>
                    </el-timeline>
                </el-card>

                <el-card style="margin-bottom: 10px" shadow="hover" v-if="paymentRecords.length > 0">
                    <h4>收款记录</h4>
                    <el-timeline style="padding-left: 0!important;">
                        <el-timeline-item
                                v-for="paymentRecord in paymentRecords"
                                :key="paymentRecord.id"
                                :timestamp="paymentRecord.paidDate" placement="top">
                            <p>
                                <span style="font-weight: bolder">操作人：</span>
                                <span>{{paymentRecord.operator}}</span>
                            </p>
                            <p>
                                <span style="font-weight: bolder">收款：</span>
                                <span>{{MoneyFormat(paymentRecord.paidAmount)}}</span>
                            </p>
                            <p>
                                <span style="font-weight: bolder">备注：</span>
                                <span>{{paymentRecord.remark == null?'无':paymentRecord.remark}}</span>
                            </p>
                        </el-timeline-item>
                    </el-timeline>
                </el-card>

            </el-tab-pane>
        </el-tabs>

        <el-drawer :visible.sync="drawer" size="42%"
                   destroy-on-close
                   :append-to-body="true">
            <template slot="title">
                <el-alert
                        title="新增其它电费"
                        type="success"
                        :closable="false">
                </el-alert>
            </template>
            <OtherElecBillAdd :month="month" :clientId="clientId"
                              :clientName="clientName" :amount="monthBill.amount"
                              @OtherElecBillAdd="OtherElecBillAdd"></OtherElecBillAdd>
        </el-drawer>

    </div>
</template>

<script>
    import {
        GetClientMonthElecBill,
        GetElecBillAmountChangeRecords, GetElecBillPaymentRecords,
        GetMonthElecBills,
        GetOtherMonthElecBills, InsertOperationRecord, UpdateElecBillAmount
    } from "../../api";
    import OtherElecBillAdd from "./OtherElecBillAdd"
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "MonthElecBillDetail",
        components: {OtherElecBillAdd},
        data() {
            return {
                unionid: null,
                drawer: false,
                formShow: false,
                changeAble: false,
                monthBill: {},
                amountForm: {
                    amount: null,
                    remark: null
                },
                elecBills: [],
                otherBills: [],
                changeRecords: [],
                paymentRecords: []
            }
        },
        computed: {
            elecBillTable() {
                return {
                    height: '200px',
                    showOverflow: true,
                    size: 'small',
                    data: this.elecBills,
                    border: 'full',
                    align: 'center',
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '50'},
                        {resizable: true, title: '房源', field: 'houseName'},
                        {resizable: true, title: '表计名称', field: 'meterName'},
                        {resizable: true, title: '表计编号', field: 'meterId'},
                        {resizable: true, title: '上次行度', field: 'lastDegree', formatter: 'FormatNum'},
                        {resizable: true, title: '本次行度', field: 'latestDegree', formatter: 'FormatNum'},
                        {resizable: true, title: '用量', field: 'elecUsage', formatter: 'FormatNum'},
                        {resizable: true, title: '单价', field: 'unitPrice', formatter: 'FormatMoney'},
                        {resizable: true, title: '金额', width: '90', field: 'bill', formatter: 'FormatMoney'},
                    ]
                }
            },
            otherBillTable() {
                return {
                    height: '180px',
                    size: 'small',
                    data: this.otherBills,
                    border: 'full',
                    align: 'center',
                    columns: [
                        {resizable: true, width: '90', title: '月份', field: 'billMonth'},
                        {resizable: true, width: '90', title: '电费类型', field: 'kind'},
                        {resizable: true, width: '90', title: '金额', field: 'amount', formatter: 'FormatMoney'},
                        {resizable: true, title: '备注', field: 'remark'},
                    ]
                }
            }
        },
        methods: {
            GetInfos() {
                GetClientMonthElecBill(this.clientId, this.month).then((res) => {
                    this.monthBill = res
                })

                GetMonthElecBills(this.clientId, this.month).then((res) => {
                    this.elecBills = res
                })

                GetOtherMonthElecBills(this.clientId, this.month).then((res) => {
                    this.otherBills = res
                })

                GetElecBillAmountChangeRecords(this.clientId, this.month).then((res) => {
                    this.changeRecords = res
                })

                GetElecBillPaymentRecords(this.clientId, this.month).then((res) => {
                    this.paymentRecords = res
                })
            },
            MoneyFormat(value) {
                return formatMoney(value)
            },
            showForm() {
                this.amountForm.amount = this.monthBill.amount
                this.amountForm.remark = this.monthBill.remark
                this.formShow = true
            },
            changeCheck() {
                if (this.amountForm.remark != null && String(this.amountForm.remark).trim() != '') {
                    this.changeAble = true
                } else {
                    this.changeAble = false
                }
            },
            changeAmount() {
                UpdateElecBillAmount(this.clientId, this.month, this.amountForm.amount, this.amountForm.remark).then((res) => {
                    if (res === 1) {
                        var type = 'changeElecMonthBillCountAmount(' + this.clientId + ',' + this.month + ')'
                        var detail = this.amountForm.amount + '|' + this.amountForm.remark
                        InsertOperationRecord(this.unionid, type, detail).then((res) => {
                            if (res === 1) {
                                this.formShow = false
                                this.GetInfos()
                            }
                        })
                    }
                })
            },
            OtherElecBillAdd() {
                this.drawer = false
                this.GetInfos()
            }
        },
        props: {
            clientId: {
                type: String,
                required: true
            },
            month: {
                type: String,
                required: true
            },
            clientName: {
                type: String,
                required: true
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')
            this.GetInfos()
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

</style>
