<template>
    <div class="container">

        <el-form ref="uploadForm" :model="uploadForm" :rules="rules" label-width="80px">

            <el-form-item label="表计名称">
                <el-input v-model="uploadForm.meterName" disabled></el-input>
            </el-form-item>

            <el-form-item label="月份">
                <el-input v-model="uploadForm.month" disabled></el-input>
            </el-form-item>

            <el-form-item label="用量" prop="usage">
                <el-input v-model="uploadForm.usage" @input="check"></el-input>
            </el-form-item>

            <el-form-item label="金额" prop="amount">
                <el-input v-model="uploadForm.amount" @input="check"></el-input>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" :disabled="!submitCheck" @click="submit">提交</el-button>
            </el-form-item>

        </el-form>

    </div>
</template>

<script>
    import {GetElecMeterDetail, InsertOfficialElecUsage, InsertOperationRecord} from "../../api";

    export default {
        name: "UploadForm",
        data() {
            var priceReg = /^-?\d*\.?\d+$/
            var validatePrice = (rule, value, callback) => {
                if (!value) {
                    this.submitCheck = false
                    return callback(new Error('单价不能为空！'))
                } else {
                    if (!priceReg.test(value)) {
                        this.submitCheck = false
                        callback(new Error('单价必须为数字！'))
                    } else {
                        this.submitCheck = true
                        callback()
                    }
                }
            }

            return {
                unionid: null,
                submitCheck: false,
                uploadForm: {
                    meterName: '表计名称',
                    month: '',
                    usage: null,
                    amount: null
                },
                rules: {
                    usage: [
                        {validator: validatePrice, trigger: 'change'}
                    ],
                    amount: [
                        {validator: validatePrice, trigger: 'change'}
                    ],
                }
            }
        },
        props: {
            meterId: {
                type: String,
                required: true
            },
            month: {
                type: String,
                required: true
            }
        },
        methods: {
            submit() {
                InsertOfficialElecUsage(this.meterId, this.uploadForm).then((res) => {
                    if (res === 1) {
                        var detail = this.meterId + ',' + this.month
                        InsertOperationRecord(this.unionid, 'insertOfficialElecUsage', detail).then((res) => {
                            if (res === 1) {
                                this.$emit('updateOfficialUsageInfo')
                            }
                        })
                    }
                })
            },
            check() {
                this.$refs.uploadForm.validate().catch((e) => {
                    this.submitCheck = false
                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')
            this.uploadForm.month = this.month

            GetElecMeterDetail(this.meterId).then((res) => {
                this.uploadForm.meterName = res.meterName
            })
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

</style>
