<template>

    <div>

        <div v-loading="loading" v-if="authority">

            <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
                <el-collapse-item name="collapseItem" class="form-container">
                    <template slot="title">
                        <el-tag type="success" style="width: 100%;padding-left: 15px">
                            <i class="el-icon-search" style="margin-right: 5px"></i>
                            <span v-if="searchObj.type === '可开电子票账单'">共查询到 {{bills.length}} 条记录</span>
                            <span v-else>共查询到 {{invoices.length}} 条记录</span>
                        </el-tag>
                    </template>

                    <el-form :inline="true"
                             :model="searchObj"
                             class="form"
                             size="small"
                             label-width="72px">

                        <el-form-item>
                            <el-radio-group v-model="searchObj.type" size="small" @change="submit">
                                <el-radio-button label="可开电子票账单"></el-radio-button>
                                <el-radio-button label="已开电子票"></el-radio-button>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="所属项目:" style="margin-left: 10px">
                            <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                                <el-option v-for="project in projects" :key="project.abbreviation"
                                           :value="project.projName"
                                           :label="project.projName"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="账单月份" v-if="searchObj.type === '已开电子票'">
                            <el-date-picker @change="submit"
                                            type="months"
                                            value-format="yyyy-MM"
                                            v-model="searchObj.months"
                                            placeholder="选择一个或多个月">
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                </el-collapse-item>
            </el-collapse>

            <!-- 表格 -->
            <div :style="tableContainerStyle">

                <vxe-grid ref="xTable" v-if="searchObj.type === '可开电子票账单'"
                          id="electronicInvoicableTable"
                          class="electronicInvoicableTable"
                          v-bind="electronicInvoicableTable"
                          @cell-click="cellClick">

                    <template #paymentMethod="{ row }">
                        <el-tag v-if="row.paymentMethod == '1'" type="warning" size="small">江苏稻谷物业管理有限公司苏州分公司</el-tag>
                        <el-tag v-if="row.paymentMethod == '3'" type="warning" size="small">苏州步步高物业管理有限公司</el-tag>
                    </template>

                    <template #collection="{ row }">
                        <el-tag v-if="row.collectionCode != null" type="success" size="small">已收款</el-tag>
                        <el-tag v-else type="danger" size="small">未收款</el-tag>
                    </template>

                    <template #paymentInfo="{ row }">
                        <el-tag v-if="row.paymentInfo != null" size="small" type="success">微信支付</el-tag>
                        <el-tag v-else size="small">对公打款</el-tag>
                    </template>

                    <template #operate="{ row }">
                        <el-button type="primary" size="mini" @click.stop="btnClick(row)">开票</el-button>
                    </template>

                </vxe-grid>

                <vxe-grid ref="xTable2" v-else
                          id="issuedElectronicInvoicableTable"
                          class="issuedElectronicInvoicableTable"
                          v-bind="issuedElectronicInvoicableTable"
                          @cell-click="cellClick2">
                </vxe-grid>

            </div>

        </div>

        <div v-else>
            <el-result style="height: -webkit-calc(100vh - 250px)"
                       icon="warning" title="无权限"
                       subTitle="抱歉，无权限操作">
            </el-result>
        </div>


        <!--详情-->
        <el-drawer :visible.sync="detailDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="detailDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <WuyeBillDetail :id="billId"></WuyeBillDetail>
        </el-drawer>

        <!-- 开票  -->
        <el-drawer :visible.sync="makeDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="makeDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <WyMpInvoiceMake :id="makeId" @MakeWyMpInvoice="MakeWyMpInvoice"></WyMpInvoiceMake>
        </el-drawer>

        <!-- 发票  -->
        <el-drawer :visible.sync="invoiceDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="invoiceDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <WyMpInvoiceDetail :paymentMethod="paymentMethod" :invoiceInfo="invoiceInfo"></WyMpInvoiceDetail>
        </el-drawer>

    </div>

</template>

<script>
    import WuyeBillDetail from "../property/WuyeBillDetail"
    import WyMpInvoiceMake from "./WyMpInvoiceMake"
    import WyMpInvoiceDetail from "./WyMpInvoiceDetail"
    import {
        GetElectronicInvoicableWuyeBills,
        GetIssuedElectronicInvoices,
        GetProjectsWithoutAll,
        GetWuyeAuthority
    } from "../../api";
    import XEUtils from 'xe-utils'

    export default {
        name: "WyMpInvoiceManagement",
        components: {WuyeBillDetail, WyMpInvoiceMake, WyMpInvoiceDetail},
        data() {
            return {
                loading: false,
                unionid: null,
                authority: true,
                projects: [],
                searchObj: {
                    type: '可开电子票账单',
                    projName: '稻谷国际中心',
                    months: [XEUtils.toDateString(XEUtils.getWhatMonth(new Date(), -1), 'yyyy-MM')]
                },
                bills: [],
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                detailDrawer: false,
                detailDrawerTitle: '',
                billId: null,
                makeDrawer: false,
                makeDrawerTitle: '',
                makeId: null,
                invoices: [],
                invoiceDrawer: false,
                invoiceDrawerTitle: '',
                invoiceInfo: null,
                paymentMethod: null,
                u8FeeKinds: [
                    {label: '电费', value: '01002'},
                    {label: '水费', value: '01004'},
                    {label: '出租车位', value: '01005'},
                ],
                paymentMethodKinds: [
                    {label: '江苏稻谷物业管理有限公司苏州分公司', value: '1'},
                    {label: '苏州步步高物业管理有限公司', value: '3'},
                ]
            }
        },
        computed: {
            electronicInvoicableTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    rowConfig: {
                        isHover: true
                    },
                    data: this.bills,
                    columnConfig: {
                        width: 'auto',
                        minWidth: '130'
                    },
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {resizable: true, title: '账单月份', field: 'billMonth'},
                        {resizable: true, title: '收费项目', field: 'u8fee',
                            filters: this.u8FeeKinds,
                            filterMethod: this.U8FeeFilter},
                        {
                            resizable: true,
                            title: '实际收款',
                            field: 'receivedAmount',
                            formatter: 'FormatMoney',
                            className: 'font-blue'
                        },
                        {resizable: true, title: '支付信息', field: 'paymentInfo', slots: {default: 'paymentInfo'}},
                        {resizable: true, title: '财务收款', field: 'collectionCode', slots: {default: 'collection'}},
                        {resizable: true, title: '收款公司', field: 'paymentMethod', slots: {default: 'paymentMethod'},width: '250',
                            filters: this.paymentMethodKinds,
                            filterMethod: this.PaymentMethodFilter},
                        {title: '操作', field: 'operation', fixed: 'right', width: 100, slots: {default: 'operate'}}
                    ]
                }
            },
            issuedElectronicInvoicableTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    rowConfig: {
                        isHover: true
                    },
                    data: this.invoices,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {resizable: true, title: '账单月份', field: 'billMonth'},
                        {resizable: true, title: '收费项目', field: 'u8fee'},
                        {resizable: true, title: '开票信息', field: 'invoiceInfo'}
                    ]
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            U8FeeFilter({value, row, column}) {
                return row.u8feeCode == value
            },
            PaymentMethodFilter({value, row, column}) {
                return row.paymentMethod == value
            },
            submit() {

                switch (this.searchObj.type) {
                    case '可开电子票账单':
                        this.loading = true
                        GetElectronicInvoicableWuyeBills(this.searchObj.projName).then((res) => {
                            this.bills = res
                            this.loading = false
                        })
                        break
                    case '已开电子票':
                        this.loading = true
                        if (this.searchObj.months == null) {
                            this.invoices = []
                            this.loading = false
                        } else {
                            GetIssuedElectronicInvoices(this.searchObj).then((res) => {
                                this.invoices = res
                                this.loading = false
                            })
                        }
                        break
                }
            },
            cellClick({row}) {
                this.detailDrawerTitle = row.billMonth + " " + row.clientName + " " + row.u8fee
                this.billId = row.id
                this.detailDrawer = true
            },
            btnClick(row) {
                this.makeDrawerTitle = row.billMonth + " " + row.clientName + " " + row.u8fee
                this.makeId = row.id
                this.makeDrawer = true
            },
            MakeWyMpInvoice() {
                this.submit()
                this.makeDrawer = false
            },
            cellClick2({row}) {
                this.invoiceDrawerTitle = row.billMonth + " " + row.clientName + " " + row.u8fee + " - 电子发票"
                this.invoiceInfo = row.invoiceInfo
                this.paymentMethod = row.paymentMethod
                this.invoiceDrawer = true
            }
        },
        created() {

            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeAuthority(this.unionid).then((res) => {

                /* 权限控制 */
                if (res.authorities.indexOf('mpInvoiceMake') != -1) {
                    this.authority = true
                } else {
                    this.authority = false
                }

                this.searchObj.projName = '稻谷国际中心'
                if (res.projName === 'ALL') {
                    GetProjectsWithoutAll().then((res) => {
                        this.projects = res
                    })
                } else {
                    this.projects = []
                    var arr = res.projName.split(',')

                    this.searchObj.projName = arr[0]

                    for (var i = 0; i <= arr.length - 1; i++) {
                        this.projects.push({
                            projName: arr[i]
                        })
                    }
                }

                this.submit()
            })

        },
        mounted() {
            this.$nextTick(function () {
                if (this.authority) {
                    this.bigHeight = this.$refs.collapse.$el.offsetHeight
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                }
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .electronicInvoicableTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .electronicInvoicableTable >>> .font-blue {
        color: #45aaf2;
    }

    .electronicInvoicableTable >>> .footer-row {
        background-color: #F8F8F9;
    }

    .issuedElectronicInvoicableTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .issuedElectronicInvoicableTable >>> .font-blue {
        color: #45aaf2;
    }

    .issuedElectronicInvoicableTable >>> .footer-row {
        background-color: #F8F8F9;
    }

</style>
