<template>
    <div>

        <FolderFormTable>

            <el-form slot="leftContent" :model="searchObj" label-width="80px" size="small">

                <el-form-item label="客户名称">
                    <el-input v-model="searchObj.clientName" clearable style="width: 215px" @change="submit"></el-input>
                </el-form-item>

                <el-form-item label="联系人">
                    <el-input v-model="searchObj.contactPerson" clearable style="width: 215px"
                              @change="submit"></el-input>
                </el-form-item>

                <el-form-item label="联系电话">
                    <el-input v-model="searchObj.contactInfo" clearable style="width: 215px"
                              @change="submit"></el-input>
                </el-form-item>

                <el-form-item label="微信">
                    <el-input v-model="searchObj.wechat" clearable style="width: 215px" @change="submit"></el-input>
                </el-form-item>

                <el-form-item style="text-align: right;padding-right: 28px">
                    <el-button size="small" plain @click="reset">重置</el-button>
                    <el-button size="small" type="primary" @click="submit">搜索</el-button>
                </el-form-item>

            </el-form>

            <!--表格-->
            <div slot="rightContent" v-loading="loading">

                <el-tag type="success" style="width: 100%;margin-bottom: 10px">
                    <i class="el-icon-search" style="margin-right: 5px"></i>
                    <span>共查询到 {{total}} 条数据</span>
                </el-tag>

                <div style="height: -webkit-calc(100vh - 205px)">
                    <vxe-grid
                            ref="xTable"
                            id="businessSearchTable"
                            class="businessSearchTable"
                            v-bind="businessSearchTable"
                            @cell-click="cellClick"
                            @page-change="PageChange">
                    </vxe-grid>
                </div>
            </div>
        </FolderFormTable>

        <!-- 抽屉 -->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <DailyReportDetail v-if="type === 'dailyReport'" :id="id"></DailyReportDetail>
            <BusinessInfo v-else :id="id"></BusinessInfo>
        </el-drawer>

    </div>
</template>

<script>
    import FolderFormTable from "../utils/FolderFormTable";
    import {SearchBusiness, SearchBusinessCount} from "../../api";
    import BusinessInfo from "./BusinessInfo"
    import DailyReportDetail from "./DailyReportDetail"
    import XEUtils from 'xe-utils'

    export default {
        name: "BusinessSearch",
        components: {FolderFormTable, BusinessInfo, DailyReportDetail},
        data() {
            return {
                loading: false,
                drawer: false,
                drawerTitle: '',
                type: null,
                searchObj: {
                    clientName: '',
                    contactPerson: '',
                    contactInfo: '',
                    wechat: ''
                },
                businesses: [],
                id: null,
                currentPage: 1,
                pageSize: 50,
                total: 0
            }
        },
        computed: {
            businessSearchTable() {
                return {
                    height: 'auto',
                    size: 'small',
                    showOverflow: true,
                    rowConfig: {
                        isHover: true
                    },
                    pagerConfig: {
                        layouts: ['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Sizes', 'Total'],
                        pageSizes: [10, 20, 50, 100],
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        total: this.total
                    },
                    data: this.businesses,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '类型', field: 'type'},
                        {
                            resizable: true, title: '创建时间', field: 'time', formatter: function ({cellValue}) {
                                return XEUtils.toDateString(cellValue, 'yyyy-MM-dd')
                            }
                        },
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {resizable: true, title: '业务员', field: 'name'},
                    ]
                }
            }
        },
        methods: {
            reset() {
                this.searchObj.clientName = ''
                this.searchObj.contactPerson = ''
                this.searchObj.contactInfo = ''
                this.searchObj.wechat = ''
                this.businesses = []
            },
            GetInfo() {
                this.loading = true
                SearchBusinessCount(this.searchObj).then((res) => {
                    this.total = res
                    SearchBusiness(this.searchObj, this.pageSize, this.currentPage).then((res) => {
                        this.businesses = res
                        this.loading = false
                    })
                })
            },
            submit() {
                this.currentPage = 1
                this.pageSize = 50
                this.GetInfo()
            },
            cellClick({row}) {

                console.log(row)

                if (row.type === '商机') {
                    this.type = 'businessInfo'
                    this.id = Number(row.id)
                } else {
                    this.type = 'dailyReport'
                    this.id = Number(row.id)
                }
                this.drawerTitle = row.clientName + ' - 详情'
                this.drawer = true
            },
            PageChange({currentPage, pageSize}) {
                this.currentPage = currentPage
                this.pageSize = pageSize
                this.GetInfo()
            },
        }
    }
</script>

<style scoped>

    .businessSearchTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .businessSearchTable >>> .font-blue {
        color: #45aaf2;
    }


</style>
