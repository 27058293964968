<template>
    <div class="container" v-loading="allLoading">

        <el-form :model="addForm" label-width="80px" size="medium">

            <el-form-item label="公司名称" required>
                <el-input v-model="addForm.companyName" @input="check" @blur="searchClient" clearable
                          @clear="clear"></el-input>
            </el-form-item>

            <div v-loading="loading">
                <el-alert
                        :title="title"
                        type="success"
                        :closable="false" style="margin-bottom: 10px">
                </el-alert>
                <vxe-grid v-bind="table" v-if="clients.length > 0">
                    <template #operate="{ row }">
                        <el-button type="primary" size="mini" plain @click="choose(row)">选择</el-button>
                    </template>
                </vxe-grid>
            </div>

            <el-divider></el-divider>

            <el-form-item label="类型" required>
                <el-select v-model="addForm.kind" placeholder="是否为客户" style="width: 100%" @change="check">
                    <el-option label="客户" :value="true"></el-option>
                    <el-option label="非客户" :value="false"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="主营业务" required>
                <el-input v-model="addForm.mainBusiness" clearable @input="check"></el-input>
            </el-form-item>

            <el-form-item label="地址" required>
                <el-input v-model="addForm.address" clearable @input="check"></el-input>
            </el-form-item>

            <el-form-item label="接待人" required>
                <el-input v-model="addForm.receptionist" clearable @input="check"></el-input>
            </el-form-item>

            <el-form-item label="电话" required>
                <el-input v-model="addForm.contactInfo" clearable @input="check"></el-input>
            </el-form-item>

            <el-form-item label="微信" required>
                <el-input v-model="addForm.wechat" clearable @input="check"></el-input>
            </el-form-item>

            <el-divider></el-divider>

            <el-form-item label="拜访时间" required>
                <el-date-picker style="width: 100%"
                                v-model="addForm.visitTime"
                                type="datetime"
                                :clearable="false"
                                value-format="yyyy-MM-dd hh:mm"
                                placeholder="选择日期时间" @change="check">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="当前阶段" required>
                <el-input v-model="addForm.currentStage" clearable @input="check"></el-input>
            </el-form-item>

            <el-form-item label="拜访内容" required>
                <el-input
                        type="textarea"
                        :autosize="{ minRows: 4}"
                        placeholder="拜访内容"
                        v-model="addForm.visitContent" @input="check">
                </el-input>
            </el-form-item>

            <el-form-item label="协同人">
                <el-select v-model="addForm.coordinator"
                           clearable multiple filterable placeholder="请选择协同人" style="width: 100%">
                    <el-option
                            v-for="item in employees"
                            :key="item.unionid"
                            :label="item.name"
                            :value="item.unionid">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" :disabled="!disable" @click="submit">提交</el-button>
            </el-form-item>

        </el-form>

    </div>
</template>

<script>
    import {GetEmployees, GetSimilarDailyReports, InsertDailyReport} from "../../api";
    import XEUtils from 'xe-utils'

    export default {
        name: "DailyReportAdd",
        data() {
            return {
                allLoading: false,
                unionid: null,
                loading: false,
                title: '无相似客户',
                addForm: {
                    visitTime: XEUtils.toDateString(Date.now(), 'yyyy-MM-dd hh:mm'),
                    companyName: null,
                    kind: null,
                    mainBusiness: null,
                    address: null,
                    receptionist: null,
                    contactInfo: null,
                    wechat: null,
                    currentStage: null,
                    visitContent: null,
                    coordinator: null
                },
                clients: [],
                employees: [],
                disable: false
            }
        },
        watch: {
            clients: function () {
                if (this.clients.length > 0) {
                    this.title = '存在以下相似客户可选择'
                } else {
                    this.title = '无相似客户'
                }
            }
        },
        computed: {
            table() {
                return {
                    size: 'mini',
                    columnConfig: {
                        resizable: true
                    },
                    maxHeight: '500px',
                    showOverflow: false,
                    columns: [
                        {field: 'companyName', title: '公司名称'},
                        {field: 'visitTime', title: '上次拜访'},
                        {field: 'currentStage', title: '当前阶段'},
                        {title: '操作', fixed: 'right', width: 100, slots: {default: 'operate'}}
                    ],
                    data: this.clients
                }
            }
        },
        methods: {
            searchClient() {
                if (this.addForm.companyName == null || String(this.addForm.companyName).trim() === '') {

                } else {
                    this.loading = true
                    GetSimilarDailyReports(this.unionid, this.addForm.companyName).then((res) => {
                        this.clients = res
                        this.loading = false
                    })
                }
            },
            clear() {
                this.addForm.companyName = null
                this.clients = []
            },
            choose(row) {
                this.addForm.companyName = row.companyName
                this.addForm.kind = row.kind
                this.addForm.mainBusiness = row.mainBusiness
                this.addForm.address = row.address
                this.addForm.receptionist = row.receptionist
                this.addForm.contactInfo = row.contactInfo
                this.addForm.wechat = row.wechat
                this.clients = []
            },
            check() {
                if (this.addForm.companyName == null || String(this.addForm.companyName).trim() === ''
                    || this.addForm.kind === null
                    || this.addForm.mainBusiness == null || String(this.addForm.mainBusiness).trim() === ''
                    || this.addForm.address == null || String(this.addForm.address).trim() === ''
                    || this.addForm.receptionist == null || String(this.addForm.receptionist).trim() === ''
                    || this.addForm.contactInfo == null || String(this.addForm.contactInfo).trim() === ''
                    || this.addForm.wechat == null || String(this.addForm.wechat).trim() === ''
                    || this.addForm.currentStage == null || String(this.addForm.currentStage).trim() === ''
                    || this.addForm.visitContent == null || String(this.addForm.visitContent).trim() === '') {
                    this.disable = false
                } else {
                    this.disable = true
                }
            },
            submit() {
                this.allLoading = true
                InsertDailyReport(this.addForm, this.unionid).then((res) => {
                    this.allLoading = false
                    this.$emit('DailyReportAdd')
                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetEmployees('招商部', this.unionid).then((res) => {
                this.employees = res
            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

</style>
