<template>
    <div class="container">

        <el-card shadow="hover" style="margin-bottom: 10px"
                 v-for="unreadComment in unreadComments" :key="unreadComment.senderName + unreadComment.time">
            <p style="font-size: 15px">
                {{unreadComment.senderName}}
                <span style="color: #b3b1b1">{{unreadComment.kind==='comment'?'评论了：':'回复了：'}}</span>
            </p>
            <p style="font-size: 15px">
                <span>{{unreadComment.type==='businessComment'?'商机：':'拜访记录：'}}</span>
                {{unreadComment.companyName}}
            </p>
            <div style="font-size: 14px;color: #b3b1b1">{{unreadComment.time}}</div>
            <div style="width: 100%;text-align: right">
                <el-button type="primary" plain size="small" @click="click(unreadComment)">查看</el-button>
            </div>
        </el-card>

        <el-drawer :visible.sync="drawer" size="50%"
                   destroy-on-close
                   :append-to-body="true">
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="success"
                        :closable="false">
                </el-alert>
            </template>
            <DailyReportDetail v-if="type === 'dailyReport'" :id="id" :tab="tab"></DailyReportDetail>
            <BusinessInfo v-else :id="id" :tab="tab"></BusinessInfo>
        </el-drawer>

    </div>
</template>

<script>
    import {GetUnreadCommentList} from "../../api";
    import BusinessInfo from "./BusinessInfo"
    import DailyReportDetail from "./DailyReportDetail"

    export default {
        name: "UnreadComments",
        components: {BusinessInfo, DailyReportDetail},
        data() {
            return {
                unionid: null,
                drawer: false,
                drawerTitle: '',
                unreadComments: [],
                type: null,
                tab: null,
                id: null
            }
        },
        watch: {
            drawer: function (value, oldValue) {
                if (!value) {
                    GetUnreadCommentList(this.unionid).then((res) => {
                        this.unreadComments = res
                    })
                }
            }
        },
        methods: {
            click(e) {
                if (e.type === 'businessComment') {
                    this.type = 'businessInfo'
                    this.tab = 'third'
                } else {
                    this.type = 'dailyReport'
                    this.tab = 'second'
                }
                this.id = Number(e.id)
                this.drawerTitle = e.companyName + ' - 详情'
                this.drawer = true
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetUnreadCommentList(this.unionid).then((res) => {
                this.unreadComments = res
            })
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

</style>
