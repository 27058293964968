<template>
    <div class="container" v-loading="loading">

        <el-form :model="form" label-width="80px" size="medium">

            <el-form-item label="所属项目:">

                <el-select v-model="form.projName" placeholder="所属项目" @change="ProjChange" style="width: 100%">
                    <el-option v-for="project in projects" :key="project.abbreviation"
                               :value="project.projName"
                               :label="project.projName"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="客户名称:">
                <el-select v-model="form.clientContract" placeholder="客户名称" @change="ClientChange" style="width: 100%"
                           filterable :disabled="clients.length == 0">
                    <el-option v-for="client in clients" :key="client.clientId"
                               :value="client.contract"
                               :label="client.clientName"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="合同编号:">
                <el-select v-model="form.contractNo" placeholder="合同编号" @change="ContractChange" style="width: 100%"
                           :disabled="contracts.length == 0">
                    <el-option v-for="contract in contracts" :key="contract.contractNo"
                               :value="contract.contractNo"
                               :label="contract.contractNo">

                        <span style="float: left">{{ contract.contractNo }} </span>
                        <span style="float: right; text-align: right;color: #8492a6; font-size: 13px;width: 250px;overflow: hidden;text-overflow: ellipsis">{{ contract.houseName }}</span>

                    </el-option>
                </el-select>
            </el-form-item>


        </el-form>

        <div v-if="form.contractNo != '' && form.contractNo != null">
            <el-divider content-position="left">合同信息</el-divider>
            <el-form label-width="80px" size="medium">
                <el-form-item label="合同周期">
                    <el-input v-model="contractInfo.contractStartDate + ' ~ ' + contractInfo.contractEndDate"
                              readonly></el-input>
                </el-form-item>

                <el-form-item label="合同房源">
                    <el-input
                            type="textarea"
                            :autosize="{ minRows: 1}"
                            v-model="contractInfo.houseName" readonly>
                    </el-input>
                </el-form-item>
            </el-form>


            <vxe-grid ref="rentalContractBillCollectionTable"
                      id="rentalContractBillCollectionTable"
                      class="rentalContractBillCollectionTable"
                      v-bind="rentalContractBillCollectionTable" style="margin-bottom: 50px">

                <template #state="{ row }">

                    <el-tag v-if="row.unpaidAmount <= 0" type="success" size="small">已收款</el-tag>
                    <el-button v-else size="mini" type="danger" @click="Collect(row.id)">收款</el-button>

                </template>
            </vxe-grid>
        </div>

        <el-dialog title="收款" :visible.sync="formShow" :append-to-body="true"
                   :close-on-click-modal="false" :close-on-press-escape="false">

            <el-form ref="rentalBillCollectionForm" :form="collectionForm" label-width="80px" size="medium">

                <el-form-item label="账单周期">
                    <el-input v-model="collectionForm.startDate + ' ~ ' + collectionForm.endDate" disabled></el-input>
                </el-form-item>

                <el-form-item label="账单金额">
                    <el-input :value="MoneyFormat(collectionForm.amount)" disabled></el-input>
                </el-form-item>

                <el-form-item label="减免金额">
                    <el-input-number v-model="collectionForm.reliefAmount"
                                     :precision="2" :step="0.01" :max="collectionForm.amount">
                    </el-input-number>
                </el-form-item>

                <el-form-item label="已收金额">
                    <el-input-number v-model="collectionForm.receivedAmount"
                                     :precision="2" :step="0.01">
                    </el-input-number>
                </el-form-item>

                <el-form-item label="收款日期">
                    <el-date-picker style="width: 100%"
                                    v-model="collectionForm.collectionDate"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="收款日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="formShow = false">取 消</el-button>
                <el-button type="primary" @click="SubmitCollection">确 定</el-button>
            </div>

        </el-dialog>
    </div>
</template>

<script>
    import {
        GetProjectsWithoutAll, GetRentalBillDetail,
        GetRentalBillList_ByClient,
        GetRentalClientInfos,
        GetRentalContractBills, UpdateRentalBill
    } from "../../api";
    import XEUtils from "xe-utils";
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "RentalBillCollection",
        data() {
            return {
                loading: false,
                form: {
                    projName: '',
                    clientContract: '',
                    contractNo: ''
                },
                projects: [],
                clients: [],
                contracts: [],
                billList: [],
                contractInfo: {},
                formShow: false,
                collectionForm: {
                    billId: null,
                    startDate: '',
                    endDate: '',
                    amount: 0,
                    reliefAmount: 0,
                    receivedAmount: 0,
                    collectionDate: ''
                }
            }
        },
        computed: {
            rentalContractBillCollectionTable: function () {
                return {
                    height: '500',
                    size: 'small',
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    scrollY: {
                        mode: 'wheel'
                    },
                    columnConfig: {
                        width: 'auto',
                        minWidth: '130'
                    },
                    data: this.billList,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {
                            resizable: true, title: '费项', width: '180', formatter: function ({row}) {
                                return row.fee + '\n' + row.startDate + '~' + row.endDate
                            }
                        },
                        {
                            resizable: true,
                            title: '账单金额',
                            field: 'amount',
                            formatter: 'FormatMoney',
                            className: 'font-blue'
                        },
                        {resizable: true, title: '减免金额', field: 'reliefAmount', formatter: 'FormatMoney'},
                        {resizable: true, title: '已收金额', field: 'receivedAmount', formatter: 'FormatMoney'},
                        {
                            resizable: true, title: '未缴金额', field: 'unpaidAmount', formatter: 'FormatMoney',
                        },
                        {resizable: true, title: '应缴日期', field: 'dueDate'},
                        {
                            resizable: true, title: '状态', fixed: 'right', width: '100', slots: {default: 'state'}
                        }
                    ]
                }
            }
        },
        methods: {
            MoneyFormat(value) {
                return formatMoney(value)
            },
            async ProjChange() {
                this.loading = true
                this.form.clientContract = ''
                this.form.contractNo = ''
                this.clients = []
                this.contracts = []
                await GetRentalBillList_ByClient(9999, 1, {
                    projName: [this.form.projName],
                    clientName: ''
                }).then((res) => {
                    this.clients = res.list
                })
                this.loading = false
            },
            async ClientChange() {
                this.loading = true
                this.form.contractNo = ''
                this.contracts = []
                await GetRentalClientInfos(this.form.clientContract.split(",")[0]).then((res) => {
                    this.contracts = res.contractList
                })
                this.loading = false
            },
            async ContractChange() {
                this.loading = true

                this.contractInfo = this.contracts.find(item => item.contractNo == this.form.contractNo)

                await GetRentalContractBills(this.form.contractNo).then((res) => {
                    this.billList = res
                })
                this.loading = false
            },
            DayCalculate(date) {
                return (XEUtils.getDateDiff(new Date(date).getTime(), new Date().getTime()).time / 86400000).toFixed(0)
            },
            async Collect(id) {
                this.loading = true
                await GetRentalBillDetail(id).then((res) => {

                    this.collectionForm.billId = res.id
                    this.collectionForm.startDate = res.startDate
                    this.collectionForm.endDate = res.endDate
                    this.collectionForm.amount = res.amount
                    this.collectionForm.reliefAmount = Number(res.reliefAmount)
                    this.collectionForm.receivedAmount = Number(res.receivedAmount)
                    this.collectionForm.collectionDate = res.collectionDate == null ? XEUtils.toDateString(Date.now(), 'yyyy-MM-dd') : res.collectionDate

                    this.formShow = true
                })
                this.loading = false
            },
            async SubmitCollection() {
                this.loading = true
                await UpdateRentalBill(this.collectionForm).then(async (res) => {
                    if (res === 'success') {

                        await GetRentalContractBills(this.form.contractNo).then((res) => {
                            this.billList = res
                        })
                        this.$message.success('保存成功！');
                    }else{
                        this.$message.error('保存失败！');
                    }
                })
                this.formShow = false
                this.loading = false
            }
        },
        async created() {
            this.loading = true
            await GetProjectsWithoutAll().then((res) => {
                this.projects = res
            })
            this.loading = false
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .rentalContractBillCollectionTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .rentalContractBillCollectionTable >>> .font-blue {
        color: #45aaf2;
    }

    .rentalContractBillCollectionTable >>> .font-red {
        color: #f10716;
    }

    .rentalContractBillCollectionTable >>> .footer-row {
        background-color: #F8F8F9;
    }

</style>
