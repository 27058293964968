<template>
    <div class="container">

        <el-form :model="pdfForm" label-width="80px" v-loading="loading">

            <el-form-item label="选择月份">
                <el-date-picker :clearable="false" style="width: 100%"
                                v-model="pdfForm.month"
                                value-format="yyyy-MM"
                                type="month" :picker-options="pickerOptions" placeholder="选择月" @change="check">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="收款公司">
                <el-select v-model="pdfForm.paymentMethod"
                           placeholder="选择收款公司" style="width: 100%" @change="GetClients">
                    <el-option value="1" label="江苏稻谷物业管理有限公司苏州分公司"></el-option>
                    <el-option value="3" label="苏州步步高物业管理有限公司"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="选择客户">
                <el-select v-model="pdfForm.clientIds" clearable multiple filterable
                           placeholder="请选择客户" style="width: 100%" @change="check">
                    <el-option
                            v-for="item in clients"
                            :key="item.clientId"
                            :label="item.clientName"
                            :value="item.clientId">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" @click="submit" :disabled="!able">生成PDF</el-button>
            </el-form-item>

        </el-form>

    </div>
</template>

<script>
    import XEUtils from 'xe-utils'
    import {ExportElecBillPDF, GetElecBillClients} from "../../api";

    export default {
        name: "ElecBillPdf",
        data() {
            return {
                clients: [],
                pdfForm: {
                    month: XEUtils.toDateString(XEUtils.getWhatMonth(new Date(), -1), 'yyyy-MM'),
                    paymentMethod:'3',
                    clientIds: []
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > XEUtils.getWhatMonth(new Date(), -1);
                    },
                },
                able: true,
                loading: false,
            }
        },
        props: {
            abbreviation: {
                type: String,
                required: true
            }
        },
        methods: {
            check() {
                if (this.pdfForm.clientIds.length == 0 || this.pdfForm.month == null) {
                    this.able = false
                } else {
                    this.able = true
                }
            },
            submit() {
                this.loading = true
                ExportElecBillPDF(this.abbreviation, this.pdfForm).then((res) => {
                    const link = document.createElement('a')
                    let blob = new Blob([res], {type: 'application/pdf'})
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    // 文件名称,可自定义,也可以从后端获取
                    link.download = "电费缴费通知单" + XEUtils.toDateString(XEUtils.now(), 'yyyyMMddHHmmss')
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    this.loading = false
                })
            },
            GetClients() {
                this.loading = true
                this.pdfForm.clientIds = []
                GetElecBillClients(this.abbreviation,this.pdfForm.month,this.pdfForm.paymentMethod).then((res) => {
                    for (var i = 0; i < res.length; i++) {
                        this.pdfForm.clientIds.push(res[i].clientId)
                    }
                    this.clients = res
                    this.loading = false
                })
            }
        },
        created() {
            this.GetClients()
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

</style>
