<template>

    <div>
        <!--搜索表单-->
        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
            <el-collapse-item name="collapseItem" class="form-container">
                <template slot="title">
                    <el-tag type="success" style="width: 100%;padding-left: 15px">
                        <i class="el-icon-search" style="margin-right: 5px"></i>
                        <span>共查询到 {{elecMeters.length}} 条数据</span>
                    </el-tag>
                </template>

                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px" style="padding-left: 15px">

                    <el-form-item label="所属项目:">
                        <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                            <el-option v-for="project in projects" :key="project.abbreviation"
                                       :value="project.abbreviation"
                                       :label="project.projName"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="表计编号:">
                        <el-input v-model="searchObj.elecMeterId" placeholder="表计编号" clearable
                                  @change="submit"></el-input>
                    </el-form-item>

                    <el-form-item label="表计名称:">
                        <el-input v-model="searchObj.elecMeterName" placeholder="表计名称" clearable
                                  @change="submit"></el-input>
                    </el-form-item>

                </el-form>
            </el-collapse-item>
        </el-collapse>

        <!-- 表格 -->
        <div :style="tableContainerStyle">
            <vxe-grid ref="xTable"
                      id="elecMeterTable"
                      class="elecMeterTable"
                      v-bind="elecMeterTable"
                      @toolbar-button-click="toolbarButtonClickEvent"
                      @cell-click="cellClick">
            </vxe-grid>
        </div>

        <!--抽屉-->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <ElecMeterDetail :meterId="meterId"></ElecMeterDetail>
        </el-drawer>

    </div>

</template>

<script>
    import {GetProjectsWithoutAll, GetElecMeters} from "../../api";
    import ElecMeterDetail from "./ElecMeterDetail";

    export default {
        name: "ElecMeterManagement",
        components: {ElecMeterDetail},
        data() {
            return {
                drawer: false,
                drawerTitle: '',
                projects: [],
                elecMeters: [],
                meterId: null,
                meterAttributes: [
                    {label: '总分表计', value: '总分表计'},
                    {label: '公摊表计', value: '公摊表计'},
                    {label: '自用表计', value: '自用表计'}
                ],
                searchObj: {
                    projName: 'GJZX',
                    elecMeterId: '',
                    elecMeterName: ''
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
            }
        },
        computed: {
            /*表格实例对象*/
            elecMeterTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'print', name: '打 印', icon: 'vxe-icon--print'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'},
                        ],
                        size: 'medium',
                        custom: true
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    rowClassName: this.rowStyle,
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    printConfig: {
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.elecMeters,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '表计名称', field: 'meterName'},
                        {resizable: true, title: '表计编号', field: 'meterId'},
                        {
                            resizable: true, title: '表计属性', field: 'meterAttribute',
                            filters: this.meterAttributes,
                            filterMethod: this.MeterAttributeFilter
                        },
                        {resizable: true, title: '倍率', field: 'magnification'},
                        {resizable: true, title: '撤表信息', field: 'removeInfo'},
                    ]
                }
            }
        },
        methods: {
            MeterAttributeFilter({value, row, column}) {
                return row.meterAttribute == value
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        GetElecMeters(this.searchObj).then((res) => {
                            this.elecMeters = res
                        })
                        break
                    case 'export-plus':
                        this.$refs.xTable.openExport()
                        break
                    case 'print':
                        this.$refs.xTable.openPrint()
                        break
                    case 'zoom':
                        this.$refs.xTable.zoom()
                        break

                }
            },
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            submit() {
                GetElecMeters(this.searchObj).then((res) => {
                    this.elecMeters = res
                })
            },
            cellClick({row}) {
                this.meterId = row.meterId
                this.drawerTitle = row.meterName + ' - ' + row.meterAttribute + ' - ' + row.meterId
                this.drawer = true
            },
            rowStyle({row}) {
                switch (row.meterAttribute) {
                    case '总分表计':
                        return 'row-blue'
                        break
                    case '公摊表计':
                        return 'row-yellow'
                        break
                    case '自用表计':
                        return 'row-green'
                        break

                }
            }
        },
        created() {
            GetProjectsWithoutAll().then((res) => {
                this.projects = res
            })

            GetElecMeters(this.searchObj).then((res) => {
                this.elecMeters = res
            })
        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .elecMeterTable >>> .row-blue {
        background-color: #dff7fe;
    }

    .elecMeterTable >>> .row-green {
        background-color: #e9fcef;
    }

    .elecMeterTable >>> .row-yellow {
        background-color: #fefeda;
    }


    .elecMeterTable >>> .vxe-body--row {
        cursor: pointer;
    }

</style>
