<template>

    <div class="container" v-loading="loading">

        <el-steps simple :active="step" finish-status="success">
            <el-step title="检查开票内容"></el-step>
            <el-step title="开票结果"></el-step>
        </el-steps>

        <transition name="el-fade-in">
            <div v-show="step == 0">

                <div :style="InvoiceStyle">
                    <el-form :model="form" :rules="rules" ref="parkInvoiceOpenForm" size="small" label-position="top">
                        <el-divider content-position="left">推送方式（电话、邮箱同步于小程序用户资料）</el-divider>

                        <el-row :gutter="20">
                            <el-col :span="6">
                                <el-form-item label="推送电话" prop="phone" required>
                                    <el-input v-model="form.phone" clearable placeholder="请输入手机号码"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="推送邮箱" prop="email" required>
                                    <el-input v-model="form.email" clearable placeholder="请输入邮箱"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="小程序推送">
                                    <el-input :value="form.miniPro?'自动推送':'未绑定小程序'" disabled></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-divider content-position="left">开票公司</el-divider>

                        <el-row :gutter="20">
                            <el-col :span="6">
                                <el-form-item label="公司名称">
                                    <el-input :value="form.paymentMethod==='1'?'江苏稻谷物业管理有限公司苏州分公司':'苏州步步高物业管理有限公司'"
                                              disabled></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="发票种类" prop="invoiceLine" required>
                                    <el-select v-model="form.invoiceLine"
                                               placeholder="选择发票种类" style="width: 100%">
                                        <el-option value="bs" label="数电专票(电子)"></el-option>
                                        <el-option value="pc" label="数电普票(电子)"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="先票后款" required>
                                    <el-radio v-model="form.invoiceFirst" :label="false">否</el-radio>
                                    <el-radio v-model="form.invoiceFirst" :label="true">是</el-radio>
                                </el-form-item>
                            </el-col>


                        </el-row>

                        <el-divider content-position="left">开票内容（抬头信息同步于物业客户档案）</el-divider>
                        <el-row :gutter="20">
                            <el-col :span="6">
                                <el-form-item label="购方名称" prop="clientName" required>
                                    <el-input v-model="form.clientName" type="textarea" :rows="1"
                                              resize="none" @change="CheckInvoiceLine"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="购方税号（个人客户填身份证号）" required prop="taxNumber">
                                    <el-input v-model="form.taxNumber" type="textarea" :rows="1"
                                              resize="none"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="开户行">
                                    <el-input v-model="form.bank"
                                              type="textarea" :rows="1" resize="none"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="账号">
                                    <el-input v-model="form.bankAccount"
                                              type="textarea" :rows="1" resize="none"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="地址">
                                    <el-input v-model="form.address"
                                              type="textarea" :rows="1" resize="none"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="电话">
                                    <el-input v-model="form.telephone"
                                              type="textarea" :rows="1" resize="none"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="开票金额">
                                    <el-input :value="FormatMoney(form.totalAmount)" disabled></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="商品明细">
                                    <el-button style="width: 100%" type="info" @click="drawer = true">点击查看明细
                                    </el-button>
                                </el-form-item>
                            </el-col>

                            <el-col :span="24">
                                <el-form-item label="发票备注" prop="content" required>
                                    <el-input v-model="form.content" type="textarea" placeholder="请输入开票内容"
                                              :rows="4" :maxlength="200"
                                              @keydown.enter.native="handleTextareaKeydown"
                                              resize="none">
                                    </el-input>
                                </el-form-item>
                            </el-col>

                        </el-row>
                    </el-form>
                </div>

                <el-row :gutter="20">
                    <el-col :span="24" style="text-align: right">
                        <el-button @click="ToStep1" type="primary" size="medium" style="margin-top: 5px">开 票<i
                                class="el-icon-arrow-right el-icon--right"></i></el-button>
                    </el-col>
                </el-row>

            </div>
        </transition>

        <transition name="el-fade-in">
            <div v-show="step > 1" style="padding-top: 150px">

                <!-- 1、开票结果 2、同步账单 3、小程序推送 -->
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-result :icon="resultInvoice" title="开票结果" :subTitle="resultInvoiceDesc"></el-result>
                    </el-col>
                    <el-col :span="8">
                        <el-result :icon="resultBill" title="账单同步" :subTitle="resultBillDesc"></el-result>
                    </el-col>
                    <el-col :span="8">
                        <el-result :icon="resultMiniPro" title="小程序推送" :subTitle="resultMiniProDesc"></el-result>
                    </el-col>

                    <el-col :span="24" style="text-align: center">
                        <el-button type="primary" size="medium" style="margin-top: 50px" @click="closeBtn">完 成
                        </el-button>
                    </el-col>
                </el-row>
            </div>
        </transition>


        <!-- 商品明细 -->
        <el-drawer :visible.sync="drawer" size="60%" destroy-on-close :append-to-body="true">
            <template slot="title">
                <el-alert
                        title="商品明细"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <div class="container">
                <vxe-grid ref="parkOrderInvoiceDetailTable"
                          id="parkOrderInvoiceDetailTable"
                          class="parkOrderInvoiceDetailTable"
                          v-bind="parkOrderInvoiceDetailTable">
                </vxe-grid>
            </div>
        </el-drawer>

    </div>

</template>

<script>
    import {
        BackFillInvoiceInfo,
        GetInvoiceDetail,
        GetParkInvoiceOpenInfo,
        GetProjNameDetail, PushInvoiceNotification,
        RequestShuDianInvoice, SendParkInvoiceMsg
    } from "../../api";
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "ParkInvoiceOpen",
        props: {
            applicationId: {
                type: Number,
                required: true
            }
        },
        data() {
            var validatePhone = (rule, value, callback) => {
                if (value === '' || value == null) {
                    callback(new Error('手机号不能为空'));
                } else {
                    var regexPhone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
                    if (!(regexPhone.test(value))) {
                        callback(new Error('手机号格式不正确'));
                    }
                    callback();
                }
            }

            var validateEmail = (rule, value, callback) => {
                if (value === '' || value == null) {
                    callback(new Error('邮箱不能为空'));
                } else {
                    var regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                    if (!(regexEmail.test(value))) {
                        callback(new Error('邮箱格式不正确'));
                    }
                    callback();
                }
            };

            var validateInvoiceLine = (rule, value, callback) => {
                if (value === '' || value == null) {
                    callback(new Error('不能为空'));
                } else {
                    if (this.form.clientName.length <= 4 && value === 'bs') {
                        callback(new Error('个人客户请开普票'));
                    } else {
                        callback();
                    }
                }
            }

            var validateContent = (rule, value, callback) => {
                if (value != null && value.indexOf('null') == -1 && value != '') {
                    if (value.length > 200) {
                        callback(new Error('长度不可超过200个字'));
                    } else {
                        callback();
                    }
                } else {
                    callback(new Error('开票内容有误或为空'));
                }
            };

            return {
                loading: false,
                unionid: null,
                InvoiceStyle: {
                    height: '',
                    overflow: 'auto',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                },
                step: 0,
                form: {
                    phone: null,
                    email: null,
                    miniPro: true,
                    paymentMethod: '3',
                    invoiceLine: null,
                    clientName: null,
                    taxNumber: null,
                    bank: null,
                    bankAccount: null,
                    address: null,
                    telephone: null,
                    content: null,
                    totalAmount: 0,
                    invoiceFirst: false
                },
                rules: {
                    phone: [
                        {validator: validatePhone, trigger: ['blur', 'change']}
                    ],
                    email: [
                        {validator: validateEmail, trigger: ['blur', 'change']}
                    ],
                    invoiceLine: [
                        {validator: validateInvoiceLine, trigger: ['blur', 'change']}
                    ],
                    content: [
                        {validator: validateContent, trigger: ['blur', 'change']}
                    ],
                    clientName: [
                        {required: true, message: '不能为空', trigger: ['blur', 'change']}
                    ],
                    taxNumber: [
                        {required: true, message: '不能为空', trigger: ['blur', 'change']}
                    ]
                },
                drawer: false,
                selectIds: [],
                detailList: [],
                resultInvoice: 'error',
                resultInvoiceDesc: null,
                resultBill: 'error',
                resultBillDesc: null,
                resultMiniPro: 'error',
                resultMiniProDesc: null,
            }
        },
        computed: {
            parkOrderInvoiceDetailTable: function () {
                return {
                    height: '600',
                    showOverflow: true,
                    size: 'small',
                    data: this.detailList,
                    columns: [
                        {resizable: true, title: '商品名称', field: 'goodsName'},
                        {resizable: true, title: '税收编码', field: 'goodsCode'},
                        {resizable: true, title: '含税金额', field: 'taxIncludedAmount',formatter: 'FormatMoney',},
                        {resizable: true, title: '税率', field: 'taxRate'},
                        {resizable: true, title: '税额', field: 'tax',formatter: 'FormatMoney',},
                        {resizable: true, title: '不含税金额', field: 'taxExcludedAmount',formatter: 'FormatMoney',}
                    ],
                    showFooter: true,
                    footerRowClassName: 'footer-row',
                    footerMethod({columns, data}) {

                        return [
                            columns.map((column, columnIndex) => {
                                if (columnIndex === 0) {
                                    return '合计：'
                                }

                                if (['taxIncludedAmount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return formatMoney(count.toFixed(2))
                                }

                                if (['tax'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return formatMoney(count.toFixed(2))
                                }

                                if (['taxExcludedAmount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return formatMoney(count.toFixed(2))
                                }

                                return ''
                            }),
                        ]
                    }
                }
            }
        },
        methods: {
            CheckInvoiceLine() {
                this.$refs.parkInvoiceOpenForm.validateField('invoiceLine');
            },
            FormatMoney(value) {
                return formatMoney(value)
            },
            handleTextareaKeydown() {
                const e = window.event || arguments[0]
                if (e.key === 'Enter' || e.code === 'Enter' || e.keyCode === 13) {
                    this.$message.warning('开票内容不可换行！');
                    e.returnValue = false
                    return false
                }
            },
            closeBtn() {
                this.$emit('OpenParkInvoice')
            },
            ToStep1() {
                this.resultInvoice = 'error'
                this.resultInvoiceDesc = null
                this.resultBill = 'error'
                this.resultBillDesc = null
                this.resultMiniPro = 'error'
                this.resultMiniProDesc = null

                this.$refs.parkInvoiceOpenForm.validate((e) => {
                    if (e) {
                        this.$alert('请确认开票内容无误！', '确认开票内容', {
                            confirmButtonText: '开票',
                            cancelButtonText: '取消',
                            cancelButtonClass: 'reject',
                            showCancelButton: true,
                            distinguishCancelAndClose: true,
                            type: 'warning',
                            callback: action => {
                                if (action === 'confirm') {
                                    //1、开票
                                    this.step++
                                    this.loading = true
                                    RequestShuDianInvoice(this.form, this.selectIds).then((res) => {

                                        if (res.code === 'E0000') {
                                            this.resultInvoice = 'success'
                                            this.resultInvoiceDesc = JSON.stringify(res)
                                            this.$message.success('开票成功！');
                                            // 2、同步账单
                                            BackFillInvoiceInfo(this.selectIds, res.result.invoiceSerialNum, this.form.invoiceFirst, this.unionid).then((res) => {
                                                if (res === 1) {
                                                    this.resultBill = 'success'
                                                    this.resultBillDesc = '开票信息同步至账单成功'
                                                    this.$message.success('开票信息同步至账单成功！');
                                                    // 3、微信推送
                                                    if (this.form.miniPro) {

                                                        SendParkInvoiceMsg(this.applicationId).then((res) => {

                                                            if (res === 1) {
                                                                this.resultMiniPro = 'success'
                                                                this.resultMiniProDesc = '推送成功'
                                                                this.step++
                                                                this.loading = false
                                                            } else {
                                                                this.resultMiniPro = 'error'
                                                                this.resultMiniProDesc = '推送失败'
                                                                this.step++
                                                                this.loading = false
                                                            }
                                                        })
                                                    } else {
                                                        this.resultMiniPro = 'info'
                                                        this.resultMiniProDesc = '未绑定小程序，无法推送'
                                                        this.loading = false
                                                    }
                                                } else {
                                                    this.$message.error('开票信息同步至账单失败！');
                                                    this.resultBill = 'error'
                                                    this.resultBillDesc = '开票信息同步至账单失败'
                                                    this.loading = false
                                                }
                                            })

                                        } else {
                                            this.resultInvoice = 'error'
                                            this.resultInvoiceDesc = JSON.stringify(res)
                                            this.resultBill = 'error'
                                            this.resultBillDesc = null
                                            this.resultMiniPro = 'error'
                                            this.resultMiniProDesc = null
                                            this.$message.error('开票失败！');
                                            this.loading = false
                                        }
                                    })
                                }
                                if (action === 'cancel') {

                                }
                            }
                        })
                    }else {
                        this.$message.error('有未填项或有内容格式不正确！');
                    }
                })

            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            this.$alert('开具数电发票前，请先与财务联系进行后台认证，否则将开票失败！', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    this.loading = true
                    GetParkInvoiceOpenInfo(this.applicationId).then((res) => {
                        this.selectIds = [res.bill.id]
                        var chargeContent = res.bill.chargeContent.split(';')
                        var content = '车位租赁费：' + res.bill.receivedAmount + '元，' + chargeContent[0] + ';' + res.invoiceInfo.buyerName

                        this.form.phone = res.invoiceInfo.userPhone
                        this.form.email = res.invoiceInfo.userEmail
                        this.form.paymentMethod = res.bill.paymentMethod
                        this.form.invoiceLine = res.application.type
                        this.form.clientName = res.invoiceInfo.buyerName
                        this.form.taxNumber = res.invoiceInfo.taxNum
                        this.form.bank = res.invoiceInfo.bank
                        this.form.bankAccount = res.invoiceInfo.bankAccount
                        this.form.address = res.invoiceInfo.address
                        this.form.telephone = res.invoiceInfo.telephone
                        this.form.totalAmount = res.bill.receivedAmount
                        this.form.invoiceFirst = res.bill.collectionCode==null?true:false

                        GetProjNameDetail(res.bill.projName).then((res) => {
                            this.form.content = res + content
                            this.loading = false


                            GetInvoiceDetail(this.selectIds).then((res) => {
                                this.detailList = res
                                this.loading = false
                            })

                        })
                    })

                }
            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
        white-space: pre-line
    }

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .parkOrderInvoiceDetailTable >>> .footer-row {
        background-color: #F8F8F9;
    }

</style>
