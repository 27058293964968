<template>

    <div>
        <el-card v-if="formShow" shadow="never">
            <el-form :model="transferForm" label-position="left" label-width="70px">
                <el-form-item label="产 证:">
                    <el-select v-model="transferForm.houseTransfer" placeholder="产证过户情况">
                        <el-option label="✅ 已过户" :value="true"></el-option>
                        <el-option label="❌ 未过户" :value="false"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="电 表:" prop="unitPrice">
                    <el-select v-model="transferForm.electricMeterTransfer" placeholder="电表过户情况">
                        <el-option label="✅ 已过户" :value="true"></el-option>
                        <el-option label="❌ 未过户" :value="false"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="float: right">
                    <el-button size="small" plain @click="FormShowChange">取消</el-button>
                    <el-button size="small" plain type="success" @click="FormSubmit">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-row :gutter="12" v-else>
            <el-col :span="24">
                <el-card shadow="hover"
                         @mouseenter.native="hover = true"
                         @mouseleave.native="hover = false">
                    <el-descriptions title="过户情况" :column="1" border size="medium" label-class-name="label">
                        <template slot="extra">
                            <el-button v-if="hover&&authority" size="small" type="danger" plain @click="FormShowChange">
                                修改
                            </el-button>
                        </template>
                        <el-descriptions-item label="产证过户">
                            <el-tag type="warning" size="small"
                                    v-if="houseTransferSituation.houseTransfer">已过户
                            </el-tag>
                        </el-descriptions-item>
                        <el-descriptions-item label="电表过户">
                            <el-tag type="warning" size="small"
                                    v-if="houseTransferSituation.electricMeterTransfer">已过户
                            </el-tag>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-card>
            </el-col>
        </el-row>
    </div>

</template>

<script>
    import {GetAuthority, InsertOperationRecord, UpdateTransferSituation} from '../../api/index'

    export default {
        name: "HouseTransferSituation",
        data() {
            return {
                unionid: null,
                hover: false,
                formShow: false,
                authority: false,
                transferForm: {
                    houseTransfer: null,
                    electricMeterTransfer: null
                }
            }
        },
        props: {
            houseTransferSituation: {
                type: Object,
                required: false
            }
        },
        methods: {
            FormShowChange() {
                this.formShow = !this.formShow
                this.hover = !this.hover
            },
            FormSubmit() {
                UpdateTransferSituation(this.houseTransferSituation.houseId,
                    this.transferForm.electricMeterTransfer, this.transferForm.houseTransfer).then((res) => {
                    if (res === 1) {
                        InsertOperationRecord(this.unionid, 'updateTransferSituation',
                            this.houseTransferSituation.houseId).then((res) => {
                            if (res === 1) {
                                this.formShow = false
                                this.$emit('UpdateTransferSituation')
                            }
                        })
                    }
                })
            }
        },
        watch: {
            houseTransferSituation: function () {
                this.transferForm.houseTransfer = this.houseTransferSituation.houseTransfer
                this.transferForm.electricMeterTransfer = this.houseTransferSituation.electricMeterTransfer
            },
            formShow: function () {
                this.transferForm.houseTransfer = this.houseTransferSituation.houseTransfer
                this.transferForm.electricMeterTransfer = this.houseTransferSituation.electricMeterTransfer
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')
            GetAuthority('transferSituationEdit', this.unionid).then((res) => {
                this.authority = res
            })
        }
    }
</script>

<style scoped>

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-col {
        margin-bottom: 10px;
    }

    .el-select {
        width: 100%;
    }

</style>
