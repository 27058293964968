<template>
    <div class="container" v-loading="loading">

        <el-tabs v-model="tabName">
            <el-tab-pane label="合同信息" name="first">
                <el-descriptions labelClassName="label" contentClassName="content"
                                 class="description"
                                 :column="2" style="margin-bottom: 20px" border>
                    <el-descriptions-item label="合同编号">{{contract.contractNo}}</el-descriptions-item>
                    <el-descriptions-item label="客户名称">{{clientName}}</el-descriptions-item>
                    <el-descriptions-item label="开始时间">{{contract.contractStartDate}}</el-descriptions-item>
                    <el-descriptions-item label="结束时间">{{contract.contractEndDate}}</el-descriptions-item>
                    <el-descriptions-item label="合同房源" span="2">{{contract.houseName}}</el-descriptions-item>
                    <el-descriptions-item label="计费面积" span="2">{{contract.billingArea + ' ㎡'}}</el-descriptions-item>
                    <el-descriptions-item label="合同总额" span="2">{{MoneyFormat(contract.contract_amountTotal)}}
                    </el-descriptions-item>
                    <el-descriptions-item label="合同减免金额" span="2">{{MoneyFormat(contract.contract_reliefAmountTotal)}}
                    </el-descriptions-item>
                    <el-descriptions-item label="合同已收金额" span="2">
                        {{MoneyFormat(contract.contract_receivedAmountTotal)}}
                    </el-descriptions-item>
                    <el-descriptions-item label="合同未缴金额" span="2">{{MoneyFormat(contract.contract_unpaidAmountTotal)}}
                    </el-descriptions-item>
                    <el-descriptions-item label="已出账金额" span="2">{{MoneyFormat(contract.billed_amountTotal)}}
                    </el-descriptions-item>
                    <el-descriptions-item label="已出账减免金额" span="2">{{MoneyFormat(contract.billed_reliefAmountTotal)}}
                    </el-descriptions-item>
                    <el-descriptions-item label="已出账已收金额" span="2">
                        {{MoneyFormat(contract.billed_receivedAmountTotal)}}
                    </el-descriptions-item>
                    <el-descriptions-item label="已出账未缴金额" span="2">
                        <el-link type="danger">{{MoneyFormat(contract.billed_unpaidAmountTotal)}}</el-link>
                    </el-descriptions-item>
                    <el-descriptions-item label="业务员" span="2">{{contract.employeeName}}</el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>

            <el-tab-pane label="账单详情" name="second">
                <vxe-grid ref="rentalContractBillTable"
                          id="rentalContractBillTable"
                          class="rentalContractBillTable"
                          v-bind="rentalContractBillTable" style="margin-bottom: 50px">

                    <template #state="{ row }">

                        <el-tag v-if="row.unpaidAmount <= 0" type="success" size="small">已收款</el-tag>
                        <el-tag v-if="(row.unpaidAmount > 0) && new Date(row.billingDate) <= new Date() && new Date(row.dueDate) > new Date()"
                                type="warning" size="small">已出账未逾期
                        </el-tag>
                        <el-tag v-if="(new Date(row.dueDate) <= new Date()) && row.unpaidAmount > 0" type="danger"
                                size="small">
                            已逾期 {{ DayCalculate(row.dueDate) + '天'}}
                        </el-tag>

                    </template>
                </vxe-grid>
            </el-tab-pane>

            <el-tab-pane label="催费记录" name="third">

                <el-timeline style="padding-left: 2px!important;">

                    <el-timeline-item
                            v-for="record in recordList"
                            :key="record.id"
                            type="warning"
                            :timestamp="record.followDate" placement="top">
                        <p>
                            <span style="color: #6c6a6a;font-weight: bold">跟进人：</span>
                            <span>{{record.employeeName}}</span>
                        </p>

                        <p>
                            <span style="color: #6c6a6a;font-weight: bold">跟进内容：</span>
                            <span>{{record.followContent}}</span>
                        </p>

                        <el-row :gutter="20">
                            <el-col :span="8" v-for="(pic,index) in record.pictures" :key="index">
                                <el-image
                                        style="width: 100%; height: 200px"
                                        :src="'http://www.dao-valley.cn:8022/' + contract.contractNo + '/' + pic"
                                        fit="contain"
                                        :preview-src-list="PreviewList(record.pictures)"></el-image>
                            </el-col>
                        </el-row>
                    </el-timeline-item>
                </el-timeline>
            </el-tab-pane>


        </el-tabs>


    </div>
</template>

<script>
    import {GetRentalBillCollectionRecords, GetRentalContractBills} from "../../api";
    import formatMoney from "../../utils/formatMoney";
    import XEUtils from "xe-utils";

    export default {
        name: "RentalContractDetail",
        props: {
            contract: {
                required: true,
                type: Object
            },
            clientName: {
                required: true,
                type: String
            }
        },
        data() {
            return {
                loading: false,
                tabName: 'second',
                billList: [],
                recordList: []
            }
        },
        computed: {
            rentalContractBillTable: function () {
                return {
                    height: '700',
                    size: 'small',
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    scrollY: {
                        mode: 'wheel'
                    },
                    columnConfig: {
                        width: 'auto',
                        minWidth: '130'
                    },
                    data: this.billList,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {
                            resizable: true, title: '费项', width: '180', formatter: function ({row}) {
                                return row.fee + '\n' + row.startDate + '~' + row.endDate
                            }
                        },
                        {
                            resizable: true,
                            title: '账单金额',
                            field: 'amount',
                            formatter: 'FormatMoney',
                            className: 'font-blue'
                        },
                        {resizable: true, title: '减免金额', field: 'reliefAmount', formatter: 'FormatMoney'},
                        {resizable: true, title: '已收金额', field: 'receivedAmount', formatter: 'FormatMoney'},
                        {
                            resizable: true, title: '未缴金额', field: 'unpaidAmount', formatter: 'FormatMoney',
                            className: function ({row}) {
                                if (new Date(row.billingDate) <= new Date() && row.unpaidAmount > 0) {
                                    return 'font-red'
                                }
                            }
                        },
                        {resizable: true, title: '出账日期', field: 'billingDate'},
                        {resizable: true, title: '应缴日期', field: 'dueDate'},
                        {resizable: true, title: '收款状态', field: 'collectionState'},
                        {resizable: true, title: '收款日期', field: 'collectionDate'},
                        {
                            resizable: true, title: '状态', fixed: 'right', width: '120', slots: {default: 'state'}
                        },
                    ]
                }
            }
        },
        methods: {
            MoneyFormat(value) {
                return formatMoney(value)
            },
            DayCalculate(date) {
                return (XEUtils.getDateDiff(new Date(date).getTime(), new Date().getTime()).time / 86400000).toFixed(0)
            },
            PreviewList(picList) {
                var list = []
                for (var i = 0;i < picList.length;i++) {
                    list.push('http://www.dao-valley.cn:8022/' + this.contract.contractNo + '/' + picList[i])
                }
                return list
            }
        },
        async created() {
            this.loading = true
            await GetRentalContractBills(this.contract.contractNo).then((res) => {
                this.billList = res
            })
            await GetRentalBillCollectionRecords(this.contract.contractNo).then((res) => {
                res.forEach(function (item) {
                    item.pictures = item.pictures.split('|')
                })
                this.recordList = res
            })

            this.loading = false
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }


    .rentalContractBillTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .rentalContractBillTable >>> .font-blue {
        color: #45aaf2;
    }

    .rentalContractBillTable >>> .font-red {
        color: #f10716;
    }

    .rentalContractBillTable >>> .footer-row {
        background-color: #F8F8F9;
    }

</style>
