<template>
    <div>

        <!--搜索表单-->
        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
            <el-collapse-item name="collapseItem" class="form-container">
                <template slot="title">
                    <el-tag type="success" style="width: 100%;padding-left: 15px">
                        <i class="el-icon-search" style="margin-right: 5px"></i>
                        <span v-if="searchObj.type === '月度账单'">共查询到 {{elecBills.length}} 份账单</span>
                        <span v-else>共查询到 {{elecBills.length}} 位客户</span>
                    </el-tag>
                </template>

                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px">

                    <el-form-item>
                        <el-radio-group v-model="searchObj.type" size="small" @change="submit">
                            <el-radio-button label="月度账单"></el-radio-button>
                            <el-radio-button label="客户账单"></el-radio-button>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="查询月份" v-show="searchObj.type === '月度账单'">
                        <el-date-picker style="width: 200px" :clearable="false" @change="submit"
                                        v-model="searchObj.month"
                                        value-format="yyyy-MM"
                                        type="month" :picker-options="pickerOptions" placeholder="选择月">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="所属项目:" v-if="searchObj.type === '月度账单'">
                        <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                            <el-option v-for="project in projects" :key="project.abbreviation"
                                       :value="project.abbreviation"
                                       :label="project.projName"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="所属项目:" v-else>
                        <el-select v-model="searchObj.projName2" placeholder="所属项目" @change="submit">
                            <el-option v-for="project in projects2" :key="project.abbreviation"
                                       :value="project.abbreviation"
                                       :label="project.projName"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="客户名称:">
                        <el-input v-model="searchObj.clientName" placeholder="客户名称" clearable
                                  @change="submit"></el-input>
                    </el-form-item>

                </el-form>
            </el-collapse-item>
        </el-collapse>

        <!--表格-->
        <div :style="tableContainerStyle">
            <vxe-grid ref="xTable"
                      id="elecBillTable"
                      class="elecBillTable"
                      v-bind="elecBillTable"
                      @toolbar-button-click="toolbarButtonClickEvent"
                      @toolbar-tool-click="toolbarToolClickEvent"
                      @cell-click="cellClick">
            </vxe-grid>
        </div>

        <!--clientBill 抽屉-->
        <el-drawer :visible.sync="clientBillDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="clientBillDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <ClientBillDetail :clientId="clientId" :clientName="clientName"></ClientBillDetail>
        </el-drawer>

        <!-- makePdf 抽屉 -->
        <el-drawer :visible.sync="pdfDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        title="生成缴费通知单"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <ElecBillPdf :abbreviation="searchObj.projName2"></ElecBillPdf>
        </el-drawer>

        <!-- 推送微信消息 抽屉 -->
        <el-drawer :visible.sync="messageDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        title="推送电费账单消息"
                        type="success"
                        :closable="false">
                </el-alert>
            </template>
            <SendElecBillMessage></SendElecBillMessage>
        </el-drawer>


    </div>
</template>

<script>
    import {GetAuthority, GetElecBills, GetMonthClientElecBills, GetProjects, GetProjectsWithoutAll} from "../../api";
    import ClientBillDetail from "./ClientBillDetail"
    import ElecBillPdf from "./ElecBillPdf"
    import SendElecBillMessage from "./SendElecBillMessage"
    import XEUtils from 'xe-utils'

    export default {
        name: "ElecBillManagement",
        components: {ClientBillDetail, ElecBillPdf, SendElecBillMessage},
        data() {
            return {
                unionid: null,
                authority: false,
                clientBillDrawer: false,
                clientBillDrawerTitle: '',
                pdfDrawer: false,
                messageDrawer: false,
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                projects: [],
                projects2: [],
                searchObj: {
                    type: '月度账单',
                    month: XEUtils.toDateString(XEUtils.getWhatMonth(new Date(), -1), 'yyyy-MM'),
                    projName: 'ALL',
                    projName2: 'GJZX',
                    clientName: '',
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > XEUtils.getWhatMonth(new Date(), -1);
                    },
                },
                elecBills: [],
                clientId: null,
                clientName: null,
                tools: [
                    {code: 'send', name: '推送账单', status: 'success', disabled: false},
                ],
                columns: [
                    {resizable: true, title: '序号', type: 'seq', width: '60'},
                    {resizable: true, title: '所属项目', field: 'remark'},
                    {resizable: true, title: '客户编号', field: 'clientId'},
                    {resizable: true, title: '客户名称', field: 'clientName'},
                    {resizable: true, title: '金额', field: 'amount', formatter: 'FormatMoney'},
                ]
            }
        },
        computed: {
            elecBillTable() {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'print', name: '打 印', icon: 'vxe-icon--print'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'}
                        ],
                        tools: this.tools,
                        size: 'medium',
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    printConfig: {
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.elecBills,
                    columns: this.columns
                }
            }
        },
        watch: {
            clientBillDrawer: function (val, oldVal) {
                this.submit()
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            submit() {
                if (this.searchObj.type === '月度账单') {

                    var object = {
                        billMonth: this.searchObj.month,
                        projName: this.searchObj.projName,
                        clientName: this.searchObj.clientName
                    }

                    GetMonthClientElecBills(object).then((res) => {

                        this.tools = [
                            {code: 'send', name: '推送账单', status: 'success', disabled: !this.authority},
                        ]

                        this.columns = [
                            {resizable: true, title: '序号', type: 'seq', width: '60'},
                            {resizable: true, title: '所属项目', field: 'remark'},
                            {resizable: true, title: '客户编号', field: 'clientId'},
                            {resizable: true, title: '客户名称', field: 'clientName'},
                            {resizable: true, title: '金额', field: 'amount', formatter: 'FormatMoney'},
                        ]

                        this.elecBills = res
                    })


                } else {
                    var obj = {
                        projName: this.searchObj.projName2,
                        clientName: this.searchObj.clientName
                    }

                    GetElecBills(obj).then((res) => {

                        this.tools = [
                            {code: 'pdf', name: '缴费通知单', status: 'danger'},
                        ]

                        this.columns = [
                            {resizable: true, title: '序号', type: 'seq', width: '60'},
                            {resizable: true, title: '客户编号', field: 'clientId'},
                            {resizable: true, title: '客户名称', field: 'clientName'},
                            {resizable: true, title: '电费账单', field: 'billCount'},
                            {resizable: true, title: '未支付账单', field: 'notPaidBillCount'}
                        ]

                        this.elecBills = res
                    })
                }
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        this.$refs.xTable.openExport()
                        break
                    case 'print':
                        this.$refs.xTable.openPrint()
                        break
                    case 'zoom':
                        this.$refs.xTable.zoom()
                        break

                }
            },
            toolbarToolClickEvent({code}) {
                switch (code) {
                    case 'pdf':
                        this.pdfDrawer = true
                        break
                    case  'send':
                        this.messageDrawer = true
                        break
                }
            },
            cellClick({row}) {
                this.clientBillDrawerTitle = row.clientName + ' - 电费账单'
                this.clientId = row.clientId
                this.clientName = row.clientName
                this.clientBillDrawer = true
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetProjects().then((res) => {
                this.projects = res
            })

            GetProjectsWithoutAll().then((res) => {
                this.projects2 = res
            })

            /*send权限*/
            GetAuthority('wuyeSendBill', this.unionid).then((res) => {
                this.authority = res
            })

            this.submit()
        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .elecBillTable >>> .vxe-body--row {
        cursor: pointer;
    }

</style>
