<template>

    <div class="container">

        <el-form ref="wuyeClientAddForm" :model="form" :rules="rules" label-width="80px" size="medium">

            <el-form-item label="收款公司" required prop="paymentMethod">
                <el-select v-model="form.paymentMethod"
                           placeholder="选择收款公司" style="width: 100%">
                    <el-option value="1" label="江苏稻谷物业管理有限公司苏州分公司"></el-option>
                    <el-option value="3" label="苏州步步高物业管理有限公司"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="所属项目" required prop="citemcode">
                <el-select v-model="form.citemcode" placeholder="所属项目" style="width: 100%">
                    <el-option v-for="project in projects" :key="project.abbreviation"
                               :value="project.citemcode"
                               :label="project.projName"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="客户类型" required prop="type">
                <el-select v-model="form.type"
                           placeholder="选择客户类型" style="width: 100%">
                    <el-option :value="1" label="公司客户"></el-option>
                    <el-option :value="2" label="个人客户"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="客户名称" required prop="clientName">
                <el-input v-model="form.clientName" clearable></el-input>
            </el-form-item>

            <el-form-item label="客户编号" required prop="clientId">
                <el-input v-model="form.clientId" placeholder="客户编号，租赁/销售 客户编号同步于左邻" clearable></el-input>
            </el-form-item>

            <el-form-item label="编号缩写">
                <el-input v-model="form.abbreviation" clearable></el-input>
            </el-form-item>

            <el-form-item label="客户属性" required prop="attribute">
                <el-select v-model="form.attribute"
                           clearable multiple filterable
                           placeholder="选择客户属性" style="width: 100%">

                    <el-option v-for="attribute in attributes" :key="attribute.clientAttributeCode"
                               :value="attribute.clientAttribute"
                               :label="attribute.clientAttribute"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="联系人" required prop="contactPerson">
                <el-input v-model="form.contactPerson" placeholder="联系人" clearable></el-input>
            </el-form-item>

            <el-form-item label="联系电话" required prop="contactPhone">
                <el-input v-model="form.contactPhone" placeholder="联系人电话" clearable></el-input>
            </el-form-item>

            <el-form-item label="进场时间" required prop="entryDate">
                <el-date-picker style="width: 100%"
                                v-model="form.entryDate"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="选择进场时间" @change="check">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="退场时间" prop="exitDate">
                <el-date-picker style="width: 100%"
                                v-model="form.exitDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择退场时间" @change="check">
                </el-date-picker>
            </el-form-item>

            <el-divider content-position="left">企业信息</el-divider>

            <el-form-item label="企业代码">
                <el-input v-model="form.enterpriseCode" placeholder="企业代码" clearable></el-input>
            </el-form-item>

            <el-form-item label="税号">
                <el-input v-model="form.taxNumber" placeholder="税号" clearable></el-input>
            </el-form-item>

            <el-form-item label="开户行">
                <el-input v-model="form.bank" placeholder="开户行" clearable></el-input>
            </el-form-item>

            <el-form-item label="银行账号">
                <el-input v-model="form.bankAccount" placeholder="银行账号" clearable></el-input>
            </el-form-item>

            <el-form-item label="地址">
                <el-input v-model="form.address" placeholder="地址" clearable></el-input>
            </el-form-item>

            <el-form-item label="电话">
                <el-input v-model="form.telephone" placeholder="电话" clearable></el-input>
            </el-form-item>

            <el-divider content-position="left">其他</el-divider>

            <el-form-item label="负责人" required prop="employeeUnionid">
                <el-select v-model="form.employeeUnionid"
                           clearable multiple placeholder="选择负责人" style="width: 100%">
                    <el-option v-for="employee in employees" :key="employee.unionid"
                               :value="employee.unionid"
                               :label="employee.name"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="备注">
                <el-input
                        type="textarea"
                        :autosize="{ minRows: 4}"
                        placeholder="备注"
                        v-model="form.remark">
                </el-input>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" @click="submit">新增</el-button>
            </el-form-item>

        </el-form>

    </div>

</template>

<script>
    import {
        GetEmployees,
        GetProjectsWithoutAll,
        GetWuyeClientAttributes,
        GetWuyeFitemss97s,
        InsertWuyeClient
    } from "../../api";

    export default {
        name: "WuyeClientAdd",
        data() {

            const dateCheck1 = (rule, value, callback) => {

                var start = this.form.entryDate == null ? null : new Date(this.form.entryDate)
                var end = this.form.exitDate == null ? null : new Date(this.form.exitDate)

                if (!value) {
                    return callback(new Error('开始时间不能为空'))
                }

                if (end != null) {
                    if (start > end) {
                        return callback(new Error('开始时间>结束时间'))
                    } else {
                        return callback()
                    }
                } else {
                    return callback()
                }

            }

            const dateCheck2 = (rule, value, callback) => {

                var start = this.form.entryDate == null ? null : new Date(this.form.entryDate)
                var end = this.form.exitDate == null ? null : new Date(this.form.exitDate)

                if (start == null && end != null) {
                    return callback(new Error('开始时间不能为空'))
                } else {

                    if (end != null) {
                        if (start > end) {
                            return callback(new Error('开始时间 > 结束时间'))
                        } else {
                            return callback()
                        }
                    } else {
                        return callback()
                    }

                }
            }

            return {
                unionid: null,
                projects: [],
                attributes: [],
                employees: [],
                form: {
                    paymentMethod: '3',
                    citemcode: '',
                    clientName: '',
                    type: null,
                    attribute: [],
                    clientId: '',
                    abbreviation: '',
                    contactPerson: '',
                    contactPhone: '',
                    enterpriseCode: '',
                    taxNumber: '',
                    bank: '',
                    bankAccount: '',
                    address: '',
                    telephone: '',
                    entryDate: null,
                    exitDate: null,
                    employeeUnionid: [],
                    remark: ''
                },
                rules: {
                    paymentMethod: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    citemcode: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    type: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    clientName: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    clientId: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    attribute: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    contactPerson: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    contactPhone: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    employeeUnionid: [
                        {required: true, message: '不能为空', trigger: ['change']}
                    ],
                    entryDate: [
                        {validator: dateCheck1, trigger: 'change'}
                    ],
                    exitDate: [
                        {validator: dateCheck2, trigger: 'change'}
                    ]
                }
            }
        },
        methods: {
            check() {
                this.$refs.wuyeClientAddForm.validateField(['entryDate', 'exitDate'], (e) => {
                })
            },
            submit() {
                this.$refs.wuyeClientAddForm.validate((e) => {

                    if (e) {
                        InsertWuyeClient(this.form, this.unionid).then((res) => {
                            if (res === 1) {
                                this.$emit('WuyeClientInsert')
                            }
                        })
                    } else {
                        this.$message.error('有未填项！');
                    }

                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetEmployees('物业客服部', 'null').then((res) => {
                this.employees = res
            })

            GetWuyeFitemss97s().then((res) => {
                this.projects = res
            })

            GetWuyeClientAttributes().then((res) => {
                this.attributes = res
            })
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }


</style>
