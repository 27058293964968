<template>

    <div class="container" v-loading="loading" v-if="detail != null">

        <el-tabs v-model="activeName">
            <el-tab-pane label="客户信息" name="first">
                <el-descriptions title=""
                                 labelClassName="label"
                                 class="description"
                                 :column="1"
                                 border
                                 style="margin-bottom: 20px">
                    <el-descriptions-item label="所属项目" :span="2">{{detail.user.projName}}</el-descriptions-item>
                    <el-descriptions-item label="客户名称" :span="2">{{detail.user.clientName}}</el-descriptions-item>
                    <el-descriptions-item label="客户编号">{{detail.user.clientId}}</el-descriptions-item>
                    <el-descriptions-item label="微信绑定手机号">{{detail.user.telephone}}</el-descriptions-item>
                    <el-descriptions-item label="用户名称">{{detail.userInfo.name}}</el-descriptions-item>
                    <el-descriptions-item label="联系电话">{{detail.userInfo.phone}}</el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>

            <el-tab-pane label="开票信息" name="second">
                <el-descriptions title="收款信息"
                                 labelClassName="label"
                                 class="description"
                                 :column="2"
                                 border
                                 style="margin-bottom: 20px">
                    <el-descriptions-item label="支付方式" :span="2">{{detail.payType === 1?'银行转账':'微信支付'}}
                    </el-descriptions-item>
                    <el-descriptions-item label="金额">{{MoneyFormat(detail.payDetail.amount)}}</el-descriptions-item>
                    <el-descriptions-item label="账单月份">{{detail.payDetail.billMonth}}</el-descriptions-item>
                </el-descriptions>

                <el-descriptions title="开票信息"
                                 labelClassName="label"
                                 class="description"
                                 :column="1"
                                 border
                                 style="margin-bottom: 20px">
                    <el-descriptions-item label="公司名称">{{detail.client.clientName}}</el-descriptions-item>
                    <el-descriptions-item label="税号">{{detail.client.taxNumber}}</el-descriptions-item>
                    <el-descriptions-item label="开户行及账号">{{detail.client.bank + ' ' + detail.client.bankAccount}}
                    </el-descriptions-item>
                    <el-descriptions-item label="地址及电话">{{detail.client.address + ' ' + detail.client.telephone}}
                    </el-descriptions-item>
                    <el-descriptions-item label="开票内容" v-if="!(!detail.invoice.state && detail.invoice.valid)">
                        <div v-html="NewlineFormat(detail.invoice.invoiceContent)"></div>
                    </el-descriptions-item>
                    <el-descriptions-item label="发票号码"
                                          v-if="detail.invoice.valid && detail.invoice.state && detail.invoice.invoiceKind === 2">
                        <div v-html="detail.invoice.receiptNumber"></div>
                    </el-descriptions-item>
                </el-descriptions>

                <!-- 待审核 -->
                <div v-if="detail.invoice.valid && !detail.invoice.state">

                    <el-alert type="error" :closable="false" style="margin-bottom: 20px"
                              title="1.请先确认开票内容    2.再开票">
                    </el-alert>

                    <el-form :model="form" label-width="80px">

                        <el-form-item label="发票类型">
                            <el-input :value="detail.invoice.invoiceKind===1?'增值税普通发票（电子）':'增值税专用发票（纸质）'" readonly
                                      placeholder="请输入内容"></el-input>
                        </el-form-item>

                        <el-form-item label="开票内容">
                            <el-input style="width: 70%"
                                      type="textarea"
                                      :rows="2"
                                      placeholder="请输入开票内容"
                                      v-model="form.content"
                                      @input="checkInput">
                            </el-input>
                            <el-button type="success" :disabled="confirm" plain size="small" style="margin-left: 10px"
                                       @click="contentConfirm">确认开票内容
                            </el-button>
                        </el-form-item>

                        <el-form-item label="发票号码" v-if="detail.invoice.invoiceKind != 1">
                            <el-input v-model="form.number" placeholder="请输入发票号码" @input="checkNumber"></el-input>
                        </el-form-item>

                        <el-form label-width="80px">
                            <el-form-item style="text-align: right">
                                <el-button size="small" type="danger" @click="Reject">拒绝</el-button>
                                <el-button size="small" type="primary" :disabled="!disabled"
                                           v-if="detail.invoice.invoiceKind===1" @click="MakeInvoice">开电子发票
                                </el-button>
                                <el-button size="small" type="primary" :disabled="!disabled"
                                           v-if="detail.invoice.invoiceKind===2" @click="UpdateElecInvoiceState">已开纸质票
                                </el-button>
                            </el-form-item>
                        </el-form>

                    </el-form>
                </div>

                <!--已审核-->
                <div v-if="detail.invoice.valid && detail.invoice.state">

                    <!--电子普票-->
                    <div v-if="detail.invoice.invoiceKind === 1">
                        <el-alert type="success" :closable="false" style="margin-bottom: 20px"
                                  title="已开 增值税普通发票（电子）">
                        </el-alert>
                        <el-image v-if="invoice != null"
                                  style="width: 100%; height: 550px;margin-bottom: 20px"
                                  :src="invoice.list[0].c_imgUrls"
                                  fit="fill"
                                  :preview-src-list="[invoice.list[0].c_imgUrls]">
                        </el-image>
                    </div>

                    <div v-else>
                        <el-alert type="success" :closable="false" style="margin-bottom: 20px"
                                  title="已开 增值税专用发票（纸质）">
                        </el-alert>
                    </div>

                </div>

            </el-tab-pane>

            <el-tab-pane label="记录" name="third">

                <div v-if="detail.invoice.valid && detail.invoice.invoiceKind != 1 && detail.invoice.pickUpUnionid != null && !detail.invoice.clientPickUpState">
                    <el-divider content-position="left">客户领票</el-divider>

                    <el-form :model="pickUpForm" label-width="80px" size="medium">
                        <el-form-item label="领票时间">
                            <el-date-picker style="width: 100%"
                                            v-model="pickUpForm.dateStamp"
                                            type="datetime"
                                            :clearable="false"
                                            value-format="timestamp"
                                            placeholder="选择领票时间">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item style="text-align: right">
                            <el-button size="small" type="primary" @click="submit">客户领票</el-button>
                        </el-form-item>

                    </el-form>

                    <el-divider content-position="left">记录</el-divider>
                </div>

                <el-timeline style="padding-left: 2px!important;">

                    <el-timeline-item v-if="detail.invoice.clientPickUpTime != null"
                                      :timestamp="detail.invoice.clientPickUpTime"
                                      placement="top" type="success">
                        客户领取发票
                    </el-timeline-item>

                    <el-timeline-item v-if="detail.invoice.pickUpTime != null"
                                      :timestamp="detail.invoice.pickUpTime"
                                      placement="top" type="success">
                        物业客服领取发票
                    </el-timeline-item>

                    <el-timeline-item v-if="detail.invoice.billingTime != null"
                                      :timestamp="detail.invoice.billingTime"
                                      placement="top" type="success">
                        开票完成
                    </el-timeline-item>

                    <el-timeline-item v-if="detail.invoice.applyTime != null"
                                      :timestamp="detail.invoice.applyTime"
                                      placement="top" type="success">
                        提交发票申请
                    </el-timeline-item>

                </el-timeline>

            </el-tab-pane>
        </el-tabs>

    </div>


</template>

<script>
    import {
        ClientPickUpElecInvoice,
        GetWuyeElecInvoiceDetail, InsertOperationRecord,
        MakeElecInvoice, RejectElecInvoice,
        SearchFpqqlsh,
        TakeEffectElecInvoice,
        UpdateElecInvoiceContent
    } from "../../api";
    import formatMoney from "../../utils/formatMoney";
    import XEUtils from 'xe-utils'

    export default {
        name: "ElecInvoiceDetail",
        props: {
            id: {
                type: Number,
                required: true
            },
        },
        data() {
            return {
                activeName: 'second',
                timer: "",
                unionid: null,
                loading: false,
                detail: null,
                confirm: false,
                confirmState: true,
                disabled: false,
                form: {
                    content: '',
                    number: ''
                },
                pickUpForm: {
                    dateStamp: XEUtils.timestamp(new Date())
                },
                invoice: null
            }
        },
        methods: {
            MoneyFormat(value) {
                return formatMoney(value);
            },
            NewlineFormat(value) {
                return value.replace("\n", "<br/>")
            },
            checkInput() {
                this.disabled = false
                this.confirmState = false
                if (this.form.content.indexOf('null') == -1 && this.form.content != '' && this.form.content != null) {
                    this.confirm = false
                } else {
                    this.confirm = true
                }
                this.checkNumber()
            },
            checkNumber() {
                if (this.detail.invoice.invoiceKind === 1) {

                    if (this.confirmState) {
                        this.disabled = true
                    } else {
                        this.disabled = false
                    }

                } else {
                    if (this.confirmState && this.form.number != '' && this.form.number != null) {
                        this.disabled = true
                    } else {
                        this.disabled = false
                    }
                }
            },
            contentConfirm() {
                this.loading = true
                UpdateElecInvoiceContent(this.detail.invoice.id, this.form.content).then((res) => {
                    this.confirmState = true
                    this.checkNumber()
                    this.timer = setTimeout(() => {
                        this.loading = false
                    }, 500);
                })
            },
            Reject() {
                this.$alert('是否拒绝此条开票申请？', '拒绝开票申请', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    showCancelButton: true,
                    callback: action => {
                        if (action === 'confirm') {
                            RejectElecInvoice(this.id).then((res) => {
                                if (res === 1) {
                                    InsertOperationRecord(this.unionid, 'rejectElecInvoice', this.id).then((res) => {
                                        if (res === 1) {
                                            this.$emit('UpdateElecInvoiceDetail')
                                        }
                                    })
                                }
                            })
                        }
                    }
                });
            },
            MakeInvoice() {
                this.loading = true
                MakeElecInvoice(this.id).then((res) => {
                    TakeEffectElecInvoice(this.id, 1, res.fpqqlsh, null).then((res) => {
                        if (res === 1) {
                            InsertOperationRecord(this.unionid, 'checkElecInvoice', this.id).then((res) => {
                                if (res === 1) {
                                    this.loading = false
                                    this.$emit('UpdateElecInvoiceDetail')
                                }
                            })
                        }
                    })
                })
            },
            UpdateElecInvoiceState() {
                this.loading = true
                TakeEffectElecInvoice(this.id, 2, null, this.form.number).then((res) => {
                    if (res === 1) {
                        InsertOperationRecord(this.unionid, 'checkElecInvoice', this.id).then((res) => {
                            if (res === 1) {
                                this.loading = false
                                this.$emit('UpdateElecInvoiceDetail')
                            }
                        })
                    }
                })
            },
            submit() {
                ClientPickUpElecInvoice(this.id).then((res) => {
                    if (res === 1) {
                        this.$emit('UpdateElecInvoiceDetail')
                    }
                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeElecInvoiceDetail(this.id).then((res) => {

                this.form.content = res.invoice.invoiceContent
                this.detail = res
                this.checkInput()

                if (res.invoice.fpqqlsh != null) {
                    SearchFpqqlsh(res.invoice.fpqqlsh).then((res) => {
                        this.invoice = res
                    })
                }
            })

        },
        beforeDestroy() {
            clearTimeout(this.timer);
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
        white-space: pre-line
    }

</style>
