<template>

    <div class="container" v-loading="loading">

        <!-- 描述列表 -->
        <div v-show="!formShow">
            <el-descriptions title="车位信息"
                             labelClassName="label"
                             class="description"
                             :column="2"
                             border
                             style="margin-bottom: 20px">

                <template slot="extra">
                    <el-button type="danger" size="small" @click="formShow = true" plain>修改</el-button>
                </template>

                <el-descriptions-item label="项目">{{detail.projName}}</el-descriptions-item>
                <el-descriptions-item label="位置">{{detail.position}}</el-descriptions-item>
                <el-descriptions-item label="区域">{{detail.region}}</el-descriptions-item>
                <el-descriptions-item label="类型">{{detail.kind}}</el-descriptions-item>
                <el-descriptions-item label="车位号">{{detail.parkingNo}}</el-descriptions-item>
                <el-descriptions-item label="车位属性">{{detail.parkingAttribute}}</el-descriptions-item>
                <el-descriptions-item label="月租金">{{MoneyFormat(detail.rentPrice)}}</el-descriptions-item>
                <el-descriptions-item label="月管理费">{{MoneyFormat(detail.managementPrice)}}</el-descriptions-item>
                <el-descriptions-item label="当前客户" :span="2">{{detail.nowClient}}</el-descriptions-item>
                <el-descriptions-item label="备注" :span="2">{{detail.remark}}</el-descriptions-item>
            </el-descriptions>

            <el-divider content-position="left">车位记录</el-divider>

            <vxe-grid ref="xTable"
                      id="parkingDetailRecordTable"
                      class="parkingDetailRecordTable"
                      v-bind="parkingDetailRecordTable">

            </vxe-grid>
        </div>

        <!-- 修改表格 -->
        <div v-show="formShow">

            <el-form ref="parkingDetailForm" :model="form" label-width="80px" size="medium">

                <el-form-item label="车位属性">
                    <el-select v-model="form.parkingAttribute" filterable
                               placeholder="请选择车位属性" style="width: 100%">
                        <el-option label="产权车位" value="产权车位"></el-option>
                        <el-option label="专用车位" value="专用车位"></el-option>
                        <el-option label="公用车位" value="公用车位"></el-option>
                        <el-option label="其他" value="其他"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="月租金">
                    <el-input-number v-model="form.rentPrice"
                                     :precision="2" :step="50" :min="100">
                    </el-input-number>
                </el-form-item>

                <el-form-item label="月管理费">
                    <el-input-number v-model="form.managementPrice"
                                     :precision="2" :step="50" :min="50">
                    </el-input-number>
                </el-form-item>

                <el-form-item label="所属客户" v-if="form.parkingAttribute === '专用车位' || form.parkingAttribute === '其他'">
                    <el-select v-model="form.clientId" filterable clearable
                               placeholder="请选择客户" style="width: 100%">
                        <el-option
                                v-for="item in clients"
                                :key="item.clientId"
                                :label="item.clientName"
                                :value="item.clientId">
                            <span style="float: left">{{ item.clientName }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.clientId }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="备注">
                    <el-input
                            type="textarea"
                            :autosize="{ minRows: 4}"
                            placeholder="备注"
                            v-model="form.remark">
                    </el-input>
                </el-form-item>

                <el-form-item style="text-align: right">
                    <el-button plain size="small" @click="formShow = false">取消</el-button>
                    <el-button size="small" type="success" @click="submit">保存</el-button>
                </el-form-item>

            </el-form>

        </div>

    </div>

</template>

<script>
    import {GetParkingInfo, GetWuyeClientsValidCode, UpdateParking} from "../../api";
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "ParkingDetail",
        props: {
            projName: {
                type: String,
                required: true
            },
            parkingNo: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                detail: {},
                records: [],
                formShow: false,
                form: {
                    parkingAttribute: null,
                    rentPrice: 0,
                    managementPrice: 0,
                    clientId: null,
                    remark: null
                },
                clients: []
            }
        },
        computed: {
            parkingDetailRecordTable: function () {
                return {
                    height: '480',
                    showOverflow: true,
                    size: 'small',
                    rowConfig: {
                        isHover: true
                    },
                    data: this.records,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '客户', field: 'clientName', className: 'font-blue'},
                        {resizable: true, title: '开始时间', field: 'startDate'},
                        {resizable: true, title: '到期时间', field: 'endDate'},
                        {resizable: true, title: '账单', field: 'amount', formatter: 'FormatMoney'},
                        {resizable: true, title: '备注', field: 'remark'},
                    ]
                }
            }
        },
        methods: {
            MoneyFormat(value) {
                return formatMoney(value)
            },
            GetInfo() {
                this.loading = true
                GetParkingInfo(this.projName, this.parkingNo).then((res) => {
                    this.detail = res.detail
                    this.records = res.records

                    this.form.parkingAttribute = res.detail.parkingAttribute
                    this.form.rentPrice = res.detail.rentPrice
                    this.form.managementPrice = res.detail.managementPrice
                    this.form.clientId = res.detail.clientId
                    this.form.remark = res.detail.remark
                    this.loading = false
                })
            },
            submit() {
                UpdateParking(this.projName, this.parkingNo, this.form).then((res) => {
                    if (res === 1) {
                        this.GetInfo()
                        this.formShow = false
                    }
                })
            }
        },
        created() {

            GetWuyeClientsValidCode(this.projName).then((res) => {
                this.clients = res
            })

            this.GetInfo()
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
        white-space: pre-line
    }

    .parkingDetailRecordTable >>> .font-blue {
        color: #45aaf2;
    }

</style>
