<template>

    <div class="container">
        <el-descriptions
                class="description"
                v-for="item in officialUsages"
                :key="item.meterId"
                :title="item.meterName"
                :column="2"
                border>
            <template slot="extra">
                <el-button v-if="authority"
                           type="primary"
                           size="small"
                           :disabled="item.usage != null"
                           @click="uploadClick($event,item)">用量上传
                </el-button>
            </template>
            <el-descriptions-item label="月份" :span="2">{{item.settlementMonth}}</el-descriptions-item>
            <el-descriptions-item label="所属项目">{{item.projName}}</el-descriptions-item>
            <el-descriptions-item label="表计编号">{{item.meterId}}</el-descriptions-item>
            <el-descriptions-item label="用量">{{Number(item.usage).toFixed(2)}}</el-descriptions-item>
            <el-descriptions-item label="金额">{{MoneyFormat(item.amount)}}</el-descriptions-item>
        </el-descriptions>

        <el-drawer :visible.sync="drawer" size="45%"
                   destroy-on-close
                   :append-to-body="true">
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="success"
                        :closable="false">
                </el-alert>
            </template>
            <UploadForm :meterId="meterId" :month="month"
                        @updateOfficialUsageInfo="updateOfficialUsageInfo"></UploadForm>
        </el-drawer>
    </div>

</template>

<script>
    import {GetAuthority, GetOfficialUsages} from "../../api";
    import formatMoney from "../../utils/formatMoney";
    import UploadForm from "./UploadForm"

    export default {
        name: "UploadOfficialUsage",
        components: {UploadForm},
        data() {
            return {
                unionid: null,
                authority: false,
                drawer: false,
                drawerTitle: '???',
                officialUsages: [],
                meterId: null,
            }
        },
        props: {
            abbreviation: {
                type: String,
                required: true
            },
            month: {
                type: String,
                required: true
            }
        },
        methods: {
            MoneyFormat(value) {
                return formatMoney(value)
            },
            uploadClick: function (e, obj) {
                this.meterId = obj.meterId
                this.drawerTitle = obj.meterName + ' - 用量上传'
                this.drawer = true
            },
            updateOfficialUsageInfo() {
                GetOfficialUsages(this.abbreviation, this.month).then((res) => {
                    this.officialUsages = res
                    this.drawer = false
                })
            }

        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetOfficialUsages(this.abbreviation, this.month).then((res) => {
                this.officialUsages = res
            })

            GetAuthority('uploadOfficialUsage', this.unionid).then((res) => {
                this.authority = res
            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

</style>
