<template>

    <div class="container" v-loading="loading">

        <el-form :model="form" label-width="80px" size="medium">

            <el-form-item label="所属项目" prop="citemcode">
                <el-select v-model="form.projName" placeholder="所属项目" style="width: 100%" @change="Search">
                    <el-option v-for="project in projects" :key="project.abbreviation"
                               :value="project.projName"
                               :label="project.projName"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="选择月份">
                <el-date-picker :clearable="false"
                                v-model="form.month"
                                value-format="yyyy-MM"
                                style="width: 100%"
                                type="month" :picker-options="pickerOptions" placeholder="选择月" @change="Search">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="选择客户">

                <el-select v-model="form.clientId"
                           filterable placeholder="请选择客户" style="width: 100%" @change="GetInfo">
                    <el-option
                            v-for="item in bills"
                            :key="item.clientId"
                            :label="item.clientName"
                            :value="item.clientId">
                        <span style="float: left">{{ item.clientName }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ FormatMoney(item.amount) }}</span>
                    </el-option>
                </el-select>

            </el-form-item>

            <el-result v-show="show" icon="error" title="错误提示" subTitle="客户未绑定小程序 / 用户未绑定公司"></el-result>

            <div v-show="!show">
                <el-form-item label="银行回单">
                    <div class="hello">
                        <el-upload action="/api/uploadElecPaymentPic"
                                   list-type="picture-card"
                                   :auto-upload="false"
                                   accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG"
                                   :data="form"
                                   :on-change="handleEditChange"
                                   :on-exceed="handleExceed"
                                   :on-success="handleSuccess"
                                   :limit="1"
                                   :class="{hide:showUpload}"
                                   ref="fileupload">
                            <i slot="default" class="el-icon-plus"></i>
                            <div slot="file" slot-scope="{file}">

                                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                                <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-preview"
                                      @click="handlePictureCardPreview(file)">
                                  <i class="el-icon-zoom-in"></i>
                                </span>

                                <span class="el-upload-list__item-delete"
                                      @click="handleRemove(file)">
                                  <i class="el-icon-delete"></i>
                                </span>

                            </span>
                            </div>
                        </el-upload>
                    </div>

                </el-form-item>

                <el-form-item label="金额">
                    <el-input-number v-model="form.amount" style="width: 50%"
                                     :precision="2" :step="0.01" :min="0">
                    </el-input-number>
                </el-form-item>

                <el-form-item style="text-align: right">
                    <el-button size="small" type="success" @click="submit">上传</el-button>
                </el-form-item>

            </div>

        </el-form>

        <el-descriptions v-if="info != null" title="客户信息"
                         labelClassName="label"
                         class="description"
                         :column="2"
                         border
                         style="margin-bottom: 20px">
            <el-descriptions-item label="客户编号">{{info.bill.clientId}}</el-descriptions-item>
            <el-descriptions-item label="微信绑定手机号">{{info.user.telephone}}</el-descriptions-item>
            <el-descriptions-item label="用户名称">{{info.info.name}}</el-descriptions-item>
            <el-descriptions-item label="联系电话">{{info.info.phone}}</el-descriptions-item>
        </el-descriptions>


        <el-dialog width="50%" :visible.sync="dialogVisible" :append-to-body="true">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>

    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {GetAddablePayment, GetAddablePaymentInfo, GetProjectsWithoutAll} from "../../api";
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "ElecPaymentAdd",
        data() {
            return {
                unionid: null,
                loading: false,
                projects: [],
                show: false,
                form: {
                    projName: '稻谷国际中心',
                    month: XEUtils.toDateString(XEUtils.getWhatMonth(new Date(), -1), 'yyyy-MM'),
                    clientId: null,
                    clientName: null,
                    amount: null,
                    unionid: null
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > XEUtils.getWhatMonth(new Date(), -1);
                    },
                },
                bills: [],
                info: null,
                dialogImageUrl: '',
                dialogVisible: false,
                showUpload: false
            }
        },
        methods: {
            FormatMoney(value) {
                return formatMoney(value)
            },
            Search() {
                this.form.clientId = null

                GetAddablePayment(this.form.month, this.form.projName).then((res) => {
                    this.bills = res
                })
            },
            GetInfo() {

                this.form.clientName = null
                this.form.amount = null
                this.form.unionid = null

                this.$refs['fileupload'].clearFiles()
                this.showUpload = false

                this.loading = true
                GetAddablePaymentInfo(this.form.clientId, this.form.month).then((res) => {

                    if (res.user == null) {
                        this.show = true
                    } else {
                        this.show = false
                        this.info = res
                        this.form.clientName = res.bill.clientName
                        this.form.amount = res.bill.amount
                        this.form.unionid = res.info.unionid
                    }
                    this.loading = false
                })

            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleEditChange(file) {

                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 2MB!');
                    this.handleRemove(file)
                }

                if (this.$refs['fileupload'].uploadFiles.length == 0) {
                    this.showUpload = false
                } else {
                    this.showUpload = true
                }

            },
            handleExceed() {
                this.$message.error('只能上传一张回单');
            },
            handleRemove(file) {
                let uploadFiles = this.$refs['fileupload'].uploadFiles;
                let index = uploadFiles.indexOf(file);
                uploadFiles.splice(index, 1);


                if (this.$refs['fileupload'].uploadFiles.length == 0) {
                    this.showUpload = false
                } else {
                    this.showUpload = true
                }

            },
            submit() {
                if (this.form.clientId == null || this.form.clientId === ''
                    || this.form.clientName == null || this.form.clientName === ''
                    || this.form.amount == null || this.form.amount == 0
                    || this.form.unionid == null || this.form.unionid === ''
                    || this.$refs['fileupload'].uploadFiles.length == 0) {
                    this.$message.error('有信息未填');
                } else {

                    this.$alert('确认金额无误？', '确认金额', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        cancelButtonClass: 'reject',
                        showCancelButton: true,
                        distinguishCancelAndClose: true,
                        callback: action => {
                            if (action === 'confirm') {
                                this.loading = true
                                this.$refs['fileupload'].submit();
                            }
                            if (action === 'cancel') {

                            }

                        }
                    });

                }

            },
            handleSuccess(response, file, fileList) {
                if (response === 1) {
                    this.loading = false
                    this.$emit('InsertElecPayment')
                }
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetProjectsWithoutAll().then((res) => {
                this.projects = res
            })

            this.Search()
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
        white-space: pre-line
    }

    .hello >>> .hide .el-upload--picture-card {
        display: none;
    }

</style>
