<template>

    <div v-loading="loading">
        <el-form ref="parkBillForm" :model="form" :rules="rules" label-width="80px" size="medium">

            <el-form-item label="选择客户" required prop="clientId">
                <el-select v-model="form.clientId" filterable @change="ClientChange"
                           placeholder="请选择客户" style="width: 100%">
                    <el-option
                            v-for="item in clients"
                            :key="item.clientId"
                            :label="item.clientName"
                            :value="item.clientId">
                        <span style="float: left">{{ item.clientName }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.clientId }}</span>
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="收款公司" required prop="paymentMethod">
                <el-select v-model="form.paymentMethod"
                           placeholder="选择收款公司" style="width: 100%">
                    <el-option value="1" label="江苏稻谷物业管理有限公司苏州分公司"></el-option>
                    <el-option value="3" label="苏州步步高物业管理有限公司"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="计费周期" required prop="timeRange">
                <el-date-picker style="width: 100%"
                                v-model="form.timeRange"
                                type="daterange"
                                range-separator="至"
                                value-format="yyyy-MM-dd"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :clearable="false">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="月单价" required prop="unitPrice">
                <el-input-number v-model="form.unitPrice"
                                 :precision="2" :step="50" :min="50">
                </el-input-number>
            </el-form-item>

            <el-form-item label="车位数量" required prop="quantity">
                <el-input-number v-model="form.quantity"
                                 :precision="2" :step="1" :min="1">
                </el-input-number>
            </el-form-item>

            <el-form-item label="原金额" required prop="amount">
                <el-input-number v-model="form.amount"
                                 :precision="2" :step="100" :min="100">
                </el-input-number>
            </el-form-item>

            <el-form-item label="减免金额" required prop="reliefAmount">
                <el-input-number v-model="form.reliefAmount"
                                 :precision="2" :step="0.01" :max="form.amount">
                </el-input-number>
            </el-form-item>

            <el-form-item label="应收日期" required prop="dueDate">
                <el-date-picker style="width: 100%"
                                v-model="form.dueDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择应收日期">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="付款人" required prop="payer" v-if="payerShow">
                <el-input v-model="form.payer" placeholder="请输入客户名称（简称即可）/姓名"></el-input>
            </el-form-item>

            <el-form-item label="备注">
                <el-input
                        type="textarea"
                        :autosize="{ minRows: 4}"
                        placeholder="备注"
                        v-model="form.remark">
                </el-input>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" @click="submit">新增</el-button>
            </el-form-item>

        </el-form>
    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {GetAllWuyeClients, GetWuyeClientsValidCode, InsertWuyeBill} from "../../../api";

    export default {
        name: "ParkBillAddForm",
        props: {
            projName: {
                type: String,
                required: true
            },
            inventory: {
                type: String,
                required: true
            },
            billMonth: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                unionid: null,
                loading: false,
                clients: [],
                payerShow: false,
                form: {
                    paymentMethod: null,
                    clientId: null,
                    contractNo: null,
                    timeRange: [],
                    unitPrice: 100,
                    quantity: 1,
                    amount: 100,
                    reliefAmount: 0,
                    dueDate: null,
                    remark: null,
                    payer: null
                },
                rules: {
                    paymentMethod: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    clientId: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    timeRange: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    dueDate: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            ClientChange(e) {

                var r = this.clients.filter(function (x) {
                    return x.clientId == e;
                });
                this.form.paymentMethod = r[0].paymentMethod
                this.payerShow = r[0].clientName === '其他零星客户'
            },
            submit() {
                this.$refs.parkBillForm.validate((e) => {
                    if (e) {

                        this.$alert('请确认收款公司无误！', '确认收款公司', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            showCancelButton: true,
                            callback: action => {
                                if (action === 'confirm') {
                                    this.loading = true
                                    InsertWuyeBill('park', this.projName, this.billMonth, this.inventory, this.form, this.unionid, null).then((res) => {
                                        if (res === 1) {
                                            this.loading = false
                                            this.$emit('ParkBillAddFormClose')
                                        }
                                    })
                                }
                            }
                        });

                    } else {
                        this.$message.error('有未填项！');
                    }
                })
            }
        },
        created() {

            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeClientsValidCode(this.projName).then((res) => {
                this.clients = res
            })

        }
    }
</script>

<style scoped>

</style>
