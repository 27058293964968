<template>
    <div class="container">

        <img src="../../public/logo.png" style="margin-right: 5px;width: 300px"/>

        <p style="color: #ec132b">没有权限！请先认证</p>
        <el-form :model="registerForm" ref="form" label-position="left">
            <el-form-item label="请输入姓名">
                <el-input style="width: 300px" v-model="registerForm.name" @input="check"></el-input>
            </el-form-item>

            <el-form-item label="请输入电话">
                <el-input v-model="registerForm.telephone" @input="check"></el-input>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button type="primary" style="width: 100%" :disabled="!disabled" @click="submit">认证</el-button>
            </el-form-item>

        </el-form>

        <div style="text-align: center;font-size: 14px;margin-top: 100px">

            <p style="text-align: center">Copyright@稻谷(苏州)互联网产业园</p>
            <p style="text-align: center">Dao Valley © 2016</p>

            <p style="text-align: center">
                <a href="https://beian.miit.gov.cn/" target="_blank" style="color: black;text-decoration:none;">苏ICP备2021055036号</a>
            </p>

            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32050902101345"
               style="text-decoration:none;">
                <img src="../../public/beian.png" style="margin-right: 5px"/>
                <span style="color:#939393;">苏公网安备 32050902101345号</span>
            </a>
        </div>

    </div>

</template>

<script>
    import {Authentication} from "../api";

    export default {
        name: "Register",
        data() {
            return {
                registerForm: {
                    newUnionid: null,
                    name: null,
                    telephone: null
                },
                disabled: false
            }
        },
        methods: {
            check() {
                if (this.registerForm.name == null || String(this.registerForm.name).trim() === ''
                    || this.registerForm.telephone == null || String(this.registerForm.telephone).trim() === '') {
                    this.disabled = false
                } else {
                    this.disabled = true
                }
            },
            submit() {
                Authentication(this.registerForm).then((res) => {
                    var msg = ''
                    if (res === 'error') {
                        msg = '无权限，请联系管理员'
                    } else {
                        msg = '认证成功，请重新扫码登录'
                    }

                    this.$router.push({
                        path: '/',
                        query: {
                            msg: msg
                        }
                    })

                })
            }
        },
        created() {
            this.registerForm.newUnionid = sessionStorage.getItem('newUnion')
        }
    }
</script>

<style scoped>

    .container {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
    }

</style>
