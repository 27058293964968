<template>

    <div class="container" v-loading="loading">

        <el-descriptions title="物业账单详情"
                         labelClassName="label"
                         class="description"
                         :column="2"
                         border
                         style="margin-bottom: 20px">

            <template slot="extra">
                <el-button v-if="detail.reviewer === '微信小程序'" type="danger" size="small" style="width: 120px" @click="CheckBill">审 核</el-button>
            </template>

            <el-descriptions-item label="账单月份">{{detail.billMonth}}</el-descriptions-item>
            <el-descriptions-item label="账单码">{{detail.billCode}}</el-descriptions-item>

            <el-descriptions-item label="账单类型">{{detail.u8fee}}</el-descriptions-item>
            <el-descriptions-item label="房源">{{detail.houseName}}</el-descriptions-item>

            <el-descriptions-item label="原金额">{{MoneyFormat(detail.amount)}}</el-descriptions-item>
            <el-descriptions-item label="减免金额">{{MoneyFormat(detail.reliefAmount)}}</el-descriptions-item>

            <el-descriptions-item label="应收金额">{{MoneyFormat(detail.amount - detail.reliefAmount)}}
            </el-descriptions-item>
            <el-descriptions-item label="实际收款">
                <el-tag v-if="detail.collectionCheck == null" size="small" type="danger">未收款</el-tag>
                <el-tag v-else size="small">{{MoneyFormat(detail.receivedAmount)}}</el-tag>
            </el-descriptions-item>

            <el-descriptions-item label="收费周期">{{detail.chargeContent}}</el-descriptions-item>
            <el-descriptions-item label="应收日期">{{detail.dueDate}}</el-descriptions-item>

            <el-descriptions-item label="制单人">{{detail.maker}}</el-descriptions-item>
            <el-descriptions-item label="审核人">{{detail.reviewer}}</el-descriptions-item>

            <el-descriptions-item label="制单时间">{{detail.createTime}}</el-descriptions-item>
            <el-descriptions-item label="最近修改">{{detail.changeTime}}</el-descriptions-item>

            <el-descriptions-item label="结算方式">
                <el-tag v-if="detail.collectionCheck == null && detail.paymentMethod=='2'" size="small" type="danger">
                    未收款
                </el-tag>
                <el-tag v-if="detail.collectionCheck == null && detail.paymentMethod!='2'" size="small" type="danger">
                    {{detail.paymentMethod=='1'?'未收款（江苏稻谷物业管理有限公司苏州分公司）':'未收款（苏州步步高物业管理有限公司）'}}
                </el-tag>
                <el-tag v-if="detail.collectionCheck != null && detail.paymentMethod!='2'" size="small">
                    {{detail.paymentMethod=='1'?'江苏稻谷物业管理有限公司苏州分公司':'苏州步步高物业管理有限公司'}}
                </el-tag>
                <el-tag v-if="detail.collectionCheck != null && detail.paymentMethod=='2'" size="small" type="info">现金
                </el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="收款人">
                <el-tag v-if="detail.collectionCheck == null" size="small" type="danger">未收款</el-tag>
                <el-tag v-else size="small">{{detail.collectionCheck + ' ' + detail.collectionCheckDate}}</el-tag>
            </el-descriptions-item>

            <el-descriptions-item label="开票信息" :span="2">
                <el-tag v-if="detail.paymentMethod == '2'" size="small" type="info">不可开票</el-tag>
                <el-tag v-if="detail.invoiceInfo == 'empty' " size="small" type="warning">无需开票</el-tag>
                <el-tag v-if="detail.invoiceInfo == null && detail.paymentMethod != '2' " size="small" type="danger">
                    未开票
                </el-tag>
                <el-tag v-if="detail.invoiceInfo != null && detail.invoiceInfo != 'empty'" size="small">
                    {{detail.invoiceInfo}}
                </el-tag>
            </el-descriptions-item>

            <el-descriptions-item label="备注" :span="2">
                {{detail.remark}}
            </el-descriptions-item>
        </el-descriptions>

        <el-descriptions title="车位订单详情"
                         labelClassName="label"
                         class="description"
                         :column="2"
                         border
                         style="margin-bottom: 20px">

            <el-descriptions-item label="车牌号" :span="2">{{parkOrder.carNum}}</el-descriptions-item>
            <el-descriptions-item label="开始时间" >{{parkOrder.startDate}}</el-descriptions-item>
            <el-descriptions-item label="结束时间" >{{parkOrder.endDate}}</el-descriptions-item>
            <el-descriptions-item label="车位数量" >{{parkOrder.quantity}}</el-descriptions-item>
            <el-descriptions-item label="车位单价" >{{MoneyFormat(parkOrder.unitPrice)}}</el-descriptions-item>
            <el-descriptions-item label="总金额" >
                <el-tag size="small">{{MoneyFormat(parkOrder.amount)}}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="创建时间" >{{parkOrder.createTime}}</el-descriptions-item>
            <el-descriptions-item label="用户姓名" >{{user.name}}</el-descriptions-item>
            <el-descriptions-item label="联系电话" >{{user.phone}}</el-descriptions-item>
            <el-descriptions-item label="订单号" :span="2">{{parkOrder.orderNo}}</el-descriptions-item>
            <el-descriptions-item label="支付流水号" :span="2">{{parkOrder.orderId}}</el-descriptions-item>
            <el-descriptions-item label="第三方订单号" :span="2">{{parkOrder.partyOrderId}}</el-descriptions-item>
        </el-descriptions>
    </div>


</template>

<script>
    import {
        GetElecPaymentDetail,
        GetParkOrderDetail,
        GetParkUser,
        GetWuyeBillByOrderId,
        GetWuyeBillDetail, UpdateWuyeBillReviewer
    } from "../../api";
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "ParkOrderDetail",
        props: {
            id: {
                type: Number,
                required: true
            },
            billId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                unionid: null,
                loading: false,
                parkOrder: {},
                user: {},
                detail: {}
            }
        },
        methods: {
            MoneyFormat(value) {
                return formatMoney(value)
            },
            CheckBill() {
                this.$alert('请确认物业账单无误！', '确认账单', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    showCancelButton: true,
                    callback: action => {
                        if (action === 'confirm') {
                            this.loading = true
                            UpdateWuyeBillReviewer(this.billId,this.unionid).then((res) =>{
                                if (res === 1) {
                                    this.loading = false
                                    this.$emit('ParkOrderDetailClose')
                                }
                            })
                        }
                    }
                });
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            this.loading = true
            GetParkOrderDetail(this.id).then((res) => {
                this.parkOrder = res
                GetParkUser(res.unionid).then((res)=>{
                    this.user = res
                    GetWuyeBillDetail(this.billId).then((res)=>{
                        this.detail = res
                        this.loading = false
                    })
                })
            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
        white-space: pre-line
    }

</style>
