<template>
    <div>
        <div v-loading="loading" v-if="authority">
            <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
                <el-collapse-item name="collapseItem" class="form-container">

                    <template slot="title">
                        <el-tag type="success" style="width: 100%;padding-left: 15px">
                            <i class="el-icon-search" style="margin-right: 5px"></i>
                            <span>共查询到 {{orderList.length}} 条记录</span>
                        </el-tag>
                    </template>

                    <el-form :inline="true"
                             :model="searchObj"
                             class="form"
                             size="small"
                             label-width="72px">

                        <el-form-item label="所属项目:" style="margin-left: 10px">
                            <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                                <el-option v-for="project in projects" :key="project.abbreviation"
                                           :value="project.projName"
                                           :label="project.projName"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="创建时间:">
                            <el-date-picker style="width: 250px"
                                            v-model="searchObj.timeRange"
                                            type="daterange"
                                            range-separator="至"
                                            value-format="yyyy-MM-dd"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            @change="submit"
                                            :clearable="false">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="用户电话:">
                            <el-input v-model="searchObj.phone" placeholder="用户电话" clearable
                                      @change="submit"></el-input>
                        </el-form-item>

                    </el-form>

                </el-collapse-item>
            </el-collapse>

            <!-- 表格 -->
            <div :style="tableContainerStyle">
                <vxe-grid ref="parkOrderTable"
                          id="parkOrderTable"
                          class="parkOrderTable"
                          v-bind="parkOrderTable"
                          @page-change="PageChange"
                          @toolbar-button-click="toolbarButtonClickEvent">

                    <template #operate="{ row }">
                        <el-button v-if="row.reviewer === '微信小程序'" type="danger" size="mini" @click.stop="btnClick(row)">审核</el-button>
                        <el-button v-else type="primary" size="mini" @click.stop="btnClick(row)">详情</el-button>
                    </template>

                </vxe-grid>

            </div>
        </div>
        <div v-else>
            <el-result style="height: -webkit-calc(100vh - 250px)"
                       icon="warning" title="无权限"
                       subTitle="抱歉，无权限操作">
            </el-result>
        </div>

        <!-- 车位 详情-->
        <el-drawer :visible.sync="detailDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        title="车位订单 - 详情"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <ParkOrderDetail :id="id" :billId="billId" @ParkOrderDetailClose="ParkOrderDetailClose"></ParkOrderDetail>
        </el-drawer>

    </div>
</template>

<script>
    import XEUtils from 'xe-utils'
    import {GetParkOrders, GetProjectsWithoutAll, GetWuyeAuthority} from "../../api";
    import ParkOrderDetail from "./ParkOrderDetail"

    export default {
        name: "ParkOrderManagement",
        components: {ParkOrderDetail},
        data() {
            return {
                unionid: null,
                loading: false,
                authority: true,
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                projects: [],
                searchObj: {
                    projName: '',
                    timeRange: [
                        XEUtils.toDateString(XEUtils.getWhatDay(Date.now(), -30), 'yyyy-MM-dd'),
                        XEUtils.toDateString(XEUtils.getWhatDay(Date.now()), 'yyyy-MM-dd'),
                    ],
                    phone: ''
                },
                currentPage: 1,
                pageSize: 50,
                total: 0,
                orderList: [],
                detailDrawer: false,
                id: null,
                billId: null
            }
        },
        computed: {
            parkOrderTable: function () {
                return {
                    height: 'auto',
                    size: 'small',
                    border: true,
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'}
                        ],
                        size: 'medium',
                        custom: true
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    scrollY: {
                        mode: 'wheel'
                    },
                    columnConfig: {
                        width: 'auto',
                        minWidth: '150'
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.orderList,
                    pagerConfig: {
                        layouts: ['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Sizes', 'Total'],
                        pageSizes: [10, 20, 50, 100],
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        total: this.total
                    },
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {
                            resizable: true, title: '订单信息', align:'center',children: [
                                {resizable: true, title: '车牌号', field: 'carNum', className: 'font-blue'},
                                {resizable: true, title: '开始日期', field: 'startDate',formatter: function ({cellValue}) {
                                        return XEUtils.toDateString(cellValue, 'yyyy-MM-dd HH:mm:ss')
                                    }},
                                {resizable: true, title: '结束日期', field: 'endDate',formatter: function ({cellValue}) {
                                        return XEUtils.toDateString(cellValue, 'yyyy-MM-dd HH:mm:ss')
                                    }},
                                {resizable: true, title: '月份数', field: 'cycle'},
                                {resizable: true, title: '车位单价', field: 'unitPrice', formatter: 'FormatMoney'},
                                {resizable: true, title: '车位数量', field: 'quantity'},
                                {resizable: true, title: '支付金额', field: 'amount', className: 'font-blue', formatter: 'FormatMoney'},
                                {resizable: true, title: 'orderId', field: 'orderId',visible: false},
                                {resizable: true, title: 'orderNo', field: 'orderNo',visible: false},
                                {resizable: true, title: 'partyOrderNo', field: 'partyOrderNo',visible: false},
                                {resizable: true, title: '创建时间', field: 'createTime',visible: false}
                            ]
                        },
                        {
                            resizable: true, title: '用户信息', align:'center',children: [
                                {resizable: true, title: '用户姓名', field: 'name'},
                                {resizable: true, title: '电话', field: 'phone'}
                            ]
                        },
                        {title: '操作', field: 'operation', fixed: 'right', align:'center',width: 80, slots: {default: 'operate'}}
                    ]
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            PageChange({currentPage, pageSize}) {
                this.currentPage = currentPage
                this.pageSize = pageSize
                this.submit()
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        this.$refs.parkOrderTable.openExport()
                        break
                    case 'zoom':
                        this.$refs.parkOrderTable.zoom()
                        break
                }
            },
            btnClick(row) {
                this.id = row.id
                this.billId = row.billId
                this.detailDrawer = true
            },
            submit() {
                this.loading = true
                GetParkOrders(this.searchObj,this.pageSize,this.currentPage).then((res) => {
                    this.total = res.count
                    this.orderList = res.list
                    this.loading = false
                })
            },
            ParkOrderDetailClose() {
                this.detailDrawer = false
                this.submit()
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeAuthority(this.unionid).then((res) => {
                /* 权限控制 */
                if (res.authorities.indexOf('parkOperation') != -1) {
                    this.authority = true
                } else {
                    this.authority = false
                }

                this.searchObj.projName = '稻谷国际中心'
                if (res.projName === 'ALL') {
                    GetProjectsWithoutAll().then((res) => {
                        this.projects = res
                    })
                } else {
                    this.projects = []
                    var arr = res.projName.split(',')

                    this.searchObj.projName = arr[0]

                    for (var i = 0; i <= arr.length - 1; i++) {
                        this.projects.push({
                            projName: arr[i]
                        })
                    }
                }

                this.submit()
            })
        },
        mounted() {
            this.$nextTick(function () {
                if (this.authority) {
                    this.bigHeight = this.$refs.collapse.$el.offsetHeight
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                }
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .parkOrderTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .parkOrderTable >>> .font-blue {
        color: #45aaf2;
    }

    .parkOrderTable >>> .footer-row {
        background-color: #F8F8F9;
    }

</style>
