<template>
    <div class="container">
        <el-tabs value="first">
            <el-tab-pane label="当前方案" name="first">
                <div style="height: -webkit-calc(100vh - 160px)">
                    <vxe-grid
                            ref="xTable1"
                            id="currentTable"
                            class="currentTable"
                            v-bind="currentTable">

                        <template #operate="{ row }">
                            <el-button type="warning" size="mini" plain @click="endClick(row)" :disabled="!authority">
                                提前终止
                            </el-button>
                        </template>

                    </vxe-grid>
                </div>
            </el-tab-pane>
            <el-tab-pane label="历史方案" name="second">
                <div style="height: -webkit-calc(100vh - 160px)">
                    <vxe-grid
                            ref="xTable2"
                            id="historyTable"
                            class="historyTable"
                            v-bind="historyTable">
                    </vxe-grid>
                </div>
            </el-tab-pane>
        </el-tabs>


        <el-drawer :visible.sync="drawer" size="45%"
                   destroy-on-close
                   :append-to-body="true">
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="success"
                        :closable="false">
                </el-alert>
            </template>
            <div class="container">
                <el-form :model="endForm" label-width="80px">

                    <el-form-item label="终止时间">
                        <el-date-picker style="width: 100%"
                                        v-model="endForm.endDate"
                                        type="date"
                                        value-format="yyyy-MM-dd"
                                        :picker-options="pickerOptions"
                                        placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item style="text-align: right">
                        <el-button size="small" type="primary" @click="submit">终止</el-button>
                    </el-form-item>

                </el-form>
            </div>
        </el-drawer>

    </div>
</template>

<script>
    import {
        GetAuthority,
        GetCurrentElecBillPlans,
        GetHistoryElecBillPlans,
        InsertOperationRecord,
        UpdateElecBillPlanEndDate
    } from "../../api";
    import XEUtils from 'xe-utils'

    export default {
        name: "ClientElecBillPlans",
        data() {
            return {
                unionid: null,
                drawer: false,
                drawerTitle: '',
                authority: false,
                currentPlans: [],
                historyPlans: [],
                endForm: {
                    planId: '',
                    endDate: ''
                },
                pickerOptions: {},
            }
        },
        computed: {
            currentTable() {
                return {
                    height: 'auto',
                    size: 'small',
                    data: this.currentPlans,
                    rowConfig: {
                        isHover: true
                    },
                    border: 'full',
                    align: 'center',
                    columns: [
                        {resizable: true, title: '房源', field: 'houseName'},
                        {resizable: true, title: '状态', field: 'houseState'},
                        {resizable: true, title: '表计名称', field: 'meterName'},
                        {resizable: true, title: '表计编号', field: 'meterId'},
                        {
                            resizable: true, title: '付款周期', field: 'payCycle', formatter: function ({cellValue}) {
                                return cellValue + '个月'
                            }
                        },
                        {resizable: true, title: '单价（元/度）', field: 'unitPrice', formatter: 'FormatNum'},
                        {
                            resizable: true, title: '单价有效期', children: [
                                {resizable: true, width: 90, title: '开始时间', field: 'startDate'},
                                {resizable: true, width: 90, title: '结束时间', field: 'endDate'}
                            ]
                        },
                        {title: '操作', fixed: 'right', width: 100, slots: {default: 'operate'}}
                    ]
                }
            },
            historyTable() {
                return {
                    height: 'auto',
                    size: 'small',
                    data: this.historyPlans,
                    rowConfig: {
                        isHover: true
                    },
                    border: 'full',
                    align: 'center',
                    columns: [
                        {resizable: true, title: '房源', field: 'houseName'},
                        {resizable: true, title: '状态', field: 'houseState'},
                        {resizable: true, title: '表计名称', field: 'meterName'},
                        {resizable: true, title: '表计编号', field: 'meterId'},
                        {
                            resizable: true, title: '付款周期', field: 'payCycle', formatter: function ({cellValue}) {
                                return cellValue + '个月'
                            }
                        },
                        {resizable: true, title: '单价（元/度）', field: 'unitPrice', formatter: 'FormatNum'},
                        {
                            resizable: true, title: '单价有效期', children: [
                                {resizable: true, width: 90, title: '开始时间', field: 'startDate'},
                                {resizable: true, width: 90, title: '结束时间', field: 'endDate'}
                            ]
                        },
                    ]
                }
            }
        },
        props: {
            clientId: {
                type: String,
                required: true
            }
        },
        methods: {
            updateInfo() {
                GetCurrentElecBillPlans(this.clientId).then((res) => {
                    this.currentPlans = res
                })
                GetHistoryElecBillPlans(this.clientId).then((res) => {
                    this.historyPlans = res
                })
            },
            endClick(row) {
                this.endForm.planId = row.planId
                this.pickerOptions = {
                    disabledDate(time) {
                        return time.getTime() < XEUtils.getWhatDay(row.startDate)
                    }
                }
                this.drawerTitle = row.planId + ' - 计费方案终止'
                this.drawer = true
            },
            submit() {
                UpdateElecBillPlanEndDate(this.endForm).then((res) => {
                    if (res === 1) {
                        InsertOperationRecord(this.unionid, 'endElecBillPlan', this.endForm.planId).then((res) => {
                            if (res === 1) {
                                this.drawer = false
                                this.updateInfo()
                            }
                        })
                    }
                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetAuthority('endElecBillPlan', this.unionid).then((res) => {
                this.authority = res
            })

            this.updateInfo()
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

</style>
