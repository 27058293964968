<template>

    <div>
        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
            <el-collapse-item name="collapseItem" class="form-container">
                <template slot="title">
                    <el-tag type="success" style="width: 100%;padding-left: 15px">
                        <i class="el-icon-search" style="margin-right: 5px"></i>
                        <span>共查询到 {{this.wuyeUserInfos.length}} 位用户</span>
                    </el-tag>
                </template>

                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px" style="padding-left: 15px">

                    <el-form-item label="用户姓名:">
                        <el-input v-model="searchObj.name" placeholder="用户姓名" clearable @change="submit"></el-input>
                    </el-form-item>

                </el-form>
            </el-collapse-item>
        </el-collapse>

        <!-- 表格 -->
        <div :style="tableContainerStyle">
            <vxe-grid ref="xTable"
                      id="wuyeUserInfoTable"
                      class="wuyeUserInfoTable"
                      v-bind="wuyeUserInfoTable"
                      @toolbar-button-click="toolbarButtonClickEvent">
            </vxe-grid>
        </div>

    </div>

</template>

<script>
    import {GetWuyeUserInfos} from "../../api";

    export default {
        name: "WuyeUserInfo",
        data() {
            return {
                wuyeUserInfos: [],
                searchObj: {
                    name: ''
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                }
            }
        },
        computed: {
            wuyeUserInfoTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'}
                        ],
                        size: 'medium',
                        custom: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    data: this.wuyeUserInfos,
                    columns: [
                        {resizable: true, title: '创建时间', field: 'time'},
                        {resizable: true, title: '姓名', field: 'name'},
                        {
                            resizable: true, title: '性别', field: 'sex', formatter: function ({cellValue}) {
                                if (!cellValue) {
                                    return '男'
                                } else {
                                    return '女'
                                }

                            }
                        },
                        {resizable: true, title: '联系电话', field: 'phone'}
                    ]
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            submit() {
                GetWuyeUserInfos(this.searchObj.name).then((res) => {
                    this.wuyeUserInfos = res
                })
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        GetWuyeUserInfos(this.searchObj.name).then((res) => {
                            this.wuyeUserInfos = res
                        })
                        break
                }
            },
        },
        created() {

            GetWuyeUserInfos(this.searchObj.name).then((res) => {
                this.wuyeUserInfos = res
            })

        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

</style>
