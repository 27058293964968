<template>

    <div class="container">

        <el-descriptions title="本期用量详情"
                         labelClassName="label"
                         class="description"
                         :column="2"
                         border
                         size="medium"
                         style="margin-bottom: 20px">
            <el-descriptions-item label="所属项目" :span="2">{{detail.projName}}</el-descriptions-item>
            <el-descriptions-item label="表计属性">{{detail.meterAttribute}}</el-descriptions-item>
            <el-descriptions-item label="表计名称">{{detail.meterName}}</el-descriptions-item>
            <el-descriptions-item label="表计编号">{{detail.meterId}}</el-descriptions-item>
            <el-descriptions-item label="倍率">{{Number(detail.magnification).toFixed(2)}}</el-descriptions-item>
            <el-descriptions-item label="用量周期">{{detail.elecCycle}}</el-descriptions-item>
            <el-descriptions-item label="周期结算日">{{detail.settlementDate}}</el-descriptions-item>
            <el-descriptions-item label="上次行度">{{Number(detail.lastDegree).toFixed(2)}}</el-descriptions-item>
            <el-descriptions-item label="本次行度">{{Number(detail.latestDegree).toFixed(2)}}</el-descriptions-item>
            <el-descriptions-item label="本期用量" :span="2">{{Number(detail.elecUsage).toFixed(2)}}</el-descriptions-item>
        </el-descriptions>

        <el-descriptions title="本期用量备注"
                         v-if="!formShow"
                         labelClassName="label"
                         class="description"
                         :column="1"
                         size="medium"
                         border>
            <template slot="extra">
                <el-button v-if="authority" type="danger" size="small" plain @click="editClick">编辑备注</el-button>
            </template>
            <el-descriptions-item label="本期备注">{{detail.remark}}</el-descriptions-item>
        </el-descriptions>

        <el-form :model="remarkForm" label-position="left" label-width="70px" v-else>
            <el-form-item label="本期备注">
                <el-input type="textarea" v-model="remarkForm.remark" clearable></el-input>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" plain @click="remarkFormChange">取消</el-button>
                <el-button size="small" plain type="success" @click="remarkFormSubmit">保存</el-button>
            </el-form-item>

            <el-divider></el-divider>
        </el-form>

        <!--公摊表计-->
        <div v-if="detail.meterAttribute === '公摊表计'">
            <el-descriptions title="电表负载"
                             v-if="!loadFormShow"
                             labelClassName="label"
                             class="description"
                             :column="1"
                             size="medium"
                             border>
                <template slot="extra">
                    <el-button type="warning" size="small" plain @click="loadEditClick">编辑负载</el-button>
                </template>
                <el-descriptions-item label="电表负载">{{sharedMeterDetail.meterLoad}}</el-descriptions-item>
            </el-descriptions>

            <el-form :model="loadForm" label-position="left" label-width="70px" v-else>

                <el-divider></el-divider>

                <el-form-item label="电表负载">
                    <el-input type="textarea" v-model="loadForm.meterLoad" clearable></el-input>
                </el-form-item>

                <el-form-item style="text-align: right">
                    <el-button size="small" plain @click="loadFormChange">取消</el-button>
                    <el-button size="small" plain type="success" @click="loadFormSubmit">保存</el-button>
                </el-form-item>
            </el-form>
        </div>


        <!--自用表计-->
        <div v-if="detail.meterAttribute === '自用表计'">
            <el-descriptions title="关联房源"
                             labelClassName="label"
                             class="description"
                             :column="2"
                             border
                             size="medium"
                             style="margin-bottom: 20px">
                <el-descriptions-item label="所属项目" :span="2">{{selfUseMeterDetail.projName}}</el-descriptions-item>
                <el-descriptions-item label="楼宇">{{selfUseMeterDetail.building}}</el-descriptions-item>
                <el-descriptions-item label="房源">{{selfUseMeterDetail.houseName}}</el-descriptions-item>
                <el-descriptions-item label="房源状态">
                    <el-tag size="small">{{selfUseMeterDetail.houseState}}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="建筑面积">{{Number(selfUseMeterDetail.constructionArea).toFixed(2) + ' ㎡'}}
                </el-descriptions-item>
                <el-descriptions-item label="客户名称" :span="2">{{selfUseMeterDetail.clientName}}</el-descriptions-item>
            </el-descriptions>
        </div>

        <!--总分表计-->
        <div v-if="detail.meterAttribute === '总分表计'">

            <el-descriptions title="本期损耗"
                             labelClassName="label"
                             class="description"
                             :column="2"
                             border
                             size="medium"
                             style="margin-bottom: 20px">
                <el-descriptions-item label="子表数量">{{sumMeterLoss.childMeterCount}}</el-descriptions-item>
                <el-descriptions-item label="子表用量和">{{Number(sumMeterLoss.childMeterUsageSum).toFixed(2)}}
                </el-descriptions-item>
                <el-descriptions-item label="损耗">{{Number(sumMeterLoss.lossUsage).toFixed(2)}}</el-descriptions-item>
                <el-descriptions-item label="损耗率">{{Number(sumMeterLoss.lossRate * 100).toFixed(2) + '%'}}
                </el-descriptions-item>
            </el-descriptions>

            <vxe-grid v-bind="childMeterTable"
                      class="childMeterTable"
                      @cell-click="cellClick"
                      style="margin-bottom: 40px"></vxe-grid>
        </div>

        <!--抽屉-->
        <el-drawer :visible.sync="drawer" size="50%"
                   destroy-on-close
                   :append-to-body="true">
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <ElecRecordDetail :elecId="newElecId" :month="month" :projName="projName"></ElecRecordDetail>
        </el-drawer>

    </div>

</template>

<script>
    import {
        GetAuthority, GetElecRecord,
        GetSelfUseMeterRecord,
        GetSharedMeterRecord, GetSumMeterChildMeterRecords, GetSumMeterRecord, InsertOperationRecord,
        UpdateElecMeterLoad,
        UpdateElecRecordRemark
    } from "../../api";

    export default {
        name: "ElecRecordDetail",
        data() {
            return {
                unionid: null,
                drawer: false,
                drawerTitle: '',
                authority: false,
                formShow: false,
                elecRecord: {},
                detail: {},
                sumMeterDetail: {},
                sharedMeterDetail: {},
                selfUseMeterDetail: {},
                remarkForm: {
                    elecId: null,
                    remark: null
                },
                loadFormShow: false,
                loadForm: {
                    meterId: null,
                    meterLoad: null
                },
                sumMeterLoss: {},
                sumMeterChildMeters: [],
                newElecId: null
            }
        },
        props: {
            elecId: {
                type: Number,
                required: true
            },
            month: {
                type: String,
                required: true
            },
            projName: {
                type: String,
                required: true
            }
        },
        computed: {

            childMeterTable() {

                return {
                    height: 500,
                    size: 'small',
                    data: this.sumMeterChildMeters,
                    rowClassName: this.rowStyle,
                    rowConfig: {
                        isHover: true
                    },
                    columns: [
                        {resizable: true, title: '属性', field: 'meterAttribute'},
                        {resizable: true, title: '名称', field: 'meterName'},
                        {resizable: true, title: '编号', field: 'meterId'},
                        {
                            resizable: true,
                            title: '本期用量',
                            field: 'elecUsage',
                            formatter: 'FormatNum',
                            sortable: true,
                            sortBy: 'elecUsage'
                        },
                        {resizable: true, title: '子表数', field: 'childMeterCount'},
                        {resizable: true, title: '子表用量和', field: 'childMeterUsageSum', formatter: 'FormatNum'},
                        {
                            resizable: true,
                            title: '损耗',
                            field: 'lossUsage',
                            formatter: 'FormatNum',
                            sortable: true,
                            sortBy: 'lossUsage'
                        },
                        {
                            resizable: true,
                            title: '损耗率',
                            field: 'lossRate',
                            formatter: 'FormatPercent',
                            sortable: true,
                            sortBy: 'lossRate'
                        },
                    ]
                }
            }
        },
        methods: {
            editClick() {
                this.remarkForm.elecId = this.elecId
                this.remarkForm.remark = this.detail.remark
                this.formShow = true
            },
            loadEditClick() {
                this.loadForm.meterId = this.detail.meterId
                this.loadForm.meterLoad = this.sharedMeterDetail.meterLoad
                this.loadFormShow = true
            },
            remarkFormChange() {
                this.formShow = false
            },
            remarkFormSubmit() {
                UpdateElecRecordRemark(this.remarkForm).then((res) => {
                    if (res === 1) {
                        InsertOperationRecord(this.unionid, 'updateElecRemark', this.elecId).then((res) => {
                            if (res === 1) {
                                this.getDetail()
                                this.formShow = false
                            }
                        })
                    }
                })
            },
            loadFormChange() {
                this.loadFormShow = false
            },
            loadFormSubmit() {
                UpdateElecMeterLoad(this.loadForm).then((res) => {
                    if (res === 1) {
                        InsertOperationRecord(this.unionid, 'updateElecMeter', this.detail.meterId).then((res) => {
                            if (res === 1) {
                                this.getDetail()
                                this.loadFormShow = false
                            }
                        })
                    }
                })
            },
            getDetail() {
                GetElecRecord(this.elecId).then((res) => {
                    this.detail = res

                    switch (res.meterAttribute) {
                        case '自用表计':
                            GetSelfUseMeterRecord(this.elecId).then((res) => {
                                this.selfUseMeterDetail = res
                            })
                            break
                        case '公摊表计':
                            GetSharedMeterRecord(this.elecId).then((res) => {
                                this.sharedMeterDetail = res
                            })
                            break
                        case '总分表计':
                            GetSumMeterRecord(res.meterId, this.month).then((res) => {
                                this.sumMeterLoss = res
                                GetSumMeterChildMeterRecords(res.meterId, this.month).then((res) => {
                                    this.sumMeterChildMeters = res
                                })
                            })
                            break
                    }

                })
            },
            rowStyle({row}) {
                if (row.lossRate < 0 || row.lossRate > 0.1 || (row.elecUsgae === 0 && row.lossUsage != 0)) {
                    return 'row-red'
                } else {
                    return ''
                }
            },
            cellClick({row}) {
                this.newElecId = row.elecId
                this.drawerTitle = row.meterAttribute + ' - ' + row.meterName + ' - ' + row.meterId
                this.drawer = true
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetAuthority('elecRecordEdit', this.unionid).then((res) => {
                this.authority = res
            })

            this.getDetail()

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

    .childMeterTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .childMeterTable >>> .row-red {
        background-color: #fcece7;
    }

</style>
