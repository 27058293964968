<template>

    <div>
        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
            <el-collapse-item name="collapseItem" class="form-container">
                <template slot="title">
                    <el-tag type="success" style="width: 100%;padding-left: 15px">
                        <i class="el-icon-search" style="margin-right: 5px"></i>
                        <span>共查询到 {{ total }} 条数据</span>
                    </el-tag>
                </template>

                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px" style="padding-left: 15px">

                    <el-form-item label="选择时间:">
                        <el-date-picker style="width: 250px"
                                        v-model="searchObj.timeRange"
                                        type="daterange"
                                        range-separator="至"
                                        value-format="yyyy-MM-dd"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        @change="submit">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="跟进人:">
                        <el-select style="width: 250px" v-model="searchObj.searchUnionids"
                                   collapse-tags multiple filterable placeholder="跟进人" @change="submit">
                            <el-checkbox v-model="checked" @change="selectAll">全选</el-checkbox>
                            <el-option v-for="employee in employees" :key="employee.unionid"
                                       :value="employee.unionid"
                                       :label="employee.name"></el-option>
                        </el-select>
                    </el-form-item>

                </el-form>
            </el-collapse-item>
        </el-collapse>

        <div :style="tableContainerStyle" v-loading="loading">
            <vxe-grid
                    ref="businessEveryDayCountTable"
                    id="businessEveryDayCountTable"
                    class="businessEveryDayCountTable"
                    v-bind="businessEveryDayCountTable"
                    @toolbar-button-click="toolbarButtonClickEvent"
                    @cell-click="cellClick"
                    @page-change="PageChange">
            </vxe-grid>
        </div>

        <!-- 抽屉 -->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <DailyReportDetail v-if="type === 'dailyReport'" :id="id" :followUpId="followUpId"></DailyReportDetail>
            <BusinessInfo v-else :id="id" :followUpId="followUpId"></BusinessInfo>
        </el-drawer>

    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {
        GetBusinessAuthority,
        GetBusinessEmployees,
        GetComplexEveryDay,
        GetComplexEveryDayCount
    } from "../../api";
    import BusinessInfo from "./BusinessInfo"
    import DailyReportDetail from "./DailyReportDetail"

    export default {
        name: "BusinessEveryDayCount",
        components: {BusinessInfo, DailyReportDetail},
        data() {
            return {
                loading: false,
                unionid: null,
                drawer: false,
                drawerTitle: '',
                employees: [],
                checked: true,
                searchObj: {
                    timeRange: [
                        XEUtils.toDateString(XEUtils.getWhatDay(Date.now(), -7), 'yyyy-MM-dd'),
                        XEUtils.toDateString(Date.now(), 'yyyy-MM-dd'),
                    ],
                    searchUnionids: []
                },
                complexFollowUps: [],
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                type: null,
                id: null,
                followUpId: null,
                currentPage: 1,
                pageSize: 50,
                total: 0,
                reader: false
            }
        },
        watch: {
            drawer: function (val, oldVal) {
                if (!val) {
                    this.GetInfo()
                }
            }
        },
        computed: {
            businessEveryDayCountTable() {
                var that = this
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'print', name: '打 印', icon: 'vxe-icon--print'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'},
                        ],
                        size: 'medium',
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    printConfig: {
                        modes: ['current'],
                        useStyle: true
                    },
                    pagerConfig: {
                        layouts: ['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Sizes', 'Total'],
                        pageSizes: [10, 20, 50, 100],
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        total: this.total
                    },
                    data: this.complexFollowUps,
                    columns: [
                        {
                            field: 'haveRead', width: '40', formatter: function ({cellValue}) {

                                if (!that.reader) {
                                    return ''
                                } else {

                                    if (cellValue.includes(that.unionid)) {
                                        return ''
                                    } else {
                                        return '🔴'
                                    }

                                }

                            }
                        },
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '类型', field: 'type', width: '100'},
                        {resizable: true, title: '客户名称', field: 'businessName', className: 'font-blue'},
                        {
                            resizable: true, title: '跟进时间', field: 'time', formatter: function ({cellValue}) {
                                return XEUtils.toDateString(cellValue, 'yyyy-MM-dd')
                            }
                        },
                        {resizable: true, title: '当前阶段', field: 'currentStage'},
                        {
                            resizable: true, title: '跟进人', formatter: function ({row}) {
                                return row.coordinatorName == undefined ? row.unionName : row.unionName + ',' + row.coordinatorName
                            }
                        },
                        {
                            resizable: true, title: '距今', formatter: function ({row}) {
                                return XEUtils.getDateDiff(new Date(row.time).getTime(), new Date().getTime()).dd == undefined ?
                                    '' : (XEUtils.getDateDiff(new Date(row.time).getTime(), new Date().getTime()).time / 86400000).toFixed(0) + ' 天前'
                            }
                        }
                    ]
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            GetInfo() {
                if (this.searchObj.searchUnionids.length > 0) {
                    this.loading = true
                    GetComplexEveryDayCount(this.searchObj).then((res) => {
                        this.total = res
                        GetComplexEveryDay(this.searchObj, this.pageSize, this.currentPage).then((res) => {
                            this.complexFollowUps = res
                            this.loading = false
                        })
                    })
                } else {
                    this.total = 0
                    this.complexFollowUps = []
                }
            },
            submit() {
                this.currentPage = 1
                this.pageSize = 50
                this.GetInfo()
            },
            selectAll() {
                this.searchObj.searchUnionids = []
                if (this.checked) {
                    this.employees.forEach((item) => {
                        this.searchObj.searchUnionids.push(item.unionid)
                    });
                }
                this.submit()
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        this.$refs.businessEveryDayCountTable.openExport()
                        break
                    case 'print':
                        this.$refs.businessEveryDayCountTable.openPrint()
                        break
                    case 'zoom':
                        this.$refs.businessEveryDayCountTable.zoom()
                        break

                }
            },
            cellClick({row}) {
                if (row.type === '商机') {
                    this.type = 'businessInfo'
                    this.id = Number(row.id)
                    this.followUpId = Number(row.followUpId)
                } else {
                    this.type = 'dailyReport'
                    this.id = Number(row.id)
                    this.followUpId = Number(row.followUpId)
                }
                this.drawerTitle = row.businessName + ' - 详情'
                this.drawer = true
            },
            PageChange({currentPage, pageSize}) {
                this.currentPage = currentPage
                this.pageSize = pageSize
                this.GetInfo()
            },
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetBusinessAuthority(this.unionid).then((res) => {
                this.reader = res.role == 'reader'
            })

            GetBusinessEmployees(this.unionid).then((res) => {
                this.employees = res
                for (var i = 0; i < res.length; i++) {
                    this.searchObj.searchUnionids.push(res[i].unionid)
                }

                this.submit()

            })

        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    /*.el-select {
        width: 200px
    }*/

    .businessEveryDayCountTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .businessEveryDayCountTable >>> .font-blue {
        color: #45aaf2;
    }

    .el-checkbox {
        text-align: right;
        width: 90%;
        margin-bottom: 10px;
    }

</style>
