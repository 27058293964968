<template>

    <div class="container">

        <el-descriptions v-if="client.type === 1" title="开票信息"
                         labelClassName="label"
                         class="description"
                         :column="1"
                         border
                         style="margin-bottom: 20px">
            <el-descriptions-item label="公司名称">{{client.clientName}}</el-descriptions-item>
            <el-descriptions-item label="税号">{{client.taxNumber}}</el-descriptions-item>
            <el-descriptions-item label="开户行及账号">
                {{ (client.bank != null && client.bankAccount != null)?client.bank + ' ' + client.bankAccount:null}}
            </el-descriptions-item>
            <el-descriptions-item label="地址及电话">
                {{ (client.address != null && client.telephone != null)?client.address + ' ' + client.telephone:null}}
            </el-descriptions-item>
        </el-descriptions>

        <el-descriptions v-else title="开票信息"
                         labelClassName="label"
                         class="description"
                         :column="1"
                         border
                         style="margin-bottom: 20px">
            <el-descriptions-item label="姓名">{{client.clientName}}</el-descriptions-item>
            <el-descriptions-item label="身份证号">{{client.taxNumber}}</el-descriptions-item>
        </el-descriptions>

        <el-divider content-position="left">账单明细</el-divider>

        <vxe-grid ref="xTable"
                  id="clientWuyeBillListTable"
                  class="clientWuyeBillListTable"
                  v-bind="clientWuyeBillListTable"
                  @toolbar-button-click="toolbarButtonClickEvent"
                  @cell-click="cellClick">

            <!-- 收款信息、开票信息、支付方式 -->
            <template #collection="{ row }">
                <el-tag v-if="row.collectionCode != null" type="success" size="small">已收款</el-tag>
                <el-tag v-else type="danger" size="small">未收款</el-tag>
            </template>

            <template #invoice="{ row }">
                <el-tag v-if="row.paymentMethod == '2'" type="info" size="small">不可开票</el-tag>
                <el-tag v-if="row.invoiceInfo == 'empty'" type="warning" size="small">无需开票</el-tag>
                <el-tag v-if="row.paymentMethod != '2' && row.invoiceInfo != null && row.invoiceInfo != 'empty'"
                        type="success" size="small">已开票
                </el-tag>
                <el-tag v-if="row.paymentMethod != '2' && row.invoiceInfo == null" type="danger" size="small">未开票
                </el-tag>
            </template>

            <template #paymentMethod="{ row }">
                <el-tag v-if="(row.paymentMethod != '2') && row.collectionCode != null" size="small">{{'对公' + row.paymentMethod}}</el-tag>
                <!--<el-tag v-if="row.paymentMethod == '1' && row.collectionCode == null" size="small">未支付</el-tag>-->
                <el-tag v-if="row.paymentMethod == '2' && row.collectionCode != null" type="info" size="small">
                    现金
                </el-tag>
                <el-tag v-if="row.collectionCode == null" type="danger" size="small">
                    {{'对公' + row.paymentMethod}}
                </el-tag>
            </template>

        </vxe-grid>

        <!--详情-->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close :append-to-body="true">
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <WuyeBillDetail :id="billId" @WuyeBillDetailClose="WuyeBillDetailClose"></WuyeBillDetail>
        </el-drawer>


    </div>

</template>

<script>
    import {GetClientWuyeBillLists, GetWuyeClientDetail} from "../../api";
    import formatMoney from "../../utils/formatMoney";
    import WuyeBillDetail from "./WuyeBillDetail"

    export default {
        name: "WuyeClientBillDetail",
        components: {WuyeBillDetail},
        props: {
            clientId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                unionid: null,
                client: {},
                bills: [],
                drawer: false,
                drawerTitle: '',
                billId: null
            }
        },
        computed: {
            clientWuyeBillListTable: function () {
                return {
                    height: '580',
                    showOverflow: true,
                    toolbarConfig: {
                        buttons: [
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                        ],
                        size: 'medium'
                    },
                    size: 'small',
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.bills,
                    columns: [
                        {resizable: true, title: '账单月份', field: 'billMonth'},
                        {resizable: true, title: '收费项目', field: 'u8fee'},
                        {resizable: true, title: '应收金额', field: 'amount', formatter: this.FormatReceivableAmount},
                        {
                            resizable: true,
                            title: '实际收款',
                            field: 'receivedAmount',
                            formatter: this.FormatMoney,
                            className: 'font-blue'
                        },
                        {resizable: true, title: '收款', field: 'collectionCode', slots: {default: 'collection'}},
                        {resizable: true, title: '发票', field: 'invoiceInfo', slots: {default: 'invoice'}},
                        {resizable: true, title: '付款方式', field: 'paymentMethod', slots: {default: 'paymentMethod'}},
                        {resizable: true, title: '备注', field: 'remark'}
                    ],
                    showFooter: true,
                    footerRowClassName: 'footer-row',
                    footerMethod({columns, data}) {

                        return [
                            columns.map((column, columnIndex) => {
                                if (columnIndex === 0) {
                                    return '合计：'
                                }

                                if (['amount'].includes(column.property)) {

                                    let count = 0
                                    let relief = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    data.forEach(item => {
                                        relief += Number(item['reliefAmount'])
                                    })
                                    return formatMoney((count - relief).toFixed(2))
                                }

                                if (['receivedAmount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {

                                        if (item.collectionCode != null) {
                                            count += Number(item[column.property])
                                        }
                                    })
                                    return formatMoney(count.toFixed(2))
                                }

                                return ''
                            }),
                        ]
                    }
                }
            }
        },
        methods: {
            FormatReceivableAmount({cellValue, row, column}) {

                return formatMoney(row.amount - row.reliefAmount)
            },
            FormatMoney({cellValue, row, column}) {

                if (row.collectionCode == null) {
                    return ''
                } else {
                    return formatMoney(cellValue)
                }

            },
            cellClick({row}) {
                this.drawerTitle = row.billMonth + " " + row.clientName + " " + row.u8fee
                this.billId = row.id
                this.drawer = true
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'export-plus':
                        this.$refs.xTable.openExport()
                        break
                }
            },
            WuyeBillDetailClose() {
                this.drawer = false
                GetClientWuyeBillLists(this.clientId).then((res) => {
                    this.bills = res
                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeClientDetail(this.clientId).then((res) => {
                this.client = res
            })

            GetClientWuyeBillLists(this.clientId).then((res) => {
                this.bills = res
            })
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
        white-space: pre-line
    }

    .clientWuyeBillListTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .clientWuyeBillListTable >>> .font-blue {
        color: #45aaf2;
    }

    .clientWuyeBillListTable >>> .footer-row {
        background-color: #F8F8F9;
    }

</style>
