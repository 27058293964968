<template>
    <div class="container" v-loading="loading">


        <el-form ref="addParkingRecord" :model="form" :rules="rules" label-width="80px" size="medium">

            <el-form-item label="客户选择" required prop="clientId">
                <el-select v-model="form.clientId" filterable @change="ClientChange"
                           placeholder="请选择客户" style="width: 100%">
                    <el-option
                            v-for="item in clients"
                            :key="item.clientId"
                            :label="item.clientName"
                            :value="item.clientId">
                        <span style="float: left">{{ item.clientName }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.clientId }}</span>
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="车位选择" required prop="parkingInfo">
                <el-select v-model="form.parkingInfo" filterable multiple clearable
                           placeholder="请选择车位" style="width: 100%" @change="ParkingChange">
                    <el-option
                            v-for="item in parkings"
                            :key="item.parkingNo"
                            :label="item.parkingNo + ' (月租金' + item.rentPrice + '元,月管理费' + item.managementPrice + '元)'"
                            :value="item.parkingNo + ';' + item.rentPrice + ';' + item.managementPrice">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="计费周期" required prop="timeRange">
                <el-date-picker style="width: 100%"
                                v-model="form.timeRange"
                                type="daterange"
                                range-separator="至"
                                value-format="yyyy-MM-dd"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :clearable="false" @change="TimeChange">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="月份数">
                <el-input-number v-model="form.months"
                                 :precision="0" :step="1" :min="0">
                </el-input-number>
            </el-form-item>

            <el-form-item label="付款人" required prop="payer" v-if="payerShow">
                <el-input v-model="form.payer" placeholder="请输入客户名称（简称即可）/姓名"></el-input>
            </el-form-item>

            <el-form-item label="备注">
                <el-input
                        type="textarea"
                        :autosize="{ minRows: 4}"
                        placeholder="备注"
                        v-model="form.remark">
                </el-input>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" @click="submit">新增</el-button>
            </el-form-item>
        </el-form>

        <vxe-table
                border
                show-header-overflow
                show-overflow
                :row-config="{isHover: true}"
                :data="items">
            <vxe-column field="parkingNo" title="车位号"></vxe-column>
            <vxe-column field="rentPrice" title="月租金" formatter="FormatMoney"></vxe-column>
            <vxe-column field="managementPrice" title="月管理费" formatter="FormatMoney"></vxe-column>
            <vxe-column field="months" title="月份数"></vxe-column>
            <vxe-column field="amount" title="金额" formatter="FormatMoney"></vxe-column>
        </vxe-table>

    </div>
</template>

<script>
    import {GetParkingList, GetWuyeClientsValidCode, InsertParkingRecord} from "../../api";

    export default {
        name: "ParkingRegister",
        props: {
            projName: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                unionid: null,
                loading: false,
                parkings: [],
                clients: [],
                payerShow: false,
                form: {
                    parkingInfo: [],
                    parkingNos: null,
                    clientId: null,
                    timeRange: [],
                    months: 0,
                    payer: null,
                    remark: null
                },

                rules: {
                    clientId: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    timeRange: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    parkingInfo: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ]
                }
            }
        },
        computed: {
            amount: function () {
                return (this.form.rentPrice + this.form.managementPrice) * this.form.months
            },
            items: function () {
                var arr = []
                for (var i = 0; i <= this.form.parkingInfo.length - 1; i++) {
                    arr.push({
                        parkingNo: this.form.parkingInfo[i].split(';')[0],
                        rentPrice: this.form.parkingInfo[i].split(';')[1],
                        managementPrice: this.form.parkingInfo[i].split(';')[2],
                        months: this.form.months,
                        amount: (Number(this.form.parkingInfo[i].split(';')[1]) + Number(this.form.parkingInfo[i].split(';')[2])) * this.form.months
                    })
                }
                return arr;
            }
        },
        methods: {
            ClientChange(e) {

                var r = this.clients.filter(function (x) {
                    return x.clientId == e;
                });

                this.payerShow = r[0].clientName === '其他零星客户'
            },
            ParkingChange() {

                var arr = []
                for (var i = 0; i <= this.form.parkingInfo.length - 1; i++) {
                    arr.push(this.form.parkingInfo[i].split(';')[0])
                    this.form.parkingNos = arr.join(',')
                }
            },
            TimeChange() {
                //用-分成数组
                var date1 = this.form.timeRange[0].split("-")
                var date2 = this.form.timeRange[1].split("-")
                //获取年,月数
                var year1 = parseInt(date1[0]),
                    month1 = parseInt(date1[1]),
                    year2 = parseInt(date2[0]),
                    month2 = parseInt(date2[1])
                //通过年,月差计算月份差
                this.form.months = (year2 - year1) * 12 + (month2 - month1) + 1
            },
            submit() {
                this.$refs.addParkingRecord.validate((e) => {
                    if (e) {

                        this.$alert('请确认明细无误！', '确认明细', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            showCancelButton: true,
                            callback: action => {
                                if (action === 'confirm') {
                                    this.loading = true
                                    InsertParkingRecord(this.projName, this.form, this.amount, this.unionid).then((res) => {

                                        if (res.state === 'success') {
                                            this.loading = false
                                            this.$emit('ParkingRecordAddFormClose')
                                        }
                                        if (res.state === 'fail') {
                                            this.loading = false
                                            this.$message.error('车位号：' + res.info + '在该时间段内有重复记录，请重新添加！');
                                        }
                                    })
                                }
                            }
                        });
                    } else {
                        this.$message.error('有未填项！');
                    }
                })

            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            this.loading = true
            GetParkingList({projName: this.projName, parkingNo: '', clientName: ''}).then((res) => {
                this.parkings = res

                GetWuyeClientsValidCode(this.projName).then((res) => {
                    this.clients = res
                    this.loading = false
                })
            })
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
        white-space: pre-line
    }

</style>
