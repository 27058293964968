<template>
    <div class="container" v-loading="loading">

        <el-form :inline="true" :model="form">

            <el-form-item label="房源名称">
                <el-input v-model="form.houseName" clearable></el-input>
            </el-form-item>

            <el-form-item>
                <el-button size="small" type="primary" @click="Search">查询</el-button>
            </el-form-item>

        </el-form>

        <el-table
                :data="tableData"
                border
                style="width: 100%">
            <el-table-column
                    prop="date"
                    label="日期"
                    width="180">
            </el-table-column>
            <el-table-column
                    prop="name"
                    label="姓名"
                    width="180">
            </el-table-column>
            <el-table-column
                    prop="address"
                    label="地址">
            </el-table-column>
        </el-table>


    </div>
</template>

<script>
    import {GetHouseInfos2} from "../../api";

    export default {
        name: "HouseChoose",
        props: {
            projName: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                form: {
                    houseName: ''
                }
            }
        },
        methods: {
            Search() {
                this.loading = true
                GetHouseInfos2(this.projName, this.form.houseName).then((res) => {
                    console.log(res)
                    this.loading = false
                })

            }
        }
    }
</script>

<style scoped>
    .container {
        width: 94%;
        margin-left: 3%;
    }
</style>
