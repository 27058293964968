<template>
    <div class="container">

        <el-form :model="editForm" label-width="80px" size="medium">

            <el-form-item label="商机名称" required>
                <el-input v-model="editForm.businessName" @blur="searchClient" @input="nameCheck" clearable
                          @clear="clear"></el-input>
            </el-form-item>

            <div v-loading="loading">
                <el-alert
                        :title="title"
                        :type="type"
                        :closable="false" style="margin-bottom: 10px">
                </el-alert>
                <vxe-grid v-bind="table" v-if="repeatClients.length > 0"></vxe-grid>
            </div>

            <el-divider></el-divider>

            <el-form-item label="所属项目" required>
                <el-select v-model="editForm.projName" placeholder="所属项目" style="width: 100%" @change="check">
                    <el-option v-for="project in projects" :key="project.abbreviation"
                               :value="project.projName"
                               :label="project.projName"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="类型" required>
                <el-select v-model="editForm.kind" placeholder="客户类型" style="width: 100%" @change="check">
                    <el-option label="一般客户" value="一般客户"></el-option>
                    <el-option label="渠道客户" value="渠道客户"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="主营业务" required>
                <el-input v-model="editForm.mainBusiness" clearable @input="check"></el-input>
            </el-form-item>

            <el-form-item label="地址" required>
                <el-input v-model="editForm.address" clearable @input="check"></el-input>
            </el-form-item>

            <el-form-item label="联系人" required>
                <el-input v-model="editForm.contactPerson" clearable @input="check"></el-input>
            </el-form-item>

            <el-form-item label="电话" required>
                <el-input v-model="editForm.contactInfo" clearable @input="check"></el-input>
            </el-form-item>

            <el-form-item label="微信" required>
                <el-input v-model="editForm.wechat" clearable @input="check"></el-input>
            </el-form-item>

            <el-form-item label="客户来源" required>
                <el-select v-model="editForm.origin" placeholder="客户来源" style="width: 100%" @change="check">
                    <el-option v-for="origin in origins" :key="origin.name"
                               :value="origin.name"
                               :label="origin.name"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="业务员" required>
                <el-select v-model="editForm.unionid"
                           clearable multiple filterable
                           placeholder="选择业务员" style="width: 100%" @change="check">
                    <el-option v-for="employee in employees" :key="employee.unionid"
                               :value="employee.unionid"
                               :label="employee.name"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="意向房源">
                <el-input :value="editForm.houseNames.join(',')" readonly>

                    <template slot="append">
                        <el-button type="primary" @click="OpenHouse">选择</el-button>
                    </template>
                </el-input>
            </el-form-item>

            <el-form-item label="备注">
                <el-input
                        type="textarea"
                        :autosize="{ minRows: 4}"
                        placeholder="备注"
                        v-model="editForm.remark" @input="check">
                </el-input>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" :disabled="!disable" @click="submit">保存</el-button>
            </el-form-item>

        </el-form>

        <el-dialog title="意向房源" :visible.sync="houseShow" :append-to-body="true" v-loading="houseLoading">

            <el-form :inline="true" :model="form">

                <el-form-item label="房源名称">
                    <el-input v-model="form.houseName" clearable></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button size="small" type="primary" @click="SearchHouse">查询</el-button>
                </el-form-item>

            </el-form>

            <div style="margin-bottom: 15px">
                已选择：{{editForm.houseNames.join(',')}}
            </div>

            <el-table
                    :data="houseList"
                    height="400"
                    border
                    style="width: 100%">
                <el-table-column
                        prop="projName"
                        label="项目">
                </el-table-column>
                <el-table-column
                        prop="houseName"
                        label="房源">
                </el-table-column>
                <el-table-column
                        prop="houseState"
                        label="状态">
                </el-table-column>

                <el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                type="primary"
                                @click="HouseClick(scope.row)"
                                :disabled="editForm.houseIds.includes(scope.row.houseId)">选择
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>


        </el-dialog>

    </div>
</template>

<script>
    import {
        GetBusinessInfo,
        GetBusinessOrigins,
        GetEmployees, GetHouseInfos2,
        GetProjects,
        GetRepeatBusinesses,
        UpdateBusinessInfo
    } from "../../api";

    export default {
        name: "BusinessEdit",
        data() {
            return {
                unionid: null,
                loading: false,
                title: '无相似客户',
                type: 'success',
                projects: [],
                origins: [],
                employees: [],
                repeatClients: [],
                editForm: {
                    businessName: null,
                    projName: null,
                    kind: null,
                    mainBusiness: null,
                    address: null,
                    contactPerson: null,
                    contactInfo: null,
                    wechat: null,
                    origin: null,
                    unionid: [],
                    remark: null,
                    houseNames: [],
                    houseIds: []
                },
                disable: true,
                ownName: null,
                houseLoading: false,
                houseShow: false,
                form: {
                    houseName: ''
                },
                houseList: []
            }
        },
        props: {
            id: {
                type: Number,
                required: true
            }
        },
        watch: {
            repeatClients: function () {
                if (this.repeatClients.length > 0) {
                    this.title = '存在以下相似客户'
                    this.type = 'error'
                } else {
                    this.title = '无相似客户'
                    this.type = 'success'
                }
            }
        },
        computed: {
            table() {
                return {
                    size: 'mini',
                    maxHeight: '500px',
                    showOverflow: false,
                    columnConfig: {
                        resizable: true
                    },
                    columns: [
                        {field: 'businessName', title: '商机名称'},
                        {field: 'unionName', title: '业务员'},
                        {field: 'followUpDate', title: '最近跟进时间'},
                        {field: 'currentStage', title: '当前阶段'},
                    ],
                    data: this.repeatClients
                }
            }
        },
        methods: {
            searchClient() {
                if (this.editForm.businessName == null || String(this.editForm.businessName).trim() === '') {

                } else {
                    /*查询客户*/
                    this.loading = true
                    GetRepeatBusinesses(this.editForm.businessName, this.ownName).then((res) => {
                        this.repeatClients = res
                        this.loading = false
                    })
                }
                this.check()
            },
            clear() {
                this.editForm.businessName = this.ownName
                this.repeatClients = []
                this.check()
            },
            nameCheck() {
                this.disable = false
                if (this.editForm.businessName === this.ownName) {
                    this.disable = true
                }
            },
            check() {
                if (this.editForm.businessName == null || String(this.editForm.businessName).trim() === ''
                    || this.editForm.projName == null
                    || this.editForm.kind == null
                    || this.editForm.mainBusiness == null || String(this.editForm.mainBusiness).trim() === ''
                    || this.editForm.address == null || String(this.editForm.address).trim() === ''
                    || this.editForm.contactPerson == null || String(this.editForm.contactPerson).trim() === ''
                    || this.editForm.contactInfo == null || String(this.editForm.contactInfo).trim() === ''
                    || this.editForm.wechat == null || String(this.editForm.wechat).trim() === ''
                    || this.editForm.origin == null
                    || this.editForm.unionid.length === 0) {
                    this.disable = false
                } else {
                    this.disable = true
                }
            },
            submit() {
                UpdateBusinessInfo(this.id, this.editForm, this.unionid).then((res) => {
                    this.$emit('businessEdit')
                })
            },
            SearchHouse() {
                this.houseLoading = true
                GetHouseInfos2(this.editForm.projName, this.form.houseName).then((res) => {
                    this.houseList = res
                    this.houseLoading = false
                })
            },
            OpenHouse() {
                this.editForm.houseIds = []
                this.editForm.houseNames = []
                this.houseShow = true
            },
            HouseClick(row) {
                this.editForm.houseIds.push(row.houseId)
                this.editForm.houseNames.push(row.houseName)
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetProjects().then((res) => {
                this.projects = res
            })

            GetBusinessOrigins().then((res) => {
                this.origins = res
            })

            GetEmployees('招商部', 'null').then((res) => {
                this.employees = res
            })

            GetBusinessInfo(this.id).then((res) => {
                this.ownName = res.businessName
                this.editForm.businessName = res.businessName
                this.editForm.projName = res.projName
                this.editForm.kind = res.kind
                this.editForm.mainBusiness = res.mainBusiness
                this.editForm.address = res.address
                this.editForm.contactPerson = res.contactPerson
                this.editForm.contactInfo = res.contactInfo
                this.editForm.wechat = res.wechat
                this.editForm.origin = res.origin
                this.editForm.unionid = res.unionid.split(',')
                this.editForm.remark = res.remark
                this.editForm.houseIds = res.intentHouseId.split(',')
                this.editForm.houseNames = res.intentHouseName.split(',')
            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

</style>
