<template>

    <div>

        <!-- 有权限 -->
        <div v-loading="loading" v-if="authority">

            <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
                <el-collapse-item name="collapseItem" class="form-container">
                    <template slot="title">
                        <el-tag type="success" style="width: 100%;padding-left: 15px">
                            <i class="el-icon-search" style="margin-right: 5px"></i>
                            <span>共查询到 {{clients.length}} 条记录</span>
                        </el-tag>
                    </template>

                    <el-form :inline="true"
                             :model="searchObj"
                             class="form"
                             size="small"
                             label-width="72px">

                        <el-form-item>
                            <el-radio-group v-model="searchObj.type" size="small" @change="submit">
                                <el-radio-button label="待生效"></el-radio-button>
                                <el-radio-button label="已生效"></el-radio-button>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="所属项目:" style="margin-left: 10px" v-if="searchObj.type === '已生效'">
                            <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                                <el-option v-for="project in projects" :key="project.abbreviation"
                                           :value="project.projName"
                                           :label="project.projName"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="客户名称:" v-if="searchObj.type === '已生效'">
                            <el-input v-model="searchObj.clientName" placeholder="客户名称" clearable
                                      @change="submit"></el-input>
                        </el-form-item>

                    </el-form>
                </el-collapse-item>
            </el-collapse>

            <!-- 表格 -->
            <div :style="tableContainerStyle">
                <vxe-grid ref="xTable"
                          id="wuyeClientTable"
                          class="wuyeClientTable"
                          v-bind="wuyeClientTable"
                          @toolbar-button-click="toolbarButtonClickEvent"
                          @toolbar-tool-click="toolbarToolClickEvent"
                          @cell-click="cellClick">
                </vxe-grid>
            </div>

            <!--新增-->
            <el-drawer :visible.sync="addDrawer" size="50%" destroy-on-close
                       :close-on-press-escape="false"
                       :wrapperClosable="false">
                <template slot="title">
                    <el-alert
                            :title="addDrawerTitle"
                            type="warning"
                            :closable="false">
                    </el-alert>
                </template>

                <WuyeClientAdd @WuyeClientInsert="WuyeClientInsert"></WuyeClientAdd>
            </el-drawer>

            <!--详情-->
            <el-drawer :visible.sync="detailDrawer" size="50%" destroy-on-close>
                <template slot="title">
                    <el-alert
                            :title="detailDrawerTitle"
                            type="warning"
                            :closable="false">
                    </el-alert>
                </template>

                <WuyeClientDetail :clientId="clientId"></WuyeClientDetail>
            </el-drawer>

        </div>

        <!-- 无权限 -->
        <div v-else>
            <el-result style="height: -webkit-calc(100vh - 250px)"
                       icon="warning" title="无权限"
                       subTitle="抱歉，无权限查看">

            </el-result>
        </div>

    </div>


</template>

<script>
    import {GetProjects, GetProjectsWithoutAll, GetWuyeAuthority, GetWuyeClients} from "../../api";
    import WuyeClientAdd from "./WuyeClientAdd"
    import WuyeClientDetail from "./WuyeClientDetail"

    export default {
        name: "WuyeClientManagement",
        components: {WuyeClientAdd, WuyeClientDetail},
        data() {
            return {
                unionid: null,
                authority: true,
                loading: false,
                addDrawer: false,
                addDrawerTitle: '新增物业客户',
                detailDrawer: false,
                detailDrawerTitle: '客户详情',
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                projects: [],
                searchObj: {
                    type: '待生效',
                    projName: '',
                    clientName: ''
                },
                clients: [],
                clientId: null,
                btnDisabled: true
            }
        },
        watch: {
            detailDrawer: function (val, oldVal) {
                if (!val) {
                    this.submit()
                }
            }
        },
        computed: {
            /*表格实例对象*/
            wuyeClientTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'}
                        ],
                        size: 'medium',
                        tools: [
                            {code: 'add', name: '新增客户', status: 'success', disabled: this.btnDisabled},
                        ]
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.clients,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '所属项目', field: 'projName'},
                        {resizable: true, title: 'U8项目编码', field: 'u8projCode', visible: false},
                        {resizable: true, title: '客户编号', field: 'clientId'},
                        {resizable: true, title: '编号缩写', field: 'abbreviation', visible: false},
                        {
                            resizable: true, title: '客户类型', field: 'type', formatter: function ({cellValue}) {
                                if (cellValue === 1) {
                                    return '公司客户'
                                } else {
                                    return '个人客户'
                                }
                            }
                        },
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {resizable: true, title: '客户属性', field: 'attribute', visible: false},
                        {resizable: true, title: '联系人', field: 'contactPerson', visible: false},
                        {resizable: true, title: '联系电话', field: 'contactPhone', visible: false},
                        {resizable: true, title: '企业代码', field: 'enterpriseCode', visible: false},
                        {resizable: true, title: '税号', field: 'taxNumber', visible: false},
                        {resizable: true, title: '开户行', field: 'bank', visible: false},
                        {resizable: true, title: '银行账号', field: 'bankAccount', visible: false},
                        {resizable: true, title: '地址', field: 'address', visible: false},
                        {resizable: true, title: '电话', field: 'telephone', visible: false},
                        {resizable: true, title: 'U8客户编码', field: 'u8clientCode', visible: false},
                        {resizable: true, title: '入场时间', field: 'entryDate'},
                        {resizable: true, title: '退场时间', field: 'exitDate', visible: false},
                        {resizable: true, title: '合同信息', field: 'contracts', visible: false},
                        {resizable: true, title: '备注', field: 'remark', visible: false},
                        {resizable: true, title: '创建时间', field: 'createTime', visible: false},
                        {resizable: true, title: '生效时间', field: 'validTime', visible: false}
                    ]
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            submit() {

                switch (this.searchObj.type) {

                    case '待生效':
                        GetWuyeClients(false, this.searchObj.projName, this.searchObj.clientName).then((res) => {
                            this.clients = res
                        })
                        break
                    case '已生效':
                        GetWuyeClients(true, this.searchObj.projName, this.searchObj.clientName).then((res) => {
                            this.clients = res
                        })
                        break

                }

            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        this.$refs.xTable.openExport()
                        break
                }
            },
            toolbarToolClickEvent({code}) {
                switch (code) {
                    case 'add':
                        this.addDrawer = true
                        break

                }
            },
            cellClick({row}) {
                this.clientId = row.clientId
                this.detailDrawerTitle = row.clientName + ' - 客户详情'
                this.detailDrawer = true
            },
            WuyeClientInsert() {
                this.submit()
                this.addDrawer = false
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeAuthority(this.unionid).then((res) => {

                if (res === '' || res == null) {
                    this.authority = false
                } else {
                    this.authority = true

                    /* 权限控制 */
                    if (res.authorities.indexOf('editClient') != -1) {
                        this.btnDisabled = false
                    } else {
                        this.btnDisabled = true
                    }

                    this.searchObj.projName = '稻谷国际中心'
                    if (res.projName === 'ALL') {
                        GetProjectsWithoutAll().then((res) => {
                            this.projects = res
                        })
                    } else {
                        this.projects = []
                        var arr = res.projName.split(',')

                        this.searchObj.projName = arr[0]

                        for (var i = 0; i <= arr.length - 1; i++) {
                            this.projects.push({
                                projName: arr[i]
                            })
                        }
                    }

                    this.submit()
                }

            })
        },
        mounted() {
            this.$nextTick(function () {

                if (this.authority) {
                    this.bigHeight = this.$refs.collapse.$el.offsetHeight
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                }

            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .wuyeClientTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .wuyeClientTable >>> .font-blue {
        color: #45aaf2;
    }

</style>
