<template>

    <div v-loading="loading">

        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
            <el-collapse-item name="collapseItem" class="form-container">
                <template slot="title">
                    <el-tag type="success" style="width: 100%;padding-left: 15px">
                        <i class="el-icon-search" style="margin-right: 5px"></i>
                        <span v-if="searchObj.type === '可开票账单'">共查询到 {{invoicablePayments.length}} 条记录</span>
                        <span v-else>共查询到 {{invoices.length}} 条记录</span>
                    </el-tag>
                </template>

                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px">

                    <el-form-item>
                        <el-radio-group v-model="searchObj.type" size="small" @change="submit">
                            <el-radio-button label="可开票账单"></el-radio-button>
                            <el-radio-button label="已开发票"></el-radio-button>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="账单月份:">
                        <el-date-picker :clearable="false"
                                        v-model="searchObj.month"
                                        value-format="yyyy-MM"
                                        style="width: 100%"
                                        type="month" :picker-options="pickerOptions" placeholder="选择月" @change="submit">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="所属项目:" style="margin-left: 10px">
                        <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                            <el-option v-for="project in projects" :key="project.abbreviation"
                                       :value="project.projName"
                                       :label="project.projName"></el-option>
                        </el-select>
                    </el-form-item>

                </el-form>
            </el-collapse-item>
        </el-collapse>

        <!-- 表格 -->
        <div :style="tableContainerStyle">

            <!-- 可开票 表格 -->
            <vxe-grid v-if="searchObj.type === '可开票账单'"
                      ref="xTable"
                      id="elecInvoicableTable"
                      class="elecInvoicableTable"
                      v-bind="elecInvoicableTable"
                      @toolbar-button-click="toolbarButtonClickEvent">

                <template #operate="{ row }">
                    <el-button type="warning" size="mini" plain @click="GoInvoice(row)">开票</el-button>
                    <el-button type="primary" size="mini" plain @click="GoIssued(row)">已开票</el-button>
                </template>
            </vxe-grid>

            <!-- 已开票表格 -->
            <vxe-grid v-else
                      ref="xTable2"
                      id="wuyeElecInvoiceTable"
                      class="wuyeElecInvoiceTable"
                      v-bind="wuyeElecInvoiceTable"
                      @toolbar-button-click="toolbarButtonClickEvent2">

                <template #operate="{ row }">
                    <el-button type="primary" size="mini" plain @click="GoDetail(row)">查看</el-button>
                </template>
            </vxe-grid>

        </div>

        <!-- 详情抽屉 -->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <ElecInvoiceDetail :id="id"></ElecInvoiceDetail>
        </el-drawer>

        <!-- 开票抽屉 -->
        <el-drawer :visible.sync="makeDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="makeDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <MakeElecInvoice :clientId="clientId" :billMonth="billMonth" :payType="payType"
                             @ElecInvoiceMake="ElecInvoiceMake"></MakeElecInvoice>
        </el-drawer>

        <!-- 标记为已开票 -->
        <el-drawer :visible.sync="issuedDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="issuedDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <MakeElecInvoiceIssued :clientId="clientId2" :billMonth="billMonth2" :payType="payType2"
                                   @ElecPaperInvoiceAdd="ElecPaperInvoiceAdd"></MakeElecInvoiceIssued>
        </el-drawer>

    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {
        GetInvoicableElecPayments,
        GetProjects,
        GetProjectsWithoutAll,
        GetWuyeAuthority,
        GetWuyeElecInvoices
    } from "../../api";
    import ElecInvoiceDetail from "./ElecInvoiceDetail"
    import MakeElecInvoice from "./MakeElecInvoice"
    import MakeElecInvoiceIssued from "./MakeElecInvoiceIssued"

    export default {
        name: "WuyeElecInvoiceManagement",
        components: {ElecInvoiceDetail, MakeElecInvoice, MakeElecInvoiceIssued},
        data() {
            return {
                loading: false,
                projects: [],
                searchObj: {
                    type: '可开票账单',
                    projName: '稻谷国际中心',
                    month: XEUtils.toDateString(XEUtils.getWhatMonth(new Date(), -1), 'yyyy-MM'),
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > XEUtils.getWhatMonth(new Date(), -1);
                    },
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                invoicablePayments: [],
                invoices: [],
                invoiceKinds: [
                    {label: '增值税普通发票（电子）', value: 1},
                    {label: '增值税专用发票（纸质）', value: 2}
                ],
                drawer: false,
                drawerTitle: '',
                id: null,
                makeDrawer: false,
                makeDrawerTitle: '',
                clientId: null,
                billMonth: null,
                payType: null,
                issuedDrawer: false,
                issuedDrawerTitle: '',
                clientId2: null,
                billMonth2: null,
                payType2: null
            }
        },
        computed: {
            /*表格实例对象*/
            elecInvoicableTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                        ],
                        size: 'medium'
                    },
                    rowConfig: {
                        isHover: true
                    },
                    data: this.invoicablePayments,
                    columns: [
                        {
                            resizable: true, title: '时间', field: 'time', formatter: function ({cellValue}) {
                                return XEUtils.toDateString(cellValue, 'yyyy-MM-dd HH:mm:ss')
                            }
                        },
                        {resizable: true, title: '项目', field: 'projName'},
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {resizable: true, title: '账单月份', field: 'billMonth'},
                        {resizable: true, title: '付款方式', field: 'type'},
                        {resizable: true, title: '金额', field: 'amount', formatter: 'FormatMoney'},
                        {title: '操作', fixed: 'right', width: 180, slots: {default: 'operate'}}
                    ]
                }
            },
            wuyeElecInvoiceTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                        ],
                        size: 'medium'
                    },
                    rowConfig: {
                        isHover: true
                    },
                    data: this.invoices,
                    columns: [
                        {resizable: true, title: '开票时间', field: 'billingTime'},
                        {resizable: true, title: '项目', field: 'projName'},
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {resizable: true, title: '账单月份', field: 'billMonth'},
                        {resizable: true, title: '开票金额', field: 'amount', formatter: 'FormatMoney'},
                        {
                            resizable: true, title: '发票类型', field: 'invoiceKind',
                            filters: this.invoiceKinds,
                            filterMethod: this.InvoiceKindFilter,
                            formatter: function ({cellValue}) {
                                if (cellValue === 1) {
                                    return '增值税普通发票（电子）'
                                } else {
                                    return '增值税专用发票（纸质）'
                                }
                            }
                        },
                        {title: '操作', fixed: 'right', width: 100, slots: {default: 'operate'}}
                    ]
                }
            }
        },
        methods: {
            InvoiceKindFilter({value, row, column}) {
                return row.invoiceKind == value
            },
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            submit() {
                this.invoicablePayments = []
                this.invoices = []

                switch (this.searchObj.type) {
                    case '可开票账单':
                        GetInvoicableElecPayments(this.searchObj.projName, this.searchObj.month).then((res) => {
                            this.invoicablePayments = res
                        })
                        break
                    case '已开发票':
                        GetWuyeElecInvoices(this.searchObj.projName, this.searchObj.month).then((res) => {
                            this.invoices = res
                        })
                        break
                }

            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                }
            },
            toolbarButtonClickEvent2({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                }
            },
            GoInvoice(row) {
                this.clientId = row.clientId
                this.billMonth = row.billMonth
                this.payType = row.type
                this.makeDrawerTitle = row.clientName + ' - 新开电费发票'
                this.makeDrawer = true
            },
            GoDetail(row) {
                this.id = row.id
                this.drawerTitle = row.clientName + " - 电费 - 发票详情"
                this.drawer = true
            },
            ElecInvoiceMake() {
                this.submit()
                this.makeDrawer = false
            },
            GoIssued(row) {
                this.clientId2 = row.clientId
                this.billMonth2 = row.billMonth
                this.payType2 = row.type
                this.issuedDrawerTitle = row.clientName + ' - 已开电费发票'
                this.issuedDrawer = true
            },
            ElecPaperInvoiceAdd() {
                this.submit()
                this.issuedDrawer = false
            }
        },
        created() {

            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeAuthority(this.unionid).then((res) => {

                if (res.projName === 'ALL') {
                    GetProjectsWithoutAll().then((res) => {
                        this.projects = res
                    })
                } else {
                    this.projects = []
                    var arr = res.projName.split(',')

                    this.searchObj.projName = arr[0]

                    for (var i = 0; i <= arr.length - 1; i++) {
                        this.projects.push({
                            projName: arr[i]
                        })
                    }
                }

                this.submit()
            })

        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .elecInvoicableTable >>> .font-blue {
        color: #45aaf2;
    }

    .wuyeElecInvoiceTable >>> .font-blue {
        color: #45aaf2;
    }

</style>
