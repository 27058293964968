<template>

    <div class="container" v-loading="loading">
        <el-image v-if="invoice != null"
                  style="width: 100%; height: 550px;margin-bottom: 20px"
                  :src="invoice.imgUrls"
                  fit="fill"
                  :preview-src-list="[invoice.imgUrls]">
        </el-image>
    </div>

</template>

<script>
    import {SearchFpqqlsh} from "../../api";

    export default {
        name: "WyMpInvoiceDetail",
        props: {
            invoiceInfo: {
                type: String,
                required: true
            },
            paymentMethod: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                invoice: null
            }
        },
        created() {

            var arr = this.invoiceInfo.split("-")

            SearchFpqqlsh(this.paymentMethod,arr[3]).then((res) => {

                console.log(res)
                this.invoice = res.result[0]
            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

</style>
