<template>
    <div class="container" v-loading="loading">

        <el-descriptions labelClassName="label"
                         class="description"
                         :column="1" style="margin-bottom: 20px" border>
            <el-descriptions-item label="项目">{{client.projName}}</el-descriptions-item>
            <el-descriptions-item label="客户名称">{{client.clientName}}</el-descriptions-item>
        </el-descriptions>

        <el-divider content-position="left">合同信息</el-divider>

        <vxe-grid ref="rentalClientContractTable"
                  id="rentalClientContractTable"
                  class="rentalClientContractTable"
                  v-bind="rentalClientContractTable">
            <template #operate="{ row }">
                <el-button type="primary" size="mini" @click.stop="btnClick(row)">详情</el-button>
            </template>

        </vxe-grid>

        <el-drawer :visible.sync="drawer" size="60%"
                   destroy-on-close
                   :append-to-body="true">

            <template slot="title">
                <el-alert
                        title="合同详情"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <RentalContractDetail :contract="contract" :clientName="client.clientName"></RentalContractDetail>
        </el-drawer>

    </div>
</template>

<script>
    import {GetRentalClientInfos} from "../../api";
    import formatMoney from "../../utils/formatMoney";
    import RentalContractDetail from "./RentalContractDetail";

    export default {
        name: "RentalClientDetail",
        components: {RentalContractDetail},
        props: {
            contractNo: {
                required: true,
                type: String
            }
        },
        data() {
            return {
                loading: false,
                client: {},
                contractList: [],
                drawer: false,
                contract: {}
            }
        },
        computed: {
            rentalClientContractTable: function () {
                var that = this
                return {
                    height: '500',
                    size: 'small',
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    scrollY: {
                        mode: 'wheel'
                    },
                    columnConfig: {
                        width: 'auto',
                        minWidth: '150'
                    },
                    data: this.contractList,
                    columns: [

                        {resizable: true, title: '合同编号', field: 'contractNo',className: 'font-blue'},
                        {resizable: true, title: '房源', field: 'houseName', showOverflow: true},
                        {resizable: true, title: '计费面积', field: 'billingArea',formatter: 'FormatArea'},
                        {resizable: true, title: '开始时间', field: 'contractStartDate'},
                        {resizable: true, title: '结束时间', field: 'contractEndDate'},
                        {resizable: true, title: '合同总额', field: 'contract_amountTotal', formatter: 'FormatMoney',className: 'font-blue'},
                        {resizable: true, title: '未缴金额', field: 'billed_unpaidAmountTotal', formatter: 'FormatMoney',className: 'font-red'},
                        {title: '操作', field: 'operation', fixed: 'right', align:'center',width: 80, slots: {default: 'operate'}}
                    ],
                    showFooter: true,
                    footerRowClassName: 'footer-row',
                    footerMethod({columns, data}) {

                        return [
                            columns.map((column, columnIndex) => {
                                if (columnIndex === 0) {
                                    return '合计：'
                                }


                                if (['billed_unpaidAmountTotal'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return formatMoney(count.toFixed(2))
                                }


                                if (['contract_amountTotal'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return formatMoney(count.toFixed(2))
                                }

                                return ''
                            }),
                        ]
                    }
                }
            }
        },
        methods: {
            btnClick(row) {
                this.contract = row
                this.drawer = true
            }
        },
        async created() {
            this.loading = true

            await GetRentalClientInfos(this.contractNo).then((res) => {
                this.client = res.client
                this.contractList = res.contractList
            })

            this.loading = false

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .rentalClientContractTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .rentalClientContractTable >>> .font-blue {
        color: #45aaf2;
    }

    .rentalClientContractTable >>> .font-red {
        color: #f10716;
    }

    .rentalClientContractTable >>> .footer-row {
        background-color: #F8F8F9;
    }

</style>
