<template>
    <div class="container" v-loading="loading">

        <el-steps simple :active="step" finish-status="success">
            <el-step title="合同基本信息"></el-step>
            <el-step title="核对账单明细"></el-step>
            <el-step title="上传结果"></el-step>
        </el-steps>

        <!-- 步骤1 -->
        <transition name="el-fade-in">
            <div v-show="step == 0" style="padding-top: 15px">
                <el-form ref="rentalBillUploadForm" :model="uploadData" :rules="rules" label-width="100px"
                         size="medium">

                    <el-form-item label="所属项目:" required prop="abbreviation">
                        <el-select v-model="uploadData.abbreviation" placeholder="所属项目" @change="ProjChange"
                                   style="width: 100%">
                            <el-option v-for="project in projects" :key="project.abbreviation"
                                       :value="project.abbreviation"
                                       :label="project.projName"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="客户名称:" required prop="clientId">
                        <el-select v-model="uploadData.clientId" placeholder="请先选择所属项目获取客户列表" style="width: 100%"
                                   filterable :disabled="clients.length == 0">
                            <el-option v-for="client in clients" :key="client.clientId"
                                       :value="client.clientId"
                                       :label="client.clientName">
                                <span style="float: left">{{ client.clientName }} </span>
                                <span style="float: right; text-align: right;color: #8492a6; font-size: 13px">{{ client.clientId }}</span>

                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="合同编号:" required prop="contractNo">
                        <el-input v-model="uploadData.contractNo"></el-input>
                    </el-form-item>

                    <el-form-item label="合同周期:" required prop="timeRange">
                        <el-date-picker style="width: 100%"
                                        v-model="uploadData.timeRange"
                                        type="daterange"
                                        range-separator="至"
                                        value-format="yyyy-MM-dd"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        :clearable="false">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="计费面积:" required prop="billingArea">
                        <el-input-number v-model="uploadData.billingArea"
                                         :precision="2" :step="0.01" :min="0">
                        </el-input-number>
                    </el-form-item>

                    <el-form-item label="合同负责人" required prop="unionid">
                        <el-select v-model="uploadData.unionid"
                                   clearable multiple filterable
                                   placeholder="选择业务员" style="width: 100%">
                            <el-option v-for="employee in employees" :key="employee.unionid"
                                       :value="employee.unionid"
                                       :label="employee.name"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="上传合同" required>
                        <el-upload action="/api/uploadRentalContract"
                                   multiple
                                   :limit="1"
                                   :auto-upload="false"
                                   accept=".docx"
                                   :on-change="HandleChange"
                                   :on-remove="HandleChange"
                                   :on-exceed="HandleExceed"
                                   :on-success="HandleSuccess"
                                   :data="uploadData"
                                   :file-list="fileList"
                                   ref="rentalBillUpload">
                            <el-button size="small" type="primary" plain>
                                <i class="el-icon-upload"></i>
                                选择 word 版合同文件
                            </el-button>
                        </el-upload>
                    </el-form-item>


                    <el-form-item style="text-align: right" v-show="btnShow">
                        <el-button size="small" type="success" @click="submit">下一步</el-button>
                    </el-form-item>

                </el-form>
            </div>
        </transition>

        <!-- 步骤2 -->
        <transition name="el-fade-in">
            <div v-show="step == 1">
                <vxe-grid ref="initialContractBillTable"
                          id="initialContractBillTable"
                          class="initialContractBillTable"
                          v-bind="initialContractBillTable" style="margin-top: 20px;margin-bottom: 20px">
                </vxe-grid>

                <el-row :gutter="20">
                    <el-col :span="12" style="text-align: left">
                        <el-button @click="Back" type="info" size="medium" style="margin-top: 5px"
                                   icon="el-icon-arrow-left">上一步
                        </el-button>
                    </el-col>
                    <el-col :span="12" style="text-align: right">
                        <el-button @click="UploadBill" type="primary" size="medium" style="margin-top: 5px">上 传<i
                                class="el-icon-arrow-right el-icon--right"></i></el-button>
                    </el-col>
                </el-row>

            </div>
        </transition>

        <!-- 步骤3 -->
        <transition name="el-fade-in">
            <div v-show="step > 1" style="padding-top: 150px">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-result :icon="uploadState" title="上传结果"></el-result>
                    </el-col>

                    <el-col :span="24" style="text-align: center">
                        <el-button @click="ToStep0" type="primary" size="medium" style="margin-top: 50px">继续上传
                        </el-button>
                    </el-col>
                </el-row>
            </div>
        </transition>

    </div>
</template>

<script>
    import {
        GetEmployees,
        GetHouseClients,
        GetProjectsWithoutAll,
        UpdateWuyeBillDetail,
        UploadRentalBills
    } from "../../api";

    export default {
        name: "RentalBillImport",
        data() {

            var validateArea = (rule, value, callback) => {
                if (value <= 0) {
                    callback(new Error('请输入计费面积'));
                } else {
                    callback();
                }
            };

            return {
                loading: false,
                step: 0,
                btnShow: false,
                fileList: [],
                employees: [],
                uploadData: {
                    abbreviation: '',
                    clientId: '',
                    contractNo: '',
                    timeRange: [],
                    billingArea: 0,
                    unionid: []
                },
                rules: {
                    abbreviation: [
                        {required: true, message: '不能为空', trigger: ['blur', 'change']}
                    ],
                    clientId: [
                        {required: true, message: '不能为空', trigger: ['blur', 'change']}
                    ],
                    contractNo: [
                        {required: true, message: '不能为空', trigger: ['blur', 'change']}
                    ],
                    timeRange: [
                        {required: true, message: '不能为空', trigger: ['blur', 'change']}
                    ],
                    billingArea: [
                        {validator: validateArea, trigger: ['blur', 'change']}
                    ],
                    unionid: [
                        {required: true, message: '不能为空', trigger: ['blur', 'change']}
                    ]
                },
                projects: [],
                clients: [],
                billList: [],
                billIdList: [],
                uploadState: 'error'
            }
        },
        computed: {
            initialContractBillTable: function () {
                return {
                    height: '600',
                    size: 'small',
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    scrollY: {
                        mode: 'wheel'
                    },
                    columnConfig: {
                        width: 'auto',
                        minWidth: '130'
                    },
                    data: this.billList,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '所属项目', field: 'projName'},
                        {resizable: true, title: '客户名称', field: 'clientName'},
                        {resizable: true, title: '合同编号', field: 'contractNo'},
                        {
                            resizable: true, title: '费项', width: '180', formatter: function ({row}) {
                                return row.fee + '\n' + row.startDate + '~' + row.endDate
                            }
                        },
                        {
                            resizable: true,
                            title: '账单金额',
                            field: 'amount',
                            formatter: 'FormatMoney',
                            className: 'font-blue'
                        },
                        {resizable: true, title: '减免金额', field: 'reliefAmount', formatter: 'FormatMoney'},
                        {resizable: true, title: '已收金额', field: 'receivedAmount', formatter: 'FormatMoney'},
                        {resizable: true, title: '未缴金额', field: 'unpaidAmount', formatter: 'FormatMoney',},
                        {resizable: true, title: '出账日期', field: 'billingDate'},
                        {resizable: true, title: '应缴日期', field: 'dueDate'},
                        {resizable: true, title: '业务员', field: 'employeeName', fixed: 'right'}
                    ]
                }
            }
        },
        methods: {
            HandleExceed() {
                this.$message.error('只能上传一个文件！');
            },
            HandleSuccess(response, file, fileList) {

                if (response.state === 'success') {
                    console.log(response)
                    this.billList = response.billList
                    this.billIdList = response.idArr
                    this.step++
                } else {
                    this.$refs['rentalBillUpload'].clearFiles();
                    this.btnShow = false
                    this.$message.error('合同文件错误！');
                }
            },
            HandleChange(file, fileList) {
                if (fileList.length == 0) {
                    this.btnShow = false
                } else {
                    this.btnShow = true
                }
            },
            async ProjChange() {
                this.loading = true
                this.uploadData.clientId = ''
                await GetHouseClients({projName: this.uploadData.abbreviation, clientName: ''}).then((res) => {
                    this.clients = res
                })
                this.loading = false
            },
            submit() {
                this.$refs.rentalBillUploadForm.validate((e) => {
                    if (e) {
                        this.$refs['rentalBillUpload'].submit();
                    } else {
                        this.$message.error('有未填项！');
                    }
                })
            },
            Back() {
                this.$refs['rentalBillUpload'].clearFiles();
                this.btnShow = false
                this.step--
            },
            async UploadBill() {

                this.$alert('请确认账单明细无误！', '确认账单明细', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    showCancelButton: true,
                    callback: async action => {
                        if (action === 'confirm') {
                            this.loading = true
                            await UploadRentalBills(this.billIdList.join(',')).then((res) => {
                                if (res === 'success') {

                                } else {

                                }
                                this.uploadState = res
                            })
                            this.step++
                            this.loading = false
                        }
                    }
                });
            },
            ToStep0() {
                this.uploadData.abbreviation = ''
                this.uploadData.clientId = ''
                this.uploadData.contractNo = ''
                this.uploadData.timeRange = []
                this.uploadData.billingArea = 0
                this.uploadData.unionid = []

                this.$refs['rentalBillUpload'].clearFiles();
                this.$refs.rentalBillUploadForm.resetFields()
                this.btnShow = false
                this.step = 0
            }
        },
        async created() {
            this.loading = true
            await GetProjectsWithoutAll().then((res) => {
                this.projects = res
            })
            await GetEmployees('招商部', 'null').then((res) => {
                this.employees = res
            })
            this.loading = false
        }
    }
</script>

<style scoped>

    .container {
        /*width: 94%;*/
        /*margin-left: 3%;*/
    }

    .initialContractBillTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .initialContractBillTable >>> .font-blue {
        color: #45aaf2;
    }

    .initialContractBillTable >>> .font-red {
        color: #f10716;
    }

    .initialContractBillTable >>> .footer-row {
        background-color: #F8F8F9;
    }

</style>
