<template>

    <div class="container">

        <el-form ref="wuyeContractAddForm" :model="form" :rules="rules" label-width="80px" size="medium">

            <el-form-item label="签订时间" required prop="signDate">
                <el-date-picker style="width: 100%"
                                v-model="form.signDate"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="选择签订时间">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="合同编号" required prop="contractNo">
                <el-input v-model="form.contractNo" placeholder="合同编号" clearable></el-input>
            </el-form-item>

            <el-form-item label="计费周期" required prop="billingCycle">
                <el-select v-model="form.billingCycle" placeholder="请选择计费周期" style="width: 100%">
                    <el-option label="3个月" :value="3"></el-option>
                    <el-option label="6个月" :value="6"></el-option>
                    <el-option label="12个月" :value="12"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="合同时间" required prop="dateRange">
                <el-date-picker style="width: 100%"
                                v-model="form.dateRange"
                                type="daterange"
                                range-separator="至"
                                value-format="yyyy-MM-dd"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期" @change="GetHouses">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="房源" required prop="houseIds">
                <el-select v-model="form.houseIds"
                           clearable multiple filterable placeholder="请选择房源" style="width: 100%">
                    <el-option
                            v-for="item in houses"
                            :key="item.houseId"
                            :label="item.houseName"
                            :value="item.houseId">
                        <span style="float: left">{{ item.houseName }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.building }}</span>
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="备注">
                <el-input
                        type="textarea"
                        :autosize="{ minRows: 4}"
                        placeholder="备注"
                        v-model="form.remark">
                </el-input>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" @click="Submit">保 存</el-button>
            </el-form-item>

        </el-form>

    </div>

</template>

<script>
    import {GetHouseInfoWithoutWuyeContracts, InsertWuyeContract} from "../../api";

    export default {
        name: "WuyeContractAdd",
        props: {
            projName: {
                type: String,
                required: true
            },
            projCode: {
                type: String,
                required: true
            },
            clientId: {
                type: String,
                required: true
            },
            clientName: {
                type: String,
                required: true
            },
            clientCode: {
                type: String,
                required: true
            }
        },
        data() {

            return {
                houses: [],
                form: {
                    contractNo: '',
                    houseIds: [],
                    signDate: null,
                    dateRange: null,
                    billingCycle: null,
                    remark: ''
                },
                rules: {
                    contractNo: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    houseIds: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    signDate: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    dateRange: [
                        {required: true, message: '不能为空', trigger: 'change'}
                    ],
                    billingCycle: [
                        {required: true, message: '不能为空', trigger: 'change'}
                    ],
                }
            }
        },
        methods: {
            GetHouses() {
                this.houses = []
                this.$refs.wuyeContractAddForm.validateField(['dateRange'], (e) => {
                    if (e === '不能为空') {

                    } else {
                        GetHouseInfoWithoutWuyeContracts(this.projName,
                            this.form.dateRange[0], this.form.dateRange[1]).then((res) => {
                            this.houses = res
                        })
                    }

                })

            },
            Submit() {

                this.$refs.wuyeContractAddForm.validate((e) => {
                    if (e) {
                        var startDate = this.form.dateRange[0]
                        var endDate = this.form.dateRange[1]

                        InsertWuyeContract(this.projName, this.projCode, this.clientId, this.clientName, this.clientCode,
                            this.form, startDate, endDate).then((res) => {
                            if (res === 1) {
                                this.$emit('WuyeContractInsert')
                            }
                        })
                    } else {
                        this.$message.error('有未填项！');
                    }
                })
            }
        },
        created() {

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

</style>
