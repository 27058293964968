<template>

    <div class="container" v-loading="loading">


        <div v-if="groupInfo.length > 1">
            <el-result style="height: -webkit-calc(100vh - 250px)"
                       icon="error" title="错误提示"
                       subTitle="客户不一致，请重新选择！">
            </el-result>
        </div>

        <div v-else>

            <el-form ref="parkingGenerateForm" :model="form" :rules="rules" label-width="80px" size="medium">


                <el-form-item label="所属项目">
                    <el-input v-model="form.projName" disabled></el-input>
                </el-form-item>

                <el-form-item label="账单类型">
                    <el-input v-model="form.inventory" disabled></el-input>
                </el-form-item>

                <el-form-item label="账单月份" required prop="billMonth">
                    <el-date-picker :clearable="false"
                                    v-model="form.billMonth"
                                    value-format="yyyy-MM"
                                    style="width: 100%"
                                    type="month" placeholder="账单月份">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="客户名称">
                    <el-input v-model="form.clientName" disabled></el-input>
                </el-form-item>

                <el-form-item label="付款人" v-if="form.payer != null">
                    <el-input v-model="form.payer" disabled></el-input>
                </el-form-item>

                <el-form-item label="计费周期">
                    <el-date-picker style="width: 100%"
                                    v-model="form.timeRange"
                                    type="daterange"
                                    range-separator="至"
                                    value-format="yyyy-MM-dd"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :clearable="false">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="原金额">
                    <el-input-number v-model="form.amount"
                                     :precision="2" :step="1" :min="0" disabled>
                    </el-input-number>
                </el-form-item>

                <el-form-item label="减免金额">
                    <el-input-number v-model="form.reliefAmount"
                                     :precision="2" :step="0.01" :max="form.amount">
                    </el-input-number>
                </el-form-item>

                <el-form-item label="应收日期" required prop="dueDate">
                    <el-date-picker style="width: 100%"
                                    v-model="form.dueDate"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    :clearable="false"
                                    placeholder="选择应收日期">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="备注">
                    <el-input
                            type="textarea"
                            :autosize="{ minRows: 4}"
                            placeholder="备注"
                            v-model="form.remark">
                    </el-input>
                </el-form-item>

                <el-form-item style="text-align: right">
                    <el-button size="small" type="success" @click="submit">同步</el-button>
                </el-form-item>

            </el-form>

        </div>

    </div>

</template>

<script>
    import {GenerateParkingRecords, GetGenerateParkingRecords, GetParkingRecordDetail} from "../../api";
    import XEUtils from 'xe-utils'

    export default {
        name: "ParkingGenerate",
        props: {
            ids: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                unionid: null,
                loading: false,
                records: [],
                groupInfo: [],
                form: {
                    projName: null,
                    inventory: '出租车位',
                    billMonth: null,
                    clientId: null,
                    clientName: null,
                    payer: null,
                    timeRange: [],
                    amount: 0,
                    reliefAmount: 0,
                    dueDate: null,
                    remark: ''
                },
                rules: {}
            }
        },
        methods: {
            submit() {
                this.$alert('请确认信息无误！', '同步账单', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    showCancelButton: true,
                    callback: action => {
                        if (action === 'confirm') {
                            this.loading = true
                            GenerateParkingRecords(this.ids, this.form, this.unionid).then((res) => {
                                if (res === 1) {
                                    this.$emit('ParkingRecordGenerate')
                                    this.loading = false
                                }
                            })
                        }
                    }
                });

            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            this.loading = true
            GetGenerateParkingRecords(this.ids).then((res) => {
                this.records = res.records
                this.groupInfo = res.groupInfo

                if (this.groupInfo.length == 1) {
                    this.form.projName = res.groupInfo[0].projName
                    this.form.billMonth = XEUtils.toDateString(XEUtils.getWhatMonth(res.groupInfo[0].startDate), 'yyyy-MM')
                    this.form.clientId = res.groupInfo[0].clientId
                    this.form.clientName = res.groupInfo[0].clientName.split(";")[0]
                    this.form.payer = res.groupInfo[0].clientName.split(";")[1]
                    this.form.timeRange = [res.groupInfo[0].startDate, res.groupInfo[0].endDate]
                    this.form.dueDate = XEUtils.toDateString(res.groupInfo[0].startDate, 'yyyy-MM-dd')
                }

                for (var i = 0; i <= this.records.length - 1; i++) {
                    this.form.amount = this.form.amount + this.records[i].amount
                    if (this.form.remark === '') {
                        this.form.remark = '车位号：' + this.records[i].parkingNo + '(' + this.records[i].startDate
                            + '至' + this.records[i].endDate + ')'
                    } else {
                        this.form.remark = this.form.remark + '\n车位号：' + this.records[i].parkingNo + '(' + this.records[i].startDate
                            + '至' + this.records[i].endDate + ')'
                    }
                }

                this.loading = false
            })
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
        white-space: pre-line
    }

</style>
