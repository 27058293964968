<template>

    <div v-loading="loading">


        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">

            <el-collapse-item name="collapseItem" class="form-container">
                <template slot="title">
                    <el-tag type="success" style="width: 100%;padding-left: 15px">
                        <i class="el-icon-search" style="margin-right: 5px"></i>
                        <span v-if="searchObj.type === '客户绑定状态'">共查询到 {{clients.length}} 条记录</span>
                        <span v-else>共查询到 {{data.length}} 条记录</span>
                    </el-tag>
                </template>

                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px">

                    <el-form-item>
                        <el-radio-group v-model="searchObj.type" size="small" @change="submit">
                            <el-radio-button label="待审核"></el-radio-button>
                            <el-radio-button label="已审核"></el-radio-button>
                            <el-radio-button label="客户绑定状态"></el-radio-button>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="所属项目:" style="margin-left: 10px">
                        <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                            <el-option v-for="project in projects" :key="project.abbreviation"
                                       :value="project.projName"
                                       :label="project.projName"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item v-if="searchObj.type != '客户绑定状态'" label="用户名称:">
                        <el-input v-model="searchObj.userName" placeholder="用户姓名" clearable @change="submit"></el-input>
                    </el-form-item>

                    <el-form-item label="客户名称:">
                        <el-input v-model="searchObj.clientName" placeholder="客户名称" clearable
                                  @change="submit"></el-input>
                    </el-form-item>

                </el-form>
            </el-collapse-item>

        </el-collapse>

        <!-- 表格 -->
        <div :style="tableContainerStyle">
            <vxe-grid ref="xTable" v-if="searchObj.type != '客户绑定状态'"
                      id="wuyeUserTable"
                      class="wuyeUserTable"
                      v-bind="wuyeUserTable"
                      @cell-click="cellClick"
                      @toolbar-button-click="toolbarButtonClickEvent">

                <template #operate="{ row }">
                    <el-button type="primary" size="mini" @click.stop="btnClick(row)" :disabled="!btnDisabled">审核
                    </el-button>
                </template>

                <template #remove="{ row }">
                    <el-button v-if="row.state" type="warning" size="mini" @click.stop="remove(row)"
                               :disabled="!btnDisabled">解绑
                    </el-button>
                </template>

                <template #state="{ row }">
                    <el-tag v-if="row.state" type="success" size="small">生效中</el-tag>
                    <el-tag v-else type="danger" size="small">已解绑</el-tag>
                </template>

            </vxe-grid>

            <vxe-grid ref="xTable2" v-else
                      id="wuyeClientBingStateTable"
                      class="wuyeClientBingStateTable"
                      v-bind="wuyeClientBingStateTable"
                      @toolbar-button-click="toolbarButtonClickEvent2"
                      @cell-click="cellClick2">

                <template #state="{ row }">
                    <el-tag v-if="row.userUnionid != undefined && row.userUnionid != null" type="success" size="small">
                        已绑定
                    </el-tag>
                    <el-tag v-else type="danger" size="small">未绑定</el-tag>
                </template>

            </vxe-grid>

        </div>

        <!-- 详情 -->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="title"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <WyMpUserDetail :userUnionid="userUnionid"></WyMpUserDetail>
        </el-drawer>

    </div>

</template>

<script>
    import WyMpUserDetail from './WyMpUserDetail'
    import {
        GetProjectsWithoutAll,
        GetWuyeAuthority,
        GetWuyeCertificationObjs, GetWuyeClientBindingStates,
        UpdateWuyeUser,
        WxSendBoundClientChange
    } from "../../api";

    export default {
        name: "WyMpUserCertificationManagement",
        components: {WyMpUserDetail},
        data() {
            return {
                unionid: null,
                btnDisabled: false,
                loading: false,
                projects: [],
                searchObj: {
                    type: '待审核',
                    projName: '',
                    userName: '',
                    clientName: ''
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                columns: [],
                data: [],
                drawer: false,
                title: '',
                userUnionid: null,
                clients: []
            }
        },
        computed: {
            /*表格实例对象*/
            wuyeUserTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    border: 'full',
                    align: 'center',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                        ],
                        size: 'medium',
                        custom: true
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    data: this.data,
                    columns: this.columns
                }
            },
            wuyeClientBingStateTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    border: 'full',
                    align: 'center',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                        ],
                        size: 'medium',
                        custom: true
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    data: this.clients,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '客户编号', field: 'clientId'},
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {
                            resizable: true, title: '客户类型', field: 'type', formatter: function ({cellValue}) {
                                if (cellValue === 1) {
                                    return '公司客户'
                                } else {
                                    return '个人客户'
                                }
                            }
                        },
                        {resizable: true, title: '客户属性', field: 'attribute'},
                        {resizable: true, title: '状态', field: 'state', slots: {default: 'state'}}
                    ]
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            submit() {
                this.loading = true
                var type = 1

                switch (this.searchObj.type) {

                    case "待审核":
                        type = 1
                        this.columns = [
                            {resizable: true, title: '序号', type: 'seq', width: '60'},
                            {
                                resizable: true, title: '用户信息', children: [
                                    {resizable: true, title: '用户姓名', field: 'name', className: 'font-blue'},
                                    {resizable: true, title: '联系电话', field: 'phone'},
                                ]
                            },
                            {resizable: true, title: '申请时间', field: 'time'},
                            {
                                resizable: true, title: '绑定客户信息', children: [
                                    {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                                    {resizable: true, title: '客户编号', field: 'clientId'},
                                ]
                            },
                            {resizable: true, title: '微信绑定手机号', field: 'telephone'},
                            {title: '操作', field: 'operation', fixed: 'right', width: 100, slots: {default: 'operate'}}
                        ]
                        GetWuyeCertificationObjs(this.searchObj, type).then((res) => {
                            this.data = res
                            this.loading = false
                        })
                        break
                    case "已审核":
                        type = 2
                        this.columns = [
                            {resizable: true, title: '序号', type: 'seq', width: '60'},
                            {resizable: true, title: '申请时间', field: 'time'},
                            {
                                resizable: true, title: '用户信息', children: [
                                    {resizable: true, title: '用户姓名', field: 'name', className: 'font-blue'},
                                    {resizable: true, title: '联系电话', field: 'phone'},
                                ]
                            },
                            {resizable: true, title: '微信绑定手机号', field: 'telephone'},
                            {
                                resizable: true, title: '绑定客户信息', children: [
                                    {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                                    {resizable: true, title: '客户编号', field: 'clientId'},
                                ]
                            },
                            {resizable: true, title: '状态', field: 'state', slots: {default: 'state'}},
                            {title: '操作', field: 'remove', fixed: 'right', width: 100, slots: {default: 'remove'}}
                        ]
                        GetWuyeCertificationObjs(this.searchObj, type).then((res) => {
                            this.data = res
                            this.loading = false
                        })
                        break
                    case '客户绑定状态':
                        GetWuyeClientBindingStates(this.searchObj.projName, this.searchObj.clientName).then((res) => {
                            this.clients = res
                            this.loading = false
                        })
                        break

                }

            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                }
            },
            toolbarButtonClickEvent2({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                }
            },
            cellClick({row}) {
                this.title = row.name + ' - 用户详情'
                this.userUnionid = row.unionid
                this.drawer = true
            },
            cellClick2({row}) {
                if (row.userUnionid != undefined && row.userUnionid != null) {
                    this.title = '绑定用户详情'
                    this.userUnionid = row.userUnionid
                    this.drawer = true
                }
            },
            btnClick(row) {
                this.$alert('是否通过该客户认证？', '客户认证', {
                    confirmButtonText: '确定',
                    cancelButtonText: '拒绝',
                    cancelButtonClass: 'reject',
                    showCancelButton: true,
                    distinguishCancelAndClose: true,
                    callback: action => {
                        if (action === 'confirm') {
                            UpdateWuyeUser(row.id, true, true).then((res) => {
                                if (res === 1) {
                                    // 发送通知
                                    WxSendBoundClientChange(row.unionid, row.clientName, 1).then((res) => {
                                        if (res === 1) {
                                            this.submit()
                                        }
                                    })
                                }
                            })
                        }

                        if (action === 'cancel') {
                            UpdateWuyeUser(row.id, false, false).then((res) => {
                                if (res === 1) {
                                    // 发送通知
                                    WxSendBoundClientChange(row.unionid, row.clientName, 2).then((res) => {
                                        if (res === 1) {
                                            this.submit()
                                        }
                                    })
                                }
                            })
                        }

                    }
                });
            },
            remove(row) {
                this.$alert('是否解除该用户与客户之间的绑定关系？', '解除绑定', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    showCancelButton: true,
                    callback: action => {
                        if (action === 'confirm') {
                            this.loading = true
                            UpdateWuyeUser(row.id, false, false).then((res) => {
                                if (res === 1) {
                                    // 发送通知
                                    WxSendBoundClientChange(row.unionid, row.clientName, 3).then((res) => {
                                        if (res === 1) {
                                            this.submit()
                                        }
                                    })
                                }
                            })
                        }
                    }
                });
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeAuthority(this.unionid).then((res) => {

                /* 权限控制 */
                if (res.authorities.indexOf('mpUserMGMT') != -1) {
                    this.btnDisabled = true
                } else {
                    this.btnDisabled = false
                }

                this.searchObj.projName = '稻谷国际中心'
                if (res.projName === 'ALL') {
                    GetProjectsWithoutAll().then((res) => {
                        this.projects = res
                    })
                } else {
                    this.projects = []
                    var arr = res.projName.split(',')

                    this.searchObj.projName = arr[0]

                    for (var i = 0; i <= arr.length - 1; i++) {
                        this.projects.push({
                            projName: arr[i]
                        })
                    }
                }

                this.submit()

            })

        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .wuyeUserTable >>> .font-blue {
        color: #45aaf2;
    }

    .wuyeUserTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .wuyeClientBingStateTable >>> .font-blue {
        color: #45aaf2;
    }

    .wuyeClientBingStateTable >>> .vxe-body--row {
        cursor: pointer;
    }

</style>
