<template>

    <div>
        <el-card v-if="formShow" shadow="never">
            <el-form :model="priceForm" :rules="rules" label-position="left" label-width="70px">
                <el-form-item label="单 价:" prop="unitPrice">
                    <el-input v-model="priceForm.unitPrice"></el-input>
                </el-form-item>
                <el-form-item label="总 价:">
                    <el-input disabled v-model="totalPrice"></el-input>
                </el-form-item>
                <el-form-item style="float: right">
                    <el-button size="small" plain @click="FormShowChange">取消</el-button>
                    <el-button size="small" plain type="success" @click="FormSubmit" :disabled="!submitCheck">保存
                    </el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-row :gutter="12" v-else>
            <el-col :span="24">
                <el-card shadow="hover"
                         @mouseenter.native="hover = true"
                         @mouseleave.native="hover = false">
                    <el-descriptions title="价格" :column="1" border size="medium">
                        <template slot="extra">
                            <el-button v-if="hover&&authority" size="small" type="danger" plain @click="FormShowChange">
                                修改
                            </el-button>
                        </template>
                        <el-descriptions-item v-if="housePrice.houseType === '销售性质'" label="单价">
                            {{CurrencyFormat(housePrice.unitPrice)}}
                        </el-descriptions-item>
                        <el-descriptions-item v-else label="单价">{{CurrencyFormat(housePrice.unitPrice)}}
                        </el-descriptions-item>
                        <el-descriptions-item v-if="housePrice.houseType === '销售性质'" label="总价">
                            {{CurrencyFormat(housePrice.totalPrice)}}
                        </el-descriptions-item>
                        <el-descriptions-item v-else label="月单价">{{CurrencyFormat(housePrice.totalPrice)}}
                        </el-descriptions-item>
                    </el-descriptions>
                </el-card>
            </el-col>
            <el-col :span="24" v-if="housePrice.houseType === '销售性质'">
                <el-card shadow="hover">
                    <el-descriptions :title="'全款 '+(housePrice.fullPayDiscount * 100)+' 折'" :column="1" border
                                     size="medium">
                        <el-descriptions-item label="单价">{{CurrencyFormat(housePrice.fullPayUnitPrice)}}
                        </el-descriptions-item>
                        <el-descriptions-item label="总价">{{CurrencyFormat(housePrice.fullPayTotalPrice)}}
                        </el-descriptions-item>
                    </el-descriptions>
                </el-card>
            </el-col>
            <el-col :span="24" v-if="housePrice.houseType === '销售性质'">
                <el-card shadow="hover">
                    <el-descriptions :title="'贷款 '+(housePrice.loanPayDiscount * 100)+' 折'" :column="1" border
                                     size="medium">
                        <el-descriptions-item label="单价">{{CurrencyFormat(housePrice.loanPayUnitPrice)}}
                        </el-descriptions-item>
                        <el-descriptions-item label="总价">{{CurrencyFormat(housePrice.loanPayTotalPrice)}}
                        </el-descriptions-item>
                    </el-descriptions>
                </el-card>
            </el-col>
        </el-row>
    </div>

</template>

<script>
    import {GetAuthority, InsertOperationRecord, UpdateHousePrice} from '../../api/index'
    import formatMoney from '../../utils/formatMoney'

    export default {
        name: "housePrice",
        data() {

            var priceReg = /^-?\d*\.?\d+$/
            var validatePrice = (rule, value, callback) => {
                if (!value) {
                    this.submitCheck = false
                    return callback(new Error('单价不能为空！'))
                } else {
                    if (!priceReg.test(value)) {
                        this.submitCheck = false
                        callback(new Error('单价必须为数字！'))
                    } else {
                        this.submitCheck = true
                        callback()
                    }
                }
            }

            return {
                unionid: null,
                hover: false,
                formShow: false,
                authority: false,
                priceForm: {
                    unitPrice: this.housePrice.unitPrice,
                },
                submitCheck: true,
                rules: {
                    unitPrice: [
                        {validator: validatePrice, trigger: 'change'}
                    ],
                }
            }
        },
        computed: {
            totalPrice: function () {
                return this.CurrencyFormat(this.priceForm.unitPrice * this.housePrice.constructionArea)
            }
        },
        props: {
            housePrice: {
                type: Object,
                required: true
            }
        },
        watch: {
            housePrice: function () {
                this.priceForm.unitPrice = this.housePrice.unitPrice
            },
            formShow: function () {
                this.priceForm.unitPrice = this.housePrice.unitPrice
            }
        },
        methods: {
            CurrencyFormat(value) {
                if (value > 0) return formatMoney(value)
                return "暂未定价"
            },
            FormShowChange() {
                this.formShow = !this.formShow
                this.hover = !this.hover
            },
            FormSubmit() {
                UpdateHousePrice(this.housePrice.houseId, this.priceForm.unitPrice).then((res) => {
                    if (res === 1) {
                        InsertOperationRecord(this.unionid, 'updateHousePrice', this.housePrice.houseId).then((res) => {
                            if (res === 1) {
                                this.formShow = false
                                this.$emit('UpdateHousePrice')
                            }
                        })
                    }
                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')
            GetAuthority('housePriceChange', this.unionid).then((res) => {
                this.authority = res
            })
        }
    }
</script>

<style scoped>

    .el-col {
        margin-bottom: 10px;
    }

</style>
