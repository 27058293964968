<template>
    <div class="container">

        <el-descriptions title="账单信息"
                         labelClassName="label"
                         class="description"
                         :column="1"
                         border
                         size="medium"
                         style="margin-bottom: 20px">
            <el-descriptions-item label="客户名称">{{monthBill.clientName}}</el-descriptions-item>
            <el-descriptions-item label="账单月份">{{monthBill.billMonth}}</el-descriptions-item>
            <el-descriptions-item label="应收金额">{{MoneyFormat(monthBill.amount)}}</el-descriptions-item>
            <el-descriptions-item label="已收金额">{{MoneyFormat(monthBill.paidIn)}}</el-descriptions-item>
        </el-descriptions>

        <el-form :model="payForm" label-width="80px" size="medium">

            <h4>本次收款</h4>

            <el-form-item label="本次收款">
                <el-input-number v-model="payForm.paidAmount"
                                 :precision="2" :step="0.01" :min="0">
                </el-input-number>
            </el-form-item>

            <el-form-item label="支付时间">
                <el-date-picker style="width: 100%"
                                v-model="payForm.paidDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="支付时间"
                                :clearable="false">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="备注">
                <el-input type="textarea" v-model="payForm.remark" clearable></el-input>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" plain @click="submit">确定</el-button>
            </el-form-item>
        </el-form>

    </div>
</template>

<script>
    import {GetClientMonthElecBill, InsertElecBillPaymentRecord, UpdateElecBillPaidIn} from "../../api";
    import formatMoney from "../../utils/formatMoney";
    import XEUtils from 'xe-utils'

    export default {
        name: "PaymentInterface",
        data() {
            return {
                unionid: null,
                monthBill: {},
                payForm: {
                    clientName: null,
                    paidAmount: null,
                    paidDate: XEUtils.toDateString(Date.now(), 'yyyy-MM-dd'),
                    remark: null
                }
            }
        },
        props: {
            clientId: {
                type: String,
                required: true
            },
            month: {
                type: String,
                required: true
            }
        },
        methods: {
            MoneyFormat(value) {
                return formatMoney(value)
            },
            submit() {
                InsertElecBillPaymentRecord(this.unionid, this.clientId, this.month, this.payForm).then((res) => {
                    if (res === 1) {
                        UpdateElecBillPaidIn(this.clientId, this.month).then((res) => {
                            if (res === 1) {
                                this.$emit('PaymentDrawerChange')
                            }
                        })
                    }
                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')
            GetClientMonthElecBill(this.clientId, this.month).then((res) => {
                this.monthBill = res
                this.payForm.paidAmount = res.amount - res.paidIn
                this.payForm.clientName = res.clientName
            })
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

</style>
