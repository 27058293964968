<template>

    <!--<div v-loading="loading">
        <el-form ref="energyBillForm" :model="form" :rules="rules" label-width="80px" size="medium">

            <el-form-item label="选择客户" required prop="clientId">
                <el-select v-model="form.clientId" filterable
                           placeholder="请选择客户" style="width: 100%">
                    <el-option
                            v-for="item in clients"
                            :key="item.clientId"
                            :label="item.clientName"
                            :value="item.clientId">
                        <span style="float: left">{{ item.clientName }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.clientId }}</span>
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="计费周期" required prop="timeRange">
                <el-date-picker style="width: 100%"
                                v-model="form.timeRange"
                                type="daterange"
                                range-separator="至"
                                value-format="yyyy-MM-dd"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :clearable="false">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="用量" required prop="quantity">
                <el-input-number v-model="form.quantity"
                                 :precision="2" :step="1" :min="0.00">
                </el-input-number>
            </el-form-item>

            <el-form-item label="总金额" required prop="amount">
                <el-input-number v-model="form.amount"
                                 :precision="2" :step="0.01" :min="0.00">
                </el-input-number>
            </el-form-item>

            <el-form-item label="应收日期" required prop="dueDate">
                <el-date-picker style="width: 100%"
                                v-model="form.dueDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择应收日期">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="备注">
                <el-input
                        type="textarea"
                        :autosize="{ minRows: 4}"
                        placeholder="备注"
                        v-model="form.remark">
                </el-input>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" @click="submit">新增</el-button>
            </el-form-item>

        </el-form>
    </div>-->

    <div>
        <el-result icon="warning" title="提示" subTitle="请使用批量导入"></el-result>
    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {GetAllWuyeClients, GetWuyeClientsValidCode, InsertWuyeBill} from "../../../api";

    export default {
        name: "EnergyBillAddForm",
        props: {
            projName: {
                type: String,
                required: true
            },
            inventory: {
                type: String,
                required: true
            },
            billMonth: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                unionid: null,
                loading: false,
                clients: [],
                form: {
                    clientId: null,
                    contractNo: null,
                    timeRange: [],
                    quantity: 0,
                    unitPrice: null,
                    amount: 0,
                    dueDate: null,
                    remark: null
                },
                rules: {
                    clientId: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    timeRange: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    dueDate: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            submit() {
                this.$refs.energyBillForm.validate((e) => {
                    if (e) {

                        if (this.form.amount == 0) {
                            this.$message.error('价格不能为0！');
                        } else {
                            InsertWuyeBill('energy', this.projName, this.billMonth, this.inventory, this.form, this.unionid).then((res) => {
                                if (res === 1) {
                                    this.$emit('EnergyBillAddFormClose')
                                }
                            })
                        }

                    } else {
                        this.$message.error('有未填项！');
                    }
                })
            }
        },
        created() {

            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeClientsValidCode(this.projName).then((res) => {
                this.clients = res
            })

        }
    }
</script>

<style scoped>

</style>
