<template>
    <div class="container" v-loading="loading">

        <div v-if="certificateDetail != null">
            <el-descriptions title="客户信息"
                             labelClassName="label"
                             class="description"
                             :column="2"
                             border
                             style="margin-bottom: 20px">
                <el-descriptions-item label="所属项目" :span="2">{{certificateDetail.user.projName}}</el-descriptions-item>
                <el-descriptions-item label="客户名称" :span="2">{{certificateDetail.user.clientName}}
                </el-descriptions-item>
                <el-descriptions-item label="客户编号">{{certificateDetail.user.clientId}}</el-descriptions-item>
                <el-descriptions-item label="微信绑定手机号">{{certificateDetail.user.telephone}}</el-descriptions-item>
                <el-descriptions-item label="用户名称">{{certificateDetail.userInfo.name}}</el-descriptions-item>
                <el-descriptions-item label="联系电话">{{certificateDetail.userInfo.phone}}</el-descriptions-item>
            </el-descriptions>

            <el-descriptions title="转账信息"
                             labelClassName="label"
                             class="description"
                             :column="2"
                             border
                             style="margin-bottom: 20px">
                <el-descriptions-item label="电费月份">{{certificateDetail.certificate.billMonth}}</el-descriptions-item>
                <el-descriptions-item label="提交时间">{{certificateDetail.certificate.uploadTime}}</el-descriptions-item>
                <el-descriptions-item label="金额" v-if="certificateDetail.certificate.checkState">
                    {{moneyFormat(certificateDetail.certificate.amount)}}
                </el-descriptions-item>
            </el-descriptions>

            <el-divider content-position="left">转账回单</el-divider>

            <el-image v-if="certificateDetail.certificate.checkState"
                      style="width: 100%; height: 500px;margin-bottom: 20px"
                      :src="'http://www.dao-valley.cn:8022/' + certificateDetail.certificate.picUrl"
                      fit="fill"
                      :preview-src-list="['http://www.dao-valley.cn:8022/' + certificateDetail.certificate.picUrl]">
            </el-image>

            <div v-else>
                <!--<el-alert type="error" :closable="false" style="margin-bottom: 20px"
                          title="1.请先确认金额    2.再确认转账信息">
                </el-alert>
                <el-form :model="amountForm" label-width="80px">
                    <el-form-item label="金额">
                        <el-input-number v-model="amountForm.amount" style="width: 50%"
                                         :precision="2" :step="0.01" :min="0" @change="disabled = false">
                        </el-input-number>
                        <el-button type="success" plain size="small" style="margin-left: 10px" @click="amountConfirm">确认金额</el-button>
                    </el-form-item>
                </el-form>

                <el-form label-width="80px">
                    <el-form-item style="text-align: right">
                        <el-button type="primary" :disabled="!disabled" @click="submit">确认转账信息</el-button>
                    </el-form-item>
                </el-form>-->

                <el-form :model="amountForm" label-width="80px" size="medium">

                    <el-form-item label="银行回单">
                        <div class="hello">
                            <el-upload action="/api/confirmElecPayment"
                                       list-type="picture-card"
                                       :auto-upload="false"
                                       accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG"
                                       :data="amountForm"
                                       :on-change="handleEditChange"
                                       :on-exceed="handleExceed"
                                       :on-success="handleSuccess"
                                       :limit="1"
                                       :class="{hide:showUpload}"
                                       ref="fileupload2">
                                <i slot="default" class="el-icon-plus"></i>
                                <div slot="file" slot-scope="{file}">

                                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                                    <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-preview"
                                      @click="handlePictureCardPreview(file)">
                                  <i class="el-icon-zoom-in"></i>
                                </span>

                                <span class="el-upload-list__item-delete"
                                      @click="handleRemove(file)">
                                  <i class="el-icon-delete"></i>
                                </span>

                            </span>
                                </div>
                            </el-upload>
                        </div>

                    </el-form-item>

                    <el-form-item label="确认金额">
                        <el-input-number v-model="amountForm.amount" style="width: 50%"
                                         :precision="2" :step="0.01" :min="0">
                        </el-input-number>
                    </el-form-item>

                    <el-form-item style="text-align: right">
                        <el-button size="small" type="success" @click="submit">上传</el-button>
                    </el-form-item>

                </el-form>

            </div>
        </div>

        <el-dialog width="50%" :visible.sync="dialogVisible" :append-to-body="true">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>

    </div>
</template>

<script>
    import {
        GetElecCertificateDetail, InsertOperationRecord,
        UpdateWuyeElecCertificateAmount,
        UpdateWuyeElecCertificateCheckState
    } from "../../api";
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "ElecCertificateDetail",
        data() {
            return {
                unionid: null,
                certificateDetail: null,
                loading: false,
                amountForm: {
                    id: null,
                    amount: 0
                },
                dialogImageUrl: '',
                dialogVisible: false,
                showUpload: false
            }
        },
        props: {
            id: {
                type: Number,
                required: true
            },
        },
        methods: {
            moneyFormat(value) {
                return formatMoney(value)
            },
            /*amountConfirm() {
                this.loading = true
                UpdateWuyeElecCertificateAmount(this.certificateDetail.certificate.id,this.amountForm.amount).then((res)=>{
                    GetElecCertificateDetail(this.id).then((res)=>{
                        this.certificateDetail = res
                        if (res.certificate.amount == null) {
                            this.amountForm.amount = Number(res.amount)
                            this.disabled = false
                        }else{
                            this.amountForm.amount = Number(res.certificate.amount)
                            this.disabled = true
                        }
                        this.loading = false
                    })
                })
            },*/
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleEditChange(file) {

                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 2MB!');
                    this.handleRemove(file)
                }

                if (this.$refs['fileupload2'].uploadFiles.length == 0) {
                    this.showUpload = false
                } else {
                    this.showUpload = true
                }

            },
            handleExceed() {
                this.$message.error('只能上传一张回单');
            },
            handleRemove(file) {
                let uploadFiles = this.$refs['fileupload2'].uploadFiles;
                let index = uploadFiles.indexOf(file);
                uploadFiles.splice(index, 1);


                if (this.$refs['fileupload2'].uploadFiles.length == 0) {
                    this.showUpload = false
                } else {
                    this.showUpload = true
                }

            },
            submit() {

                if (this.amountForm.id == null || this.amountForm.id === ''
                    || this.amountForm.amount == null || this.amountForm.amount === 0
                    || this.$refs['fileupload2'].uploadFiles.length == 0) {
                    this.$message.error('有信息未填');
                } else {

                    this.$alert('确认金额无误？', '确认金额', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        cancelButtonClass: 'reject',
                        showCancelButton: true,
                        distinguishCancelAndClose: true,
                        callback: action => {
                            if (action === 'confirm') {
                                this.loading = true
                                this.$refs['fileupload2'].submit();
                            }
                            if (action === 'cancel') {

                            }

                        }
                    });


                }

                /*UpdateWuyeElecCertificateCheckState(this.certificateDetail.certificate.id,true).then((res)=>{
                    if (res === 1) {
                        InsertOperationRecord(this.unionid,'checkElecCertificate',this.certificateDetail.certificate.id).then((res)=>{
                            if(res === 1){
                                this.$emit('UpdateElecCertificateAmount')
                            }
                        })
                    }
                })*/


            },
            handleSuccess(response, file, fileList) {
                if (response === 1) {
                    this.loading = false
                    this.$emit('UpdateElecCertificateAmount')
                }
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            this.amountForm.id = this.id

            GetElecCertificateDetail(this.id).then((res) => {
                this.certificateDetail = res
                if (res.certificate.amount == null) {
                    this.amountForm.amount = Number(res.amount)
                    this.disabled = false
                } else {
                    this.amountForm.amount = Number(res.certificate.amount)
                    this.disabled = true
                }

            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

    .hello >>> .hide .el-upload--picture-card {
        display: none;
    }

</style>
