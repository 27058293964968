<template>
    <div class="container">

        <div style="height: -webkit-calc(100vh - 100px)">
            <vxe-grid
                    ref="xTable"
                    id="clientElecBillsTable"
                    class="clientElecBillsTable"
                    v-bind="clientElecBillsTable">
                <template #operate="{ row }">
                    <el-button type="primary" size="mini" plain @click="detailClick(row)" :disabled="!detailAuthority">
                        明细
                    </el-button>
                    <el-button type="success" size="mini" plain @click="paymentClick(row)"
                               :disabled="!paymentAuthority">收款
                    </el-button>
                </template>
            </vxe-grid>
        </div>

        <!-- detail 抽屉-->
        <el-drawer :visible.sync="detailDrawer" size="45%"
                   destroy-on-close
                   :append-to-body="true">
            <template slot="title">
                <el-alert
                        :title="detailDrawerTitle"
                        type="success"
                        :closable="false">
                </el-alert>
            </template>
            <MonthElecBillDetail :clientId="clientId" :month="month" :clientName="clientName"></MonthElecBillDetail>
        </el-drawer>

        <!-- payment 抽屉 -->
        <el-drawer :visible.sync="paymentDrawer" size="45%"
                   destroy-on-close
                   :append-to-body="true">
            <template slot="title">
                <el-alert
                        :title="paymentDrawerTitle"
                        type="success"
                        :closable="false">
                </el-alert>
            </template>
            <PaymentInterface :clientId="clientId" :month="month"
                              @PaymentDrawerChange="PaymentDrawerChange"></PaymentInterface>
        </el-drawer>

    </div>
</template>

<script>
    import {GetAuthority, GetClientMonthElecBills} from "../../api";
    import MonthElecBillDetail from "./MonthElecBillDetail"
    import PaymentInterface from "./PaymentInterface"

    export default {
        name: "ClientBillDetail",
        components: {MonthElecBillDetail, PaymentInterface},
        data() {
            return {
                unionid: null,
                detailDrawer: false,
                detailDrawerTitle: '',
                detailAuthority: false,
                paymentDrawer: false,
                paymentDrawerTitle: '',
                paymentAuthority: false,
                elecBills: [],
                month: null
            }
        },
        props: {
            clientId: {
                type: String,
                required: true
            },
            clientName: {
                type: String,
                required: true
            }
        },
        computed: {
            clientElecBillsTable() {
                return {
                    height: 'auto',
                    size: 'small',
                    data: this.elecBills,
                    rowConfig: {
                        isHover: true
                    },
                    border: 'full',
                    align: 'center',
                    columns: [
                        {resizable: true, title: '账单月份', field: 'billMonth'},
                        {resizable: true, title: '应收金额', field: 'amount', formatter: 'FormatMoney'},
                        {resizable: true, title: '实收金额', field: 'paidIn', formatter: 'FormatMoney'},
                        {title: '操作', fixed: 'right', width: 145, slots: {default: 'operate'}}
                    ]
                }
            }
        },
        watch: {
            detailDrawer: function (val, oldVal) {
                GetClientMonthElecBills(this.clientId).then((res) => {
                    this.elecBills = res
                })
            },
            paymentDrawer: function (val, oldVal) {
                GetClientMonthElecBills(this.clientId).then((res) => {
                    this.elecBills = res
                })
            }
        },
        methods: {
            detailClick(row) {
                this.month = row.billMonth
                this.detailDrawerTitle = this.clientName + ' ' + row.billMonth + ' 账单明细'
                this.detailDrawer = true
            },
            paymentClick(row) {
                this.month = row.billMonth
                this.paymentDrawerTitle = this.clientName + ' ' + row.billMonth + ' 收款'
                this.paymentDrawer = true
            },
            PaymentDrawerChange() {
                this.paymentDrawer = false
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            /*明细权限*/
            GetAuthority('elecBillDetail', this.unionid).then((res) => {
                this.detailAuthority = res
            })

            /*收款权限*/
            GetAuthority('elecBillPayment', this.unionid).then((res) => {
                this.paymentAuthority = res
            })

            GetClientMonthElecBills(this.clientId).then((res) => {
                this.elecBills = res
            })
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

</style>
