<template>

    <div v-loading="loading">
        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
            <el-collapse-item name="collapseItem" class="form-container">
                <template slot="title">
                    <el-tag type="success" style="width: 100%;padding-left: 15px">
                        <i class="el-icon-search" style="margin-right: 5px"></i>
                        <span>共查询到 {{invoices.length}} 条记录</span>
                    </el-tag>
                </template>

                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px">

                    <el-form-item>
                        <el-radio-group v-model="searchObj.type" size="small" @change="submit">
                            <el-radio-button label="待审核"></el-radio-button>
                            <el-radio-button label="已审核"></el-radio-button>
                            <el-radio-button label="已领取"></el-radio-button>
                            <el-radio-button label="失效"></el-radio-button>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="选择时间:" v-if="searchObj.type !== '待审核'">
                        <el-date-picker style="width: 250px"
                                        v-model="searchObj.timeRange"
                                        type="daterange"
                                        range-separator="至"
                                        value-format="yyyy-MM-dd"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        @change="submit">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="所属项目:" style="margin-left: 10px">
                        <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                            <el-option v-for="project in projects" :key="project.abbreviation"
                                       :value="project.abbreviation"
                                       :label="project.projName"></el-option>
                        </el-select>
                    </el-form-item>

                </el-form>
            </el-collapse-item>
        </el-collapse>

        <!-- 表格 -->
        <div :style="tableContainerStyle">
            <vxe-grid ref="xTable"
                      id="elecInvoiceTable"
                      class="elecInvoiceTable"
                      v-bind="elecInvoiceTable"
                      @toolbar-button-click="toolbarButtonClickEvent"
                      @toolbar-tool-click="toolbarToolClickEvent"
                      @checkbox-change="checkboxChange"
                      @checkbox-all="checkboxChange">

                <template #operate="{ row }">
                    <el-button type="primary" size="mini" plain @click="btnClick(row)" :disabled="!authority">查看
                    </el-button>
                </template>

            </vxe-grid>
        </div>

        <!-- 抽屉 -->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <ElecInvoiceDetail :id="id" @UpdateElecInvoiceDetail="UpdateElecInvoiceDetail"></ElecInvoiceDetail>
        </el-drawer>
    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {GetAuthority, GetProjects, GetWuyeElecInvoices, PickUpElecInvoices, SendElecInvoicePickUp} from "../../api";
    import ElecInvoiceDetail from "./ElecInvoiceDetail"

    export default {
        name: "WuyeElecInvoice",
        components: {ElecInvoiceDetail},
        data() {
            return {
                loading: false,
                unionid: null,
                authority: false,
                pickAuthority: false,
                sendAuthority: false,
                drawer: false,
                drawerTitle: null,
                id: null,
                projects: [],
                searchObj: {
                    type: '待审核',
                    projName: 'ALL',
                    timeRange: [
                        XEUtils.toDateString(XEUtils.getWhatDay(Date.now(), -30), 'yyyy-MM-dd'),
                        XEUtils.toDateString(Date.now(), 'yyyy-MM-dd'),
                    ]
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                invoices: [],
                invoiceKinds: [
                    {label: '增值税普通发票（电子）', value: 1},
                    {label: '增值税专用发票（纸质）', value: 2}
                ],
                checked: [],
                tools: [],
                columns: [
                    {resizable: true, title: '申请时间', field: 'applyTime'},
                    {resizable: true, title: '项目', field: 'projName'},
                    {resizable: true, title: '发票抬头', field: 'clientName', className: 'font-blue'},
                    {
                        resizable: true, title: '发票类型', field: 'invoiceKind',
                        filters: this.invoiceKinds,
                        filterMethod: this.InvoiceKindFilter,
                        formatter: function ({cellValue}) {
                            if (cellValue === 1) {
                                return '增值税普通发票（电子）'
                            } else {
                                return '增值税专用发票（纸质）'
                            }
                        }
                    },
                    {resizable: true, title: '发票总额', field: 'amount', formatter: 'FormatMoney'},
                    {title: '操作', fixed: 'right', width: 100, slots: {default: 'operate'}}
                ]
            }
        },
        computed: {
            /*表格实例对象*/
            elecInvoiceTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                        ],
                        size: 'medium',
                        tools: this.tools
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    checkboxConfig: {
                        labelField: 'id'
                    },
                    data: this.invoices,
                    checkboxConfig: {
                        checkMethod: this.checkMethod
                    },
                    columns: this.columns
                }
            }
        },
        methods: {
            InvoiceKindFilter({value, row, column}) {
                return row.invoiceKind == value
            },
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            submit() {
                this.invoices = []
                this.$refs.xTable.resetColumn({
                    resizable: true
                })
                // type 分类
                switch (this.searchObj.type) {

                    case '待审核':
                        this.tools = []

                        this.columns = [
                            {resizable: true, title: '申请时间', field: 'applyTime'},
                            {resizable: true, title: '项目', field: 'projName'},
                            {resizable: true, title: '发票抬头', field: 'clientName', className: 'font-blue'},
                            {
                                resizable: true, title: '发票类型', field: 'invoiceKind',
                                filters: this.invoiceKinds,
                                filterMethod: this.InvoiceKindFilter,
                                formatter: function ({cellValue}) {
                                    if (cellValue === 1) {
                                        return '增值税普通发票（电子）'
                                    } else {
                                        return '增值税专用发票（纸质）'
                                    }
                                }
                            },
                            {resizable: true, title: '发票总额', field: 'amount', formatter: 'FormatMoney'},
                            {title: '操作', fixed: 'right', width: 100, slots: {default: 'operate'}}
                        ]

                        GetWuyeElecInvoices(this.unionid, this.searchObj).then((res) => {
                            this.invoices = res
                        })

                        break
                    case '已审核':
                        this.tools = [
                            {code: 'pick', name: '纸质票领取', status: 'success', disabled: !this.pickAuthority},
                        ]

                        this.columns = [
                            {type: 'checkbox', width: 40},
                            {resizable: true, title: '申请时间', field: 'applyTime'},
                            {resizable: true, title: '项目', field: 'projName'},
                            {resizable: true, title: '发票抬头', field: 'clientName', className: 'font-blue'},
                            {
                                resizable: true, title: '发票类型', field: 'invoiceKind',
                                filters: this.invoiceKinds,
                                filterMethod: this.InvoiceKindFilter,
                                formatter: function ({cellValue}) {
                                    if (cellValue === 1) {
                                        return '增值税普通发票（电子）'
                                    } else {
                                        return '增值税专用发票（纸质）'
                                    }
                                }
                            },
                            {resizable: true, title: '发票总额', field: 'amount', formatter: 'FormatMoney'},
                            {title: '操作', fixed: 'right', width: 100, slots: {default: 'operate'}}
                        ]

                        GetWuyeElecInvoices(this.unionid, this.searchObj).then((res) => {
                            this.invoices = res
                        })

                        break
                    case '已领取':

                        this.tools = [
                            {code: 'send', name: '通知客户领取', status: 'warning', disabled: !this.sendAuthority}
                        ]

                        this.columns = [
                            {type: 'checkbox', width: 40},
                            {resizable: true, title: '推送次数', field: 'sendCount'},
                            {resizable: true, title: '领取时间', field: 'pickUpTime'},
                            {resizable: true, title: '项目', field: 'projName'},
                            {resizable: true, title: '发票抬头', field: 'clientName', className: 'font-blue'},
                            {
                                resizable: true, title: '发票类型', field: 'invoiceKind',
                                filters: this.invoiceKinds,
                                filterMethod: this.InvoiceKindFilter,
                                formatter: function ({cellValue}) {
                                    if (cellValue === 1) {
                                        return '增值税普通发票（电子）'
                                    } else {
                                        return '增值税专用发票（纸质）'
                                    }
                                }
                            },
                            {resizable: true, title: '发票总额', field: 'amount', formatter: 'FormatMoney'},
                            {
                                resizable: true,
                                title: '客户领取',
                                field: 'clientPickUpState',
                                formatter: function ({cellValue}) {
                                    if (cellValue) {
                                        return '✅'
                                    } else {
                                        return ''
                                    }
                                }
                            },
                            {title: '操作', fixed: 'right', width: 100, slots: {default: 'operate'}}
                        ]

                        GetWuyeElecInvoices(this.unionid, this.searchObj).then((res) => {
                            this.invoices = res
                        })
                        break
                    case '失效':
                        this.tools = []

                        this.columns = [
                            {resizable: true, title: '申请时间', field: 'applyTime'},
                            {resizable: true, title: '项目', field: 'projName'},
                            {resizable: true, title: '发票抬头', field: 'clientName', className: 'font-blue'},
                            {
                                resizable: true, title: '发票类型', field: 'invoiceKind',
                                filters: this.invoiceKinds,
                                filterMethod: this.InvoiceKindFilter,
                                formatter: function ({cellValue}) {
                                    if (cellValue === 1) {
                                        return '增值税普通发票（电子）'
                                    } else {
                                        return '增值税专用发票（纸质）'
                                    }
                                }
                            },
                            {resizable: true, title: '发票总额', field: 'amount', formatter: 'FormatMoney'},
                            {title: '操作', fixed: 'right', width: 100, slots: {default: 'operate'}}
                        ]

                        GetWuyeElecInvoices(this.unionid, this.searchObj).then((res) => {
                            this.invoices = res
                        })
                        break

                }
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                }
            },
            toolbarToolClickEvent({code}) {
                switch (code) {
                    case 'pick':
                        PickUpElecInvoices(this.unionid, this.checked).then((res) => {
                            this.checked = []
                            this.$message({
                                message: '你领取了 ' + res + ' 张发票',
                                type: 'warning'
                            });
                            this.searchObj.type = '已领取'
                            this.submit()
                        })
                        break
                    case 'send':
                        if (this.checked.length > 0) {
                            this.loading = true
                            SendElecInvoicePickUp(this.checked).then((res) => {
                                this.checked = []
                                this.$message({
                                    message: '你推送了 ' + res + ' 条通知',
                                    type: 'warning'
                                });
                                this.submit()
                                this.loading = false
                            })
                        }
                        break
                }
            },
            checkMethod({row}) {
                switch (this.searchObj.type) {
                    case '已审核':
                        if (row.invoiceKind === 1) {
                            return false
                        } else {
                            if (row.pickUpUnionid != null) {
                                return false
                            } else {
                                return true
                            }
                        }
                        break
                    case '已领取':
                        if (row.clientPickUpState) {
                            return false
                        } else {
                            return true
                        }
                        break
                }

            },
            checkboxChange() {
                var a = this.$refs.xTable.getCheckboxRecords(true)
                this.checked = []
                for (var i = 0; i < a.length; i++) {
                    this.checked.push(a[i].id)
                }
            },
            btnClick(row) {
                this.id = row.id
                this.drawerTitle = row.clientName + " - 电费 - 发票详情"
                this.drawer = true
            },
            UpdateElecInvoiceDetail() {
                this.submit()
                this.drawer = false
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetAuthority('wuyeInvoice', this.unionid).then((res) => {
                this.authority = res
            })

            GetProjects().then((res) => {
                this.projects = res
            })

            /*领取权限*/
            GetAuthority('wuyeElecInvoicePickUp', this.unionid).then((res) => {
                this.pickAuthority = res
            })

            /*推送全新啊*/
            GetAuthority('wuyeSendBill', this.unionid).then((res) => {
                this.sendAuthority = res
            })

        },
        mounted() {
            this.$nextTick(function () {
                this.submit()
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .elecInvoiceTable >>> .font-blue {
        color: #45aaf2;
    }

</style>
