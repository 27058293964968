<template>

    <div class="container">
        <el-descriptions title="房源详情" :column="3" border style="margin-bottom: 20px">
            <el-descriptions-item>
                <template slot="label">所属项目</template>
                {{houseInfo.projName}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">楼宇</template>
                {{houseInfo.building}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">楼层</template>
                {{houseInfo.floor + 'F'}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">房间名称</template>
                {{houseInfo.houseName}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">房源状态</template>
                <el-tag size="small">{{houseInfo.houseState}}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">建筑面积</template>
                {{houseInfo.constructionArea + ' ㎡'}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">房源性质</template>
                {{houseInfo.houseType}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">房源ID</template>
                <el-tag size="small" type="success">{{houseInfo.houseId}}</el-tag>
            </el-descriptions-item>
        </el-descriptions>

        <el-tabs :value="tabName">
            <el-tab-pane label="客户信息" name="client">
                <el-collapse value="1">
                    <el-collapse-item title="当前客户" name="1">
                        <vxe-grid v-bind="nowClientTable"></vxe-grid>
                    </el-collapse-item>

                    <el-collapse-item title="未来客户">
                        <vxe-grid v-bind="futureClientTable"></vxe-grid>
                    </el-collapse-item>

                    <el-collapse-item title="历史客户">
                        <vxe-grid v-bind="historyClientTable"></vxe-grid>
                    </el-collapse-item>
                </el-collapse>
            </el-tab-pane>

            <el-tab-pane label="价格信息" name="price">
                <HousePrice :housePrice="housePrice" @UpdateHousePrice="UpdateHouseInfo"></HousePrice>
            </el-tab-pane>

            <el-tab-pane label="过户情况" name="transfer" v-if="houseInfo.houseType === '销售性质'">
                <HouseTransferSituation :houseTransferSituation="houseTransferSituation"
                                        @UpdateTransferSituation="UpdateHouseInfo"></HouseTransferSituation>
            </el-tab-pane>
        </el-tabs>

    </div>


</template>

<script>

    import HousePrice from "./HousePrice";
    import HouseTransferSituation from "./HouseTransferSituation";
    import {
        GetHouseInfoDetail,
        GetNowClient,
        GetFutureClient,
        GetHistoryClient,
        GetHousePrice,
        GetHouseTransferSituation
    } from '../../api/index'

    export default {
        name: "HouseInfo",
        components: {HousePrice, HouseTransferSituation},
        data() {
            return {
                houseInfo: {},
                nowClients: [],
                futureClients: [],
                historyClients: [],
                housePrice: {},
                houseTransferSituation: {},
            }
        },
        methods: {
            UpdateHouseInfo() {
                GetHouseInfoDetail(this.houseId).then((res) => {
                    this.houseInfo = res
                    if (res.houseType === '销售性质') {
                        GetHouseTransferSituation(this.houseId).then((res) => {
                            this.houseTransferSituation = res
                        })
                    }
                })

                GetNowClient(this.houseId).then((res) => {
                    this.nowClients = res
                })

                GetFutureClient(this.houseId).then((res) => {
                    this.futureClients = res
                })

                GetHistoryClient(this.houseId).then((res) => {
                    this.historyClients = res
                })

                GetHousePrice(this.houseId).then((res) => {
                    this.housePrice = res
                })
            }
        },
        props: {
            houseId: {
                type: Number,
                required: true
            },
            tabName: {
                type: String,
                required: true
            }
        },
        computed: {
            nowClientTable: function () {
                return {
                    showOverflow: true,
                    size: 'small',
                    data: this.nowClients,
                    columns: [
                        {resizable: true, title: '客户编号', field: 'clientId'},
                        {resizable: true, width: '25%', title: '客户名称', field: 'clientName'},
                        {resizable: true, title: '客户属性', field: 'clientAttribute'},
                        {resizable: true, title: '合同编号', field: 'contractId'},
                        {resizable: true, title: '开始时间', field: 'contractStartDate'},
                        {resizable: true, title: '结束时间', field: 'contractEndDate'},
                    ]
                }
            },
            futureClientTable: function () {
                return {
                    showOverflow: true,
                    size: 'small',
                    data: this.futureClients,
                    columns: [
                        {resizable: true, title: '客户编号', field: 'clientId'},
                        {resizable: true, width: '25%', title: '客户名称', field: 'clientName'},
                        {resizable: true, title: '客户属性', field: 'clientAttribute'},
                        {resizable: true, title: '合同编号', field: 'contractId'},
                        {resizable: true, title: '开始时间', field: 'contractStartDate'},
                        {resizable: true, title: '结束时间', field: 'contractEndDate'},
                    ]
                }
            },
            historyClientTable: function () {
                return {
                    showOverflow: true,
                    size: 'small',
                    data: this.historyClients,
                    columns: [
                        {resizable: true, title: '客户编号', field: 'clientId'},
                        {resizable: true, width: '25%', title: '客户名称', field: 'clientName'},
                        {resizable: true, title: '客户属性', field: 'clientAttribute'},
                        {resizable: true, title: '合同编号', field: 'contractId'},
                        {resizable: true, title: '开始时间', field: 'contractStartDate'},
                        {resizable: true, title: '结束时间', field: 'contractEndDate'},
                    ]
                }
            }

        },
        created() {
            this.UpdateHouseInfo();
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

</style>
