<template>
    <div class="container" v-loading="loading">

        <el-tabs v-model="tabName" @tab-click="handleClick">
            <el-tab-pane label="基本信息" name="first">

                <el-descriptions title="客户信息"
                                 labelClassName="label"
                                 class="description"
                                 :column="1"
                                 border
                                 style="margin-bottom: 20px">
                    <el-descriptions-item label="类别">{{businessInfo.kind}}</el-descriptions-item>
                    <el-descriptions-item label="项目">{{businessInfo.projName}}</el-descriptions-item>
                    <el-descriptions-item label="客户名称">{{businessInfo.businessName}}</el-descriptions-item>
                    <el-descriptions-item label="主营业务">{{businessInfo.mainBusiness}}</el-descriptions-item>
                    <el-descriptions-item label="地址">{{businessInfo.address}}</el-descriptions-item>
                    <el-descriptions-item label="联系人">{{businessInfo.contactPerson}}</el-descriptions-item>
                    <el-descriptions-item label="电话">{{businessInfo.contactInfo}}</el-descriptions-item>
                    <el-descriptions-item label="微信">{{businessInfo.wechat}}</el-descriptions-item>
                    <el-descriptions-item label="客户来源">{{businessInfo.origin}}</el-descriptions-item>
                    <el-descriptions-item label="业务员">{{businessInfo.unionName}}</el-descriptions-item>
                    <el-descriptions-item label="意向房源">{{businessInfo.intentHouseName}}</el-descriptions-item>
                    <el-descriptions-item label="备注">{{businessInfo.remark}}</el-descriptions-item>
                </el-descriptions>

                <el-descriptions title="商机编号"
                                 labelClassName="label"
                                 class="description"
                                 :column="1"
                                 border
                                 style="margin-bottom: 20px">
                    <el-descriptions-item label="创建时间">{{businessInfo.createTime}}</el-descriptions-item>
                    <el-descriptions-item label="商机编号">{{businessInfo.id}}</el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>

            <el-tab-pane label="跟进记录" name="second">

                <el-timeline style="padding-left: 2px!important;">
                    <el-timeline-item
                            v-for="item in followUps"
                            :key="item.followUp.id"
                            type="success"
                            :timestamp="item.followUp.followUpDate" placement="top">
                        <p>
                            <span style="color: #6c6a6a;font-weight: bold">当前阶段：</span>
                            <span>{{item.followUp.currentStage}}</span>
                        </p>
                        <p>
                            <span style="color: #6c6a6a;font-weight: bold">跟进方式：</span>
                            <span>{{item.followUp.followUpWay}}</span>
                        </p>
                        <p>
                            <span style="color: #6c6a6a;font-weight: bold">跟进人：</span>
                            <span>{{item.followUp.employee}}</span>
                        </p>
                        <p>
                            <span style="color: #6c6a6a;font-weight: bold">协同人：</span>
                            <span>{{item.followUp.coordinatorNames==null?'无':item.followUp.coordinatorNames}}</span>
                        </p>
                        <p>
                            <span style="color: #6c6a6a;font-weight: bold">沟通情况：</span>
                            <span>{{item.followUp.visitContent}}</span>
                        </p>
                        <el-tag type="success" v-if="item.followUp.haveRead != null" style="width: 100%">
                            {{item.followUp.haveRead}} 已读
                        </el-tag>

                        <div style="text-align: right;margin-top: 10px" v-if="item.editable">
                            <el-button type="info" plain size="small" :id="item.followUp.id" @click="Delete">删除
                            </el-button>
                            <el-button type="warning" plain size="small" :id="item.followUp.id" @click="Update">修改
                            </el-button>
                        </div>

                        <el-divider content-position="right">
                            <span style="color: #b3b1b1">填写时间：{{item.followUp.time}}</span>
                        </el-divider>
                    </el-timeline-item>
                </el-timeline>
            </el-tab-pane>

            <el-tab-pane label="评论" name="third">
                <el-divider content-position="left">发表评论</el-divider>

                <el-input
                        type="textarea"
                        :autosize="{ minRows: 4}"
                        placeholder="请输入评论"
                        v-model="comment.comment" style="margin-bottom: 10px" @input="checkComment">
                </el-input>

                <div style="text-align: right">
                    <el-button size="small" type="primary" @click="submit" :disabled="!commentAble">评论</el-button>
                </div>

                <el-divider content-position="left">评论</el-divider>

                <el-timeline style="padding-left: 2px!important;">
                    <el-timeline-item
                            v-for="commentList in commentLists"
                            :key="commentList.id"
                            :color="commentList.readState?'':'#D77070'"
                            :timestamp="commentList.time" placement="top">
                        <p v-if="commentList.kind === 'comment'">
                            <el-tag size="small" type="danger">{{commentList.senderName}}</el-tag>
                            <span style="color: #b3b1b1"> 评论了：</span>
                        </p>
                        <p v-else>
                            <el-tag size="small" type="danger">{{commentList.senderName}}</el-tag>
                            <span style="color: #b3b1b1"> 回复 </span>
                            <el-tag size="small" type="danger">{{commentList.replyName}}</el-tag>
                            <span style="color: #b3b1b1">：</span>
                        </p>
                        <p>
                            <span>{{commentList.comment}}</span>
                        </p>

                        <BusinessReply :replayUnionid="commentList.sender"
                                       :id="id" @businessReply="businessReply"></BusinessReply>

                        <el-divider></el-divider>

                    </el-timeline-item>
                </el-timeline>

            </el-tab-pane>

            <el-tab-pane label="日志" name="fourth">
                <el-timeline style="padding-left: 2px!important;">
                    <el-timeline-item v-for="record in records"
                                      :key="record.id"
                                      :timestamp="record.time" placement="top">
                        <p>
                            <span style="color: #6c6a6a;font-weight: bold">操作人：</span>
                            <span>{{record.unionid}}</span>
                        </p>
                        <p>
                            <span style="color: #6c6a6a;font-weight: bold">操作内容：</span>
                            <span>{{record.detail}}</span>
                        </p>

                    </el-timeline-item>
                </el-timeline>

            </el-tab-pane>
        </el-tabs>


        <el-drawer :visible.sync="drawer" size="45%"
                   destroy-on-close
                   :close-on-press-escape="false"
                   :wrapperClosable="false"
                   :append-to-body="true">
            <template slot="title">
                <el-alert
                        title="编辑跟进信息"
                        type="success"
                        :closable="false">
                </el-alert>
            </template>
            <FollowUpEdit :businessFollowUpId="businessFollowUpId" @FollowUpUpdate="FollowUpUpdate"></FollowUpEdit>
        </el-drawer>


    </div>
</template>

<script>
    import {
        DeteleBusinessFollowUp,
        GetBusinessComments,
        GetBusinessFollowUps,
        GetBusinessInfo,
        GetBusinessInfoUpdateRecords, GetComplexBusinessDetail,
        InsertBusinessComment, UpdateBusinessCommentHaveRead, UpdateBusinessFollowUpRead
    } from "../../api";
    import BusinessReply from './BusinessReply'
    import FollowUpEdit from './FollowUpEdit'

    export default {
        name: "BusinessInfo",
        components: {BusinessReply, FollowUpEdit},
        data() {
            return {
                drawer: false,
                loading: false,
                tabName: null,
                unionid: null,
                businessInfo: {},
                followUps: [],
                commentLists: [],
                comment: {
                    unionid: null,
                    businessId: null,
                    kind: 'comment',
                    replyUnionid: null,
                    comment: null
                },
                commentAble: false,
                records: [],
                businessFollowUpId: null
            }
        },
        props: {
            id: {
                type: Number,
                required: true
            },
            followUpId: {
                type: Number,
                required: false,
                default: null
            },
            tab: {
                type: String,
                required: false,
                default: 'second'
            }
        },
        methods: {
            handleClick(tab, event) {
                if (tab.paneName === 'third') {
                    this.loading = true
                    UpdateBusinessCommentHaveRead(this.unionid, this.id).then((res) => {
                        this.loading = false
                    })
                }
            },
            checkComment() {
                if (this.comment.comment != null && String(this.comment.comment).trim() != '') {
                    this.commentAble = true
                } else {
                    this.commentAble = false
                }
            },
            submit() {
                /*提交评论*/
                this.loading = true
                InsertBusinessComment(this.comment).then((res) => {
                    this.comment.comment = null
                    GetBusinessComments(this.unionid, this.id).then((res) => {
                        this.commentLists = res
                        this.loading = false
                    })
                })
            },
            businessReply() {
                this.loading = true
                GetBusinessComments(this.unionid, this.id).then((res) => {
                    this.commentLists = res
                    this.loading = false
                })
            },
            Delete(e) {
                var id = e.currentTarget.id

                this.$confirm('是否删除该条跟进记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    callback: action => {
                        if (action === 'confirm') {
                            this.loading = true
                            DeteleBusinessFollowUp(id).then((res) => {
                                if (res === 1) {
                                    GetBusinessFollowUps(this.unionid, this.id).then((res) => {
                                        this.followUps = res
                                        this.loading = false
                                    })
                                }
                            })
                        }
                    }
                })

            },
            Update(e) {
                this.businessFollowUpId = Number(e.currentTarget.id)
                this.drawer = true
            },
            FollowUpUpdate() {
                GetBusinessFollowUps(this.unionid, this.id).then((res) => {
                    this.followUps = res
                })
                GetBusinessInfoUpdateRecords(this.id).then((res) => {
                    this.records = res
                })
                this.drawer = false
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')
            this.tabName = this.tab

            if (this.tab === 'third') {
                UpdateBusinessCommentHaveRead(this.unionid, this.id).then((res) => {

                })
            }

            this.comment.unionid = sessionStorage.getItem('unionid')
            this.comment.businessId = this.id

            GetBusinessInfo(this.id).then((res) => {
                this.businessInfo = res
                this.comment.replyUnionid = res.unionid

                if (this.followUpId == null) {

                } else {
                    UpdateBusinessFollowUpRead(this.followUpId, this.unionid).then((res) => {

                    })
                }

            })


            GetBusinessFollowUps(this.unionid, this.id).then((res) => {
                this.followUps = res
            })

            GetBusinessComments(this.unionid, this.id).then((res) => {
                this.commentLists = res
            })

            GetBusinessInfoUpdateRecords(this.id).then((res) => {
                this.records = res
            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

</style>
