<template>
    <div class="container">

        <el-descriptions title="基本信息"
                         labelClassName="label"
                         class="description"
                         :column="1"
                         border
                         size="medium"
                         style="margin-bottom: 20px">
            <el-descriptions-item label="所属项目">{{elecMeter.projName}}</el-descriptions-item>
            <el-descriptions-item label="表计属性">{{elecMeter.meterAttribute}}</el-descriptions-item>
            <el-descriptions-item label="表计名称">{{elecMeter.meterName}}</el-descriptions-item>
            <el-descriptions-item label="表计编号">{{elecMeter.meterId}}</el-descriptions-item>
            <el-descriptions-item label="倍率">{{Number(elecMeter.magnification).toFixed(2)}}</el-descriptions-item>
        </el-descriptions>

        <el-descriptions v-if="!formShow"
                         labelClassName="label"
                         class="description"
                         :column="1"
                         size="medium"
                         border>
            <template slot="extra">
                <el-button v-if="authority" type="danger" size="small" plain @click="editClick">编辑</el-button>
            </template>
            <el-descriptions-item label="电表负载">{{elecMeter.meterLoad}}</el-descriptions-item>
            <el-descriptions-item label="撤表信息">{{elecMeter.removeInfo}}</el-descriptions-item>
        </el-descriptions>

        <el-form :model="elecMeterForm" label-position="left" label-width="70px" v-else>

            <el-form-item label="电表负载">
                <el-input type="textarea" v-model="elecMeterForm.meterLoad" clearable></el-input>
            </el-form-item>

            <el-form-item label="撤表信息">
                <el-input v-model="elecMeterForm.removeInfo" clearable
                          placeholder="格式(撤表日期+英文逗号+读数):2020-01-01,1234"></el-input>
            </el-form-item>

            <el-form-item style="float: right">
                <el-button size="small" plain @click="FormShowChange">取消</el-button>
                <el-button size="small" plain type="success" @click="FormSubmit">保存</el-button>
            </el-form-item>
        </el-form>

    </div>
</template>

<script>
    import {
        GetAuthority,
        GetElecMeterDetail,
        InsertOperationRecord,
        UpdateElecMeterDetail
    } from "../../api";

    export default {
        name: "ElecMeterDetail",
        data() {
            return {
                unionid: null,
                formShow: false,
                authority: false,
                elecMeter: {},
                elecMeterForm: {
                    meterLoad: null,
                    removeInfo: null
                }
            }
        },
        props: {
            meterId: {
                type: String,
                required: true
            }
        },
        methods: {
            editClick() {
                this.elecMeterForm.meterLoad = this.elecMeter.meterLoad
                this.elecMeterForm.removeInfo = this.elecMeter.removeInfo
                this.formShow = true
            },
            FormShowChange() {
                this.formShow = false
            },
            FormSubmit() {
                UpdateElecMeterDetail(this.meterId, this.elecMeterForm.meterLoad, this.elecMeterForm.removeInfo).then((res) => {
                    if (res === 1) {

                        InsertOperationRecord(this.unionid, 'updateElecMeter', this.meterId).then((res) => {
                            if (res === 1) {
                                GetElecMeterDetail(this.meterId).then((res) => {
                                    this.elecMeter = res
                                    this.formShow = false
                                })
                            }
                        })

                    }
                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetAuthority('elecInfoEdit', this.unionid).then((res) => {
                this.authority = res
            })

            GetElecMeterDetail(this.meterId).then((res) => {
                this.elecMeter = res
            })


        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

</style>
