<template>

    <div class="container" v-loading="loading">

        <el-tabs v-model="tabName">

            <el-tab-pane label="账单明细" name="first">

                <vxe-grid ref="xTable"
                          id="collectionWuyeClientBillTable"
                          class="collectionWuyeClientBillTable"
                          v-bind="collectionWuyeClientBillTable"
                          @toolbar-button-click="toolbarButtonClickEvent"
                          @cell-click="cellClick">

                    <!-- 收款信息、开票信息、支付方式 -->
                    <template #collection="{ row }">
                        <el-tag v-if="row.collectionCode != null" type="success" size="small">已收款</el-tag>
                        <el-tag v-else type="danger" size="small">未收款</el-tag>
                    </template>

                    <template #invoice="{ row }">
                        <el-tag v-if="row.paymentMethod == '2'" type="info" size="small">不可开票</el-tag>
                        <el-tag v-if="row.invoiceInfo == 'empty'" type="warning" size="small">无需开票</el-tag>
                        <el-tag v-if="row.paymentMethod != '2' && row.invoiceInfo != null && row.invoiceInfo != 'empty'"
                                type="success" size="small">已开票
                        </el-tag>
                        <el-tag v-if="row.paymentMethod != '2' && row.invoiceInfo == null" type="danger" size="small">未开票
                        </el-tag>
                    </template>

                    <template #paymentMethod="{ row }">
                        <el-tag v-if="(row.paymentMethod != '2') && row.collectionCode != null" size="small">{{'对公' + row.paymentMethod}}</el-tag>
                        <!--<el-tag v-if="row.paymentMethod == '1' && row.collectionCode == null" size="small">未支付</el-tag>-->
                        <el-tag v-if="row.paymentMethod == '2' && row.collectionCode != null" type="info" size="small">
                            现金
                        </el-tag>
                        <el-tag v-if="row.collectionCode == null" type="danger" size="small">
                            {{'对公' + row.paymentMethod}}
                        </el-tag>
                    </template>

                </vxe-grid>

            </el-tab-pane>


            <el-tab-pane label="催费情况" name="second">

                <el-timeline style="padding-left: 2px!important;">

                    <el-timeline-item
                            v-for="record in recordList"
                            :key="record.id"
                            type="warning"
                            :timestamp="record.followDate" placement="top">
                        <p>
                            <span style="color: #6c6a6a;font-weight: bold">跟进人：</span>
                            <span>{{record.employeeName}}</span>
                        </p>

                        <p>
                            <span style="color: #6c6a6a;font-weight: bold">跟进内容：</span>
                            <span>{{record.followContent}}</span>
                        </p>

                        <el-row :gutter="20">
                            <el-col :span="8" v-for="(pic,index) in record.pictures" :key="index">
                                <el-image
                                        style="width: 100%; height: 200px"
                                        :src="'http://www.dao-valley.cn:8022/A_WuyeCollection/' + clientId + '/' + pic"
                                        fit="contain"
                                        :preview-src-list="PreviewList(record.pictures)"></el-image>
                            </el-col>
                        </el-row>
                    </el-timeline-item>
                </el-timeline>
            </el-tab-pane>


        </el-tabs>

        <!--详情-->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close :append-to-body="true">
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <WuyeBillDetail :id="billId"></WuyeBillDetail>
        </el-drawer>

    </div>

</template>

<script>
    import {GetClientWuyeBillLists, GetWuyeCollectionRecords} from "../../api";
    import formatMoney from "../../utils/formatMoney";
    import WuyeBillDetail from "./WuyeBillDetail"

    export default {
        name: "WuyeClientCollectionDetail",
        components: {WuyeBillDetail},
        props: {
            clientId: {
                type:String,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                tabName: 'first',
                client: {},
                bills: [],
                drawer: false,
                drawerTitle: '',
                billId: null,
                recordList: []
            }
        },
        computed: {
            collectionWuyeClientBillTable: function () {
                return {
                    height: '700',
                    toolbarConfig: {
                        buttons: [
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                        ],
                        size: 'medium'
                    },
                    size: 'small',
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.bills,
                    columns: [
                        {resizable: true, title: '账单月份', field: 'billMonth'},
                        {resizable: true, title: '收费项目', field: 'u8fee', width: 180,formatter: function ({row}) {
                                return row.u8fee + '\n' + row.chargeContent
                            }},
                        {resizable: true, title: '应收金额', field: 'amount', formatter: this.FormatReceivableAmount},
                        {
                            resizable: true,
                            title: '实际收款',
                            field: 'receivedAmount',
                            formatter: this.FormatMoney,
                            className: 'font-blue'
                        },
                        {
                            resizable: true,
                            title: '未缴',
                            field: 'unpaidAmount',
                            formatter: this.FormatUnpaidAmount,
                            className: function ({row}) {

                                let count = row.amount
                                let relief = row.reliefAmount
                                let received = (row.collectionCode != null)?row.receivedAmount:0

                                let unpaid = count - relief- received

                                if (unpaid > 0) {
                                    return 'font-red'
                                }
                            }
                        },
                        {resizable: true, title: '收款', field: 'collectionCode', slots: {default: 'collection'}},
                        {resizable: true, title: '发票', field: 'invoiceInfo', slots: {default: 'invoice'}},
                        {resizable: true, title: '付款方式', field: 'paymentMethod', slots: {default: 'paymentMethod'}},
                        {resizable: true, title: '备注', field: 'remark'}
                    ],
                    showFooter: true,
                    footerRowClassName: 'footer-row',
                    footerMethod({columns, data}) {

                        return [
                            columns.map((column, columnIndex) => {
                                if (columnIndex === 0) {
                                    return '合计：'
                                }

                                if (['amount'].includes(column.property)) {

                                    let count = 0
                                    let relief = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    data.forEach(item => {
                                        relief += Number(item['reliefAmount'])
                                    })
                                    return formatMoney((count - relief).toFixed(2))
                                }

                                if (['receivedAmount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {

                                        if (item.collectionCode != null) {
                                            count += Number(item[column.property])
                                        }
                                    })
                                    return formatMoney(count.toFixed(2))
                                }

                                if (['unpaidAmount'].includes(column.property)) {

                                    let count = 0
                                    let relief = 0
                                    let received = 0
                                    data.forEach(item => {
                                        count += Number(item['amount'])
                                    })
                                    data.forEach(item => {
                                        relief += Number(item['reliefAmount'])
                                    })
                                    data.forEach(item => {
                                        if (item.collectionCode != null) {
                                            received += Number(item['receivedAmount'])
                                        }
                                    })
                                    return formatMoney((count - relief - received).toFixed(2))
                                }

                                return ''
                            }),
                        ]
                    }
                }
            }
        },
        methods: {
            FormatReceivableAmount({cellValue, row, column}) {

                return formatMoney(row.amount - row.reliefAmount)
            },
            FormatUnpaidAmount({cellValue, row, column}) {

                var unpaidAmount = 0

                if (row.collectionCode == null) {
                    unpaidAmount = row.amount - row.reliefAmount
                } else {
                    unpaidAmount =  (row.amount - row.reliefAmount - row.receivedAmount).toFixed(2)
                }

                return formatMoney(unpaidAmount)
            },
            FormatMoney({cellValue, row, column}) {

                if (row.collectionCode == null) {
                    return ''
                } else {
                    return formatMoney(cellValue)
                }
            },
            cellClick({row}) {
                this.drawerTitle = row.billMonth + " " + row.clientName + " " + row.u8fee
                this.billId = row.id
                this.drawer = true
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'export-plus':
                        this.$refs.xTable.openExport()
                        break
                }
            },
            PreviewList(picList) {
                var list = []
                for (var i = 0;i < picList.length;i++) {
                    list.push('http://www.dao-valley.cn:8022/A_WuyeCollection/' + this.clientId + '/' + picList[i])
                }
                return list
            }
        },
        async created() {
            this.loading = true
            await GetClientWuyeBillLists(this.clientId).then((res) => {
                this.bills = res
            })
            await GetWuyeCollectionRecords(this.clientId).then((res) => {
                res.forEach(function (item) {
                    item.pictures = item.pictures.split('|')
                })
                this.recordList = res
            })

            this.loading = false
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
        white-space: pre-line
    }

    .collectionWuyeClientBillTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .collectionWuyeClientBillTable >>> .font-blue {
        color: #45aaf2;
    }

    .collectionWuyeClientBillTable >>> .font-red {
        color: #f10716;
    }

    .collectionWuyeClientBillTable >>> .footer-row {
        background-color: #F8F8F9;
    }

</style>
