<template>

    <div class="container" v-loading="loading">

        <el-tabs v-model="tabName">
            <el-tab-pane label="新增账单" name="first">

                <el-form ref="addWuyeBillForm" :model="form" label-width="80px" size="medium">

                    <el-form-item label="所属项目">
                        <el-input v-model="projName" disabled></el-input>
                    </el-form-item>

                    <el-form-item label="账单月份">
                        <el-input v-model="billMonth" disabled></el-input>
                    </el-form-item>

                    <el-form-item label="账单类型">
                        <el-select v-model="form.inventory" filterable @change="InventoryChange"
                                   placeholder="请选择账单类型" style="width: 100%">
                            <el-option
                                    v-for="item in inventories"
                                    :key="item.cInvCode"
                                    :label="item.cInvName"
                                    :value="item.cInvCode">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>

                <!-- 放表单组件
                        1、物业费、能耗费 组件
                        2、电费、水费 组件
                        3、车位费 组件
                        4、其他费用 组件
                     -->
                <div v-if="form.inventory != null && form.inventory != '' ">

                    <PropertyBillAddForm v-if="formShow && (form.inventory === '01001' || form.inventory === '01003')"
                                         :projName="projName" :inventory="form.inventory" :billMonth="billMonth"
                                         @PropertyBillAddFormClose="ImportWuyeBillClose">
                    </PropertyBillAddForm>

                    <EnergyBillAddForm v-if="formShow && form.inventory === '01004'"
                                       :projName="projName" :inventory="form.inventory" :billMonth="billMonth"
                                       @EnergyBillAddFormClose="ImportWuyeBillClose">
                    </EnergyBillAddForm>

                    <ParkBillAddForm v-if="formShow && form.inventory === '01005' "
                                     :projName="projName" :inventory="form.inventory" :billMonth="billMonth"
                                     @ParkBillAddFormClose="ImportWuyeBillClose">
                    </ParkBillAddForm>

                    <OtherBillAddForm
                            v-if="formShow && form.inventory != '01001' && form.inventory != '01003' && form.inventory != '01002' && form.inventory != '01004' && form.inventory != '01005'"
                            :projName="projName" :inventory="form.inventory" :billMonth="billMonth"
                            @OtherBillAddFormClose="ImportWuyeBillClose">
                    </OtherBillAddForm>

                    <ElecBillAddForm v-if="formShow && form.inventory === '01002'"
                                     :projName="projName" :inventory="form.inventory" :billMonth="billMonth"
                                     @ElecBillAddFormClose="ImportWuyeBillClose">
                    </ElecBillAddForm>


                </div>

            </el-tab-pane>

            <el-tab-pane label="批量导入" name="second">

                <el-form label-width="80px" size="medium">

                    <el-form-item label="所属项目">
                        <el-input v-model="projName" disabled></el-input>
                    </el-form-item>

                    <el-form-item label="账单月份">
                        <el-input v-model="billMonth" disabled></el-input>
                    </el-form-item>

                    <el-form-item label="账单类型">
                        <el-select v-model="type"
                                   placeholder="选择账单类别" style="width: 100%">
                            <el-option :value="1" label="物业费+公共能耗费"></el-option>
                            <el-option :value="2" label="水费"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>

                <ImportPropertyBill v-if="type == 1" :projName="projName" :billMonth="billMonth"
                                    @ImportWuyeBillClose="ImportWuyeBillClose"></ImportPropertyBill>

                <ImportWaterBill v-if="type == 2" :projName="projName" :billMonth="billMonth"
                                 @ImportWuyeBillClose="ImportWuyeBillClose"></ImportWaterBill>

            </el-tab-pane>
        </el-tabs>


    </div>

</template>

<script>
    import ImportPropertyBill from "./ImportPropertyBill"
    import ImportWaterBill from "./ImportWaterBill"
    import EnergyBillAddForm from "./WuyeBillAddForm/EnergyBillAddForm"
    import OtherBillAddForm from "./WuyeBillAddForm/OtherBillAddForm"
    import ParkBillAddForm from "./WuyeBillAddForm/ParkBillAddForm"
    import PropertyBillAddForm from "./WuyeBillAddForm/PropertyBillAddForm"
    import ElecBillAddForm from "./WuyeBillAddForm/ElecBillAddForm"
    import {GetAllWuyeClients, GetWuyeInventories} from "../../api";

    export default {
        name: "WuyeBillAdd",
        components: {
            ImportPropertyBill,
            ImportWaterBill,
            EnergyBillAddForm,
            OtherBillAddForm,
            ParkBillAddForm,
            PropertyBillAddForm,
            ElecBillAddForm
        },
        props: {
            projName: {
                type: String,
                required: true
            },
            billMonth: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                tabName: 'first',
                type: null,
                formShow: false,
                clients: [],
                inventories: [],
                form: {
                    inventory: null
                }
            }
        },
        methods: {
            ImportWuyeBillClose() {
                this.$emit('InsertWuyeBill')
            },
            InventoryChange() {
                this.formShow = false
                this.$nextTick(function () {
                    // 加载
                    this.formShow = true
                })
            }
        },
        created() {
            GetWuyeInventories().then((res) => {
                this.inventories = res
            })
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
        white-space: pre-line
    }

</style>
