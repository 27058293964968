<template>

    <div class="side-nav">

        <el-menu :default-active="menuName" ref="sideMenu"
                 style="padding-left: 10px;padding-right: 10px;border: none"
                 class="el-menu-vertical-demo"
                 :collapse="isCollapse"
                 @select="handleSelect">

            <!-- 收起/展开 按钮 -->
            <el-menu-item @click="ChangeCollapse" :style="collapseStyle" index="button">
                <i :class="icon" style="color:#1e272e;"></i>
                <span slot="title">展开/收起</span>
            </el-menu-item>

            <!-- 菜单列表  -->

            <template v-for="item in menuList">
                <!-- 无子菜单 -->
                <el-menu-item
                        v-if="item.subMenus.length === 0"
                        :index="item.index"
                        :key="item.index"
                        :style="item.index==menuName?activeStyle:''">
                    <i :class="item.icon"></i>
                    <span slot="title">{{item.title}}</span>
                </el-menu-item>

                <!-- 有子菜单 -->
                <el-submenu v-if="item.subMenus.length > 0"
                            :index="item.index"
                            :key="item.index">
                    <template slot="title">
                        <i :class="item.icon"></i>
                        <span slot="title">{{item.title}}</span>
                    </template>
                    <el-menu-item v-for="subMenu in item.subMenus"
                                  :key="subMenu.index"
                                  :index="subMenu.index"
                                  :style="subMenu.index==menuName?activeStyle:''">{{subMenu.title}}
                    </el-menu-item>
                </el-submenu>
            </template>

        </el-menu>

    </div>
</template>

<script>
    export default {
        name: "SideNav",
        data() {
            return {
                isCollapse: false,
                icon: 'el-icon-s-fold',
                collapseStyle: {
                    color: '#1e272e',
                    width: '200px'
                },
                activeStyle: {
                    'border-radius': '10px',
                    'box-shadow': 'rgba(0,0,0,.2)  0 1px 5px 0px'
                },
                lastKey: null
            };
        },
        props: {
            moduleName: {
                type: String,
                required: true
            },
            menuName: {
                type: String,
                required: true
            }
        },
        computed: {
            menuList: function () {

                if (this.moduleName === 'commentBtn') {

                } else {
                    this.lastKey = this.moduleName
                }

                switch (this.lastKey) {
                    case 'businessModule':
                        return [
                            {
                                title: '日常汇报',
                                icon: 'el-icon-edit',
                                index: 'dailyReport',
                                subMenus: []
                            },
                            {
                                title: '我的商机',
                                icon: 'el-icon-suitcase',
                                index: 'myBusinessManagement',
                                subMenus: [
                                    {
                                        title: '我的商机',
                                        index: 'myBusiness',
                                    },
                                    {
                                        title: '关键字搜索',
                                        index: 'businessKeySearch',
                                    }
                                ]
                            },
                            {
                                title: '综合管理',
                                icon: 'el-icon-c-scale-to-original',
                                index: 'businessManagement',
                                subMenus: [
                                    {
                                        title: '客户查询',
                                        index: 'clientSearch',
                                    },
                                    {
                                        title: '每日统计',
                                        index: 'dailyCount',
                                    },
                                    {
                                        title: '工作汇总',
                                        index: 'workSummary',
                                    },
                                    {
                                        title: '全部商机',
                                        index: 'allBusiness',
                                    },
                                ]
                            },
                            {
                                title: '公共商机',
                                icon: 'el-icon-suitcase-1',
                                index: 'publicBusiness',
                                subMenus: []
                            }
                        ]
                        break
                    case 'assetsModule':
                        return [
                            {
                                title: '客户管理',
                                icon: 'el-icon-user',
                                index: 'clientManagement',
                                subMenus: [
                                    {
                                        title: '房源客户',
                                        index: 'houseClient',
                                    },
                                    {
                                        title: '其他客户',
                                        index: 'otherClient',
                                    }
                                ]
                            },
                            {
                                title: '房源管理',
                                icon: 'el-icon-office-building',
                                index: 'houseManagement',
                                subMenus: []
                            },
                            {
                                title: '租金管理',
                                icon: 'el-icon-money',
                                index: 'rentalBill',
                                subMenus: [
                                    {
                                        title: '导入账单',
                                        index: 'rentalBillImport',
                                    },
                                    {
                                        title: '租金账单',
                                        index: 'rentalBillManagement',
                                    }
                                ]
                            }
                            // {
                            //     title: '过户情况',
                            //     icon: 'el-icon-document-copy',
                            //     index: 'transferSituation',
                            //     subMenus: []
                            // },
                        ]
                        break
                    case 'elecModule':
                        return [
                            {
                                title: '表计档案',
                                icon: 'el-icon-receiving',
                                index: 'elecMeter',
                                subMenus: []
                            },
                            {
                                title: '抄表记录',
                                icon: 'el-icon-edit-outline',
                                index: 'elecRecord',
                                subMenus: []
                            },
                            {
                                title: '能耗报表',
                                icon: 'el-icon-data-analysis',
                                index: 'elecReport',
                                subMenus: [
                                    {
                                        title: '电能耗报表',
                                        index: 'elecReport',
                                    },
                                    {
                                        title: '内部用电报表',
                                        index: 'internalElecReport',
                                    }
                                ]
                            },
                            {
                                title: '电费管理',
                                icon: 'el-icon-money',
                                index: 'elecBillManagement',
                                subMenus: [
                                    {
                                        title: '电费方案',
                                        index: 'elecBillPlan',
                                    },
                                    {
                                        title: '电费账单',
                                        index: 'elecBill',
                                    }
                                ]
                            },
                        ]
                        break
                    case 'wuyeModule':
                        return [
                            {
                                title: '用户管理',
                                icon: 'el-icon-user',
                                index: 'wuyeUserManagement',
                                subMenus: [
                                    {
                                        title: '用户信息',
                                        index: 'wuyeUserInfo',
                                    },
                                    {
                                        title: '用户认证',
                                        index: 'wuyeUser',
                                    }
                                ]
                            },
                            {
                                title: '电费收款',
                                icon: 'el-icon-wallet',
                                index: 'wuyeElecManagement',
                                subMenus: [
                                    {
                                        title: '线下打款',
                                        index: 'wuyeElecCertificate',
                                    },
                                    {
                                        title: '微信支付',
                                        index: 'wuyeElecPayment',
                                    }
                                ]
                            },
                            {
                                title: '发票管理',
                                icon: 'el-icon-files',
                                index: 'invoiceManagement',
                                subMenus: [
                                    {
                                        title: '电费发票',
                                        index: 'WuyeElecInvoice'
                                    }
                                ]
                            },
                        ]
                        break
                    case 'propertyModule':
                        return [
                            {
                                title: '物业催费',
                                icon: 'el-icon-connection',
                                index: 'wuyeCollection',
                                subMenus: [
                                    {
                                        title: '我负责的',
                                        index: 'myWuyeColletion',
                                    },
                                    {
                                        title: '欠费统计',
                                        index: 'wuyeCollectionStatistics',
                                    }
                                ]
                            },
                            {
                                title: '物业客户',
                                icon: 'el-icon-user',
                                index: 'wuyeClientManagement',
                                subMenus: []
                            },
                            // {
                            //     title: '车位资产',
                            //     icon: 'el-icon-place',
                            //     index: 'parkingManagement',
                            //     subMenus: []
                            // },
                            {
                                title: '微信支付',
                                icon: 'el-icon-money',
                                index: 'wympPayment',
                                subMenus: []
                            },
                            {
                                title: '物业账单',
                                icon: 'el-icon-coin',
                                index: 'wuyeBill',
                                subMenus: [
                                    {
                                        title: '物业账单统计',
                                        index: 'wuyeBillStatistics',
                                    },
                                    {
                                        title: '物业账单明细',
                                        index: 'wuyeBillManagement',
                                    }
                                ]
                            },
                            {
                                title: '数电发票',
                                icon: 'el-icon-postcard',
                                index: 'wuyeInvoice',
                                subMenus: [
                                    {
                                        title: '开票',
                                        index: 'wuyeInvoiceOpen',
                                    },
                                    {
                                        title: '已开发票',
                                        index: 'wuyeInvoiceView',
                                    }
                                ]
                            },
                        ]
                        break
                    case 'wuyeMiniProModule':
                        return [
                            {
                                title: '用户管理',
                                icon: 'el-icon-user',
                                index: 'wuyeBill',
                                subMenus: [
                                    {
                                        title: '用户信息',
                                        index: 'wympUserInfo',
                                    },
                                    {
                                        title: '认证管理',
                                        index: 'wympUserCertification',
                                    }
                                ]
                            },
                            {
                                title: '消息推送',
                                icon: 'el-icon-s-promotion',
                                index: 'wympMsg',
                                subMenus: []
                            },
                            // {
                            //     title: '电子发票',
                            //     icon: 'el-icon-bank-card',
                            //     index: 'wympInvoice',
                            //     subMenus: []
                            // },
                        ]
                        break
                    case 'parkModule':
                        return [
                            {
                                title: '车位订单',
                                icon: 'el-icon-document-copy',
                                index: 'parkOrderManagement',
                                subMenus: []
                            },
                            {
                                title: '发票申请',
                                icon: 'el-icon-reading',
                                index: 'parkInvoiceApplicationManagement',
                                subMenus: []
                            }
                        ]
                        break
                }
            },
        },
        methods: {
            ChangeCollapse() {
                this.isCollapse = !this.isCollapse
                this.icon = this.isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'
                if (this.isCollapse) {
                    this.collapseStyle = {
                        color: '#1e272e'
                    }
                } else {
                    this.collapseStyle = {
                        color: '#1e272e',
                        width: '200px'
                    }
                }

                //重置 侧边栏的 activeIndex，防止 activeIndex='button'
                this.$refs['sideMenu'].activeIndex = this.menuName
            },
            handleSelect(key) {
                if (key === 'button') return
                this.$emit('ChangeMenu', key)
            }
        },
        created() {
            this.lastKey = this.moduleName
        }
    }
</script>

<style scoped>

    .side-nav {
        width: auto;
        height: 100%;
        background-color: #ffffff;
        overFlow-x: hidden
    }

    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 200px;
        max-height: 100%;
        min-height: 100%;
    }

</style>
