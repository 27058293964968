<template>
    <div v-loading="loading">
        <!--搜索表单-->
        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
            <el-collapse-item name="collapseItem" class="form-container">
                <template slot="title">
                    <el-tag type="success" style="width: 100%;padding-left: 15px">
                        <i class="el-icon-search" style="margin-right: 5px"></i>
                        <span>共查询到 {{total}} 条数据</span>
                    </el-tag>
                </template>

                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px" style="padding-left: 15px">

                    <el-form-item label="选择时间:">
                        <el-date-picker style="width: 250px"
                                        v-model="searchObj.timeRange"
                                        type="daterange"
                                        range-separator="至"
                                        value-format="yyyy-MM-dd"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        @change="submit">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="公司名称:">
                        <el-input v-model="searchObj.companyName" placeholder="公司名称" clearable
                                  @change="submit"></el-input>
                    </el-form-item>

                </el-form>
            </el-collapse-item>
        </el-collapse>

        <!-- 表格 -->
        <div :style="tableContainerStyle">
            <vxe-grid ref="xTable"
                      id="dailyReportTable"
                      class="dailyReportTable"
                      v-bind="dailyReportTable"
                      @toolbar-button-click="toolbarButtonClickEvent"
                      @toolbar-tool-click="toolbarToolClickEvent"
                      @cell-click="cellClick"
                      @page-change="PageChange">
            </vxe-grid>
        </div>

        <!-- detail 抽屉-->
        <el-drawer :visible.sync="detailDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="detailDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <DailyReportDetail :id="id"></DailyReportDetail>
        </el-drawer>

        <!-- addDailyReport 抽屉 -->
        <el-drawer :visible.sync="addDrawer" size="50%"
                   :close-on-press-escape="false"
                   :wrapperClosable="false"
                   destroy-on-close>
            <template slot="title">
                <el-alert
                        title="新增拜访记录"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <DailyReportAdd @DailyReportAdd="DailyReportAdd"></DailyReportAdd>
        </el-drawer>

    </div>
</template>

<script>
    import {GetMyDailyReports, GetMyDailyReportsCount} from "../../api";
    import DailyReportDetail from "./DailyReportDetail"
    import DailyReportAdd from "./DailyReportAdd"
    import XEUtils from 'xe-utils'

    export default {
        name: "DailyReportManagement",
        components: {DailyReportDetail, DailyReportAdd},
        data() {
            return {
                loading: false,
                unionid: null,
                detailDrawer: false,
                detailDrawerTitle: '',
                addDrawer: false,
                searchObj: {
                    timeRange: [
                        XEUtils.toDateString(XEUtils.getWhatMonth(Date.now(), -1), 'yyyy-MM-dd'),
                        XEUtils.toDateString(Date.now(), 'yyyy-MM-dd'),
                    ],
                    companyName: ''
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                dailyReports: [],
                id: null,
                currentPage: 1,
                pageSize: 50,
                total: 0
            }
        },
        computed: {
            dailyReportTable() {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'print', name: '打 印', icon: 'vxe-icon--print'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'},
                        ],
                        tools: [
                            {code: 'add', name: '新增拜访', status: 'primary'},
                        ],
                        size: 'medium',
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    printConfig: {
                        modes: ['current'],
                        useStyle: true
                    },
                    pagerConfig: {
                        layouts: ['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Sizes', 'Total'],
                        pageSizes: [10, 20, 50, 100],
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        total: this.total
                    },
                    data: this.dailyReports,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {
                            resizable: true, title: '类型', field: 'kind', formatter: function ({cellValue}) {
                                if (cellValue) {
                                    return '客户'
                                } else {
                                    return '非客户'
                                }
                            }, width: '100'
                        },
                        {resizable: true, title: '拜访时间', field: 'visitTime'},
                        {resizable: true, title: '公司名称', field: 'companyName', className: 'font-blue'},
                        {resizable: true, title: '当前阶段', field: 'currentStage'},
                        {resizable: true, title: '沟通内容', field: 'visitContent'}
                    ]
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        this.$refs.xTable.openExport()
                        break
                    case 'print':
                        this.$refs.xTable.openPrint()
                        break
                    case 'zoom':
                        this.$refs.xTable.zoom()
                        break

                }
            },
            toolbarToolClickEvent({code}) {
                switch (code) {
                    case 'add':
                        this.addDrawer = true
                        break
                }
            },
            cellClick({row}) {
                this.id = row.id
                this.detailDrawerTitle = row.companyName + ' - 拜访详情'
                this.detailDrawer = true
            },
            GetInfo() {
                this.loading = true
                GetMyDailyReportsCount(this.unionid, this.searchObj).then((res) => {
                    this.total = res
                    GetMyDailyReports(this.unionid, this.searchObj, this.pageSize, this.currentPage).then((res) => {
                        this.dailyReports = res
                        this.loading = false
                    })
                })
            },
            submit() {
                this.currentPage = 1
                this.pageSize = 50
                this.GetInfo()
            },
            DailyReportAdd() {
                this.addDrawer = false
                this.GetInfo()
            },
            PageChange({currentPage, pageSize}) {
                this.currentPage = currentPage
                this.pageSize = pageSize
                this.GetInfo()
            },
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')
            this.submit()
        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .dailyReportTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .dailyReportTable >>> .font-blue {
        color: #45aaf2;
    }

</style>
