<template>

    <div>
        <div v-loading="loading">

            <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">

                <el-collapse-item name="collapseItem" class="form-container">
                    <template slot="title">
                        <el-tag type="success" style="width: 100%;padding-left: 15px">
                            <i class="el-icon-search" style="margin-right: 5px"></i>
                            <span>共查询到 {{total}} 条跟进记录</span>
                        </el-tag>
                    </template>

                    <el-form :inline="true"
                             :model="searchObj"
                             class="form"
                             size="small"
                             label-width="72px">

                        <el-form-item label="关键字:">
                            <el-input v-model="searchObj.keyword" placeholder="关键字" clearable
                                      @change="submit"></el-input>
                        </el-form-item>

                    </el-form>

                </el-collapse-item>

            </el-collapse>

            <!-- 表格 -->
            <div :style="tableContainerStyle">


                <vxe-grid ref="businessKeywordTable"
                          id="businessKeywordTable"
                          class="businessKeywordTable"
                          v-bind="businessKeywordTable"
                          @toolbar-button-click="toolbarButtonClickEvent"
                          @cell-click="cellClick"
                          @page-change="PageChange">
                </vxe-grid>

            </div>

            <!-- detail 抽屉 -->
            <el-drawer :visible.sync="detailDrawer" size="50%" destroy-on-close>
                <template slot="title">
                    <el-alert
                            :title="detailDrawerTitle"
                            type="warning"
                            :closable="false">
                    </el-alert>
                </template>
                <BusinessInfo :id="id"></BusinessInfo>
            </el-drawer>

        </div>

    </div>

</template>

<script>
    import {GetBusinessFollowUpByKeyword} from "../../api";
    import BusinessInfo from './BusinessInfo'
    import XEUtils from 'xe-utils'

    export default {
        name: "BusinessKeySearch",
        components:{BusinessInfo},
        data() {
            return {
                unionid: null,
                loading: false,
                searchObj: {
                    keyword: ''
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                list: [],
                currentPage: 1,
                pageSize: 50,
                total: 0,
                id: null,
                detailDrawer: false,
                detailDrawerTitle: ''
            }
        },
        computed: {
            businessKeywordTable() {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'print', name: '打 印', icon: 'vxe-icon--print'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'},
                        ],
                        size: 'medium',
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    printConfig: {
                        modes: ['current'],
                        useStyle: true
                    },
                    pagerConfig: {
                        layouts: ['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Sizes', 'Total'],
                        pageSizes: [10, 20, 50, 100],
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        total: this.total
                    },
                    data: this.list,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '商机名称', field: 'businessName', className: 'font-blue', width: '250'},
                        {resizable: true, title: '跟进内容', field: 'visitContent'},
                        {resizable: true, title: '跟进时间', width: '200',field: 'followUpDate',formatter: function ({cellValue}) {
                                return XEUtils.toDateString(cellValue, 'yyyy-MM-dd HH:mm')
                            }},
                        {
                            resizable: true, width: '100',title: '距今', formatter: function ({row}) {
                                return XEUtils.getDateDiff(new Date(row.followUpDate).getTime(), new Date().getTime()).dd == undefined ?
                                    '' : (XEUtils.getDateDiff(new Date(row.followUpDate).getTime(), new Date().getTime()).time / 86400000).toFixed(0) + ' 天前'
                            }
                        },
                    ]
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            GetInfo() {
                this.loading = true
                GetBusinessFollowUpByKeyword(this.searchObj,this.unionid,this.pageSize,this.currentPage).then((res) => {
                    this.total = res.count
                    this.list = res.list
                    this.loading = false
                })
            },
            submit() {
                this.currentPage = 1
                this.pageSize = 50
                this.GetInfo()
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        this.$refs.businessKeywordTable.openExport()
                        break
                    case 'print':
                        this.$refs.businessKeywordTable.openPrint()
                        break
                    case 'zoom':
                        this.$refs.businessKeywordTable.zoom()
                        break

                }
            },
            PageChange({currentPage, pageSize}) {
                this.currentPage = currentPage
                this.pageSize = pageSize
                this.GetInfo()
            },
            cellClick({row, column}) {
                this.id = row.businessId
                this.detailDrawerTitle = row.businessName + ' - 详情'
                this.detailDrawer = true
            },
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')
            this.submit()
        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .businessKeywordTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .businessKeywordTable >>> .font-blue {
        color: #45aaf2;
    }

</style>
