<template>
    <div>

        <div style="text-align: right" v-show="!replyShow">
            <el-button size="mini" @click="replyShow = true">回复</el-button>
        </div>

        <div style="text-align: right" v-show="replyShow">
            <el-input
                    type="textarea"
                    :autosize="{ minRows: 4}"
                    placeholder="请输入回复"
                    v-model="replyObj.comment" style="margin-bottom: 10px" @input="checkReply">
            </el-input>
            <el-button size="mini" @click="replyShow = false">取消</el-button>
            <el-button size="mini" type="primary" :disabled="!able" @click="submit">回复</el-button>
        </div>

    </div>
</template>

<script>
    import {InsertDailyReportComment} from "../../api";

    export default {
        name: "DailyReportReply",
        data() {
            return {
                replyShow: false,
                replyObj: {
                    unionid: null,
                    dailyReportId: null,
                    replyUnionid: null,
                    kind: 'reply',
                    comment: null
                },
                able: false
            }
        },
        props: {
            replayUnionid: {
                type: String,
                required: true
            },
            id: {
                type: Number,
                required: true
            }
        },
        methods: {
            checkReply() {
                if (this.replyObj.comment != null && String(this.replyObj.comment).trim() != '') {
                    this.able = true
                } else {
                    this.able = false
                }
            },
            submit() {
                InsertDailyReportComment(this.replyObj).then((res) => {
                    /*刷新评论*/
                    this.replyShow = false
                    this.replyObj.comment = null
                    this.$emit('dailyReportReply')
                })
            }
        },
        created() {
            this.replyObj.unionid = sessionStorage.getItem('unionid')
            this.replyObj.dailyReportId = this.id
            this.replyObj.replyUnionid = this.replayUnionid
        }
    }
</script>

<style scoped>

</style>
