<template>

    <div v-loading="loading" v-if="authority">

        <el-steps simple :active="step" finish-status="success">
            <el-step title="选择账单"></el-step>
            <el-step title="检查开票内容"></el-step>
            <el-step title="开票结果"></el-step>
        </el-steps>

        <!-- 步骤1 -->
        <transition name="el-fade-in">
            <div v-show="step == 0" style="padding-top: 15px">
                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px">


                    <el-form-item label="所属项目">
                        <el-select v-model="searchObj.projName" placeholder="所属项目" @change="GetClients">
                            <el-option v-for="project in projects" :key="project.abbreviation"
                                       :value="project.projName"
                                       :label="project.projName"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="选择客户">
                        <el-select v-model="searchObj.clientId" filterable @change="ChangeClient"
                                   placeholder="请选择客户" style="width: 100%">
                            <el-option
                                    v-for="item in clients"
                                    :key="item.clientId"
                                    :label="item.clientName"
                                    :value="item.clientId">
                                <span style="float: left">{{ item.clientName }}</span>
                                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.clientId }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>

                <!-- 表格 -->
                <div :style="tableContainerStyle">
                    <vxe-grid ref="wuyeInvoiceOpenTable"
                              id="wuyeInvoiceOpenTable"
                              class="wuyeInvoiceOpenTable"
                              v-bind="wuyeInvoiceOpenTable"
                              @checkbox-change="CheckboxChange"
                              @checkbox-all="CheckboxChange">

                        <!-- 收款信息、开票信息、支付方式 -->
                        <template #collection="{ row }">
                            <el-tag v-if="row.collectionCode != null" type="success" size="small">已收款</el-tag>
                            <el-tag v-else type="danger" size="small">未收款</el-tag>
                        </template>

                        <template #paymentMethod="{ row }">
                            <el-tag v-if="row.paymentMethod == '1'" type="warning" size="small">江苏稻谷物业管理有限公司苏州分公司
                            </el-tag>
                            <el-tag v-if="row.paymentMethod == '3'" type="warning" size="small">苏州步步高物业管理有限公司</el-tag>
                        </template>
                    </vxe-grid>
                </div>

                <div style="text-align: right">
                    <el-button @click="ToStep1" type="primary" size="medium" style="margin-top: 10px"
                               :disabled="selectIds.length==0?true:false">下一步<i
                            class="el-icon-arrow-right el-icon--right"></i></el-button>
                </div>
            </div>
        </transition>

        <!-- 步骤2 -->
        <transition name="el-fade-in">
            <div v-show="step == 1">

                <div :style="InvoiceStyle">
                    <el-form :model="form" :rules="rules" ref="invoiceOpenForm" size="small" label-position="top">
                        <el-divider content-position="left">推送方式（电话、邮箱同步于小程序用户资料）</el-divider>

                        <el-row :gutter="20">
                            <el-col :span="6">
                                <el-form-item label="推送电话" prop="phone" required>
                                    <el-input v-model="form.phone" clearable placeholder="请输入手机号码"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="推送邮箱" prop="email" required>
                                    <el-input v-model="form.email" clearable placeholder="请输入邮箱"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="小程序推送">
                                    <el-input :value="form.miniPro?'自动推送':'未绑定小程序'" disabled></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-divider content-position="left">开票公司</el-divider>

                        <el-row :gutter="20">
                            <el-col :span="6">
                                <el-form-item label="公司名称">
                                    <el-input :value="form.paymentMethod==='1'?'江苏稻谷物业管理有限公司苏州分公司':'苏州步步高物业管理有限公司'"
                                              disabled></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="发票种类" prop="invoiceLine" required>
                                    <el-select v-model="form.invoiceLine"
                                               placeholder="选择发票种类" style="width: 100%">
                                        <el-option value="bs" label="数电专票(电子)"></el-option>
                                        <el-option value="pc" label="数电普票(电子)"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="先票后款" required>
                                    <el-radio v-model="form.invoiceFirst" :label="false">否</el-radio>
                                    <el-radio v-model="form.invoiceFirst" :label="true">是</el-radio>
                                </el-form-item>
                            </el-col>


                        </el-row>

                        <el-divider content-position="left">开票内容（抬头信息同步于物业客户档案）</el-divider>
                        <el-row :gutter="20">
                            <el-col :span="6">
                                <el-form-item label="购方名称" prop="clientName" required>
                                    <el-input v-model="form.clientName" type="textarea" :rows="1"
                                              resize="none" @change="CheckInvoiceLine"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="购方税号（个人客户填身份证号）" required prop="taxNumber">
                                    <el-input v-model="form.taxNumber" type="textarea" :rows="1"
                                              resize="none"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="开户行">
                                    <el-input v-model="form.bank"
                                              type="textarea" :rows="1" resize="none"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="账号">
                                    <el-input v-model="form.bankAccount"
                                              type="textarea" :rows="1" resize="none"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="地址">
                                    <el-input v-model="form.address"
                                              type="textarea" :rows="1" resize="none"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="电话">
                                    <el-input v-model="form.telephone"
                                              type="textarea" :rows="1" resize="none"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="开票金额">
                                    <el-input :value="FormatMoney(form.totalAmount)" disabled></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="商品明细">
                                    <el-button style="width: 100%" type="info" @click="drawer = true">点击查看明细
                                    </el-button>
                                </el-form-item>
                            </el-col>

                            <el-col :span="24">
                                <el-form-item label="发票备注" prop="content" required>
                                    <el-input v-model="form.content" type="textarea" placeholder="请输入开票内容"
                                              :rows="4" :maxlength="200"
                                              @keydown.enter.native="handleTextareaKeydown"
                                              resize="none">
                                    </el-input>
                                </el-form-item>
                            </el-col>

                        </el-row>
                    </el-form>
                </div>

                <el-row :gutter="20">
                    <el-col :span="12" style="text-align: left">
                        <el-button @click="step--" type="info" size="medium" style="margin-top: 5px"
                                   icon="el-icon-arrow-left">上一步
                        </el-button>
                    </el-col>
                    <el-col :span="12" style="text-align: right">
                        <el-button @click="ToStep2" type="primary" size="medium" style="margin-top: 5px">下一步<i
                                class="el-icon-arrow-right el-icon--right"></i></el-button>
                    </el-col>
                </el-row>


            </div>
        </transition>

        <!-- 步骤3 -->
        <transition name="el-fade-in">
            <div v-show="step > 1" style="padding-top: 150px">
                <!-- 1、开票结果 2、同步账单 3、小程序推送 -->


                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-result :icon="resultInvoice" title="开票结果" :subTitle="resultInvoiceDesc"></el-result>
                    </el-col>
                    <el-col :span="8">
                        <el-result :icon="resultBill" title="账单同步" :subTitle="resultBillDesc"></el-result>
                    </el-col>
                    <el-col :span="8">
                        <el-result :icon="resultMiniPro" title="小程序推送" :subTitle="resultMiniProDesc"></el-result>
                    </el-col>

                    <el-col :span="24" style="text-align: center">
                        <el-button @click="ToStep0" type="primary" size="medium" style="margin-top: 50px">继续开票
                        </el-button>
                    </el-col>
                </el-row>

            </div>
        </transition>

        <!-- 商品明细 -->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        title="商品明细"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <div class="container">
                <vxe-grid ref="wuyeInvoiceDetailTable"
                          id="wuyeInvoiceDetailTable"
                          class="wuyeInvoiceDetailTable"
                          v-bind="wuyeInvoiceDetailTable">
                </vxe-grid>
            </div>
        </el-drawer>

    </div>

    <!-- 无权限 -->
    <div v-else>
        <el-result style="height: -webkit-calc(100vh - 250px)"
                   icon="warning" title="无权限"
                   subTitle="抱歉，无权限查看">
        </el-result>
    </div>





</template>

<script>
    import {
        BackFillInvoiceInfo, GetInvoicableBills,
        GetInvoicableWuyeBills, GetInvoiceClientInfo, GetInvoiceDetail,
        GetProjectsWithoutAll, GetProjNameDetail,
        GetWuyeAuthority,
        GetWuyeClientsWithInvoicableBills, PushInvoiceNotification, RequestShuDianInvoice
    } from "../../api";
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "WuyeInvoiceOpen",
        data() {

            var validatePhone = (rule, value, callback) => {
                if (value === '' || value == null) {
                    callback(new Error('手机号不能为空'));
                } else {
                    var regexPhone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
                    if (!(regexPhone.test(value))) {
                        callback(new Error('手机号格式不正确'));
                    }
                    callback();
                }
            }

            var validateEmail = (rule, value, callback) => {
                if (value === '' || value == null) {
                    callback(new Error('邮箱不能为空'));
                } else {
                    var regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                    if (!(regexEmail.test(value))) {
                        callback(new Error('邮箱格式不正确'));
                    }
                    callback();
                }
            };

            var validateInvoiceLine = (rule, value, callback) => {
                if (value === '' || value == null) {
                    callback(new Error('不能为空'));
                } else {
                    if (this.form.clientName.length <= 4 && value === 'bs') {
                        callback(new Error('个人客户请开普票'));
                    } else {
                        callback();
                    }
                }
            }

            var validateContent = (rule, value, callback) => {
                if (value != null && value.indexOf('null') == -1 && value != '') {
                    if (value.length > 200) {
                        callback(new Error('长度不可超过200个字'));
                    } else {
                        callback();
                    }
                } else {
                    callback(new Error('开票内容有误或为空'));
                }
            };

            return {
                tableContainerStyle: {
                    height: ''
                },
                InvoiceStyle: {
                    height: '',
                    overflow: 'auto',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                },
                step: 0,
                unionid: null,
                authority: true,
                loading: false,
                projects: [],
                projNameDetail: null,
                clients: [],
                bills: [],
                searchObj: {
                    projName: '',
                    clientId: null
                },
                selectIds: [],
                contentArr: [],
                buyerDisabled: true,
                form: {
                    phone: null,
                    email: null,
                    miniPro: false,
                    paymentMethod: '1',
                    invoiceLine: null,
                    clientName: null,
                    taxNumber: null,
                    bank: null,
                    bankAccount: null,
                    address: null,
                    telephone: null,
                    content: null,
                    totalAmount: 0,
                    invoiceFirst: false
                },
                rules: {
                    phone: [
                        {validator: validatePhone, trigger: ['blur', 'change']}
                    ],
                    email: [
                        {validator: validateEmail, trigger: ['blur', 'change']}
                    ],
                    invoiceLine: [
                        {validator: validateInvoiceLine, trigger: ['blur', 'change']}
                    ],
                    content: [
                        {validator: validateContent, trigger: ['blur', 'change']}
                    ],
                    clientName: [
                        {required: true, message: '不能为空', trigger: ['blur', 'change']}
                    ],
                    taxNumber: [
                        {required: true, message: '不能为空', trigger: ['blur', 'change']}
                    ]
                },
                clientInfo: {},
                resultInvoice: 'error',
                resultInvoiceDesc: null,
                resultBill: 'error',
                resultBillDesc: null,
                resultMiniPro: 'error',
                resultMiniProDesc: null,
                drawer: false,
                detailList: []
            }
        },
        computed: {
            wuyeInvoiceOpenTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    rowConfig: {
                        isHover: true
                    },
                    checkboxConfig: {
                        checkMethod: this.checkMethod,
                        highlight: true
                    },
                    data: this.bills,
                    columns: [
                        {type: 'checkbox', field: 'check', title: '', width: 40},
                        {resizable: true, width: 80, title: '月份', field: 'billMonth'},
                        {resizable: true, title: '账单周期', field: 'chargeContent'},
                        {resizable: true, title: '房源', field: 'houseName'},
                        {resizable: true, title: '收费项目', field: 'u8fee'},
                        {
                            resizable: true,
                            title: '实际收款',
                            field: 'receivedAmount',
                            formatter: 'FormatMoney',
                            className: 'font-blue'
                        },
                        {resizable: true, title: '收款', field: 'collectionCode', slots: {default: 'collection'}},
                        {
                            resizable: true,
                            title: '收款公司',
                            field: 'paymentMethod',
                            slots: {default: 'paymentMethod'},
                            width: '250'
                        }
                    ]
                }
            },
            wuyeInvoiceDetailTable: function () {
                return {
                    height: '600',
                    showOverflow: true,
                    size: 'small',
                    data: this.detailList,
                    columns: [
                        {resizable: true, title: '商品名称', field: 'goodsName'},
                        {resizable: true, title: '税收编码', field: 'goodsCode'},
                        {resizable: true, title: '含税金额', field: 'taxIncludedAmount',formatter: 'FormatMoney',},
                        {resizable: true, title: '税率', field: 'taxRate'},
                        {resizable: true, title: '税额', field: 'tax',formatter: 'FormatMoney',},
                        {resizable: true, title: '不含税金额', field: 'taxExcludedAmount',formatter: 'FormatMoney',}
                    ],
                    showFooter: true,
                    footerRowClassName: 'footer-row',
                    footerMethod({columns, data}) {

                        return [
                            columns.map((column, columnIndex) => {
                                if (columnIndex === 0) {
                                    return '合计：'
                                }

                                if (['taxIncludedAmount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return formatMoney(count.toFixed(2))
                                }

                                if (['tax'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return formatMoney(count.toFixed(2))
                                }

                                if (['taxExcludedAmount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return formatMoney(count.toFixed(2))
                                }

                                return ''
                            }),
                        ]
                    }
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            GetClients() {
                this.clients = []
                this.bills = []
                this.searchObj.clientId = null
                this.form.invoiceLine = null
                this.loading = true
                GetWuyeClientsWithInvoicableBills(this.searchObj.projName).then((res) => {
                    this.clients = res
                    GetProjNameDetail(this.searchObj.projName).then((res) => {
                        this.projNameDetail = res
                        this.loading = false
                    })
                })
            },
            ChangeClient() {
                this.selectIds = []
                this.contentArr = []
                this.form.totalAmount = 0
                this.form.phone = null
                this.form.email = null
                this.form.invoiceLine = null
                this.form.clientName = null
                this.form.taxNumber = null
                this.form.bank = null
                this.form.bankAccount = null
                this.form.address = null
                this.form.telephone = null
                this.buyerDisabled = true
                this.loading = true
                GetInvoicableBills(this.searchObj.clientId,this.unionid).then((res) => {
                    this.bills = res
                    this.loading = false
                })
            },
            CheckboxChange() {
                this.selectIds = []
                this.contentArr = []
                this.detailList = []
                this.form.totalAmount = 0
                this.form.paymentMethod = null
                this.form.invoiceLine = null
                this.form.invoiceFirst = false
                var a = this.$refs.wuyeInvoiceOpenTable.getCheckboxRecords(true)

                var feeCode = ''

                if (a.length > 0) {
                    if (a.length > 200) {
                        this.selectIds = []
                        this.contentArr = []
                        this.form.totalAmount = 0
                        this.form.paymentMethod = null
                        this.$refs.wuyeInvoiceOpenTable.clearCheckboxRow()
                        this.$message.error('最多不可超过200条账单！');
                        return
                    } else {
                        this.form.paymentMethod = a[0].paymentMethod
                        for (var i = 0; i < a.length; i++) {
                            if (a[0].paymentMethod != a[i].paymentMethod) {
                                this.selectIds = []
                                this.contentArr = []
                                this.form.totalAmount = 0
                                this.form.paymentMethod = null
                                this.form.invoiceLine = null
                                this.form.content = null
                                this.$refs.wuyeInvoiceOpenTable.clearCheckboxRow()
                                this.$message.error('收款公司不一致！');
                                return
                            } else {

                            }

                            if (a[i].collectionCode == null) {
                                this.form.invoiceFirst = true
                            }

                            feeCode = feeCode + ',' + a[i].u8feeCode
                            this.form.totalAmount = this.form.totalAmount + a[i].receivedAmount
                            this.contentArr.push((a[i].u8feeCode == '01005' ? '车位租赁费' : a[i].u8fee) + '：' + a[i].receivedAmount + '元，'
                                + a[i].chargeContent)
                            this.selectIds.push(a[i].id)
                        }
                        this.form.content = this.projNameDetail + this.contentArr.join('；')

                        if (feeCode.indexOf('01005') != -1 && this.selectIds.length > 1) {
                            this.selectIds = []
                            this.contentArr = []
                            this.form.totalAmount = 0
                            this.form.paymentMethod = null
                            this.form.invoiceLine = null
                            this.form.content = null
                            this.$refs.wuyeInvoiceOpenTable.clearCheckboxRow()
                            this.$message.error('车位租赁费须单独开票！');
                        }
                    }
                }
            },
            CheckInvoiceLine() {
                this.$refs.invoiceOpenForm.validateField('invoiceLine');
            },
            ToStep1() {
                this.loading = true
                this.step++
                this.form.phone = null
                this.form.email = null
                this.form.invoiceLine = null
                GetInvoiceClientInfo(this.searchObj.clientId).then((res) => {

                    this.form.clientName = res.clientInfo.clientName === '其他零星客户' ? '' : res.clientInfo.clientName
                    this.form.taxNumber = res.clientInfo.taxNumber
                    this.form.bank = res.clientInfo.bank
                    this.form.bankAccount = res.clientInfo.bankAccount
                    this.form.address = res.clientInfo.address
                    this.form.telephone = res.clientInfo.telephone

                    if (res.userInfo == null) {
                        this.form.miniPro = false
                    } else {
                        this.form.miniPro = true
                        this.form.phone = res.userInfo.phone
                        this.form.email = res.userInfo.email
                    }

                    GetInvoiceDetail(this.selectIds).then((res) => {
                        this.detailList = res
                        this.loading = false
                    })
                })
            },
            ToStep2() {

                this.resultInvoice = 'error'
                this.resultInvoiceDesc = null
                this.resultBill = 'error'
                this.resultBillDesc = null
                this.resultMiniPro = 'error'
                this.resultMiniProDesc = null

                // 开票 表单检查
                this.$refs.invoiceOpenForm.validate((e) => {
                    if (e) {
                        this.$alert('请确认开票内容无误！', '确认开票内容', {
                            confirmButtonText: '开票',
                            cancelButtonText: '取消',
                            cancelButtonClass: 'reject',
                            showCancelButton: true,
                            distinguishCancelAndClose: true,
                            type: 'warning',
                            callback: action => {
                                if (action === 'confirm') {

                                    //1、开票
                                    this.step++
                                    this.loading = true
                                    RequestShuDianInvoice(this.form, this.selectIds).then((res) => {

                                        if (res.code === 'E0000') {
                                            this.resultInvoice = 'success'
                                            this.resultInvoiceDesc = JSON.stringify(res)
                                            this.$message.success('开票成功！');
                                            // 2、同步账单
                                            BackFillInvoiceInfo(this.selectIds, res.result.invoiceSerialNum, this.form.invoiceFirst, this.unionid).then((res) => {
                                                if (res === 1) {
                                                    this.resultBill = 'success'
                                                    this.resultBillDesc = '开票信息同步至账单成功'
                                                    this.$message.success('开票信息同步至账单成功！');
                                                    // 3、微信推送
                                                    if (this.form.miniPro) {
                                                        PushInvoiceNotification(this.searchObj.clientId).then((res) => {
                                                            if (res === 1) {
                                                                this.resultMiniPro = 'success'
                                                                this.resultMiniProDesc = '推送成功'
                                                                this.step++
                                                                this.loading = false
                                                            } else {
                                                                this.resultMiniPro = 'error'
                                                                this.resultMiniProDesc = '推送失败'
                                                                this.step++
                                                                this.loading = false
                                                            }
                                                        })
                                                    } else {
                                                        this.resultMiniPro = 'info'
                                                        this.resultMiniProDesc = '未绑定小程序，无法推送'
                                                        this.loading = false
                                                    }
                                                } else {
                                                    this.$message.error('开票信息同步至账单失败！');
                                                    this.resultBill = 'error'
                                                    this.resultBillDesc = '开票信息同步至账单失败'
                                                    this.loading = false
                                                }
                                            })

                                        } else {
                                            this.resultInvoice = 'error'
                                            this.resultInvoiceDesc = JSON.stringify(res)
                                            this.resultBill = 'error'
                                            this.resultBillDesc = null
                                            this.resultMiniPro = 'error'
                                            this.resultMiniProDesc = null
                                            this.$message.error('开票失败！');
                                            this.loading = false
                                        }


                                    })

                                }
                                if (action === 'cancel') {

                                }
                            }
                        });

                    } else {
                        this.$message.error('有未填项或有内容格式不正确！');
                    }
                })

            },
            ToStep0() {
                this.GetClients()
                this.step = 0
            },
            FormatMoney(value) {
                return formatMoney(value)
            },
            handleTextareaKeydown() {
                const e = window.event || arguments[0]
                if (e.key === 'Enter' || e.code === 'Enter' || e.keyCode === 13) {
                    this.$message.warning('开票内容不可换行！');
                    e.returnValue = false
                    return false
                }
            },
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            this.$alert('开具数电发票前，请先与财务联系进行后台认证，否则将开票失败！', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    GetWuyeAuthority(this.unionid).then((res) => {

                        /* 权限控制 */
                        if (res.authorities.indexOf('invoiceOpen') != -1) {
                            this.authority = true
                        } else {
                            this.authority = false
                        }

                        this.searchObj.projName = '稻谷国际中心'
                        if (res.projName === 'ALL') {
                            GetProjectsWithoutAll().then((res) => {
                                this.projects = res
                            })
                        } else {
                            this.projects = []
                            var arr = res.projName.split(',')

                            this.searchObj.projName = arr[0]

                            for (var i = 0; i <= arr.length - 1; i++) {
                                this.projects.push({
                                    projName: arr[i]
                                })
                            }
                        }

                        this.GetClients()
                    })
                }
            });


        },
        mounted() {
            this.$nextTick(function () {

                if (this.authority) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (320) + 'px)'
                    this.InvoiceStyle.height = '-webkit-calc(100vh - ' + (250) + 'px)'
                }

            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .wuyeInvoiceOpenTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .wuyeInvoiceDetailTable >>> .footer-row {
        background-color: #F8F8F9;
    }

</style>
