<template>

    <div>

        <!--        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">-->
        <!--            <el-collapse-item name="collapseItem" class="form-container">-->
        <!--                <template slot="title">-->
        <!--                    <el-tag type="success" style="width: 100%;padding-left: 15px">-->
        <!--                        <i class="el-icon-search" style="margin-right: 5px"></i>-->
        <!--                        <span>共查询到 {{this.certificationObjs.length}} 条记录</span>-->
        <!--                    </el-tag>-->
        <!--                </template>-->

        <!--                <el-form :inline="true"-->
        <!--                         :model="searchObj"-->
        <!--                         class="form"-->
        <!--                         size="small"-->
        <!--                         label-width="72px">-->

        <!--                    <el-form-item>-->
        <!--                        <el-radio-group v-model="searchObj.type" size="small" @change="submit">-->
        <!--                            <el-radio-button label="待审核"></el-radio-button>-->
        <!--                            <el-radio-button label="已通过"></el-radio-button>-->
        <!--                            <el-radio-button label="失效"></el-radio-button>-->
        <!--                        </el-radio-group>-->
        <!--                    </el-form-item>-->

        <!--                    <el-form-item label="所属项目:" style="margin-left: 10px">-->
        <!--                        <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">-->
        <!--                            <el-option v-for="project in projects" :key="project.abbreviation"-->
        <!--                                       :value="project.abbreviation"-->
        <!--                                       :label="project.projName"></el-option>-->
        <!--                        </el-select>-->
        <!--                    </el-form-item>-->

        <!--                    <el-form-item label="认证公司:">-->
        <!--                        <el-input v-model="searchObj.clientName" placeholder="公司名称" clearable @change="submit"></el-input>-->
        <!--                    </el-form-item>-->

        <!--                    <el-form-item label="用户姓名:">-->
        <!--                        <el-input v-model="searchObj.name" placeholder="用户姓名" clearable @change="submit"></el-input>-->
        <!--                    </el-form-item>-->

        <!--                </el-form>-->
        <!--            </el-collapse-item>-->
        <!--        </el-collapse>-->


        <!--        &lt;!&ndash; 表格 &ndash;&gt;-->
        <!--        <div :style="tableContainerStyle">-->
        <!--            <vxe-grid ref="xTable"-->
        <!--                      id="wuyeUserTable"-->
        <!--                      class="wuyeUserTable"-->
        <!--                      v-bind="wuyeUserTable"-->
        <!--                      @toolbar-button-click="toolbarButtonClickEvent">-->

        <!--                <template #operate="{ row }">-->
        <!--                    <el-button type="primary" size="mini" plain @click="btnClick(row)" :disabled="!authority">审核</el-button>-->
        <!--                </template>-->

        <!--            </vxe-grid>-->
        <!--        </div>-->

        <el-button type="primary" size="mini" plain @click="btnClick()">审核</el-button>

    </div>


</template>

<script>
    import {
        GetAuthority,
        GetProjects,
        GetProjectsWithoutAll,
        GetWuyeCertificationObjs, InsertOperationRecord,
        UpdateWuyeUser
    } from "../../api";

    export default {
        name: "WuyeUser",
        data() {
            return {
                unionid: null,
                projects: [],
                searchObj: {
                    type: '待审核',
                    projName: 'ALL',
                    name: '',
                    clientName: ''
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                certificationObjs: [],
                columns: [
                    {resizable: true, title: '时间', field: 'time'},
                    {resizable: true, title: '姓名', field: 'name'},
                    {resizable: true, title: '联系电话', field: 'phone'},
                    {resizable: true, title: '项目', field: 'projName'},
                    {resizable: true, title: '客户编号', field: 'clientId'},
                    {resizable: true, title: '客户名称', field: 'clientName'},
                    {resizable: true, title: '微信绑定手机号', field: 'telephone'},
                    {title: '操作', fixed: 'right', width: 100, slots: {default: 'operate'}}
                ],
                authority: false
            }
        },
        computed: {
            /*表格实例对象*/
            wuyeUserTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                        ],
                        size: 'medium',
                        custom: true
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    data: this.certificationObjs,
                    columns: this.columns
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            submit() {
                var selectedType = 1
                switch (this.searchObj.type) {
                    case '待审核':
                        selectedType = 1
                        this.columns = [
                            {resizable: true, title: '时间', field: 'time'},
                            {resizable: true, title: '姓名', field: 'name'},
                            {resizable: true, title: '联系电话', field: 'phone'},
                            {resizable: true, title: '项目', field: 'projName'},
                            {resizable: true, title: '客户编号', field: 'clientId'},
                            {resizable: true, title: '客户名称', field: 'clientName'},
                            {resizable: true, title: '微信绑定手机号', field: 'telephone'},
                            {title: '操作', fixed: 'right', width: 100, slots: {default: 'operate'}}
                        ]
                        break
                    case '已通过':
                        selectedType = 2
                        this.columns = [
                            {resizable: true, title: '时间', field: 'time'},
                            {resizable: true, title: '姓名', field: 'name'},
                            {resizable: true, title: '联系电话', field: 'phone'},
                            {resizable: true, title: '项目', field: 'projName'},
                            {resizable: true, title: '客户编号', field: 'clientId'},
                            {resizable: true, title: '客户名称', field: 'clientName'},
                            {resizable: true, title: '微信绑定手机号', field: 'telephone'}
                        ]
                        break
                    case '失效':
                        selectedType = 3
                        this.columns = [
                            {resizable: true, title: '时间', field: 'time'},
                            {resizable: true, title: '姓名', field: 'name'},
                            {resizable: true, title: '联系电话', field: 'phone'},
                            {resizable: true, title: '项目', field: 'projName'},
                            {resizable: true, title: '客户编号', field: 'clientId'},
                            {resizable: true, title: '客户名称', field: 'clientName'},
                            {resizable: true, title: '微信绑定手机号', field: 'telephone'}
                        ]
                        break
                }

                GetWuyeCertificationObjs(this.searchObj, selectedType).then((res) => {
                    this.certificationObjs = res
                })

            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                }
            },
            btnClick(row) {

                this.$alert('是否通过该客户认证？', '客户认证', {
                    confirmButtonText: '确定',
                    cancelButtonText: '拒绝',
                    cancelButtonClass: 'reject',
                    showCancelButton: true,
                    distinguishCancelAndClose: true,
                    callback: action => {
                    }
                });

            }
        },
        created() {

            this.unionid = sessionStorage.getItem('unionid')
            GetAuthority('wuyeUserCheck', this.unionid).then((res) => {
                this.authority = res
            })

            GetProjects().then((res) => {
                this.projects = res
            })

            this.submit()

        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .wuyeUserTable >>> .vxe-body--row {
        cursor: pointer;
    }


</style>

