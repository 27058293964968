<template>

    <div v-loading="loading">

        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">

            <el-collapse-item name="collapseItem" class="form-container">
                <template slot="title">
                    <el-tag type="success" style="width: 100%;padding-left: 15px">
                        <i class="el-icon-search" style="margin-right: 5px"></i>
                        <span>共查询到 {{users.length}} 位用户</span>
                    </el-tag>
                </template>

                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px" style="padding-left: 15px">

                    <el-form-item label="用户姓名:">
                        <el-input v-model="searchObj.name" placeholder="用户姓名" clearable @change="submit"></el-input>
                    </el-form-item>

                    <el-form-item label="电话:">
                        <el-input v-model="searchObj.phone" placeholder="电话" clearable @change="submit"></el-input>
                    </el-form-item>

                </el-form>
            </el-collapse-item>

        </el-collapse>

        <!-- 表格 -->
        <div :style="tableContainerStyle">
            <vxe-grid ref="xTable"
                      id="wuyeUserInfoTable"
                      class="wuyeUserInfoTable"
                      v-bind="wuyeUserInfoTable"
                      @toolbar-button-click="toolbarButtonClickEvent"
                      @cell-click="cellClick">
            </vxe-grid>
        </div>

        <!-- 详情 -->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="title"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <WyMpUserDetail :userUnionid="userUnionid"></WyMpUserDetail>
        </el-drawer>


    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {GetWuyeUserInfos} from "../../api";
    import WyMpUserDetail from "./WyMpUserDetail"

    export default {
        name: "WyMpUserInfoManagement",
        components: {WyMpUserDetail},
        data() {
            return {
                loading: false,
                searchObj: {
                    name: '',
                    phone: ''
                },
                users: [],
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                drawer: false,
                userUnionid: null,
                title: ''
            }
        },
        computed: {
            wuyeUserInfoTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'}
                        ],
                        size: 'medium',
                        custom: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    data: this.users,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {
                            resizable: true, title: '创建时间', field: 'time', formatter: function ({cellValue}) {
                                return XEUtils.toDateString(cellValue, 'yyyy-MM-dd HH:ss:mm')
                            }
                        },
                        {resizable: true, title: '姓名', field: 'name', className: 'font-blue'},
                        {resizable: true, title: '联系电话', field: 'phone'},
                        {resizable: true, title: '邮箱', field: 'email'},
                        {resizable: true, title: '绑定客户数', field: 'boundClients'},
                    ]
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            submit() {
                this.loading = true
                GetWuyeUserInfos(this.searchObj.name, this.searchObj.phone).then((res) => {
                    this.users = res
                    this.loading = false
                })
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                }
            },
            cellClick({row}) {
                this.title = row.name + ' - 用户详情'
                this.userUnionid = row.unionid
                this.drawer = true
            }
        },
        created() {
            this.submit()
        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .wuyeUserInfoTable >>> .font-blue {
        color: #45aaf2;
    }

    .wuyeUserInfoTable >>> .vxe-body--row {
        cursor: pointer;
    }

</style>
