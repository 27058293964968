<template>

    <div>

        <FolderFormTable>

            <!--查询表单-->
            <el-form slot="leftContent" ref="form" :model="searchObj" label-width="80px" size="small">

                <el-form-item label="查询月份">
                    <el-date-picker style="width: 215px" @change="intiSubmit" :clearable="false"
                                    v-model="searchObj.month"
                                    value-format="yyyy-MM"
                                    type="month" :picker-options="pickerOptions" placeholder="选择月">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="查询项目">
                    <el-select v-model="searchObj.projName" placeholder="所属项目" @change="intiSubmit"
                               style="width: 215px">
                        <el-option v-for="project in projects" :key="project.abbreviation"
                                   :value="project.abbreviation"
                                   :label="project.projName"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="查询类别">
                    <el-select v-model="searchObj.kind" placeholder="查询类别" @change="intiSubmit" style="width: 215px">
                        <el-option v-for="kind in kinds" :key="kind.value"
                                   :value="kind.value"
                                   :label="kind.label"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="表计编号">
                    <el-input v-model="searchObj.meterId" clearable style="width: 215px" @change="submit"></el-input>
                </el-form-item>

                <el-form-item label="表计名称">
                    <el-input v-model="searchObj.meterName" clearable style="width: 215px" @change="submit"></el-input>
                </el-form-item>

                <el-form-item style="text-align: right;padding-right: 28px">
                    <el-button size="small" plain @click="intiSubmit">重置</el-button>
                    <el-button size="small" type="primary" @click="submit">搜索</el-button>
                </el-form-item>
            </el-form>

            <!--表格-->
            <div slot="rightContent">

                <el-tag type="success" style="width: 100%;margin-bottom: 10px">
                    <i class="el-icon-search" style="margin-right: 5px"></i>
                    <span>{{searchTitle}} - 共查询到 {{elecRecords.length}} 条数据</span>
                </el-tag>

                <div style="height: -webkit-calc(100vh - 205px)">
                    <vxe-grid
                            ref="xTable"
                            :id="tableId"
                            class="recordTable"
                            v-bind="recordTable"
                            @toolbar-button-click="toolbarButtonClickEvent"
                            @cell-click="cellClick">
                    </vxe-grid>
                </div>

            </div>

        </FolderFormTable>

        <!--抽屉-->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <ElecRecordDetail :elecId="elecId" :month="searchObj.month"
                              :projName="searchObj.projName"></ElecRecordDetail>
        </el-drawer>

    </div>

</template>

<script>
    import FolderFormTable from "../utils/FolderFormTable";
    import ElecRecordDetail from "./ElecRecordDetail";
    import {
        GetElecRecords,
        GetProjects,
        GetSelfUseMeterRecords,
        GetSharedMeterRecords,
        GetSumMeterRecords
    } from "../../api";
    import XEUtils from 'xe-utils'

    export default {
        name: "ElecRecordManagement",
        components: {FolderFormTable, ElecRecordDetail},
        data() {
            return {
                drawer: false,
                drawerTitle: '',
                searchTitle: '基础能耗数据',
                projects: [],
                elecRecords: [],
                tableId: 'ElecRecords',
                meterAttributes: [
                    {label: '总分表计', value: '总分表计'},
                    {label: '公摊表计', value: '公摊表计'},
                    {label: '自用表计', value: '自用表计'}
                ],
                kinds: [
                    {label: '基础能耗数据', value: 'ElecRecords'},
                    {label: '总分表查询', value: 'ElecRecord_SumMeter'},
                    {label: '公摊表查询', value: 'ElecRecord_SharedMeter'},
                    {label: '自用表查询', value: 'ElecRecord_SelfUseMeter'}
                ],
                searchObj: {
                    month: XEUtils.toDateString(XEUtils.getWhatMonth(new Date(), -1), 'yyyy-MM'),
                    projName: 'GJZX',
                    kind: 'ElecRecords',
                    meterId: '',
                    meterName: ''
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > XEUtils.getWhatMonth(new Date(), -1);
                    },
                },
                columnArr: [],
                elecId: null
            }
        },
        computed: {
            recordTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'print', name: '打 印', icon: 'vxe-icon--print'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'},
                        ],
                        size: 'medium',
                        custom: true
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    rowClassName: this.rowStyle,
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    printConfig: {
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.elecRecords,
                    columns: this.columnArr
                }
            }
        },
        methods: {
            MeterAttributeFilter({value, row, column}) {
                return row.meterAttribute == value
            },
            intiSubmit() {

                if (this.searchObj.month == null) {
                    this.searchObj.month = XEUtils.toDateString(XEUtils.getWhatMonth(new Date(), -1), 'yyyy-MM')
                }

                this.searchObj.meterId = ''
                this.searchObj.meterName = ''
                this.TypeSearch(this.searchObj.kind)

            },
            submit() {
                this.TypeSearch(this.searchObj.kind)
            },
            TypeSearch(value) {
                this.createColumn(value)
                this.resetTable()
            },
            createColumn(value) {
                switch (value) {
                    case 'ElecRecords':
                        this.tableId = 'ElecRecords'
                        this.searchTitle = '基础能耗数据'
                        GetElecRecords(this.searchObj).then((res) => {
                            this.elecRecords = res
                            this.columnArr = [
                                {resizable: true, title: '序号', type: 'seq', width: '60'},
                                {resizable: true, title: '用量周期', field: 'elecCycle'},
                                {resizable: true, title: '表计名称', field: 'meterName'},
                                {resizable: true, title: '表计编号', field: 'meterId'},
                                {
                                    resizable: true, title: '表计属性', field: 'meterAttribute',
                                    filters: this.meterAttributes,
                                    filterMethod: this.MeterAttributeFilter
                                },
                                {resizable: true, title: '上次行度', field: 'lastDegree', formatter: 'FormatNum'},
                                {resizable: true, title: '本次行度', field: 'latestDegree', formatter: 'FormatNum'},
                                {resizable: true, title: '倍率', field: 'magnification', formatter: 'FormatNum'},
                                {
                                    resizable: true,
                                    title: '用量',
                                    field: 'elecUsage',
                                    formatter: 'FormatNum',
                                    sortable: true,
                                    sortBy: 'elecUsage'
                                },
                            ]
                        })
                        break
                    case 'ElecRecord_SumMeter':
                        this.tableId = 'ElecRecord_SumMeter'
                        this.searchTitle = '总分表'
                        GetSumMeterRecords(this.searchObj).then((res) => {
                            this.elecRecords = res
                            this.columnArr = [
                                {resizable: true, title: '序号', type: 'seq', width: '60'},
                                {resizable: true, title: '表计名称', field: 'meterName'},
                                {resizable: true, title: '表计编号', field: 'meterId'},
                                {resizable: true, title: '用量', field: 'elecUsage', formatter: 'FormatNum'},
                                {resizable: true, title: '子表数量', field: 'childMeterCount'},
                                {resizable: true, title: '子表用量和', field: 'childMeterUsageSum', formatter: 'FormatNum'},
                                {resizable: true, title: '用量差', field: 'lossUsage', formatter: 'FormatNum'},
                                {
                                    resizable: true,
                                    title: '损耗率',
                                    field: 'lossRate',
                                    formatter: 'FormatPercent',
                                    sortable: true,
                                    sortBy: 'lossRate'
                                },
                            ]
                        })
                        break
                    case 'ElecRecord_SharedMeter':
                        this.tableId = 'ElecRecord_SharedMeter'
                        this.searchTitle = '公摊表'
                        GetSharedMeterRecords(this.searchObj).then((res) => {
                            this.elecRecords = res
                            this.columnArr = [
                                {resizable: true, title: '序号', type: 'seq', width: '60'},
                                {resizable: true, title: '表计名称', field: 'meterName'},
                                {resizable: true, title: '表计编号', field: 'meterId'},
                                {
                                    resizable: true,
                                    title: '用量',
                                    field: 'elecUsage',
                                    formatter: 'FormatNum',
                                    sortable: true,
                                    sortBy: 'elecUsage'
                                },
                                {resizable: true, title: '备注', field: 'remark'},
                                {resizable: true, title: '电表负载', field: 'meterLoad'},
                            ]
                        })
                        break
                    case 'ElecRecord_SelfUseMeter':
                        this.tableId = 'ElecRecord_SelfUseMeter'
                        this.searchTitle = '自用表'
                        GetSelfUseMeterRecords(this.searchObj).then((res) => {
                            this.elecRecords = res
                            this.columnArr = [
                                {resizable: true, title: '序号', type: 'seq', width: '60'},
                                {resizable: true, title: '房源', field: 'houseName'},
                                {resizable: true, title: '房源状态', field: 'houseState'},
                                {resizable: true, title: '客户名称', field: 'clientName'},
                                {resizable: true, title: '表计编号', field: 'meterId'},
                                {resizable: true, title: '表计名称', field: 'meterName'},
                                {resizable: true, title: '用量周期', field: 'elecCycle'},
                                {resizable: true, title: '上次行度', field: 'lastDegree', formatter: 'FormatNum'},
                                {resizable: true, title: '本次行度', field: 'latestDegree', formatter: 'FormatNum'},
                                {resizable: true, title: '倍率', field: 'magnification', formatter: 'FormatNum'},
                                {
                                    resizable: true,
                                    title: '用量',
                                    field: 'elecUsage',
                                    formatter: 'FormatNum',
                                    sortable: true,
                                    sortBy: 'elecUsage'
                                },
                            ]
                        })
                        break
                }
            },
            resetTable() {
                this.$refs.xTable.resetColumn({
                    resizable: true
                })

                this.$refs.xTable.refreshScroll()
            },
            rowStyle({row}) {
                if (this.searchObj.kind === 'ElecRecord_SumMeter') {

                    if (row.lossRate < 0 || row.lossRate > 0.1 || (row.elecUsgae === 0 && row.lossUsage != 0)) {
                        return 'row-red'
                    } else {
                        return ''
                    }

                } else {
                    return ''
                }
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.TypeSearch(this.searchObj.kind)
                        break
                    case 'export-plus':
                        this.$refs.xTable.openExport()
                        break
                    case 'print':
                        this.$refs.xTable.openPrint()
                        break
                    case 'zoom':
                        this.$refs.xTable.zoom()
                        break

                }
            },
            cellClick({row}) {
                this.drawerTitle = row.meterAttribute + ' - ' + row.meterName + ' - ' + row.meterId
                this.elecId = row.elecId
                this.drawer = true
            }
        },
        created() {
            GetProjects().then((res) => {
                this.projects = res
            })

            this.createColumn(this.searchObj.kind)
        }

    }
</script>

<style scoped>

    .recordTable >>> .row-red {
        background-color: #fcece7;
    }

    .recordTable >>> .vxe-body--row {
        cursor: pointer;
    }


</style>
