<template>
    <div class="container">

        <div style="text-align: right">
            <el-button type="text" @click="openForm">
                <i class="el-icon-circle-plus-outline"></i> 新增入驻
            </el-button>
        </div>

        <el-tabs value="nowHouse">
            <el-tab-pane label="当前房源" name="nowHouse">
                <div style="height: -webkit-calc(100vh - 200px)">
                    <vxe-grid class="table" v-bind="nowHousesTable" @cell-click="cellClick"></vxe-grid>
                </div>
            </el-tab-pane>
            <el-tab-pane label="未来房源" name="futureHouse">
                <div style="height: -webkit-calc(100vh - 200px)">
                    <vxe-grid class="table" v-bind="futureHousesTable" @cell-click="cellClick"></vxe-grid>
                </div>
            </el-tab-pane>
            <el-tab-pane label="历史房源" name="historyHouse">
                <div style="height: -webkit-calc(100vh - 200px)">
                    <vxe-grid class="table" v-bind="historyHousesTable" @cell-click="cellClick"></vxe-grid>
                </div>
            </el-tab-pane>
        </el-tabs>

        <el-drawer :visible.sync="drawer" size="45%"
                   destroy-on-close
                   :append-to-body="true"
                   :close-on-press-escape="false"
                   :wrapperClosable="false">
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="success"
                        :closable="false">
                </el-alert>
            </template>
            <HouseClientForm :houseClientInfo="clickObj" :showForm="showForm" @UpdateInfo="UpdateInfo"
                             :clientId="clientId"></HouseClientForm>
        </el-drawer>

    </div>
</template>

<script>
    import {GetNowHouses, GetFutureHouses, GetHistoryHouses} from "../../api";
    import HouseClientForm from "./HouseClientForm";

    export default {
        name: "HouseClientInfo",
        components: {HouseClientForm},
        data() {
            return {
                drawer: false,
                drawerTitle: null,
                showForm: false,
                nowHouses: [],
                futureHouses: [],
                historyHouses: [],
                clickObj: null
            }
        },
        props: {
            clientId: {
                type: String,
                required: true
            },
        },
        methods: {
            updateInfo() {
                GetNowHouses(this.clientId).then((res) => {
                    this.nowHouses = res
                })

                GetFutureHouses(this.clientId).then((res) => {
                    this.futureHouses = res
                })

                GetHistoryHouses(this.clientId).then((res) => {
                    this.historyHouses = res
                })
            },
            openForm() {
                this.showForm = true
                this.clickObj = null
                this.drawerTitle = '新增入驻'
                this.drawer = true
            },
            cellClick({row}) {
                this.showForm = false
                this.clickObj = row
                this.drawerTitle = row.clientName + ' - ' + row.houseName
                this.drawer = true
            },
            UpdateInfo() {
                this.drawer = false
                this.updateInfo()
            }
        },
        computed: {
            nowHousesTable: function () {
                return {
                    showOverflow: true,
                    border: true,
                    size: 'small',
                    height: 'auto',
                    align: 'center',
                    data: this.nowHouses,
                    rowConfig: {
                        isHover: true
                    },
                    columns: [
                        {resizable: true, title: '房源ID', field: 'houseId'},
                        {resizable: true, title: '房源', field: 'houseName'},
                        {resizable: true, title: '客户属性', field: 'clientAttribute'},
                        {resizable: true, title: '房源状态', field: 'houseState'},
                        {resizable: true, title: '合同编号', field: 'contractId'},
                        {
                            resizable: true, title: '合同时间', children: [
                                {resizable: true, title: '开始时间', field: 'contractStartDate'},
                                {resizable: true, title: '结束时间', field: 'contractEndDate'},
                            ]
                        }
                    ]
                }
            },
            futureHousesTable: function () {
                return {
                    showOverflow: true,
                    border: true,
                    size: 'small',
                    height: 'auto',
                    align: 'center',
                    data: this.futureHouses,
                    columns: [
                        {resizable: true, title: '房源ID', field: 'houseId'},
                        {resizable: true, title: '房源', field: 'houseName'},
                        {resizable: true, title: '客户属性', field: 'clientAttribute'},
                        {resizable: true, title: '房源状态', field: 'houseState'},
                        {resizable: true, title: '合同编号', field: 'contractId'},
                        {
                            resizable: true, title: '合同时间', children: [
                                {resizable: true, title: '开始时间', field: 'contractStartDate'},
                                {resizable: true, title: '结束时间', field: 'contractEndDate'},
                            ]
                        }
                    ]
                }
            },
            historyHousesTable: function () {
                return {
                    showOverflow: true,
                    border: true,
                    size: 'small',
                    height: 'auto',
                    align: 'center',
                    data: this.historyHouses,
                    columns: [
                        {resizable: true, title: '房源ID', field: 'houseId'},
                        {resizable: true, title: '房源', field: 'houseName'},
                        {resizable: true, title: '客户属性', field: 'clientAttribute'},
                        {resizable: true, title: '房源状态', field: 'houseState'},
                        {resizable: true, title: '合同编号', field: 'contractId'},
                        {
                            resizable: true, title: '合同时间', children: [
                                {resizable: true, title: '开始时间', field: 'contractStartDate'},
                                {resizable: true, title: '结束时间', field: 'contractEndDate'},
                            ]
                        }
                    ]
                }
            },

        },
        created() {
            this.updateInfo()
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .table >>> .vxe-body--row {
        cursor: pointer;
    }

</style>
