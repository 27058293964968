<template>

    <div class="container">

        <el-form ref="wuyeContractDetailForm" :model="form" :rules="rules" label-width="80px" size="medium">

            <el-form-item label="合同编号" prop="contractNo">
                <el-input v-model="form.contractNo" placeholder="合同编号" disabled></el-input>
            </el-form-item>

            <el-form-item label="房源" required prop="houseIds">
                <el-select v-model="form.houseIds"
                           clearable multiple filterable placeholder="请选择房源" style="width: 100%" disabled>
                    <el-option
                            v-for="item in houses"
                            :key="item.houseId"
                            :label="item.houseName"
                            :value="item.houseId">
                        <span style="float: left">{{ item.houseName }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.building }}</span>
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="签订时间" prop="signDate">
                <el-date-picker style="width: 100%"
                                v-model="form.signDate"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="选择签订时间" disabled>
                </el-date-picker>
            </el-form-item>

            <el-form-item label="开始时间" prop="startDate">
                <el-date-picker style="width: 100%"
                                v-model="form.startDate"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="选择开始时间" disabled>
                </el-date-picker>
            </el-form-item>

            <el-form-item label="结束时间" required prop="endDate">
                <el-date-picker style="width: 100%"
                                v-model="form.endDate"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="选择结束时间">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="备注">
                <el-input
                        type="textarea"
                        :autosize="{ minRows: 4}"
                        placeholder="备注"
                        v-model="form.remark">
                </el-input>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" @click="submit">保 存</el-button>
            </el-form-item>

        </el-form>

    </div>

</template>

<script>
    import {GetHouseInfosByProjName, GetWuyeContractDetail, UpdateWuyeContractDetail} from "../../api";

    export default {
        name: "WuyeContractDetail",
        props: {
            contractNo: {
                type: String,
                required: true
            },
            projName: {
                type: String,
                required: true
            }
        },
        data() {
            const dateCheck2 = (rule, value, callback) => {

                var start = this.form.startDate == null ? null : new Date(this.form.startDate)
                var end = this.form.endDate == null ? null : new Date(this.form.endDate)

                if (!value) {
                    return callback(new Error('开始时间不能为空'))
                }

                if (start != null) {
                    if (start > end) {
                        return callback(new Error('开始时间>结束时间'))
                    } else {
                        return callback()
                    }
                } else {
                    return callback()
                }
            }

            return {
                houses: [],
                form: {
                    contractNo: '',
                    houseIds: [],
                    signDate: null,
                    startDate: null,
                    endDate: null,
                    remark: ''
                },
                rules: {
                    endDate: [
                        {validator: dateCheck2, trigger: 'change'}
                    ]
                }
            }
        },
        methods: {
            submit() {
                this.$refs.wuyeContractDetailForm.validate((e) => {
                    if (e) {

                        UpdateWuyeContractDetail(this.form).then((res) => {
                            if (res === 1) {
                                this.$emit('WuyeContractUpdate')
                            }
                        })

                    } else {
                        this.$message.error('有未填项！');
                    }
                })
            }
        },
        created() {

            GetHouseInfosByProjName(this.projName).then((res) => {
                this.houses = res

                GetWuyeContractDetail(this.contractNo).then((res) => {
                    this.form.contractNo = res.contractNo
                    this.form.houseIds = res.houseIds.split(',').map(i => parseInt(i, 0))
                    this.form.signDate = res.signDate
                    this.form.startDate = res.startDate
                    this.form.endDate = res.endDate
                    this.form.remark = res.remark
                })

            })
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

</style>
