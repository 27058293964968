<template>

    <div>
        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
            <el-collapse-item name="collapseItem" class="form-container">
                <template slot="title">
                    <el-tag type="success" style="width: 100%;padding-left: 15px">
                        <i class="el-icon-search" style="margin-right: 5px"></i>
                        <span>共查询到 {{this.houseClients.length}} 条数据</span>
                    </el-tag>
                </template>

                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px" style="padding-left: 15px">

                    <el-form-item label="所属项目:">
                        <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                            <el-option v-for="project in projects" :key="project.abbreviation"
                                       :value="project.abbreviation"
                                       :label="project.projName"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="客户名称:">
                        <el-input v-model="searchObj.clientName" placeholder="客户名称" clearable
                                  @change="submit"></el-input>
                    </el-form-item>

                </el-form>
            </el-collapse-item>
        </el-collapse>

        <!-- 表格 -->
        <div :style="tableContainerStyle">
            <vxe-grid ref="xTable"
                      id="houseClientTable"
                      class="houseClientTable"
                      v-bind="houseClientTable"
                      @toolbar-button-click="toolbarButtonClickEvent"
                      @cell-click="cellClick">
            </vxe-grid>
        </div>

        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <HouseClientInfo :clientId="clientId"></HouseClientInfo>
        </el-drawer>

    </div>
</template>

<script>
    import {GetHouseClients, GetProjects} from '../../api/index'
    import HouseClientInfo from "./HouseClientInfo";

    export default {
        name: "HouseClientManagement",
        components: {HouseClientInfo},
        data() {
            return {
                drawer: false,
                drawerTitle: null,
                houseClients: [],
                projects: [],
                searchObj: {
                    projName: 'ALL',
                    clientName: ''
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                clientId: null
            }
        },
        computed: {
            /*表格实例对象*/
            houseClientTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    stripe: true,
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'print', name: '打 印', icon: 'vxe-icon--print'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'},
                        ],
                        size: 'medium',
                        custom: true
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    rowClassName: this.rowStyle,
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    printConfig: {
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.houseClients,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '所属项目', field: 'projName'},
                        {resizable: true, title: '客户编号', field: 'clientId'},
                        {resizable: true, title: '客户名称', field: 'clientName'}
                    ]
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            submit() {
                GetHouseClients(this.searchObj).then((res) => {
                    this.houseClients = res
                })
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        GetHouseClients(this.searchObj).then((res) => {
                            this.houseClients = res
                        })
                        break
                    case 'export-plus':
                        this.$refs.xTable.openExport()
                        break
                    case 'print':
                        this.$refs.xTable.openPrint()
                        break
                    case 'zoom':
                        this.$refs.xTable.zoom()
                        break

                }
            },
            cellClick({row}) {
                this.drawerTitle = row.projName + ' - ' + row.clientName
                this.clientId = row.clientId
                this.drawer = true
            },
        },
        created() {

            GetHouseClients(this.searchObj).then((res) => {
                this.houseClients = res
            })

            GetProjects().then((res) => {
                this.projects = res
            })
        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .houseClientTable >>> .vxe-body--row {
        cursor: pointer;
    }


</style>
