<template>

    <div>

        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
            <el-collapse-item name="collapseItem" class="form-container">
                <template slot="title">
                    <el-tag type="success" style="width: 100%;padding-left: 15px">
                        <i class="el-icon-search" style="margin-right: 5px"></i>
                        <span>共查询到 {{total}} 条数据</span>
                    </el-tag>
                </template>

                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px" style="padding-left: 15px">

                    <el-form-item label="商机名称:">
                        <el-input v-model="searchObj.businessName" placeholder="商机名称" clearable @change="submit"></el-input>
                    </el-form-item>

                </el-form>
            </el-collapse-item>
        </el-collapse>

        <div :style="tableContainerStyle" v-loading="loading">
            <vxe-grid
                    ref="publicBusinessTable"
                    id="publicBusinessTable"
                    class="publicBusinessTable"
                    v-bind="publicBusinessTable"
                    @page-change="PageChange"
                    @toolbar-button-click="toolbarButtonClickEvent"
                    @cell-click="cellClick">
                <template #operate="{ row }">
                    <el-button type="primary" size="mini" plain @click="receiveClick(row)">领取</el-button>
                </template>

            </vxe-grid>

        </div>

        <!-- detail 抽屉 -->
        <el-drawer :visible.sync="detailDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="detailDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <BusinessInfo :id="id"></BusinessInfo>
        </el-drawer>

    </div>

</template>

<script>
    import {
        GetPublicBusinessInfos,
        GetPublicBusinessInfosCount,
        InsertOperationRecord,
        ReceiveBusinessInfo
    } from "../../api";
    import BusinessInfo from './BusinessInfo'
    import XEUtils from 'xe-utils'

    export default {
        name: "PublicBusinessInfos",
        components: {BusinessInfo},
        data() {
            return {
                unionid: null,
                businessInfos: [],
                loading: false,
                searchObj: {
                    businessName: ''
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                currentPage: 1,
                pageSize: 50,
                total: 0,
                id: null,
                detailDrawer: false,
                detailDrawerTitle: '',
            }
        },
        computed: {
            publicBusinessTable() {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'print', name: '打 印', icon: 'vxe-icon--print'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'},
                        ],
                        size: 'medium',
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    printConfig: {
                        modes: ['current'],
                        useStyle: true
                    },
                    pagerConfig: {
                        layouts: ['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Sizes', 'Total'],
                        pageSizes: [10, 20, 50, 100],
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        total: this.total
                    },
                    data: this.businessInfos,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {
                            resizable: true, title: '创建时间', field: 'createTime',width: '120', formatter: function ({cellValue}) {
                                return XEUtils.toDateString(cellValue, 'yyyy-MM-dd')
                            }
                        },
                        {resizable: true, title: '项目', field: 'projName'},
                        {resizable: true, title: '类别', field: 'kind', width: '120'},
                        {resizable: true, title: '商机名称', field: 'businessName', className: 'font-blue'},
                        {resizable: true, title: '当前阶段', field: 'currentStage', width: '120'},
                        {resizable: true, title: '最近跟进时间', field: 'followUpDate'},
                        {
                            resizable: true, title: '距今', formatter: function ({row}) {
                                return XEUtils.getDateDiff(new Date(row.followUpDate).getTime(), new Date().getTime()).dd == undefined ?
                                    '' : (XEUtils.getDateDiff(new Date(row.followUpDate).getTime(), new Date().getTime()).time / 86400000).toFixed(0) + ' 天前'
                            }
                        },
                        {resizable: true, title: '业务员', field: 'unionName'},
                        {title: '操作', fixed: 'right', width: 80, slots: {default: 'operate'}}
                    ]
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            PageChange({currentPage, pageSize}) {
                this.currentPage = currentPage
                this.pageSize = pageSize
                this.GetInfo()
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        this.$refs.publicBusinessTable.openExport()
                        break
                    case 'print':
                        this.$refs.publicBusinessTable.openPrint()
                        break
                    case 'zoom':
                        this.$refs.publicBusinessTable.zoom()
                        break

                }
            },
            cellClick({row, column}) {
                if (column.field == null) {

                } else {
                    this.id = row.id
                    this.detailDrawerTitle = row.businessName + ' - 详情'
                    this.detailDrawer = true
                }
            },
            receiveClick(row) {

                this.$alert('是否要领取此商机？', '领取商机', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    showCancelButton: true,
                    callback: action => {
                        if (action === 'confirm') {
                            //修改 unionid --->  记录 operation
                            this.loading = true
                            ReceiveBusinessInfo(row.id,this.unionid).then((res)=>{
                                if (res === 1) {
                                    this.GetInfo()
                                    this.loading = true
                                }
                            })
                        }
                    }
                });
            },
            submit() {
                this.currentPage = 1
                this.pageSize = 50
                this.GetInfo()
            },
            GetInfo() {
                this.loading = true
                GetPublicBusinessInfosCount(this.searchObj.businessName).then((res) => {
                    this.total = res
                    GetPublicBusinessInfos(this.searchObj.businessName,this.pageSize, this.currentPage).then((res)=>{
                        this.businessInfos = res
                        this.loading = false
                    })
                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            this.GetInfo()
        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    .publicBusinessTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .publicBusinessTable >>> .font-blue {
        color: #45aaf2;
    }

</style>
