<template>

    <div>
        <!-- 有权限 -->
        <div v-loading="loading" v-if="authority">

            <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
                <el-collapse-item name="collapseItem" class="form-container">
                    <template slot="title">
                        <el-tag type="success" style="width: 100%;padding-left: 15px">
                            <i class="el-icon-search" style="margin-right: 5px"></i>
                            <span v-if="searchObj.type === '按月份'">{{searchObj.month}} {{searchObj.projName}}   共查询到 {{bills.length}} 条记录</span>
                            <span v-else>{{searchObj.projName}}   共查询到 {{clientBills.length}} 位客户</span>
                        </el-tag>
                    </template>

                    <el-form :inline="true"
                             :model="searchObj"
                             class="form"
                             size="small"
                             label-width="72px">

                        <el-form-item>
                            <el-radio-group v-model="searchObj.type" size="small" @change="submit">
                                <el-radio-button label="按月份"></el-radio-button>
                                <el-radio-button label="按客户"></el-radio-button>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="账单月份:" v-show="searchObj.type === '按月份'">
                            <el-date-picker :clearable="false"
                                            v-model="searchObj.month"
                                            value-format="yyyy-MM"
                                            style="width: 100%"
                                            type="month" placeholder="选择月" @change="submit">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="所属项目:" style="margin-left: 10px">
                            <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                                <el-option v-for="project in projects" :key="project.abbreviation"
                                           :value="project.projName"
                                           :label="project.projName"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="客户名称:">
                            <el-input v-model="searchObj.clientName" placeholder="客户名称" clearable
                                      @change="submit"></el-input>
                        </el-form-item>

                    </el-form>
                </el-collapse-item>
            </el-collapse>

            <!-- 表格 -->
            <div :style="tableContainerStyle">
                <vxe-grid ref="xTable" v-if="searchObj.type === '按月份'"
                          id="wuyeBillTable"
                          class="wuyeBillTable"
                          v-bind="wuyeBillTable"
                          @toolbar-button-click="toolbarButtonClickEvent"
                          @toolbar-tool-click="toolbarToolClickEvent"
                          @cell-click="cellClick">

                    <template #payNotice="{ row }">
                        <el-tag v-if="row.payNoticeInfo != null" type="success" size="small">已推送</el-tag>
                        <el-tag v-else type="danger" size="small">未推送</el-tag>
                    </template>

                    <template #collection="{ row }">
                        <el-tag v-if="row.collectionCode != null" type="success" size="small">已收款</el-tag>
                        <el-tag v-else type="danger" size="small">未收款</el-tag>
                    </template>

                    <template #invoice="{ row }">
                        <el-tag v-if="row.paymentMethod == '2'" type="info" size="small">不可开票</el-tag>
                        <el-tag v-if="row.invoiceInfo == 'empty'" type="warning" size="small">无需开票</el-tag>
                        <el-tag v-if="row.paymentMethod != '2' && row.invoiceInfo != null && row.invoiceInfo != 'empty'"
                                type="success" size="small">已开票
                        </el-tag>
                        <el-tag v-if="row.paymentMethod != '2' && row.invoiceInfo == null" type="danger" size="small">
                            未开票
                        </el-tag>
                    </template>

                    <template #paymentMethod="{ row }">
                        <el-tag v-if="(row.paymentMethod != '2') && row.collectionCode != null" size="small">{{'对公' + row.paymentMethod}}</el-tag>
                        <!--<el-tag v-if="row.paymentMethod == '1' && row.collectionCode == null" size="small">未支付</el-tag>-->
                        <el-tag v-if="row.paymentMethod == '2' && row.collectionCode != null" type="info" size="small">
                            现金
                        </el-tag>
                        <el-tag v-if="row.collectionCode == null" type="danger" size="small">
                            {{'对公' + row.paymentMethod}}
                        </el-tag>
                    </template>

                </vxe-grid>

                <vxe-grid ref="xTable2" v-else
                          id="clientWuyeBillTable"
                          class="clientWuyeBillTable"
                          v-bind="clientWuyeBillTable"
                          @toolbar-button-click="toolbarButtonClickEvent2"
                          @cell-click="cellClick2">
                </vxe-grid>

            </div>

            <!--新增-->
            <el-drawer :visible.sync="addDrawer" size="50%" destroy-on-close
                       :close-on-press-escape="false"
                       :wrapperClosable="false">
                <template slot="title">
                    <el-alert
                            :title="searchObj.month + ' ' + searchObj.projName + ' - 新增物业账单'"
                            type="warning"
                            :closable="false">
                    </el-alert>
                </template>

                <WuyeBillAdd :projName="searchObj.projName" :billMonth="searchObj.month"
                             @InsertWuyeBill="InsertWuyeBill"></WuyeBillAdd>
            </el-drawer>

            <!--推送-->
            <el-drawer :visible.sync="sendDrawer" size="50%" destroy-on-close
                       :close-on-press-escape="false"
                       :wrapperClosable="false">
                <template slot="title">
                    <el-alert
                            :title="searchObj.month + ' ' +searchObj.projName + ' - 物业账单推送'"
                            type="warning"
                            :closable="false">
                    </el-alert>
                </template>

                <WuyeBillSend :projName="searchObj.projName" :billMonth="searchObj.month"></WuyeBillSend>
            </el-drawer>

            <!--详情-->
            <el-drawer :visible.sync="detailDrawer" size="50%" destroy-on-close>
                <template slot="title">
                    <el-alert
                            :title="detailDrawerTitle"
                            type="warning"
                            :closable="false">
                    </el-alert>
                </template>

                <WuyeBillDetail :id="billId" @WuyeBillDetailClose="WuyeBillDetailClose"></WuyeBillDetail>
            </el-drawer>

            <!-- 客户统计 -->
            <el-drawer :visible.sync="clientDrawer" size="70%" destroy-on-close>
                <template slot="title">
                    <el-alert
                            :title="clientDrawerTitle"
                            type="warning"
                            :closable="false">
                    </el-alert>
                </template>
                <WuyeClientBillDetail :clientId="clientId"></WuyeClientBillDetail>
            </el-drawer>

            <!-- 发票登记 -->
            <el-drawer :visible.sync="invoiceDrawer" size="70%" destroy-on-close
                       :close-on-press-escape="false"
                       :wrapperClosable="false">
                <template slot="title">
                    <el-alert
                            title="发票登记"
                            type="warning"
                            :closable="false">
                    </el-alert>
                </template>

                <WuyeInvoiceRegister :projName="searchObj.projName"
                                     @RegisterWuyeInvoice="RegisterWuyeInvoice"></WuyeInvoiceRegister>
            </el-drawer>

        </div>

        <!-- 无权限 -->
        <div v-else>
            <el-result style="height: -webkit-calc(100vh - 250px)"
                       icon="warning" title="无权限"
                       subTitle="抱歉，无权限查看">
            </el-result>
        </div>

    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {
        GetClientWuyeBillsCount,
        GetProjectsWithoutAll,
        GetWuyeAuthority,
        GetWuyeBills,
        GetWuyeInventories
    } from "../../api";
    import WuyeBillSend from "./WuyeBillSend"
    import WuyeBillAdd from "./WuyeBillAdd"
    import WuyeBillDetail from "./WuyeBillDetail"
    import WuyeClientBillDetail from './WuyeClientBillDetail'
    import WuyeInvoiceRegister from './WuyeInvoiceRegister'
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "WuyeBillManagement",
        components: {WuyeBillSend, WuyeBillAdd, WuyeBillDetail, WuyeClientBillDetail, WuyeInvoiceRegister},
        data() {
            return {
                unionid: null,
                authority: true,
                loading: false,
                projects: [],
                inventories: [],
                searchObj: {
                    type: '按月份',
                    month: XEUtils.toDateString(XEUtils.getWhatMonth(new Date()), 'yyyy-MM'),
                    projName: '',
                    clientName: ''
                },
                /*pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > XEUtils.getWhatMonth(new Date());
                    },
                },*/
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                bills: [],
                feeKinds: [],
                payInfoKinds: [
                    {label: '已推送', value: '已推送'},
                    {label: '未推送', value: '未推送'}
                ],
                collectionKinds: [
                    {label: '已收款', value: '已收款'},
                    {label: '未收款', value: '未收款'}
                ],
                invoiceKinds: [
                    {label: '已开票', value: '已开票'},
                    {label: '未开票', value: '未开票'},
                    {label: '不可开票', value: '不可开票'},
                    {label: '无需开票', value: '无需开票'}
                ],
                paymentMethodKinds: [
                    {label: '对公1', value: '对公1'},
                    {label: '对公3', value: '对公3'},
                    {label: '现金', value: '现金'}
                ],
                clientBills: [],
                sendDrawer: false,
                addDrawer: false,
                detailDrawer: false,
                detailDrawerTitle: '',
                billId: null,
                clientDrawer: false,
                clientDrawerTitle: '',
                clientId: null,
                invoiceDrawer: false,
                btnDisabled: true
            }
        },
        computed: {
            wuyeBillTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'}
                        ],
                        size: 'medium',
                        tools: [
                            {code: 'add', name: '新增账单', status: 'success', disabled: this.btnDisabled},
                            /*{ code: 'send',name:'推送账单',status:'warning'},*/
                            {code: 'invoice', name: '发票登记', status: 'primary', disabled: this.btnDisabled}
                        ],
                        custom: true
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.bills,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '所属项目', field: 'projName', visible: false},
                        {resizable: true, title: 'U8项目编码', field: 'u8projCode', visible: false},
                        {resizable: true, title: '账单月份', field: 'billMonth'},
                        {resizable: true, title: '客户编号', field: 'clientId', visible: false},
                        {resizable: true, title: 'U8客户代码', field: 'u8clientCode', visible: false},
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {resizable: true, title: '帐单码', field: 'billCode', visible: false},
                        {resizable: true, title: 'U8收费项目代码', field: 'u8feeCode', visible: false},
                        {
                            resizable: true, title: '收费项目', field: 'u8fee',
                            filters: this.feeKinds,
                            filterMethod: this.FeeKindFilter
                        },
                        {resizable: true, title: '房源id', field: 'houseId', visible: false},
                        {resizable: true, title: '房源名称', field: 'houseName', visible: false},
                        {resizable: true, title: '建筑面积', field: 'constructionArea', visible: false},
                        {resizable: true, title: '用量/数量', field: 'quantity', visible: false},
                        {resizable: true, title: '单价', field: 'unitPrice', visible: false},
                        {resizable: true, title: '应收金额', field: 'amount', formatter: this.FormatReceivableAmount},
                        {
                            resizable: true,
                            title: '减免金额',
                            field: 'reliefAmount',
                            formatter: 'FormatMoney',
                            visible: false
                        },
                        {
                            resizable: true, title: '实际收款', field: 'receivedAmount', formatter: this.FormatMoney,
                            className: 'font-blue'
                        },
                        {resizable: true, title: '应收日期', field: 'dueDate', visible: false},
                        {resizable: true, title: '摘要', field: 'chargeContent', visible: false},
                        {resizable: true, title: '制单人', field: 'maker', visible: false},
                        {resizable: true, title: '审核人', field: 'reviewer', visible: false},
                        {
                            resizable: true, title: '缴费通知', field: 'payNoticeInfo', slots: {default: 'payNotice'},
                            filters: this.payInfoKinds,
                            filterMethod: this.PayInfoFilter,
                            visible: false
                        },
                        {resizable: true, title: '支付信息', field: 'paymentInfo', visible: false},
                        {resizable: true, title: '收款审核', field: 'collectionCheck', visible: false},
                        {resizable: true, title: '收款审核时间', field: 'collectionCheckDate', visible: false},
                        {
                            resizable: true, title: '收款', field: 'collectionCode', slots: {default: 'collection'},
                            filters: this.collectionKinds,
                            filterMethod: this.CollectionFilter
                        },
                        {
                            resizable: true, title: '结算方式', field: 'paymentMethod', slots: {default: 'paymentMethod'},
                            filters: this.paymentMethodKinds,
                            filterMethod: this.PaymentMethodFilter
                        },
                        {
                            resizable: true, title: '发票信息', field: 'invoiceInfo', slots: {default: 'invoice'},
                            filters: this.invoiceKinds,
                            filterMethod: this.InvoiceFilter
                        },
                        {resizable: true, title: '开票审核', field: 'invoiceCheck', visible: false},
                        {resizable: true, title: '开票审核日期', field: 'invoiceCheckTime', visible: false},
                        {resizable: true, title: '发票', field: 'invoiceCode', visible: false},
                        {resizable: true, title: 'U8提取标志', field: 'u8extract', visible: false},
                        {resizable: true, title: '备注', field: 'remark', visible: false},
                        {resizable: true, title: '创建时间', field: 'createTime', visible: false},
                        {resizable: true, title: '修改时间', field: 'changeTime', visible: false}
                    ],
                    showFooter: true,
                    footerRowClassName: 'footer-row',
                    footerMethod({columns, data}) {

                        return [
                            columns.map((column, columnIndex) => {
                                if (columnIndex === 0) {
                                    return '合计：'
                                }

                                if (['amount'].includes(column.property)) {

                                    let count = 0
                                    let relief = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    data.forEach(item => {
                                        relief += Number(item['reliefAmount'])
                                    })
                                    return formatMoney((count - relief).toFixed(2))
                                }

                                if (['receivedAmount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {

                                        if (item.collectionCode != null) {
                                            count += Number(item[column.property])
                                        }
                                    })
                                    return formatMoney(count.toFixed(2))
                                }

                                return ''
                            }),
                        ]
                    }
                }
            },
            clientWuyeBillTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'}
                        ],
                        size: 'medium',
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    sortConfig: {
                        multiple: true,
                        chronological: true
                    },
                    data: this.clientBills,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {resizable: true, title: '全部账单', field: 'allBills', sortable: true, sortBy: 'allBills'},
                        {resizable: true, title: '已缴账单', field: 'paidBills', sortable: true, sortBy: 'paidBills'},
                        {
                            resizable: true,
                            title: '未缴账单',
                            field: 'unpaidBills',
                            className: 'font-red',
                            sortable: true,
                            sortBy: 'unpaidBills'
                        },
                        {
                            resizable: true,
                            title: '总金额',
                            field: 'totalAmount',
                            formatter: 'FormatMoney',
                            sortable: true,
                            sortBy: 'totalAmount'
                        },
                        {
                            resizable: true,
                            title: '已缴金额',
                            field: 'paidTotalAmount',
                            formatter: 'FormatMoney',
                            sortable: true,
                            sortBy: 'paidTotalAmount'
                        },
                        {
                            resizable: true,
                            title: '未缴金额',
                            field: 'unpaidTotalAmount',
                            formatter: 'FormatMoney',
                            className: 'font-red',
                            sortable: true,
                            sortBy: 'unpaidTotalAmount'
                        },
                    ],
                    showFooter: true,
                    footerRowClassName: 'footer-row',
                    footerMethod({columns, data}) {
                        return [
                            columns.map((column, columnIndex) => {
                                if (columnIndex === 0) {
                                    return '合计：'
                                }

                                if (['allBills'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return '共 ' + count.toFixed(0) + ' 笔'
                                }

                                if (['paidBills'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return '已缴 ' + count.toFixed(0) + ' 笔'
                                }

                                if (['unpaidBills'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return '未缴 ' + count.toFixed(0) + ' 笔'
                                }

                                if (['totalAmount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return '共 ' + formatMoney(count.toFixed(2))
                                }

                                if (['paidTotalAmount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property] == null ? 0 : item[column.property])
                                    })
                                    return '已缴 ' + formatMoney(count.toFixed(2))
                                }

                                if (['unpaidTotalAmount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property] == null ? 0 : item[column.property])
                                    })
                                    return '未缴 ' + formatMoney(count.toFixed(2))
                                }

                                return ''
                            }),
                        ]
                    }
                }
            }
        },
        methods: {
            FormatReceivableAmount({cellValue, row, column}) {
                return formatMoney(row.amount - row.reliefAmount)
            },
            FormatMoney({cellValue, row, column}) {

                if (row.collectionCode == null) {
                    return ''
                } else {
                    return formatMoney(cellValue)
                }

            },
            FeeKindFilter({value, row, column}) {
                return row.u8fee == value
            },
            PayInfoFilter({value, row, column}) {
                if (value === '已推送') {
                    return row.payNoticeInfo != null
                } else {
                    return row.payNoticeInfo == null
                }
            },
            CollectionFilter({value, row, column}) {
                if (value === '已收款') {
                    return row.collectionCode != null
                } else {
                    return row.collectionCode == null
                }
            },
            InvoiceFilter({value, row, column}) {
                switch (value) {
                    case '已开票':
                        return (row.paymentMethod == '1' && row.invoiceInfo != null && row.invoiceInfo != 'empty')
                        break
                    case '未开票':
                        return (row.paymentMethod == '1' && row.invoiceInfo == null)
                        break
                    case '不可开票':
                        return row.paymentMethod == '2'
                        break
                    case '无需开票':
                        return row.invoiceInfo == 'empty'
                        break
                }

            },
            PaymentMethodFilter({value, row, column}) {
                switch (value) {
                    case '对公1':
                        return row.paymentMethod == '1'
                        break
                    case '现金':
                        return row.paymentMethod == '2'
                        break
                    case '对公3':
                        return row.paymentMethod == '3'
                        break
                }
            },
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            submit() {
                this.loading = true
                switch (this.searchObj.type) {
                    case '按月份':
                        GetWuyeBills(this.searchObj.projName, this.searchObj.month, this.searchObj.clientName).then((res) => {
                            this.bills = res
                            this.loading = false
                        })
                        break
                    case '按客户':
                        GetClientWuyeBillsCount(this.searchObj.projName, this.searchObj.month, this.searchObj.clientName).then((res) => {
                            this.clientBills = res
                            this.loading = false
                        })
                        break
                }
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        this.$refs.xTable.openExport()
                        break
                    case 'zoom':
                        this.$refs.xTable.zoom()
                        break
                }
            },
            toolbarButtonClickEvent2({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        this.$refs.xTable2.openExport()
                        break
                    case 'zoom':
                        this.$refs.xTable2.zoom()
                        break
                }
            },
            toolbarToolClickEvent({code}) {
                switch (code) {
                    case 'add':
                        this.addDrawer = true
                        break
                    case 'send':
                        this.sendDrawer = true
                        break
                    case 'invoice':
                        this.invoiceDrawer = true
                        break
                }
            },
            cellClick({row}) {

                this.detailDrawerTitle = row.billMonth + " " + row.clientName + " " + row.u8fee
                this.billId = row.id
                this.detailDrawer = true
            },
            InsertWuyeBill() {
                this.addDrawer = false
                this.submit()
            },
            WuyeBillDetailClose() {
                this.detailDrawer = false
                this.submit()
            },
            RegisterWuyeInvoice() {
                this.invoiceDrawer = false
                this.submit()
            },
            cellClick2({row}) {
                this.clientDrawerTitle = row.clientName + " 账单明细"
                this.clientId = row.clientId
                this.clientDrawer = true
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeInventories().then((res) => {
                for (var i = 0; i <= res.length - 1; i++) {
                    this.feeKinds.push({
                        label: res[i].cInvName,
                        value: res[i].cInvName
                    })
                }
            })

            GetWuyeAuthority(this.unionid).then((res) => {

                if (res === '' || res == null) {
                    this.authority = false
                } else {
                    this.authority = true

                    /* 权限控制 */
                    if (res.authorities.indexOf('editBill') != -1) {
                        this.btnDisabled = false
                    } else {
                        this.btnDisabled = true
                    }

                    this.searchObj.projName = '稻谷国际中心'
                    if (res.projName === 'ALL') {
                        GetProjectsWithoutAll().then((res) => {
                            this.projects = res
                        })
                    } else {
                        this.projects = []
                        var arr = res.projName.split(',')

                        this.searchObj.projName = arr[0]

                        for (var i = 0; i <= arr.length - 1; i++) {
                            this.projects.push({
                                projName: arr[i]
                            })
                        }
                    }

                    this.submit()
                }

            })
        },
        mounted() {
            this.$nextTick(function () {

                if (this.authority) {
                    this.bigHeight = this.$refs.collapse.$el.offsetHeight
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                }

            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .wuyeBillTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .wuyeBillTable >>> .font-blue {
        color: #45aaf2;
    }

    .wuyeBillTable >>> .footer-row {
        background-color: #F8F8F9;
    }

    .clientWuyeBillTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .clientWuyeBillTable >>> .font-blue {
        color: #45aaf2;
    }

    .clientWuyeBillTable >>> .font-red {
        color: #f35b5b;
    }

    .clientWuyeBillTable >>> .footer-row {
        background-color: #F8F8F9;
    }

</style>
