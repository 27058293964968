<template>

    <div class="container">

        <el-descriptions
                labelClassName="label"
                class="description"
                title="客户信息"
                :column="1"
                size="medium"
                border>
            <el-descriptions-item label="所属项目">{{client.projName}}</el-descriptions-item>
            <el-descriptions-item label="客户名称">{{client.clientName}}</el-descriptions-item>
            <el-descriptions-item label="客户编号">{{client.clientId}}</el-descriptions-item>
            <el-descriptions-item label="客户标识">{{client.abbreviation}}</el-descriptions-item>
        </el-descriptions>


        <el-descriptions v-if="!formShow"
                         labelClassName="label"
                         class="description"
                         :column="1"
                         size="medium"
                         border>
            <template slot="extra">
                <el-button v-if="authority" type="danger" size="small" plain @click="editClick">编辑</el-button>
            </template>
            <el-descriptions-item label="入场时间">{{client.actualStartDate}}</el-descriptions-item>
            <el-descriptions-item label="退场时间">{{client.actualEndDate}}</el-descriptions-item>
            <el-descriptions-item label="备注">{{client.remark}}</el-descriptions-item>
        </el-descriptions>


        <el-form ref="otherClientForm" :model="otherClientForm" :rules="rules" label-position="left" label-width="70px"
                 v-else>
            <el-form-item label="入场时间" prop="actualDate">
                <el-date-picker style="width: 100%"
                                v-model="otherClientForm.actualStartDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="入场时间" @change="checkDateRange">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="退场时间" prop="actualDate">
                <el-date-picker style="width: 100%"
                                v-model="otherClientForm.actualEndDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="退场时间" @change="checkDateRange">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="备注">
                <el-input type="textarea" v-model="otherClientForm.remark" clearable></el-input>
            </el-form-item>

            <el-form-item style="float: right">
                <el-button size="small" plain @click="FormShowChange">取消</el-button>
                <el-button size="small" plain type="success" @click="FormSubmit" :disabled="!submitCheck">保存</el-button>
            </el-form-item>
        </el-form>


    </div>

</template>

<script>
    import {GetAuthority, GetOtherClient, InsertOperationRecord, UpdateOtherClient} from "../../api";

    export default {
        name: "OtherClientInfo",
        data() {

            var actualDate = (rule, value, callback) => {

                var start = this.otherClientForm.actualStartDate == null ? null : new Date(this.otherClientForm.actualStartDate)
                var end = this.otherClientForm.actualEndDate == null ? null : new Date(this.otherClientForm.actualEndDate)

                this.submitCheck = true
                if (start == null && end != null) {
                    this.submitCheck = false
                    return callback(new Error('开始时间不能为空'))
                }
                if (start > end && end != null) {
                    this.submitCheck = false
                    return callback(new Error('开始时间 > 结束时间'))
                }
            }

            return {
                unionid: null,
                client: {},
                authority: false,
                submitCheck: true,
                formShow: false,
                otherClientForm: {
                    actualStartDate: null,
                    actualEndDate: null,
                    remark: null
                },
                rules: {
                    actualDate: [
                        {validator: actualDate, trigger: 'change'}
                    ]
                }
            }
        },
        props: {
            clientId: {
                type: String,
                required: true
            }
        },
        methods: {
            editClick() {
                this.otherClientForm.actualStartDate = this.client.actualStartDate
                this.otherClientForm.actualEndDate = this.client.actualEndDate
                this.otherClientForm.remark = this.client.remark
                this.formShow = true
            },
            checkDateRange() {
                /*表单执行校验规则*/
                this.$refs.otherClientForm.validate().catch((e) => {
                })
            },
            FormShowChange() {
                this.formShow = false
            },
            FormSubmit() {
                UpdateOtherClient(this.clientId, this.otherClientForm).then((res) => {
                    if (res === 1) {
                        InsertOperationRecord(this.unionid, 'updateOtherClientInfo', this.clientId).then((res) => {
                            if (res === 1) {
                                GetOtherClient(this.clientId).then((res) => {
                                    this.client = res
                                    this.formShow = false
                                })
                            }
                        })
                    }
                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetAuthority('otherClientEdit', this.unionid).then((res) => {
                this.authority = res
            })

            GetOtherClient(this.clientId).then((res) => {
                this.client = res
            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

</style>
