<template>
    <div class="container">

        <el-form ref="elecBillPlanForm" :model="addForm" :rules="rules" label-width="80px" size="medium"
                 v-loading="loading">

            <el-form-item label="选择客户" required prop="clientId">
                <el-select v-model="addForm.clientId" filterable
                           placeholder="请选择客户" style="width: 100%" @change="clientChange">
                    <el-option
                            v-for="item in clients"
                            :key="item.clientId"
                            :label="item.clientName"
                            :value="item.clientId">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="收款公司" required prop="paymentMethod">
                <el-select v-model="addForm.paymentMethod"
                           placeholder="选择收款公司" style="width: 100%">
                    <el-option value="1" label="江苏稻谷物业管理有限公司苏州分公司"></el-option>
                    <el-option value="3" label="苏州步步高物业管理有限公司"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="选择电表" required prop="meterIds">
                <el-select v-model="addForm.meterIds"
                           clearable multiple filterable placeholder="请选择电表" style="width: 100%">
                    <el-option
                            v-for="item in meters"
                            :key="item.meterId"
                            :label="item.meterId"
                            :value="item.meterId">
                        <span style="float: left">{{ item.meterName }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.meterId }}</span>
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="开始时间" required prop="startDate">
                <el-date-picker style="width: 100%"
                                v-model="addForm.startDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择开始时间">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="结束时间" prop="endDate">
                <el-date-picker style="width: 100%"
                                v-model="addForm.endDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择结束时间">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="单价">
                <el-input-number v-model="addForm.unitPrice"
                                 :precision="2" :step="0.01" :max="10" :min="0.01">
                </el-input-number>
            </el-form-item>


            <el-form-item style="text-align: right">
                <el-button size="small" type="primary" @click="Submit">提交</el-button>
            </el-form-item>

        </el-form>

    </div>
</template>

<script>
    import {
        GetAllWuyeClients,
        GetElecBillingPlanMeters,
        GetElecClients,
        GetExistElecBillPlans,
        GetHouseClient,
        GetHouseClients,
        GetPlanableElecMeters,
        GetProjectByAbbr,
        InsertElecBillPlans,
        InsertOperationRecord
    } from "../../api";
    import XEUtils from 'xe-utils'

    export default {
        name: "ElecBillPlanAdd",
        props: {
            abbreviation: {
                type: String,
                require: true
            }
        },
        data() {
            const dateCheck1 = (rule, value, callback) => {

                var start = this.addForm.startDate == null ? null : new Date(this.addForm.startDate)
                var end = this.addForm.endDate == null ? null : new Date(this.addForm.endDate)

                if (!value) {
                    return callback(new Error('开始时间不能为空'))
                }

                if (end != null) {
                    if (start > end) {
                        return callback(new Error('开始时间>结束时间'))
                    } else {
                        return callback()
                    }
                } else {
                    return callback()
                }

            }

            const dateCheck2 = (rule, value, callback) => {

                var start = this.addForm.startDate == null ? null : new Date(this.addForm.startDate)
                var end = this.addForm.endDate == null ? null : new Date(this.addForm.endDate)

                if (start == null && end != null) {
                    return callback(new Error('开始时间不能为空'))
                } else {

                    if (end != null) {
                        if (start > end) {
                            return callback(new Error('开始时间 > 结束时间'))
                        } else {
                            return callback()
                        }
                    } else {
                        return callback()
                    }

                }
            }

            return {
                unionid: null,
                loading: false,
                clients: [],
                meters: [],
                addForm: {
                    paymentMethod: null,
                    projName: null,
                    clientId: null,
                    meterIds: [],
                    unitPrice: 1,
                    payCycle: 1,
                    startDate: null,
                    endDate: null
                },
                rules: {
                    clientId: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    meterIds: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    startDate: [
                        {validator: dateCheck1, trigger: 'change'}
                    ],
                    endDate: [
                        {validator: dateCheck2, trigger: 'change'}
                    ]
                }
            }
        },
        methods: {
            clientChange(e) {
                this.meters = []
                this.addForm.meterIds = []

                var r = this.clients.filter(function (x) {
                    return x.clientId == e;
                });
                this.addForm.paymentMethod = r[0].paymentMethod

                this.loading = true
                GetPlanableElecMeters(this.addForm.clientId).then((res) => {
                    this.loading = false
                    this.meters = res
                })
            },
            Submit() {
                this.$refs.elecBillPlanForm.validate((e) => {
                    if (e) {
                        this.loading = true
                        GetExistElecBillPlans(this.addForm).then((res) => {
                            if (res === 'empty') {
                                InsertElecBillPlans(this.addForm).then((res) => {
                                    if (res === 1) {
                                        var detail = this.addForm.meterIds.join(',') + '/' + this.addForm.startDate + '/' + this.addForm.endDate
                                        InsertOperationRecord(this.unionid, 'insertElecBillingPlan', detail).then((res) => {
                                            if (res === 1) {
                                                this.loading = false
                                                this.$emit('ElecBillPlanDrawer')
                                            }
                                        })
                                    }
                                })
                            } else {
                                this.$message.error(res + '在当前时段已存在计费方案')
                                this.loading = false
                            }
                        })
                    } else {
                        this.$message.error('有未填项')
                    }
                })
            }
            /*check() {
                this.loading = true
                GetExistElecBillPlans(this.addForm).then((res)=>{
                    if(res === 'empty'){
                        this.able = true
                    }else{
                        this.message = res
                        this.dialog = true
                    }
                    this.loading = false
                })
            },
            submit() {
                this.loading = true
                InsertElecBillPlans(this.addForm).then((res)=>{
                    if (res === 1){
                        var detail = this.addForm.meterIds.join(',') + '/' + this.addForm.startDate + '/' + this.addForm.endDate
                        InsertOperationRecord(this.unionid,'insertElecBillingPlan',detail).then((res)=>{
                            if (res === 1){
                                this.loading = false
                                this.$emit('ElecBillPlanDrawer')
                            }
                        })
                    }
                })
            }*/
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')
            this.addForm.projName = this.abbreviation

            GetProjectByAbbr(this.abbreviation).then((res) => {
                GetAllWuyeClients(res.projName, "").then((res) => {
                    this.clients = res
                })
            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

</style>
