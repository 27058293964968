<template>
    <div>

        <FolderFormTable>


            <el-form slot="leftContent" :model="searchObj" label-width="80px" size="small">

                <el-form-item label="选择时间:">
                    <el-date-picker style="width: 215px"
                                    v-model="searchObj.timeRange"
                                    type="daterange"
                                    range-separator="至"
                                    value-format="yyyy-MM-dd"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    @change="submit">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="所属项目:">
                    <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit" style="width: 215px">
                        <el-option v-for="project in projects" :key="project.abbreviation"
                                   :value="project.projName"
                                   :label="project.projName"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="商机名称:">
                    <el-input v-model="searchObj.businessName" style="width: 215px" placeholder="商机名称" clearable
                              @change="submit"></el-input>
                </el-form-item>

                <el-form-item label="当前阶段:">
                    <el-select style="width: 215px" v-model="searchObj.currentStage" placeholder="当前阶段"
                               @change="submit">
                        <el-option v-for="(item,index) in currentStages" :key="index"
                                   :value="item.value"
                                   :label="item.label"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="业务员:">
                    <el-select style="width: 215px" v-model="searchObj.searchUnionids"
                               collapse-tags multiple filterable placeholder="业务员" @change="submit">
                        <el-checkbox v-model="checked" @change="selectAll">全选</el-checkbox>
                        <el-option v-for="employee in employees" :key="employee.unionid"
                                   :value="employee.unionid"
                                   :label="employee.name"></el-option>
                    </el-select>
                </el-form-item>

            </el-form>


            <div slot="rightContent" v-loading="loading">

                <el-tag type="success" style="width: 100%;margin-bottom: 10px">
                    <i class="el-icon-search" style="margin-right: 5px"></i>
                    <span>共查询到 {{total}} 条数据</span>
                </el-tag>

                <div style="height: -webkit-calc(100vh - 205px)">
                    <vxe-grid ref="xTable"
                              id="allBusinessTable"
                              class="allBusinessTable"
                              v-bind="allBusinessTable"
                              @toolbar-button-click="toolbarButtonClickEvent"
                              @cell-click="cellClick"
                              @page-change="PageChange">
                    </vxe-grid>
                </div>

            </div>

        </FolderFormTable>


        <!-- detail 抽屉 -->
        <el-drawer :visible.sync="detailDrawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="detailDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <BusinessInfo :id="id"></BusinessInfo>
        </el-drawer>

    </div>
</template>

<script>
    import {
        GetAllBusinesses,
        GetAllBusinessesCount,
        GetBusinessEmployees,
        GetCurrentStages,
        GetProjects
    } from "../../api";
    import FolderFormTable from "../utils/FolderFormTable";
    import BusinessInfo from './BusinessInfo'
    import XEUtils from 'xe-utils'

    export default {
        name: "BusinessCount",
        components: {BusinessInfo, FolderFormTable},
        data() {
            return {
                loading: false,
                unionid: null,
                detailDrawer: false,
                detailDrawerTitle: '',
                employees: [],
                projects: [],
                checked: true,
                searchObj: {
                    timeRange: [
                        XEUtils.toDateString(XEUtils.getWhatDay(Date.now(), -180), 'yyyy-MM-dd'),
                        XEUtils.toDateString(Date.now(), 'yyyy-MM-dd'),
                    ],
                    projName: '全部',
                    searchUnionids: [],
                    businessName: '',
                    currentStage: '全部',
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                businesses: [],
                currentStages: [],
                id: null,
                currentPage: 1,
                pageSize: 50,
                total: 0
            }
        },
        computed: {
            allBusinessTable() {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'print', name: '打 印', icon: 'vxe-icon--print'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'},
                        ],
                        size: 'medium',
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    printConfig: {
                        modes: ['current'],
                        useStyle: true
                    },
                    pagerConfig: {
                        layouts: ['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Sizes', 'Total'],
                        pageSizes: [10, 20, 50, 100],
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        total: this.total
                    },
                    data: this.businesses,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {
                            resizable: true, title: '创建时间', field: 'createTime', formatter: function ({cellValue}) {
                                return XEUtils.toDateString(cellValue, 'yyyy-MM-dd')
                            }
                        },
                        {resizable: true, title: '项目', field: 'projName'},
                        {resizable: true, title: '类别', field: 'kind', width: '150'},
                        {resizable: true, title: '商机名称', field: 'businessName', className: 'font-blue'},
                        {resizable: true, title: '当前阶段', field: 'currentStage'},
                        {resizable: true, title: '最近跟进时间', field: 'followUpDate'},
                        {
                            resizable: true, title: '距今', formatter: function ({row}) {
                                return XEUtils.getDateDiff(new Date(row.followUpDate).getTime(), new Date().getTime()).dd == undefined ?
                                    '' : (XEUtils.getDateDiff(new Date(row.followUpDate).getTime(), new Date().getTime()).time / 86400000).toFixed(0) + ' 天前'
                            }
                        },
                        {resizable: true, title: '业务员', field: 'unionName'}
                    ]
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            GetInfo() {
                if (this.searchObj.searchUnionids.length > 0) {
                    this.loading = true
                    GetAllBusinessesCount(this.searchObj).then((res) => {
                        this.total = res
                        GetAllBusinesses(this.searchObj, this.pageSize, this.currentPage).then((res) => {
                            this.businesses = res
                            this.loading = false
                        })
                    })
                } else {
                    this.total = 0
                    this.businesses = []
                }

            },
            selectAll() {
                this.searchObj.searchUnionids = []
                if (this.checked) {
                    this.employees.forEach((item) => {
                        this.searchObj.searchUnionids.push(item.unionid)
                    });
                }
                this.submit()
            },
            submit() {
                this.currentPage = 1
                this.pageSize = 50
                this.GetInfo()
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        this.$refs.xTable.openExport()
                        break
                    case 'print':
                        this.$refs.xTable.openPrint()
                        break
                    case 'zoom':
                        this.$refs.xTable.zoom()
                        break

                }
            },
            cellClick({row}) {
                this.id = row.id
                this.detailDrawerTitle = row.businessName + ' - 详情'
                this.detailDrawer = true
            },
            PageChange({currentPage, pageSize}) {
                this.currentPage = currentPage
                this.pageSize = pageSize
                this.GetInfo()
            },
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetProjects().then((res) => {
                this.projects = res
            })

            GetCurrentStages().then((res) => {
                this.currentStages.push({
                    label: '全部',
                    value: '全部'
                })

                for (var i = 0; i < res.length; i++) {
                    this.currentStages.push({
                        label: res[i].name,
                        value: res[i].name
                    })
                }
            })

            GetBusinessEmployees(this.unionid).then((res) => {
                this.employees = res
                for (var i = 0; i < res.length; i++) {
                    this.searchObj.searchUnionids.push(res[i].unionid)
                }

                this.submit()

            })
        },
        mounted() {

        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    /*.el-select {
        width: 200px
    }*/

    .el-checkbox {
        text-align: right;
        width: 90%;
        margin-bottom: 10px;
    }

    .allBusinessTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .allBusinessTable >>> .font-blue {
        color: #45aaf2;
    }

</style>
