<template>
    <div>

        <FolderFormTable>

            <div slot="leftContent">

                <el-form :model="searchObj" label-width="80px" size="small">

                    <el-form-item label="选择时间:">
                        <el-date-picker style="width: 215px"
                                        v-model="searchObj.timeRange"
                                        type="daterange"
                                        range-separator="至"
                                        value-format="yyyy-MM-dd"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        @change="search">
                        </el-date-picker>
                    </el-form-item>

                </el-form>

                <div style="width: 295px;height: -webkit-calc(100vh - 250px)">
                    <vxe-grid
                            ref="everyoneWorkCountTable"
                            id="everyoneWorkCountTable"
                            class="everyoneWorkCountTable"
                            v-bind="everyoneWorkCountTable"
                            @cell-click="chooseEmployee">
                    </vxe-grid>
                </div>


            </div>

            <div slot="rightContent">
                <div style="height: -webkit-calc(100vh - 164px)">
                    <vxe-grid
                            ref="xTable"
                            id="businessEveryOneCountTable"
                            class="businessEveryOneCountTable"
                            v-bind="businessEveryOneCountTable"
                            @toolbar-button-click="toolbarButtonClickEvent"
                            @cell-click="cellClick"
                            @page-change="PageChange">
                    </vxe-grid>
                </div>
            </div>

        </FolderFormTable>

        <!-- 抽屉 -->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <DailyReportDetail v-if="type === 'dailyReport'" :id="id" :followUpId="followUpId"></DailyReportDetail>
            <BusinessInfo v-else :id="id" :followUpId="followUpId"></BusinessInfo>
        </el-drawer>

    </div>
</template>

<script>
    import FolderFormTable from "../utils/FolderFormTable";
    import XEUtils from 'xe-utils'
    import {
        GetBusinessAuthority,
        GetBusinessEveryOneCount,
        GetComplexEveryDay,
        GetComplexEveryDayCount,
        GetEveryoneWorkCount
    } from "../../api";
    import BusinessInfo from "./BusinessInfo"
    import DailyReportDetail from "./DailyReportDetail"

    export default {
        name: "BusinessEveryOneCount",
        components: {FolderFormTable, BusinessInfo, DailyReportDetail},
        data() {
            return {
                unionid: null,
                drawer: false,
                drawerTitle: '',
                searchObj: {
                    timeRange: [
                        XEUtils.toDateString(XEUtils.getWhatDay(Date.now(), -7), 'yyyy-MM-dd'),
                        XEUtils.toDateString(Date.now(), 'yyyy-MM-dd')
                    ],
                    searchUnionids: []
                },
                everyoneWorkCounts: [],
                complexFollowUps: [],
                chooseUnionid: null,
                type: null,
                id: null,
                followUpId: null,
                currentPage: 1,
                pageSize: 50,
                total: 0,
                reader: false
            }
        },
        watch: {
            drawer: function (val, oldVal) {
                if (!val) {
                    this.GetInfo()
                }
            }
        },
        computed: {
            everyoneWorkCountTable() {
                return {
                    height: 'auto',
                    size: 'mini',
                    data: this.everyoneWorkCounts,
                    rowConfig: {
                        isHover: true
                    },
                    columns: [
                        {title: '姓名', field: 'name'},
                        {title: '商机', field: 'businessFollowUpNum'},
                        {title: '拜访', field: 'dailyReportNum'},
                    ]
                }
            },
            businessEveryOneCountTable() {
                var that = this
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'print', name: '打 印', icon: 'vxe-icon--print'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'},
                        ],
                        size: 'medium',
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    printConfig: {
                        modes: ['current'],
                        useStyle: true
                    },
                    pagerConfig: {
                        layouts: ['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Sizes', 'Total'],
                        pageSizes: [10, 20, 50, 100],
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        total: this.total
                    },
                    data: this.complexFollowUps,
                    columns: [
                        {
                            field: 'haveRead', width: '40', formatter: function ({cellValue}) {

                                if (!that.reader) {
                                    return ''
                                } else {

                                    if (cellValue.includes(that.unionid)) {
                                        return ''
                                    } else {
                                        return '🔴'
                                    }

                                }

                            }
                        },
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '类型', field: 'type', width: '100'},
                        {resizable: true, title: '客户名称', field: 'businessName', className: 'font-blue'},
                        {
                            resizable: true, title: '跟进时间', field: 'time', formatter: function ({cellValue}) {
                                return XEUtils.toDateString(cellValue, 'yyyy-MM-dd')
                            }
                        },
                        {resizable: true, title: '当前阶段', field: 'currentStage'},
                        {
                            resizable: true, title: '跟进人', formatter: function ({row}) {
                                return row.coordinatorName == undefined ? row.unionName : row.unionName + ',' + row.coordinatorName
                            }
                        },
                        {
                            resizable: true, title: '距今', formatter: function ({row}) {
                                return XEUtils.getDateDiff(new Date(row.time).getTime(), new Date().getTime()).dd == undefined ?
                                    '' : (XEUtils.getDateDiff(new Date(row.time).getTime(), new Date().getTime()).time / 86400000).toFixed(0) + ' 天前'
                            }
                        }
                    ]
                }
            }
        },
        methods: {
            search() {
                GetEveryoneWorkCount(this.searchObj, this.unionid).then((res) => {
                    this.everyoneWorkCounts = res
                })
            },
            GetInfo() {
                if (this.searchObj.searchUnionids.length > 0) {
                    this.loading = true
                    GetComplexEveryDayCount(this.searchObj).then((res) => {
                        this.total = res
                        GetComplexEveryDay(this.searchObj, this.pageSize, this.currentPage).then((res) => {
                            this.complexFollowUps = res
                            this.loading = false
                        })
                    })
                } else {
                    this.total = 0
                    this.complexFollowUps = []
                }
            },
            chooseEmployee({row}) {
                this.searchObj.searchUnionids = [row.unionid]
                this.GetInfo()
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.currentPage = 1
                        this.pageSize = 50
                        this.GetInfo()
                        break
                    case 'export-plus':
                        this.$refs.xTable.openExport()
                        break
                    case 'print':
                        this.$refs.xTable.openPrint()
                        break
                    case 'zoom':
                        this.$refs.xTable.zoom()
                        break

                }
            },
            cellClick({row}) {
                if (row.type === '商机') {
                    this.type = 'businessInfo'
                    this.id = Number(row.id)
                    this.followUpId = Number(row.followUpId)
                } else {
                    this.type = 'dailyReport'
                    this.id = Number(row.id)
                    this.followUpId = Number(row.followUpId)
                }
                this.drawerTitle = row.businessName + ' - 详情'
                this.drawer = true
            },
            PageChange({currentPage, pageSize}) {
                this.currentPage = currentPage
                this.pageSize = pageSize
                this.GetInfo()
            },
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetBusinessAuthority(this.unionid).then((res) => {
                this.reader = res.role == 'reader'
            })

            GetEveryoneWorkCount(this.searchObj, this.unionid).then((res) => {
                this.everyoneWorkCounts = res
            })

        }
    }
</script>

<style scoped>

    .everyoneWorkCountTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .businessEveryOneCountTable >>> .font-blue {
        color: #45aaf2;
    }

    .businessEveryOneCountTable >>> .vxe-body--row {
        cursor: pointer;
    }

</style>
