<template>

    <div>

        <el-form :inline="true"
                 :model="searchObj"
                 class="form"
                 size="small"
                 label-width="72px">

            <el-form-item label="所属项目:" style="margin-left: 10px">
                <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                    <el-option v-for="project in projects" :key="project.abbreviation"
                               :value="project.projName"
                               :label="project.projName"></el-option>
                </el-select>
            </el-form-item>

        </el-form>

        <el-row :gutter="10">

            <el-col :span="15">
                <el-card shadow="hover" style="height: -webkit-calc(100vh - 210px)">

                    <div ref="chart" style="width:100%;height:-webkit-calc(100vh - 210px)"></div>

                </el-card>
            </el-col>

            <el-col :span="9">
                <el-card shadow="hover" style="height: -webkit-calc(100vh - 210px)">

                    <!--<div v-for="item in countArr" :key="item.cInvName">
                        {{item.cInvName}}
                        <el-progress
                                :text-inside="true"
                                :stroke-width="16"
                                :percentage="Number(((item.receivedAmount/item.amount)*100).toFixed(2))"
                                status="success">
                        </el-progress>
                    </div>-->

                    <div ref="chart2" style="width:100%;height: -webkit-calc(100vh - 210px)"></div>

                </el-card>
            </el-col>

        </el-row>

    </div>

</template>

<script>
    import {
        GetProjectsWithoutAll,
        GetProjWuyeBillCount,
        GetWuyeAuthority,
        GetWuyeInventories,
        GetWuyeInventoryCount
    } from "../../api";
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "WuyeBillStatistics",
        data() {
            return {
                unionid: null,
                countArr: [],
                countArr2: {},
                projects: [],
                feeKinds: [],
                feeKinds2: [],
                searchObj: {
                    projName: '',
                },
                data: [],
                data2: [],
                myChart: null,
                myChart2: null,
                barSeries: []
            }
        },
        methods: {
            FormatMoney(value) {
                return formatMoney(value)
            },
            getEchartData() {

                var that = this

                if (this.myChart != null && this.myChart != "" && this.myChart != undefined) {
                    this.myChart.dispose();
                }

                this.myChart = this.$echarts.init(this.$refs.chart)
                const option = {
                    title: {
                        x: 'center',
                        bottom: '6%',
                        text: '总金额：' + that.FormatMoney(that.countArr2.amount)
                    },
                    legend: [
                        {
                            type: 'scroll',
                            data: this.feeKinds,
                            y: '30'
                        },
                        {
                            type: 'scroll',
                            data: this.feeKinds2,
                        }
                    ],
                    tooltip: {
                        trigger: 'item',
                        formatter: function (params) {
                            return params.seriesName
                                + '<br />' + params.name + '：' + that.FormatMoney(params.value)
                                + '<br />占比：' + params.percent + '%'
                        }
                    },
                    series: [
                        {
                            name: '收款进度',
                            type: 'pie',
                            selectedMode: 'single',
                            radius: [0, '25%'],
                            itemStyle: {
                                borderColor: '#fff',
                                borderWidth: 1
                            },
                            label: {
                                formatter: '{name|{b}}\n{d|{d}%}',
                                minMargin: 5,
                                edgeDistance: 10,
                                lineHeight: 15,
                                rich: {}
                            },
                            labelLayout: function (params) {
                                const isLeft = params.labelRect.x < that.myChart.getWidth() / 2;
                                const points = params.labelLinePoints;
                                // Update the end point.
                                points[2][0] = isLeft
                                    ? params.labelRect.x
                                    : params.labelRect.x + params.labelRect.width;
                                return {
                                    labelLinePoints: points
                                };
                            },
                            data: this.data2
                        },
                        {
                            name: '各类费用比例',
                            type: 'pie',
                            radius: ['53%', '70%'],
                            itemStyle: {
                                borderColor: '#fff',
                                borderWidth: 1
                            },
                            labelLine: {
                                length: 30
                            },
                            label: {
                                formatter: ' {b|{b}}\n{d|{d}%}',
                                minMargin: 5,
                                edgeDistance: 10,
                                lineHeight: 15,
                                rich: {
                                    b: {
                                        color: '#4C5058',
                                        fontWeight: 'bold'
                                    },
                                    d: {
                                        color: '#4C5058',
                                        fontWeight: 'bold'
                                    }
                                }
                            },
                            labelLayout: function (params) {
                                const isLeft = params.labelRect.x < that.myChart.getWidth() / 2;
                                const points = params.labelLinePoints;
                                // Update the end point.
                                points[2][0] = isLeft
                                    ? params.labelRect.x
                                    : params.labelRect.x + params.labelRect.width;
                                return {
                                    labelLinePoints: points
                                };
                            },
                            data: this.data
                        }
                    ],
                    xAxis: {show: false},
                    yAxis: {show: false},
                }
                this.myChart.setOption(option)


                window.addEventListener("resize", function () {
                    that.myChart.resize()
                })

                this.$on('hook:destroyed', () => {
                    window.removeEventListener("resize", function () {
                        that.myChart.resize();
                    });
                })

            },
            getEchartData2() {

                var that = this

                if (this.myChart2 != null && this.myChart2 != "" && this.myChart2 != undefined) {
                    this.myChart2.dispose();
                }

                this.myChart2 = this.$echarts.init(this.$refs.chart2)
                const option = {
                    color: ['#f7b731', '#20bf6b', '#d1d8e0'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: function (params) {

                            var obj = {}
                            obj = that.countArr.find(function (item) {
                                return item.cInvName == params[0].name
                            })

                            return obj.cInvName
                                + '<br />总计：' + that.FormatMoney(obj.amount)
                                + '<br />减免金额：' + that.FormatMoney(obj.reliefAmount)
                                + '<br />已收金额：' + that.FormatMoney(obj.receivedAmount)
                                + '<br />未收金额：' + that.FormatMoney((obj.amount - obj.reliefAmount - obj.receivedAmount))
                        }
                    },
                    legend: {},
                    label: {
                        formatter: function (params) {
                            if (params.value == 0) {  //为0时不显示
                                return ''
                            } else {
                                return params.value + '%'
                            }
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value'
                    },
                    yAxis: {
                        type: 'category',
                        data: this.feeKinds
                    },
                    series: this.barSeries
                }
                this.myChart2.setOption(option)


                window.addEventListener("resize2", function () {
                    that.myChart2.resize()
                })

                this.$on('hook:destroyed', () => {
                    window.removeEventListener("resize2", function () {
                        that.myChart2.resize();
                    });
                })

            },
            submit() {
                this.feeKinds = []
                this.feeKinds2 = []
                this.data = []
                this.data2 = []
                this.barSeries = []
                GetWuyeInventoryCount(this.searchObj.projName).then((res) => {

                    this.countArr = res

                    var reliefArr = []
                    var receivedArr = []
                    var notReceivedArr = []

                    for (var i = 0; i <= res.length - 1; i++) {
                        this.feeKinds.push(res[i].cInvName)

                        this.data.push({
                            value: (res[i].amount - res[i].reliefAmount).toFixed(2),
                            name: res[i].cInvName
                        })

                        reliefArr.push(((res[i].reliefAmount / res[i].amount) * 100).toFixed(2))
                        receivedArr.push(((res[i].receivedAmount / res[i].amount) * 100).toFixed(2))
                        notReceivedArr.push((100 - ((res[i].reliefAmount / res[i].amount) * 100).toFixed(2) - ((res[i].receivedAmount / res[i].amount) * 100).toFixed(2)).toFixed(2))
                    }


                    this.barSeries.push(
                        {
                            name: '减免金额',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: reliefArr
                        },
                        {
                            name: '已收金额',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: receivedArr
                        },
                        {
                            name: '未收金额',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: notReceivedArr
                        }
                    )


                    GetProjWuyeBillCount(this.searchObj.projName).then((res) => {

                        this.countArr2 = res

                        if (res.reliefAmount === 0) {
                            this.feeKinds2.push('未收金额', '已收金额')

                            this.data2.push(
                                {
                                    value: (res.amount - res.reliefAmount - res.receivedAmount).toFixed(2),
                                    name: '未收金额'
                                },
                                {
                                    value: res.receivedAmount.toFixed(2),
                                    name: '已收金额',
                                    selected: true
                                }
                            )
                        } else {
                            this.feeKinds2.push('未收金额', '减免金额', '已收金额')

                            this.data2.push(
                                {
                                    value: (res.amount - res.reliefAmount - res.receivedAmount).toFixed(2),
                                    name: '未收金额'
                                },
                                {
                                    value: res.reliefAmount.toFixed(2),
                                    name: '减免金额'
                                },
                                {
                                    value: res.receivedAmount.toFixed(2),
                                    name: '已收金额',
                                    selected: true
                                }
                            )
                        }

                        this.getEchartData()
                        this.getEchartData2()
                    })


                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeAuthority(this.unionid).then((res) => {

                if (res === '' || res == null) {

                } else {

                    this.searchObj.projName = '稻谷国际中心'
                    if (res.projName === 'ALL') {
                        GetProjectsWithoutAll().then((res) => {
                            this.projects = res
                        })
                    } else {
                        this.projects = []
                        var arr = res.projName.split(',')

                        this.searchObj.projName = arr[0]

                        for (var i = 0; i <= arr.length - 1; i++) {
                            this.projects.push({
                                projName: arr[i]
                            })
                        }
                    }

                    this.submit()
                }

            })
        },
        mounted() {

            /*this.$nextTick(function () {

                //this.getEchartData()

            })*/

        },
    }
</script>

<style scoped>

</style>
