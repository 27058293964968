<template>
    <div v-loading="loading">

        <el-steps :active="step" finish-status="success" simple>
            <el-step title="选择客户"></el-step>
            <el-step title="导出模板"></el-step>
            <el-step title="上传模板"></el-step>
        </el-steps>

        <el-form label-width="80px" size="medium" style="margin-top: 20px">
            <el-form-item label="选择客户">
                <el-select v-model="selectedClients" clearable multiple filterable
                           placeholder="请选择客户" style="width: 100%" @change="ClientChange">
                    <el-option
                            v-for="item in clients"
                            :key="item.clientId"
                            :label="item.clientName"
                            :value="item.clientId">
                        <span style="float: left">{{ item.clientName }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.clientId }}</span>
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" @click="Export" plain>导出模板</el-button>
            </el-form-item>

        </el-form>

        <div v-if="step > 1">
            <el-divider content-position="left">上传</el-divider>
            <el-upload action="/api/importPropertyBills"
                       multiple
                       :limit="1"
                       :auto-upload="false"
                       accept=".xlsx"
                       :on-change="HandleChange"
                       :on-remove="HandleChange"
                       :on-exceed="HandleExceed"
                       :on-success="HandleSuccess"
                       :data="uploadData"
                       :file-list="fileList"
                       ref="propertyBillUpload">
                <el-button size="small" type="warning" plain>
                    <i class="el-icon-upload"></i>
                    选择文件
                </el-button>
            </el-upload>

            <div v-if="step == 3" style="text-align: right;margin-top: 10px">
                <el-button size="small" type="primary" @click="Upload">上传</el-button>
            </div>

        </div>


    </div>
</template>

<script>
    import XEUtils from 'xe-utils'
    import {
        ExportPropertyBillTemplateExcel,
        GetPossiblePropertyBillWuyeClientIds,
        GetValidContractWuyeClients
    } from "../../api";

    export default {
        name: "ImportPropertyBill",
        props: {
            projName: {
                type: String,
                required: true
            },
            billMonth: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                step: 0,
                clients: [],
                selectedClients: [],
                fileList: [],
                uploadData: {
                    unionid: null
                }
            }
        },
        methods: {
            ClientChange() {
                if (this.selectedClients.length == 0) {
                    this.step = 0
                } else {
                    this.step = 1
                }
            },
            Export() {
                this.loading = true
                ExportPropertyBillTemplateExcel(this.billMonth, this.selectedClients).then((res) => {

                    const link = document.createElement('a')
                    let blob = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    // 文件名称,可自定义,也可以从后端获取
                    link.download = "物业费能耗费导入模板" + XEUtils.toDateString(XEUtils.now(), 'yyyyMMddHHmmss')
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)

                    this.step = 2
                    this.loading = false
                })
            },
            HandleExceed() {
                this.$message.error('只能上传一个文件！');
            },
            HandleChange(file, fileList) {
                if (fileList.length == 0) {
                    this.step = 2
                } else {
                    this.step = 3
                }
            },
            Upload() {
                this.loading = true
                this.$refs['propertyBillUpload'].submit();
            },
            HandleSuccess(response, file, fileList) {
                if (response === 1) {
                    this.loading = false
                    this.$emit('ImportWuyeBillClose')
                }
            }
        },
        created() {

            this.uploadData.unionid = sessionStorage.getItem('unionid')

            this.loading = true
            GetValidContractWuyeClients(this.projName, this.billMonth).then((res) => {
                this.clients = res
                GetPossiblePropertyBillWuyeClientIds(this.projName, this.billMonth).then((res) => {
                    this.selectedClients = res
                    this.step = this.selectedClients.length == 0 ? 0 : 1
                    this.loading = false
                })
            })
        }
    }
</script>

<style scoped>

</style>
