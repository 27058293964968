<template>
    <div>


        <div v-loading="loading" v-if="mgAuthority">

            <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
                <el-collapse-item name="collapseItem" class="form-container">
                    <template slot="title">
                        <el-tag type="success" style="width: 100%;padding-left: 15px">
                            <i class="el-icon-search" style="margin-right: 5px"></i>
                            <span>共查询到 {{ total }} 条数据</span>
                        </el-tag>
                    </template>

                    <el-form :inline="true"
                             :model="searchObj"
                             class="form"
                             size="small"
                             label-width="72px">

                        <el-form-item>
                            <el-radio-group v-model="searchObj.type" size="small" @change="changeType">
                                <el-radio-button label="按欠费"></el-radio-button>
                                <el-radio-button label="按月份"></el-radio-button>
                                <el-radio-button label="按合同"></el-radio-button>
                                <el-radio-button label="按客户"></el-radio-button>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="所属项目:" style="margin-left: 10px">
                            <el-select v-model="searchObj.projName" multiple collapse-tags
                                       placeholder="所属项目" style="width: 100%" @change="submit">
                                <el-option v-for="project in projects" :key="project.abbreviation"
                                           :value="project.projName"
                                           :label="project.projName"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="出账月份:" v-show="searchObj.type === '按月份'">
                            <el-date-picker :clearable="false"
                                            v-model="searchObj.billMonth"
                                            value-format="yyyy-MM"
                                            style="width: 100%"
                                            type="month" placeholder="选择月" @change="submit">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="合同编号:" v-show="searchObj.type === '按合同'">
                            <el-input v-model="searchObj.contractNo"
                                      placeholder="合同编号" clearable
                                      @change="submit"></el-input>
                        </el-form-item>

                        <el-form-item label="客户名称:" v-show="searchObj.type === '按客户'">
                            <el-input v-model="searchObj.clientName"
                                      placeholder="客户名称" clearable
                                      @change="submit"></el-input>
                        </el-form-item>

                    </el-form>

                </el-collapse-item>

            </el-collapse>

            <!-- 表格 -->
            <div :style="tableContainerStyle">

                <vxe-grid ref="rentalUnpaidTable" v-show="searchObj.type === '按欠费'"
                          id="rentalUnpaidTable"
                          class="rentalUnpaidTable"
                          v-bind="rentalUnpaidTable"
                          @page-change="PageChange"
                          @cell-click="cellClick"
                          @toolbar-button-click="toolbarButtonClickEvent"
                          @toolbar-tool-click="toolbarToolClickEvent">
                </vxe-grid>

                <vxe-grid ref="rentalBillTable" v-show="searchObj.type === '按月份'"
                          id="rentalBillTable"
                          class="rentalBillTable"
                          v-bind="rentalBillTable"
                          @page-change="PageChange"
                          @cell-click="cellClick"
                          @toolbar-button-click="toolbarButtonClickEvent"
                          @toolbar-tool-click="toolbarToolClickEvent">
                </vxe-grid>

                <vxe-grid ref="rentalContractTable" v-show="searchObj.type === '按合同'"
                          id="rentalContractTable"
                          class="rentalContractTable"
                          v-bind="rentalContractTable"
                          @page-change="PageChange"
                          @cell-click="cellClick"
                          @toolbar-button-click="toolbarButtonClickEvent"
                          @toolbar-tool-click="toolbarToolClickEvent">
                </vxe-grid>

                <vxe-grid ref="rentalClientTable" v-show="searchObj.type === '按客户'"
                          id="rentalClientTable"
                          class="rentalClientTable"
                          v-bind="rentalClientTable"
                          @page-change="PageChange"
                          @cell-click="cellClick"
                          @toolbar-button-click="toolbarButtonClickEvent"
                          @toolbar-tool-click="toolbarToolClickEvent">
                </vxe-grid>

            </div>

            <el-drawer :visible.sync="drawer" size="70%" destroy-on-close>
                <template slot="title">
                    <el-alert
                            title="客户详情"
                            type="warning"
                            :closable="false">
                    </el-alert>
                </template>
                <RentalClientDetail :contractNo="contractNo"></RentalClientDetail>
            </el-drawer>

            <!-- 账单收款 -->
            <el-drawer :visible.sync="collectionDrawer" size="70%" destroy-on-close :append-to-body="true"
                       :close-on-press-escape="false"
                       :wrapperClosable="false">
                <template slot="title">
                    <el-alert
                            title="账单收款"
                            type="warning"
                            :closable="false">
                    </el-alert>
                </template>
                <RentalBillCollection></RentalBillCollection>
            </el-drawer>

            <!--        &lt;!&ndash; 账单导入 &ndash;&gt;-->
            <!--        <el-drawer :visible.sync="importDrawer" size="70%" destroy-on-close :append-to-body="true"-->
            <!--                   :close-on-press-escape="false"-->
            <!--                   :wrapperClosable="false">-->
            <!--            <template slot="title">-->
            <!--                <el-alert-->
            <!--                        title="账单导入"-->
            <!--                        type="warning"-->
            <!--                        :closable="false">-->
            <!--                </el-alert>-->
            <!--            </template>-->
            <!--            <RentalBillImport></RentalBillImport>-->
            <!--        </el-drawer>-->

        </div>


        <!-- 无权限 -->
        <div v-else>
            <el-result style="height: -webkit-calc(100vh - 250px)"
                       icon="warning" title="无权限"
                       subTitle="抱歉，无权限查看">
            </el-result>
        </div>

    </div>
</template>

<script>
    import {
        GetEmployee,
        GetProjectsWithoutAll, GetRentalBillAuthority,
        GetRentalBillList_ByBillMonth,
        GetRentalBillList_ByClient,
        GetRentalBillList_ByContract, GetRentalBillList_ByUnpaid
    } from "../../api";
    import XEUtils from "xe-utils";
    import formatMoney from "../../utils/formatMoney";
    import RentalClientDetail from "./RentalClientDetail";
    import RentalBillCollection from "./RentalBillCollection";
    import RentalBillImport from "./RentalBillImport";

    export default {
        name: "RentalBillManagement",
        components: {RentalClientDetail, RentalBillCollection},
        data() {
            return {
                unionid: null,
                mgAuthority: true,
                loading: false,
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                searchObj: {
                    type: '按欠费',
                    projName: [],
                    billMonth: XEUtils.toDateString(XEUtils.getWhatMonth(new Date()), 'yyyy-MM'),
                    contractNo: '',
                    clientName: ''
                },
                projects: [],
                currentPage: 1,
                pageSize: 50,
                total: 0,
                amountSum: {},
                unpaidList: [],
                billList: [],
                contractList: [],
                clientList: [],
                drawer: false,
                contractNo: null,
                collectionDrawer: false,
                importDrawer: false,
                authorized: false
            }
        },
        watch: {
            collectionDrawer: async function (val, oldVal) {
                if (!val) {
                    await this.submit()
                }
            },
            importDrawer: async function (val, oldVal) {
                if (!val) {
                    await this.submit()
                }
            }
        },
        computed: {
            rentalUnpaidTable: function () {
                var that = this
                return {
                    height: 'auto',
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'}
                        ],
                        tools: [
                            {code: 'collection', name: '收款', status: 'success', disabled: !that.authorized},
                            /* {code: 'importBill', name: '导入账单', status: 'warning'}*/
                        ],
                        size: 'medium',
                        custom: true
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    scrollY: {
                        mode: 'wheel'
                    },
                    columnConfig: {
                        width: 'auto',
                        minWidth: '150'
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.unpaidList,
                    pagerConfig: {
                        layouts: ['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Sizes', 'Total'],
                        pageSizes: [10, 20, 50, 100],
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        total: this.total
                    },
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '所属项目', field: 'projName'},
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {resizable: true, title: '合同编号', field: 'contractNo'},
                        {
                            resizable: true,
                            title: '未缴金额',
                            field: 'unpaidAmountTotal',
                            formatter: 'FormatMoney',
                            className: 'font-red'
                        },
                        {
                            resizable: true, title: '逾期', formatter: function ({row}) {
                                return XEUtils.getDateDiff(new Date(row.dueDate).getTime(), new Date().getTime()).dd == undefined ?
                                    '已出账未逾期' : (XEUtils.getDateDiff(new Date(row.dueDate).getTime(), new Date().getTime()).time / 86400000).toFixed(0) + ' 天'
                            }
                        }
                    ],
                    showFooter: true,
                    footerRowClassName: 'footer-row',
                    footerMethod({columns, data}) {

                        return [
                            columns.map((column, columnIndex) => {
                                if (columnIndex === 0) {
                                    return '合计：'
                                }

                                if (['unpaidAmountTotal'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.unpaidAmountTotal)
                                }

                                return ''
                            }),
                        ]
                    }
                }
            },
            rentalBillTable: function () {
                var that = this
                return {
                    height: 'auto',
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'}
                        ],
                        tools: [
                            {code: 'collection', name: '收款', status: 'success', disabled: !that.authorized},
                            // {code: 'importBill', name: '导入账单', status: 'warning'}
                        ],
                        size: 'medium',
                        custom: true
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    scrollY: {
                        mode: 'wheel'
                    },
                    columnConfig: {
                        width: 'auto',
                        minWidth: '150'
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.billList,
                    pagerConfig: {
                        layouts: ['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Sizes', 'Total'],
                        pageSizes: [10, 20, 50, 100],
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        total: this.total
                    },
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '所属项目', field: 'projName'},
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {resizable: true, title: '合同编号', field: 'contractNo'},
                        {resizable: true, title: '房源', field: 'houseName', showOverflow: true},
                        {resizable: true, title: '应缴日期', field: 'dueDate'},
                        {resizable: true, title: '费项', field: 'fee'},
                        {resizable: true, title: '开始时间', field: 'startDate'},
                        {resizable: true, title: '结束时间', field: 'endDate'},
                        {
                            resizable: true,
                            title: '账单金额',
                            field: 'amount',
                            formatter: 'FormatMoney',
                            className: 'font-blue'
                        },
                        {resizable: true, title: '减免金额', field: 'reliefAmount', formatter: 'FormatMoney'},
                        {resizable: true, title: '已收金额', field: 'receivedAmount', formatter: 'FormatMoney'},
                        {resizable: true, title: '未缴金额', field: 'unpaidAmount', formatter: 'FormatMoney'},
                        {resizable: true, title: '收款状态', field: 'collectionState'},
                        {resizable: true, title: '收款日期', field: 'collectionDate'},
                        {resizable: true, title: '业务员', field: 'employeeName'},
                    ],
                    showFooter: true,
                    footerRowClassName: 'footer-row',
                    footerMethod({columns, data}) {

                        return [
                            columns.map((column, columnIndex) => {
                                if (columnIndex === 0) {
                                    return '合计：'
                                }

                                if (['amount'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.amountTotal)
                                }

                                if (['reliefAmount'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.reliefAmountTotal)
                                }

                                if (['receivedAmount'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.receivedAmountTotal)
                                }

                                if (['unpaidAmount'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.unpaidAmountTotal)
                                }

                                return ''
                            }),
                        ]
                    }
                }
            },
            rentalContractTable: function () {
                var that = this
                return {
                    height: 'auto',
                    size: 'small',
                    border: true,
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'}
                        ],
                        tools: [
                            {code: 'collection', name: '收款', status: 'success', disabled: !that.authorized},
                            // {code: 'importBill', name: '导入账单', status: 'warning'}
                        ],
                        size: 'medium',
                        custom: true
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    scrollY: {
                        mode: 'wheel'
                    },
                    columnConfig: {
                        width: 'auto',
                        minWidth: '150'
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.contractList,
                    pagerConfig: {
                        layouts: ['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Sizes', 'Total'],
                        pageSizes: [10, 20, 50, 100],
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        total: this.total
                    },
                    columns: [
                        {resizable: true, title: '序号', fixed: 'left', type: 'seq', width: '60'},
                        {resizable: true, title: '所属项目', fixed: 'left', field: 'projName'},
                        {resizable: true, title: '客户名称', fixed: 'left', field: 'clientName', className: 'font-blue'},
                        {
                            resizable: true, title: '已出账', align: 'center', children: [
                                {
                                    resizable: true,
                                    title: '已出账金额',
                                    field: 'billed_amountTotal',
                                    formatter: 'FormatMoney'
                                },
                                {
                                    resizable: true,
                                    title: '已出账减免金额',
                                    field: 'billed_reliefAmountTotal',
                                    formatter: 'FormatMoney'
                                },
                                {
                                    resizable: true,
                                    title: '已出账已收金额',
                                    field: 'billed_receivedAmountTotal',
                                    formatter: 'FormatMoney'
                                },
                                {
                                    resizable: true,
                                    title: '已出账未缴金额',
                                    field: 'billed_unpaidAmountTotal',
                                    formatter: 'FormatMoney',
                                    className: 'font-red'
                                },
                            ]
                        },
                        {
                            resizable: true, title: '合同详情', align: 'center', children: [
                                {resizable: true, title: '合同编号', field: 'contractNo'},
                                {resizable: true, title: '房源', field: 'houseName', showOverflow: true},
                                {resizable: true, title: '开始时间', field: 'contractStartDate'},
                                {resizable: true, title: '结束时间', field: 'contractEndDate'},
                                {
                                    resizable: true,
                                    title: '合同总额',
                                    field: 'contract_amountTotal',
                                    formatter: 'FormatMoney',
                                    className: 'font-blue'
                                },
                                {
                                    resizable: true,
                                    title: '合同减免',
                                    field: 'contract_reliefAmountTotal',
                                    formatter: 'FormatMoney'
                                },
                                {
                                    resizable: true,
                                    title: '合同已收',
                                    field: 'contract_receivedAmountTotal',
                                    formatter: 'FormatMoney'
                                },
                                {
                                    resizable: true,
                                    title: '合同未缴',
                                    field: 'contract_unpaidAmountTotal',
                                    formatter: 'FormatMoney'
                                },
                            ]
                        },
                        {resizable: true, title: '业务员', field: 'employeeName'},
                    ],
                    showFooter: true,
                    footerRowClassName: 'footer-row',
                    footerMethod({columns, data}) {

                        return [
                            columns.map((column, columnIndex) => {
                                if (columnIndex === 0) {
                                    return '合计：'
                                }

                                if (['billed_amountTotal'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.billed_amountTotal)
                                }
                                if (['billed_reliefAmountTotal'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.billed_reliefAmountTotal)
                                }
                                if (['billed_receivedAmountTotal'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.billed_receivedAmountTotal)
                                }
                                if (['billed_unpaidAmountTotal'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.billed_unpaidAmountTotal)
                                }


                                if (['contract_amountTotal'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.contract_amountTotal)
                                }
                                if (['contract_reliefAmountTotal'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.contract_reliefAmountTotal)
                                }
                                if (['contract_receivedAmountTotal'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.contract_receivedAmountTotal)
                                }
                                if (['contract_unpaidAmountTotal'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.contract_unpaidAmountTotal)
                                }

                                return ''
                            }),
                        ]
                    }
                }
            },
            rentalClientTable: function () {
                var that = this
                return {
                    height: 'auto',
                    size: 'small',
                    border: true,
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'}
                        ],
                        tools: [
                            {code: 'collection', name: '收款', status: 'success', disabled: !that.authorized},
                            // {code: 'importBill', name: '导入账单', status: 'warning'}
                        ],
                        size: 'medium',
                        custom: true
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    scrollY: {
                        mode: 'wheel'
                    },
                    columnConfig: {
                        width: 'auto',
                        minWidth: '150'
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.clientList,
                    pagerConfig: {
                        layouts: ['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Sizes', 'Total'],
                        pageSizes: [10, 20, 50, 100],
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        total: this.total
                    },
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '所属项目', field: 'projName'},
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {
                            resizable: true, title: '已出账', align: 'center', children: [
                                {
                                    resizable: true,
                                    title: '已出账金额',
                                    field: 'billed_amountTotal',
                                    formatter: 'FormatMoney'
                                },
                                {
                                    resizable: true,
                                    title: '已出账减免金额',
                                    field: 'billed_reliefAmountTotal',
                                    formatter: 'FormatMoney'
                                },
                                {
                                    resizable: true,
                                    title: '已出账已收金额',
                                    field: 'billed_receivedAmountTotal',
                                    formatter: 'FormatMoney'
                                },
                                {
                                    resizable: true,
                                    title: '已出账未缴金额',
                                    field: 'billed_unpaidAmountTotal',
                                    formatter: 'FormatMoney',
                                    className: 'font-red'
                                },
                            ]
                        },
                        {
                            resizable: true, title: '合同详情', align: 'center', children: [
                                {resizable: true, title: '合同编号', field: 'contract'},
                                {
                                    resizable: true,
                                    title: '合同总额',
                                    field: 'contract_amountTotal',
                                    formatter: 'FormatMoney',
                                    className: 'font-blue'
                                },
                                {
                                    resizable: true,
                                    title: '合同减免',
                                    field: 'contract_reliefAmountTotal',
                                    formatter: 'FormatMoney'
                                },
                                {
                                    resizable: true,
                                    title: '合同已收',
                                    field: 'contract_receivedAmountTotal',
                                    formatter: 'FormatMoney'
                                },
                                {
                                    resizable: true,
                                    title: '合同未缴',
                                    field: 'contract_unpaidAmountTotal',
                                    formatter: 'FormatMoney'
                                },
                            ]
                        }
                    ],
                    showFooter: true,
                    footerRowClassName: 'footer-row',
                    footerMethod({columns, data}) {

                        return [
                            columns.map((column, columnIndex) => {
                                if (columnIndex === 0) {
                                    return '合计：'
                                }

                                if (['billed_amountTotal'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.billed_amountTotal)
                                }
                                if (['billed_reliefAmountTotal'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.billed_reliefAmountTotal)
                                }
                                if (['billed_receivedAmountTotal'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.billed_receivedAmountTotal)
                                }
                                if (['billed_unpaidAmountTotal'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.billed_unpaidAmountTotal)
                                }


                                if (['contract_amountTotal'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.contract_amountTotal)
                                }
                                if (['contract_reliefAmountTotal'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.contract_reliefAmountTotal)
                                }
                                if (['contract_receivedAmountTotal'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.contract_receivedAmountTotal)
                                }
                                if (['contract_unpaidAmountTotal'].includes(column.property)) {

                                    return formatMoney(that.amountSum == null ? 0 : that.amountSum.contract_unpaidAmountTotal)
                                }

                                return ''
                            }),
                        ]
                    }
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            PageChange({currentPage, pageSize}) {
                this.currentPage = currentPage
                this.pageSize = pageSize
                this.submit()
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        switch (this.searchObj.type) {
                            case '按欠费':
                                this.$refs.rentalUnpaidTable.openExport()
                                break
                            case '按月份':
                                this.$refs.rentalBillTable.openExport()
                                break
                            case '按合同':
                                this.$refs.rentalContractTable.openExport()
                                break
                            case '按客户':
                                this.$refs.rentalClientTable.openExport()
                                break
                        }
                        break
                    case 'zoom':
                        switch (this.searchObj.type) {
                            case '按欠费':
                                this.$refs.rentalUnpaidTable.zoom()
                                break
                            case '按月份':
                                this.$refs.rentalBillTable.zoom()
                                break
                            case '按合同':
                                this.$refs.rentalContractTable.zoom()
                                break
                            case '按客户':
                                this.$refs.rentalClientTable.zoom()
                                break
                        }
                        break
                }
            },
            toolbarToolClickEvent({code}) {
                switch (code) {
                    case 'collection':
                        this.collectionDrawer = true
                        break
                    // case 'importBill':
                    //     this.importDrawer = true
                    //     break
                }
            },
            cellClick({row}) {
                if (this.searchObj.type == '按客户') {
                    var a = row.contract.split(',')
                    this.contractNo = a[0]
                } else {
                    this.contractNo = row.contractNo
                }
                this.drawer = true
            },
            async changeType() {
                this.currentPage = 1
                this.pageSize = 50
                this.total = 0
                await this.submit()
            },
            async submit() {
                switch (this.searchObj.type) {
                    case '按欠费':
                        this.loading = true
                        await GetRentalBillList_ByUnpaid(this.pageSize, this.currentPage, this.searchObj).then((res) => {
                            this.unpaidList = res.list
                            this.total = res.total
                            this.amountSum = res.amountSum
                        })
                        this.loading = false
                        break
                    case '按月份':
                        this.loading = true
                        await GetRentalBillList_ByBillMonth(this.pageSize, this.currentPage, this.searchObj).then((res) => {
                            this.billList = res.list
                            this.total = res.total
                            this.amountSum = res.amountSum
                        })
                        this.loading = false
                        break
                    case '按合同':
                        this.loading = true
                        await GetRentalBillList_ByContract(this.pageSize, this.currentPage, this.searchObj).then((res) => {
                            this.contractList = res.list
                            this.total = res.total
                            this.amountSum = res.amountSum
                        })
                        this.loading = false
                        break
                    case '按客户':
                        this.loading = true
                        await GetRentalBillList_ByClient(this.pageSize, this.currentPage, this.searchObj).then((res) => {
                            this.clientList = res.list
                            this.total = res.total
                            this.amountSum = res.amountSum
                        })
                        this.loading = false
                        break
                }
            }
        },
        async created() {
            this.unionid = sessionStorage.getItem('unionid')
            this.loading = true

            await GetRentalBillAuthority(this.unionid).then((res) => {
                this.mgAuthority = (res.authorities.indexOf('rentalBillMg') != -1)
            })

            if (this.mgAuthority) {
                await GetEmployee(this.unionid).then((res) => {
                    if (res.authorities.indexOf('rentalBillEdit') != -1) {
                        this.authorized = true
                    } else {
                        this.authorized = false
                    }
                })

                await GetProjectsWithoutAll().then((res) => {
                    this.searchObj.projName = res.map(item => item.projName)
                    this.projects = res
                })

                await this.submit()
            }
            this.loading = false
        },
        mounted() {
            this.$nextTick(function () {

                if (this.mgAuthority) {
                    this.bigHeight = this.$refs.collapse.$el.offsetHeight
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                }


            })
        }
    }
</script>

<style scoped>


    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .rentalUnpaidTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .rentalUnpaidTable >>> .font-blue {
        color: #45aaf2;
    }

    .rentalUnpaidTable >>> .font-red {
        color: #f10716;
    }

    .rentalUnpaidTable >>> .footer-row {
        background-color: #F8F8F9;
    }

    .rentalBillTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .rentalBillTable >>> .font-blue {
        color: #45aaf2;
    }

    .rentalBillTable >>> .footer-row {
        background-color: #F8F8F9;
    }

    .rentalContractTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .rentalContractTable >>> .font-blue {
        color: #45aaf2;
    }

    .rentalContractTable >>> .font-red {
        color: #f10716;
    }

    .rentalContractTable >>> .footer-row {
        background-color: #F8F8F9;
    }

    .rentalClientTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .rentalClientTable >>> .font-blue {
        color: #45aaf2;
    }

    .rentalClientTable >>> .font-red {
        color: #f10716;
    }

    .rentalClientTable >>> .footer-row {
        background-color: #F8F8F9;
    }

</style>
