<template>

    <div class="container" v-loading="loading">
        <el-tabs v-model="activeName">
            <el-tab-pane label="账单推送" name="first">
                <el-alert type="error" :closable="false" style="margin-bottom: 20px"
                          title="推送前请先确认月份账单无误！">
                </el-alert>

                <el-form :inline="true">
                    <el-form-item label="选择月份">
                        <el-date-picker :clearable="false"
                                        v-model="month"
                                        value-format="yyyy-MM"
                                        type="month" :picker-options="pickerOptions" placeholder="选择月">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="success" size="small" @click="submit">推 送</el-button>
                    </el-form-item>


                </el-form>

            </el-tab-pane>
            <el-tab-pane label="推送记录" name="second">

                <div style="height: -webkit-calc(100vh - 150px)">
                    <vxe-grid ref="xTable"
                              id="elecBillMessageTable"
                              class="elecBillMessageTable"
                              v-bind="elecBillMessageTable">
                    </vxe-grid>
                </div>

            </el-tab-pane>
        </el-tabs>
    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {GetOperationRecords, InsertOperationRecord, InsertValidElecBillMonth, SendWxBillMessage} from "../../api";

    export default {
        name: "SendElecBillMessage",
        data() {
            return {
                unionid: null,
                loading: false,
                activeName: 'first',
                month: XEUtils.toDateString(XEUtils.getWhatMonth(new Date(), -1), 'yyyy-MM'),
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > XEUtils.getWhatMonth(new Date(), -1);
                    },
                },
                operationRecords: []
            }
        },
        computed: {
            elecBillMessageTable() {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    data: this.operationRecords,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '时间', field: 'time'},
                        {resizable: true, title: '操作人', field: 'unionid'},
                        {resizable: true, title: '操作内容', field: 'detail', formatter: 'FormatWxMessage'}
                    ]
                }
            }
        },
        methods: {
            GetRecords() {
                GetOperationRecords("sendWxMessage").then((res) => {
                    this.operationRecords = res
                })
            },
            submit() {
                this.loading = true

                //1、将 month 写入 validElecBillMonth 表
                //2、推送
                //3、记录操作 更新operationRecords表
                InsertValidElecBillMonth(this.month).then((res) => {
                    if (res === 1) {
                        SendWxBillMessage(this.month).then((res) => {
                            var detail = this.month + "," + res
                            InsertOperationRecord(this.unionid, 'sendWxMessage', detail).then((res) => {
                                if (res === 1) {
                                    this.activeName = 'second'
                                    this.GetRecords()
                                    this.loading = false
                                }
                            })
                        })
                    }
                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            this.GetRecords()
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

</style>
