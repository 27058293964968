<template>
    <div class="container" v-loading="loading" v-if="info != null">

        <!-- 用户 -->
        <el-descriptions title="用户信息" v-if="info.user != null && info.userInfo != null"
                         labelClassName="label"
                         class="description"
                         :column="2"
                         border
                         style="margin-bottom: 20px">
            <el-descriptions-item label="用户名称">{{info.userInfo.name}}</el-descriptions-item>
            <el-descriptions-item label="联系电话">{{info.userInfo.phone}}</el-descriptions-item>
            <el-descriptions-item label="推送邮箱">{{info.userInfo.email}}</el-descriptions-item>
            <el-descriptions-item label="绑定电话">{{info.user.telephone}}</el-descriptions-item>
        </el-descriptions>
        <!-- 账单 -->
        <el-descriptions title="账单信息"
                         labelClassName="label"
                         class="description"
                         :column="2"
                         border
                         style="margin-bottom: 20px">
            <el-descriptions-item label="账单月份">{{info.bill.billMonth}}</el-descriptions-item>
            <el-descriptions-item label="房源">{{info.bill.houseName}}</el-descriptions-item>
            <el-descriptions-item label="收费项目">{{info.bill.u8fee}}</el-descriptions-item>
            <el-descriptions-item label="实际收款">{{FormatMoney(info.bill.receivedAmount)}}</el-descriptions-item>
            <el-descriptions-item label="支付方式">{{info.bill.paymentInfo == null?'对公打款':'微信支付'}}</el-descriptions-item>
            <el-descriptions-item label="财务收款">
                <el-tag v-if="info.bill.collectionCode == null" size="small" type="danger">未收款</el-tag>
                <el-tag v-else size="small" type="success">已收款</el-tag>
            </el-descriptions-item>
        </el-descriptions>

        <el-descriptions title="开票公司"
                         labelClassName="label"
                         class="description"
                         :column="1"
                         border
                         style="margin-bottom: 20px">
            <el-descriptions-item label="开票公司">
                <el-tag size="small">{{info.bill.paymentMethod=='1'?'江苏稻谷物业管理有限公司苏州分公司':'苏州步步高物业管理有限公司'}}</el-tag>
            </el-descriptions-item>
        </el-descriptions>

        <!-- 开票信息 区分客户类型 公司/个人-->
        <el-descriptions v-if="info.client.type === 1" title="开票信息"
                         labelClassName="label"
                         class="description"
                         :column="1"
                         border
                         style="margin-bottom: 20px">
            <el-descriptions-item label="公司名称">{{info.client.clientName}}</el-descriptions-item>
            <el-descriptions-item label="税号">{{info.client.taxNumber}}</el-descriptions-item>
            <el-descriptions-item label="开户行及账号">
                {{ (info.client.bank != null && info.client.bankAccount != null)?info.client.bank + ' ' +
                info.client.bankAccount:null}}
            </el-descriptions-item>
            <el-descriptions-item label="地址及电话">
                {{ (info.client.address != null && info.client.telephone != null)?info.client.address + ' ' +
                info.client.telephone:null}}
            </el-descriptions-item>
        </el-descriptions>

        <el-descriptions v-else title="开票信息"
                         labelClassName="label"
                         class="description"
                         :column="1"
                         border
                         style="margin-bottom: 20px">
            <el-descriptions-item label="姓名">{{info.client.clientName}}</el-descriptions-item>
            <el-descriptions-item label="身份证号">{{info.client.taxNumber}}</el-descriptions-item>
        </el-descriptions>

        <el-form :model="form" label-width="80px">

            <el-form-item label="开票内容">
                <el-input style="width: 100%"
                          type="textarea"
                          :rows="3"
                          placeholder="请输入开票内容"
                          @keydown.enter.native="handleTextareaKeydown"
                          v-model="form.content">
                </el-input>
            </el-form-item>

            <el-form-item style="text-align: right" v-if="show">
                <el-button size="small" type="primary" @click="submit">开票</el-button>
            </el-form-item>

            <el-alert v-else
                      title="此账单不可开具电子票" type="error" :closable="false"
                      description="可能是因为（1）没有用户绑定至该客户（2）绑定用户未填写邮箱 （3）客户档案-开票信息未完善">
            </el-alert>


        </el-form>
    </div>
</template>

<script>
    import {BackfillWuyeBillInvoice, GetElectronicInvoiceInfo, RequestElectronicInvoice} from "../../api";
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "WyMpInvoiceMake",
        props: {
            id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                unionid: null,
                info: null,
                form: {
                    billId: null,
                    content: null
                },
                show: false
            }
        },
        methods: {
            FormatMoney(value) {
                return formatMoney(value)
            },
            handleTextareaKeydown() {
                const e = window.event || arguments[0]
                if (e.key === 'Enter' || e.code === 'Enter' || e.keyCode === 13) {
                    this.$message.warning('开票内容不可换行！');
                    e.returnValue = false
                    return false
                }
            },
            submit() {
                if (this.form.content.indexOf('null') == -1 && this.form.content != '' && this.form.content != null) {
                    this.$alert('请确认开票内容无误！', '确认开票内容', {
                        confirmButtonText: '开票',
                        cancelButtonText: '取消',
                        cancelButtonClass: 'reject',
                        showCancelButton: true,
                        distinguishCancelAndClose: true,
                        type: 'warning',
                        callback: action => {
                            if (action === 'confirm') {
                                //开票
                                this.loading = true
                                RequestElectronicInvoice(this.form.billId, this.form.content).then((res) => {
                                    if (res.code === 'E0000') {
                                        BackfillWuyeBillInvoice(this.form.billId, res.result.invoiceSerialNum, this.unionid).then((res) => {
                                            if (res === 1) {
                                                this.$message.success('开票成功！');
                                                this.loading = false
                                                this.$emit('MakeWyMpInvoice')
                                            }
                                        })
                                    }else{
                                        this.$message.error('开票失败！');
                                        this.loading = false
                                    }
                                })
                            }
                            if (action === 'cancel') {

                            }
                        }
                    });

                } else {
                    this.$message.error('开票内容有误！');
                }
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            this.loading = true
            GetElectronicInvoiceInfo(this.id).then((res) => {
                this.info = res
                this.form.billId = res.bill.id
                this.form.content = res.content

                if (res.user == null || res.userInfo == null
                    || res.userInfo.email == null || res.userInfo.email === '') {
                    this.show = false
                } else {

                    if (res.client.type == 1) {
                        if (res.client.taxNumber == null || res.client.taxNumber === ''
                            || res.client.bank == null || res.client.bank === ''
                            || res.client.bankAccount == null || res.client.bankAccount === ''
                            || res.client.address == null || res.client.address === ''
                            || res.client.telephone == null || res.client.telephone === '') {
                            this.show = false
                        } else {
                            this.show = true
                        }

                    }

                    if (res.client.type == 2) {
                        if (res.client.taxNumber == null || res.client.taxNumber === '') {
                            this.show = false
                        } else {
                            this.show = true
                        }

                    }
                }

                if (this.info.bill.paymentMethod === '3') {
                    this.$alert('使用 苏州步步高物业管理有限公司 开票前，请先与财务联系进行后台认证，否则将开票失败！', '提示', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                }



                this.loading = false
            })
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
    }

</style>
