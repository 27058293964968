<template>

    <div>

        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
            <el-collapse-item name="collapseItem" class="form-container">
                <template slot="title">
                    <el-tag type="success" style="width: 100%;padding-left: 15px">
                        <i class="el-icon-search" style="margin-right: 5px"></i>
                        <span>共查询到 {{this.certificates.length}} 条记录</span>
                    </el-tag>
                </template>

                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px">

                    <el-form-item>
                        <el-radio-group v-model="searchObj.type" size="small" @change="submit">
                            <el-radio-button label="待审核"></el-radio-button>
                            <el-radio-button label="已审核"></el-radio-button>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="选择时间:" v-if="searchObj.type === '已审核'">
                        <el-date-picker style="width: 250px"
                                        v-model="searchObj.timeRange"
                                        type="daterange"
                                        range-separator="至"
                                        value-format="yyyy-MM-dd"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        @change="submit">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="所属项目:" style="margin-left: 10px">
                        <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                            <el-option v-for="project in projects" :key="project.abbreviation"
                                       :value="project.abbreviation"
                                       :label="project.projName"></el-option>
                        </el-select>
                    </el-form-item>

                </el-form>
            </el-collapse-item>
        </el-collapse>

        <!-- 表格 -->
        <div :style="tableContainerStyle">
            <vxe-grid ref="xTable"
                      id="elecCertificateTable"
                      class="elecCertificateTable"
                      v-bind="elecCertificateTable"
                      @toolbar-button-click="toolbarButtonClickEvent"
                      @toolbar-tool-click="toolbarToolClickEvent">

                <template #operate="{ row }">
                    <el-button type="primary" size="mini" plain @click="btnClick(row)" :disabled="!authority">查看
                    </el-button>
                </template>

            </vxe-grid>
        </div>

        <!-- 抽屉 -->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <ElecCertificateDetail :id="id"
                                   @UpdateElecCertificateAmount="UpdateElecCertificateAmount"></ElecCertificateDetail>
        </el-drawer>

        <!-- 抽屉 -->
        <el-drawer :visible.sync="addDrawer" size="50%" destroy-on-close
                   :close-on-press-escape="false"
                   :wrapperClosable="false">
            <template slot="title">
                <el-alert
                        :title="addDrawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <ElecPaymentAdd @InsertElecPayment="InsertElecPayment"></ElecPaymentAdd>
        </el-drawer>

    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {GetAuthority, GetProjects, GetProjectsWithoutAll, GetWuyeElecCertificates} from "../../api";
    import ElecCertificateDetail from './ElecCertificateDetail'
    import ElecPaymentAdd from './ElecPaymentAdd'

    export default {
        name: "WuyeElecCertificate",
        components: {ElecCertificateDetail, ElecPaymentAdd},
        data() {
            return {
                unionid: null,
                authority: false,
                drawer: false,
                drawerTitle: '',
                addDrawer: false,
                addDrawerTitle: '新增收款',
                id: null,
                projects: [],
                searchObj: {
                    type: '待审核',
                    projName: 'ALL',
                    timeRange: [
                        XEUtils.toDateString(XEUtils.getWhatDay(Date.now(), -30), 'yyyy-MM-dd'),
                        XEUtils.toDateString(Date.now(), 'yyyy-MM-dd'),
                    ]
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                certificates: [],
                columns: [
                    {resizable: true, title: '提交时间', field: 'uploadTime'},
                    {resizable: true, title: '项目', field: 'projName'},
                    {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                    {resizable: true, title: '账单月份', field: 'billMonth'},
                    {title: '操作', fixed: 'right', width: 100, slots: {default: 'operate'}}
                ]
            }
        },
        computed: {
            /*表格实例对象*/
            elecCertificateTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                        ],
                        size: 'medium',
                        tools: [
                            {code: 'add', name: '新增收款', status: 'success'},
                        ]
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    data: this.certificates,
                    columns: this.columns
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            submit() {

                var state = false

                switch (this.searchObj.type) {
                    case '待审核':
                        state = false
                        this.columns = [
                            {resizable: true, title: '提交时间', field: 'uploadTime'},
                            {resizable: true, title: '项目', field: 'projName'},
                            {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                            {resizable: true, title: '账单月份', field: 'billMonth'},
                            {title: '操作', fixed: 'right', width: 100, slots: {default: 'operate'}}
                        ]
                        break
                    case '已审核':
                        state = true
                        this.columns = [
                            {resizable: true, title: '提交时间', field: 'uploadTime'},
                            {resizable: true, title: '项目', field: 'projName'},
                            {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                            {resizable: true, title: '账单月份', field: 'billMonth'},
                            {resizable: true, title: '金额', field: 'amount', formatter: 'FormatMoney'},
                            {title: '操作', fixed: 'right', width: 100, slots: {default: 'operate'}}
                        ]
                        break
                }

                GetWuyeElecCertificates(this.searchObj, state).then((res) => {
                    this.certificates = res
                })

            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                }
            },
            btnClick(row) {
                this.id = row.id
                this.drawerTitle = row.clientName + ' - 银行转账详情'
                this.drawer = true
            },
            UpdateElecCertificateAmount() {
                this.submit()
                this.drawer = false
            },
            toolbarToolClickEvent({code}) {
                switch (code) {
                    case 'add':
                        this.addDrawer = true
                        break
                }
            },
            InsertElecPayment() {
                this.addDrawer = false
                this.submit()
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')
            GetAuthority('wuyeCertificateCheck', this.unionid).then((res) => {
                this.authority = res
            })

            GetProjects().then((res) => {
                this.projects = res
            })

            this.submit()

        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .elecCertificateTable >>> .font-blue {
        color: #45aaf2;
    }

</style>
