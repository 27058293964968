<template>

    <div class="container">
        <vxe-grid
                ref="xTable"
                id="terminalTable"
                class="terminalTable"
                v-bind="terminalTable"
                @toolbar-button-click="toolbarButtonClickEvent"
                @cell-click="cellClick">
        </vxe-grid>

        <el-drawer :visible.sync="drawer" size="50%"
                   destroy-on-close
                   :append-to-body="true">
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <ElecRecordDetail :elecId="elecId" :month="month" :projName="abbreviation"></ElecRecordDetail>
        </el-drawer>

    </div>

</template>

<script>
    import {GetTerminalMeterRecords} from "../../api";
    import ElecRecordDetail from "./ElecRecordDetail";

    export default {
        name: "TerminalMeterRecords",
        data() {
            return {
                drawer: false,
                drawerTitle: '',
                terminalMeterRecords: [],
                elecId: null
            }
        },
        components: {ElecRecordDetail},
        props: {
            abbreviation: {
                type: String,
                required: true
            },
            accountNum: {
                type: String,
                required: true
            },
            month: {
                type: String,
                required: true
            }
        },
        computed: {
            terminalTable() {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        perfect: true,
                        buttons: [
                            {code: 'export-plus', name: '导 出', status: 'perfect', icon: 'vxe-icon--download'},
                            {code: 'print', name: '打 印', status: 'perfect', icon: 'vxe-icon--print'},
                            {code: 'zoom', name: '最大化/还原', status: 'perfect', icon: 'vxe-icon--zoomout'},
                        ],
                        size: 'medium',
                        custom: true
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    printConfig: {
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.terminalMeterRecords,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '用量周期', field: 'elecCycle'},
                        {resizable: true, title: '表计名称', field: 'meterName'},
                        {resizable: true, title: '表计编号', field: 'meterId'},
                        {
                            resizable: true, title: '表计属性', field: 'meterAttribute',
                            filters: this.meterAttributes,
                            filterMethod: this.MeterAttributeFilter
                        },
                        {resizable: true, title: '上次行度', field: 'lastDegree', formatter: 'FormatNum'},
                        {resizable: true, title: '本次行度', field: 'latestDegree', formatter: 'FormatNum'},
                        {resizable: true, title: '倍率', field: 'magnification', formatter: 'FormatNum'},
                        {
                            resizable: true,
                            title: '用量',
                            field: 'elecUsage',
                            formatter: 'FormatNum',
                            sortable: true,
                            sortBy: 'elecUsage'
                        },
                    ]
                }
            }
        },
        methods: {
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'export-plus':
                        this.$refs.xTable.openExport()
                        break
                    case 'print':
                        this.$refs.xTable.openPrint()
                        break
                    case 'zoom':
                        this.$refs.xTable.zoom()
                        break

                }
            },
            cellClick({row}) {
                this.drawerTitle = row.meterAttribute + ' - ' + row.meterName + ' - ' + row.meterId
                this.elecId = row.elecId
                this.drawer = true
            }
        },
        created() {
            GetTerminalMeterRecords(this.abbreviation, this.accountNum, this.month).then((res) => {
                this.terminalMeterRecords = res
            })
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
        height: -webkit-calc(100vh - 100px);
    }

    .terminalTable >>> .vxe-body--row {
        cursor: pointer;
    }

</style>
