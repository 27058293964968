<template>
    <div v-loading="loading">
        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
            <el-collapse-item name="collapseItem" class="form-container">

                <template slot="title">
                    <el-tag type="success" style="width: 100%;padding-left: 15px">
                        <i class="el-icon-search" style="margin-right: 5px"></i>
                        <span>共查询到 {{clients.length}} 位客户</span>
                    </el-tag>
                </template>

                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px">

                    <el-form-item label="所属项目:" style="margin-left: 10px">
                        <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                            <el-option v-for="project in projects" :key="project.abbreviation"
                                       :value="project.projName"
                                       :label="project.projName"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="客户名称:">
                        <el-input v-model="searchObj.clientName" placeholder="客户名称" clearable
                                  @change="submit"></el-input>
                    </el-form-item>

                </el-form>
            </el-collapse-item>
        </el-collapse>

        <!-- 表格 -->
        <div :style="tableContainerStyle">
            <vxe-grid ref="allWuyeColletionTable"
                      id="allWuyeColletionTable"
                      class="allWuyeColletionTable"
                      v-bind="allWuyeColletionTable"
                      @toolbar-button-click="toolbarButtonClickEvent">

                <template #operate="{ row }">
                    <el-button type="primary" size="mini" @click.stop="detailBtn(row)">详情</el-button>
                    <el-button type="warning" size="mini" @click.stop="followBtn(row)">跟进</el-button>
                </template>

            </vxe-grid>
        </div>


        <!-- 客户详情 -->
        <el-drawer :visible.sync="clientDrawer" size="70%" destroy-on-close
                   :close-on-press-escape="true"
                   :wrapperClosable="true">
            <template slot="title">
                <el-alert
                        :title="clientName + ' - 详情'"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <WuyeClientCollectionDetail :clientId="clientId"></WuyeClientCollectionDetail>

        </el-drawer>

        <!-- 催费跟进 -->
        <el-drawer :visible.sync="collectionDrawer" size="50%" destroy-on-close
                   :close-on-press-escape="false"
                   :wrapperClosable="false">
            <template slot="title">
                <el-alert
                        :title="clientName + ' - 催费跟进'"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>


            <WuyeCollectionAdd :clientId="clientId" @AddWuyeCollection="AddWuyeCollection"></WuyeCollectionAdd>

        </el-drawer>

    </div>
</template>

<script>

    import {GetProjectsWithoutAll, GetWuyeAuthority, GetWuyeClientChargingSituation_ByUnionid} from "../../api";
    import formatMoney from "../../utils/formatMoney";
    import XEUtils from "xe-utils";
    import WuyeClientCollectionDetail from "./WuyeClientCollectionDetail";
    import WuyeCollectionAdd from "./WuyeCollectionAdd";

    export default {
        name: "WuyeCollectionStatistics",
        components: {WuyeClientCollectionDetail,WuyeCollectionAdd},
        data() {
            return {
                unionid: null,
                loading: false,
                projects: [],
                searchObj: {
                    projName: '',
                    clientName: ''
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                clients: [],
                clientDrawer: false,
                clientName: null,
                clientId: null,
                collectionDrawer: false
            }
        },
        computed: {
            allWuyeColletionTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'}
                        ],
                        size: 'medium',
                        custom: true
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.clients,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '所属项目', field: 'projName', visible: false},
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {resizable: true, title: '客户编号', field: 'clientId', visible: false},
                        {resizable: true, title: '总账单数', field: 'billCount',width: 100},
                        {resizable: true, title: '应收金额', field: 'amount', formatter: 'FormatMoney'},
                        {resizable: true, title: '已收金额', field: 'receivedAmount', formatter: 'FormatMoney'},
                        {resizable: true, title: '未缴金额', field: 'unpaidAmount', formatter: 'FormatMoney',className: 'font-red'},
                        {
                            resizable: true, title: '欠费天数', formatter: function ({row}) {
                                return XEUtils.getDateDiff(new Date(row.minDate).getTime(), new Date().getTime()).dd == undefined ?
                                    '' : (XEUtils.getDateDiff(new Date(row.minDate).getTime(), new Date().getTime()).time / 86400000).toFixed(0) + ' 天'
                            }
                        },
                        {title: '操作', field: 'operation', fixed: 'right', align:'center',width: 160, slots: {default: 'operate'}}
                    ],
                    showFooter: true,
                    footerRowClassName: 'footer-row',
                    footerMethod({columns, data}) {
                        return [
                            columns.map((column, columnIndex) => {
                                if (columnIndex === 0) {
                                    return '合计：'
                                }

                                if (['amount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return formatMoney((count).toFixed(2))
                                }

                                if (['receivedAmount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return formatMoney((count).toFixed(2))
                                }

                                if (['unpaidAmount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return formatMoney((count).toFixed(2))
                                }

                                return ''
                            }),
                        ]
                    }
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            async submit() {
                this.loading = true
                await GetWuyeClientChargingSituation_ByUnionid('',this.searchObj).then((res) => {
                    this.clients = res
                })
                this.loading = false
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        this.$refs.allWuyeColletionTable.openExport()
                        break
                    case 'zoom':
                        this.$refs.allWuyeColletionTable.zoom()
                        break
                }
            },
            detailBtn(row) {
                this.clientName = row.clientName
                this.clientId = row.clientId
                this.clientDrawer = true
            },
            followBtn(row) {
                this.clientName = row.clientName
                this.clientId = row.clientId
                this.collectionDrawer = true
            },
            AddWuyeCollection() {
                this.submit()
                this.collectionDrawer = false
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeAuthority(this.unionid).then((res) => {

                this.searchObj.projName = '稻谷国际中心'
                if (res.projName === 'ALL') {
                    GetProjectsWithoutAll().then((res) => {
                        this.projects = res
                    })
                } else {
                    this.projects = []
                    var arr = res.projName.split(',')

                    this.searchObj.projName = arr[0]

                    for (var i = 0; i <= arr.length - 1; i++) {
                        this.projects.push({
                            projName: arr[i]
                        })
                    }
                }

                this.submit()

            })

        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .allWuyeColletionTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .allWuyeColletionTable >>> .font-blue {
        color: #45aaf2;
    }

    .allWuyeColletionTable >>> .footer-row {
        background-color: #F8F8F9;
    }

    .allWuyeColletionTable >>> .font-red {
        color: #f10716;
    }

</style>
