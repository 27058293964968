<template>
    <el-row :gutter="15">
        <el-col :style="styleLeft">
            <el-card shadow="hover" :body-style="bodyStyle">

                <div v-if="leftContentShow">
                    <slot name="leftContent"></slot>
                </div>

                <button @click="btnClick" :style="btnStyle">
                    <i :class="icon"></i>
                </button>
            </el-card>
        </el-col>

        <el-col :style="styleRight">
            <slot name="rightContent"></slot>
        </el-col>
    </el-row>
</template>

<script>
    export default {
        name: "FolderFormTable",
        data() {
            return {
                icon: 'el-icon-caret-left',
                leftContentShow: true,
                styleLeft: {
                    width: '350px',
                    transition: 'width 0.4s'
                },
                styleRight: {
                    width: '-webkit-calc(100% - 350px)',
                    transition: 'width 0.4s'
                },
                bodyStyle: {
                    height: '-webkit-calc(100vh - 190px)',
                    position: 'relative',
                    padding: '5px',
                    paddingTop: '20px'
                },
                btnStyle: {
                    display: 'block',
                    position: 'absolute',
                    textAlign: 'center',
                    border: 'none',
                    top: '0px',
                    right: '0px',
                    width: '20px',
                    height: '-webkit-calc(100vh - 160px)',
                    cursor: 'pointer'
                }
            }
        },
        methods: {
            btnClick() {
                if (this.styleLeft.width === '55px') {
                    this.styleLeft.width = '350px'
                    this.styleRight.width = '-webkit-calc(100% - 350px)'
                    this.icon = 'el-icon-caret-left'
                    this.leftContentShow = true
                } else {
                    this.styleLeft.width = '55px'
                    this.styleRight.width = '-webkit-calc(100% - 55px)'
                    this.icon = 'el-icon-caret-right'
                    this.leftContentShow = false
                }
            }
        }
    }
</script>

<style scoped>


</style>
