<template>

    <div>

        <div v-loading="loading" v-if="authority">
            <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
                <el-collapse-item name="collapseItem" class="form-container">

                    <template slot="title">
                        <el-tag type="success" style="width: 100%;padding-left: 15px">
                            <i class="el-icon-search" style="margin-right: 5px"></i>
                            <span>共查询到 {{invoiceInfoList.length}} 条记录</span>
                        </el-tag>
                    </template>

                    <el-form :inline="true"
                             :model="searchObj"
                             class="form"
                             size="small"
                             label-width="72px">

                        <el-form-item label="所属项目:">
                            <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                                <el-option v-for="project in projects" :key="project.abbreviation"
                                           :value="project.projName"
                                           :label="project.projName"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="开票月份:">
                            <el-date-picker :clearable="false"
                                            v-model="searchObj.month"
                                            value-format="yyyy-MM"
                                            style="width: 100%"
                                            type="month" placeholder="选择月份" @change="submit">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="客户名称:">
                            <el-input v-model="searchObj.clientName" placeholder="客户名称" clearable
                                      @change="submit"></el-input>
                        </el-form-item>

                    </el-form>

                </el-collapse-item>
            </el-collapse>

            <!-- 表格 -->
            <div :style="tableContainerStyle">

                <vxe-grid ref="invoiceInfoTable"
                          id="invoiceInfoTable"
                          class="invoiceInfoTable"
                          v-bind="invoiceInfoTable"
                          @toolbar-button-click="toolbarButtonClickEvent"
                          @cell-click="cellClick">

                    <template #paymentMethod="{ row }">
                        <el-tag v-if="row.paymentMethod == '1'" type="warning" size="small">江苏稻谷物业管理有限公司苏州分公司</el-tag>
                        <el-tag v-if="row.paymentMethod == '3'" type="warning" size="small">苏州步步高物业管理有限公司</el-tag>
                    </template>

                </vxe-grid>
            </div>

            <!-- 发票详情 -->
            <el-drawer :visible.sync="drawer" size="50%" destroy-on-close>
                <template slot="title">
                    <el-alert
                            title="发票详情"
                            type="warning"
                            :closable="false">
                    </el-alert>
                </template>

                <div class="container" v-loading="loading2">

                    <el-image v-if="invoiceDetail != null"
                              style="width: 100%; height: 550px;margin-bottom: 10px"
                              :src="invoiceDetail.imgUrls"
                              fit="fill"
                              :preview-src-list="[invoiceDetail.imgUrls]">
                    </el-image>

                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div style="text-align: left;">
                                <a :href="invoiceDetail.pdfUrl" target="_blank">查看PDF文件</a>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div style="text-align: right" v-if="invoiceDetail.xmlUrl">
                                <a :href="invoiceDetail.xmlUrl" target="_blank">查看xml文件</a>
                            </div>
                        </el-col>
                    </el-row>

                    <el-divider content-position="left">关联账单</el-divider>
                    <vxe-grid ref="invoiceBillListTable"
                              id="invoiceBillListTable"
                              class="invoiceBillListTable"
                              v-bind="invoiceBillListTable">

                        <template #collection="{ row }">
                            <el-tag v-if="row.collectionCode != null" type="success" size="small">已收款</el-tag>
                            <el-tag v-else type="danger" size="small">未收款</el-tag>
                        </template>

                        <template #invoice="{ row }">
                            <el-tag v-if="row.paymentMethod == '2'" type="info" size="small">不可开票</el-tag>
                            <el-tag v-if="row.invoiceInfo == 'empty'" type="warning" size="small">无需开票</el-tag>
                            <el-tag v-if="row.paymentMethod != '2' && row.invoiceInfo != null && row.invoiceInfo != 'empty'"
                                    type="success" size="small">已开票
                            </el-tag>
                            <el-tag v-if="row.paymentMethod != '2' && row.invoiceInfo == null" type="danger"
                                    size="small">
                                未开票
                            </el-tag>
                        </template>

                        <template #paymentMethod="{ row }">
                            <el-tag v-if="(row.paymentMethod != '2') && row.collectionCode != null" size="small">对公
                            </el-tag>
                            <!--<el-tag v-if="row.paymentMethod == '1' && row.collectionCode == null" size="small">未支付</el-tag>-->
                            <el-tag v-if="row.paymentMethod == '2' && row.collectionCode != null" type="info"
                                    size="small">
                                现金
                            </el-tag>
                        </template>
                    </vxe-grid>
                </div>
            </el-drawer>


        </div>

        <div v-else>
            <el-result style="height: -webkit-calc(100vh - 250px)"
                       icon="warning" title="无权限"
                       subTitle="抱歉，无权限操作">
            </el-result>
        </div>


    </div>

</template>

<script>
    import {
        GetOpenedElectronicInvoiceInfo,
        GetProjectsWithoutAll,
        GetWuyeAuthority,
        GetWuyeBillsByInvoiceInfo,
        SearchFpqqlsh
    } from "../../api";
    import XEUtils from 'xe-utils'
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "WuyeInvoiceView",
        data() {
            return {
                loading: false,
                loading2: false,
                unionid: null,
                authority: true,
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                projects: [],
                searchObj: {
                    projName: '稻谷国际中心',
                    month: XEUtils.toDateString(XEUtils.getWhatMonth(new Date()), 'yyyy-MM'),
                    clientName: ''
                },
                invoiceInfoList: [],
                drawer: false,
                billList: [],
                invoiceDetail: {}
            }
        },
        computed: {
            invoiceInfoTable: function () {
                return {
                    height: 'auto',
                    size: 'small',
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'}
                        ],
                        size: 'medium'
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.invoiceInfoList,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '项目名称', field: 'projName'},
                        {resizable: true, title: '开票时间', field: 'time'},
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {resizable: true, title: '发票请求流水号', field: 'fpqqlsh'},
                        {resizable: true, title: '开票公司', field: 'paymentMethod',width: '250',slots: {default: 'paymentMethod'}},
                        {resizable: true, title: '金额', field: 'amount', formatter: 'FormatMoney'},
                        {resizable: true, title: '开票人', field: 'name'},
                        {resizable: true, title: '收款类', field: 'U8fee'},
                        {resizable: true, title: '账单摘要', field: 'chargeContent'}
                    ],
                    showFooter: true,
                    footerRowClassName: 'footer-row',
                    footerMethod({columns, data}) {
                        return [
                            columns.map((column, columnIndex) => {
                                if (columnIndex === 0) {
                                    return '合计：'
                                }

                                if (['amount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return formatMoney(count.toFixed(2))
                                }

                                return ''
                            }),
                        ]
                    }
                }
            },
            invoiceBillListTable: function () {
                return {
                    height: '300',
                    showOverflow: true,
                    size: 'small',
                    rowConfig: {
                        isHover: true
                    },
                    data: this.billList,
                    columns: [
                        {resizable: true, title: '所属项目', field: 'projName', visible: false},
                        {resizable: true, title: '账单月份', field: 'billMonth'},
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {resizable: true, title: '收费项目', field: 'u8fee'},
                        {resizable: true, title: '实际收款', field: 'receivedAmount', formatter: 'FormatMoney'},
                        {
                            resizable: true, title: '收款', field: 'collectionCode', slots: {default: 'collection'},

                        },
                        {
                            resizable: true, title: '结算方式', field: 'paymentMethod', slots: {default: 'paymentMethod'},

                        },
                        {
                            resizable: true, title: '发票信息', field: 'invoiceInfo', slots: {default: 'invoice'},
                        }
                    ],
                    showFooter: true,
                    footerRowClassName: 'footer-row',
                    footerMethod({columns, data}) {
                        return [
                            columns.map((column, columnIndex) => {
                                if (columnIndex === 0) {
                                    return '合计：'
                                }

                                if (['receivedAmount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return formatMoney(count.toFixed(2))
                                }

                                return ''
                            }),
                        ]
                    }
                }
            },
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            submit() {
                this.loading = true
                GetOpenedElectronicInvoiceInfo(this.searchObj).then((res) => {
                    this.invoiceInfoList = res
                    this.loading = false
                })
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        this.$refs.invoiceInfoTable.openExport()
                        break
                }
            },
            cellClick({row}) {
                this.drawer = true
                this.loading2 = true
                GetWuyeBillsByInvoiceInfo(row.invoiceInfo).then((res) => {
                    this.billList = res
                    SearchFpqqlsh(row.paymentMethod, row.fpqqlsh).then((res) => {
                        this.invoiceDetail = res.result[0]
                        this.loading2 = false
                    })
                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeAuthority(this.unionid).then((res) => {

                /* 权限控制 */
                if (res.authorities.indexOf('invoiceView') != -1) {
                    this.authority = true
                } else {
                    this.authority = false
                }

                this.searchObj.projName = '稻谷国际中心'
                if (res.projName === 'ALL') {
                    GetProjectsWithoutAll().then((res) => {
                        this.projects = res
                    })
                } else {
                    this.projects = []
                    var arr = res.projName.split(',')

                    this.searchObj.projName = arr[0]

                    for (var i = 0; i <= arr.length - 1; i++) {
                        this.projects.push({
                            projName: arr[i]
                        })
                    }
                }

                this.submit()
            })

        },
        mounted() {
            this.$nextTick(function () {
                if (this.authority) {
                    this.bigHeight = this.$refs.collapse.$el.offsetHeight
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                }
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .invoiceInfoTable >>> .font-blue {
        color: #45aaf2;
    }

    .invoiceInfoTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .invoiceInfoTable >>> .footer-row {
        background-color: #F8F8F9;
    }

    .invoiceBillListTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .invoiceBillListTable >>> .footer-row {
        background-color: #F8F8F9;
    }

    .container {
        width: 94%;
        margin-left: 3%;
    }

</style>
