<template>
    <div class="container" v-loading="loading">

        <el-form ref="followUpEditForm" :model="form" :rules="rules" label-width="80px" size="medium">

            <el-form-item label="跟进人">
                <el-select v-model="form.employee" style="width: 100%" disabled>
                    <el-option
                            v-for="item in employees"
                            :key="item.unionid"
                            :label="item.name"
                            :value="item.unionid">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="跟进时间" required prop="followUpDate">
                <el-date-picker style="width: 100%"
                                v-model="form.followUpDate"
                                type="datetime"
                                :clearable="false"
                                value-format="yyyy-MM-dd hh:mm"
                                placeholder="选择跟进时间">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="跟进方式" required prop="followUpWay">
                <el-select v-model="form.followUpWay" placeholder="跟进方式" style="width: 100%">
                    <el-option v-for="item in followUpWays" :key="item.name"
                               :value="item.name"
                               :label="item.name"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="当前阶段" required prop="currentStage">
                <el-select v-model="form.currentStage" placeholder="当前阶段" style="width: 100%">
                    <el-option v-for="item in currentStages" :key="item.name"
                               :value="item.name"
                               :label="item.name"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="协同人">
                <el-select v-model="form.coordinator"
                           clearable multiple filterable placeholder="请选择协同人" style="width: 100%">
                    <el-option
                            v-for="item in employees"
                            :key="item.unionid"
                            :label="item.name"
                            :value="item.unionid">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="沟通内容" required prop="visitContent">
                <el-input
                        type="textarea"
                        :autosize="{ minRows: 4}"
                        placeholder="沟通内容"
                        v-model="form.visitContent">
                </el-input>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" @click="submit">保存</el-button>
            </el-form-item>

        </el-form>

    </div>
</template>

<script>
    import XEUtils from 'xe-utils'
    import {
        GetBusinessFollowUp,
        GetBusinessFollowUpWays,
        GetCurrentStages,
        GetEmployees,
        UpdateBusinessFollowUp
    } from "../../api";

    export default {
        name: "FollowUpEdit",
        props: {
            businessFollowUpId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                unionid: null,
                loading: false,
                currentStages: [],
                followUpWays: [],
                employees: [],
                businessId: null,
                form: {
                    employee: null,
                    followUpDate: null,
                    followUpWay: null,
                    currentStage: null,
                    coordinator: [],
                    visitContent: null
                },
                rules: {
                    followUpDate: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    followUpWay: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    currentStage: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    visitContent: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                }
            }
        },
        methods: {

            submit() {
                this.$refs.followUpEditForm.validate((e) => {

                    if (e) {
                        UpdateBusinessFollowUp(this.businessFollowUpId, this.form, this.unionid, this.businessId).then((res) => {
                            if (res === 1) {
                                this.$emit('FollowUpUpdate')
                            }
                        })
                    } else {
                        this.$message.error('有未填项！');
                    }

                })
            }
        },
        created() {

            this.unionid = sessionStorage.getItem('unionid')

            GetCurrentStages().then((res) => {
                this.currentStages = res
            })

            GetBusinessFollowUpWays().then((res) => {
                this.followUpWays = res
            })

            GetEmployees('招商部', 'null').then((res) => {
                this.employees = res
            })

            GetBusinessFollowUp(this.businessFollowUpId).then((res) => {
                this.businessId = res.businessId
                this.form.employee = res.employee
                this.form.followUpDate = res.followUpDate
                this.form.followUpWay = res.followUpWay
                this.form.currentStage = res.currentStage
                if (res.coordinator != null) {
                    this.form.coordinator = res.coordinator.split(',')
                }
                this.form.visitContent = res.visitContent
            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

</style>
