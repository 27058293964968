<template>

    <div class="container" v-loading="loading">

        <!-- 描述列表 -->
        <el-descriptions title="记录详情"
                         labelClassName="label"
                         class="description"
                         :column="2"
                         border
                         style="margin-bottom: 20px">
            <el-descriptions-item label="车位号" :span="2">{{detail.parkingNo}}</el-descriptions-item>
            <el-descriptions-item label="开始时间">{{detail.startDate}}</el-descriptions-item>
            <el-descriptions-item label="结束时间">{{detail.endDate}}</el-descriptions-item>
            <el-descriptions-item label="月租金">{{MoneyFormat(detail.rentPrice)}}</el-descriptions-item>
            <el-descriptions-item label="月管理费">{{MoneyFormat(detail.managementPrice)}}</el-descriptions-item>
            <el-descriptions-item label="月份数">{{detail.months + ' 个月'}}</el-descriptions-item>
            <el-descriptions-item label="减免金额">{{MoneyFormat(detail.reliefAmount)}}</el-descriptions-item>
            <el-descriptions-item label="总金额" :span="2">
                <el-tag size="small">{{MoneyFormat(detail.amount)}}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="客户名称" :span="2">{{detail.clientName}}</el-descriptions-item>
            <el-descriptions-item label="备注" :span="2">{{detail.remark}}</el-descriptions-item>
            <el-descriptions-item label="创建时间" :span="2">{{detail.createTime}}</el-descriptions-item>
            <el-descriptions-item label="创建人" :span="2">{{detail.maker}}</el-descriptions-item>
            <el-descriptions-item label="是否生成账单" :span="2">
                <el-tag v-if="!detail.generate" size="small" type="danger">未同步至账单</el-tag>
                <el-tag v-else size="small" type="success">已同步至账单</el-tag>
            </el-descriptions-item>
        </el-descriptions>

    </div>

</template>

<script>
    import {GetParkingRecordDetail} from "../../api";
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "ParkingRecordDetail",
        props: {
            recordId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                detail: {}
            }
        },
        methods: {
            MoneyFormat(value) {
                return formatMoney(value)
            }
        },
        created() {

            this.loading = true
            GetParkingRecordDetail(this.recordId).then((res) => {
                this.detail = res
                this.loading = false
            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
        white-space: pre-line
    }

</style>
