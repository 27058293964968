<template>

    <div>

        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
            <el-collapse-item name="collapseItem" class="form-container">
                <template slot="title">
                    <el-tag type="success" style="width: 100%;padding-left: 15px">
                        <i class="el-icon-search" style="margin-right: 5px"></i>
                        <span>共查询到 {{payments.length}} 条记录</span>
                    </el-tag>
                </template>

                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px">

                    <el-form-item label="选择时间:">
                        <el-date-picker style="width: 250px"
                                        v-model="searchObj.timeRange"
                                        type="daterange"
                                        range-separator="至"
                                        value-format="yyyy-MM-dd"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        @change="submit"
                                        :clearable="false">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="所属项目:" style="margin-left: 10px">
                        <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                            <el-option v-for="project in projects" :key="project.abbreviation"
                                       :value="project.abbreviation"
                                       :label="project.projName"></el-option>
                        </el-select>
                    </el-form-item>

                </el-form>
            </el-collapse-item>
        </el-collapse>

        <div :style="tableContainerStyle">
            <vxe-grid ref="xTable"
                      id="elecPaymentTable"
                      class="elecPaymentTable"
                      v-bind="elecPaymentTable"
                      @toolbar-button-click="toolbarButtonClickEvent"
                      @cell-click="cellClick">
            </vxe-grid>
        </div>

        <!-- 抽屉 -->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close>
            <template slot="title">
                <el-alert
                        :title="drawerTitle"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>

            <ElecPaymentDetail :id="id"></ElecPaymentDetail>

        </el-drawer>

    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {GetAuthority, GetProjects, GetProjectsWithoutAll, GetWuyeElecPayments} from "../../api";
    import ElecPaymentDetail from './ElecPaymentDetail'

    export default {
        name: "WuyeElecPayment",
        components: {ElecPaymentDetail},
        data() {
            return {
                unionid: null,
                authority: false,
                drawer: false,
                drawerTitle: null,
                projects: [],
                searchObj: {
                    projName: 'ALL',
                    timeRange: [
                        XEUtils.toDateString(XEUtils.getWhatDay(Date.now(), -1), 'yyyy-MM-dd'),
                        XEUtils.toDateString(XEUtils.getWhatDay(Date.now(), -1), 'yyyy-MM-dd'),
                    ]
                },
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                payments: [],
                id: null
            }
        },
        computed: {
            /*表格实例对象*/
            elecPaymentTable: function () {
                return {
                    height: 'auto',
                    showOverflow: true,
                    size: 'small',
                    border: true,
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                        ],
                        size: 'medium',
                        custom: true
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.payments,
                    columns: [
                        {resizable: true, title: '缴费时间', field: 'elecPayment.createTime'},
                        {resizable: true, title: '项目', field: 'elecPayment.projName'},
                        {resizable: true, title: '客户', field: 'elecPayment.clientName', className: 'font-blue'},
                        {resizable: true, title: '账单月份', field: 'elecPayment.billMonth'},
                        {resizable: true, title: '金额', field: 'elecPayment.amount', formatter: 'FormatMoney'},
                        {resizable: true, title: '手续费', field: 'feeAmt', formatter: 'FormatMoney'},
                        {resizable: true, title: '扣费后金额', field: 'receivAmount', formatter: 'FormatMoney'},
                        {resizable: true, title: '订单号', field: 'elecPayment.orderNo'},
                        {resizable: true, title: '支付流水号', field: 'elecPayment.partyOrderId'}
                    ],
                    showFooter: true,
                    footerRowClassName: 'footer-row',
                    footerMethod({columns, data}) {
                        return [
                            columns.map((column, columnIndex) => {
                                if (columnIndex === 0) {
                                    return '合计：'
                                }
                                if (['elecPayment.amount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item.elecPayment.amount)
                                    })
                                    return count.toFixed(2) + ' 元'
                                }

                                if (['feeAmt'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return count.toFixed(2) + ' 元'
                                }

                                if (['receivAmount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return count.toFixed(2) + ' 元'
                                }

                                return ''
                            }),
                        ]
                    }
                }
            }
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            submit() {

                GetWuyeElecPayments(this.searchObj).then((res) => {
                    this.payments = res
                })

            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'export-plus':
                        this.$refs.xTable.openExport()
                        break
                    case 'refresh':
                        this.submit()
                        break
                }
            },
            cellClick({row}) {
                if (this.authority) {
                    this.id = row.elecPayment.id
                    this.drawerTitle = row.elecPayment.billMonth + ' ' + row.elecPayment.clientName + ' - 电费 - 支付详情'
                    this.drawer = true
                }
            }

        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')
            GetAuthority('wuyePayment', this.unionid).then((res) => {
                this.authority = res
            })

            GetProjects().then((res) => {
                this.projects = res
            })

            this.submit()

        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .elecPaymentTable >>> .footer-row {
        background-color: #F8F8F9;
    }

    .elecPaymentTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .elecPaymentTable >>> .font-blue {
        color: #45aaf2;
    }

</style>
