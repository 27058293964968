<template>
    <div class="container" v-loading="loading">
        <el-form :model="followUpForm" label-width="80px" size="medium">

            <el-form-item label="跟进时间" required>
                <el-date-picker style="width: 100%"
                                v-model="followUpForm.followUpDate"
                                type="datetime"
                                :clearable="false"
                                value-format="yyyy-MM-dd hh:mm"
                                placeholder="选择跟进时间" @change="check">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="跟进方式" required>
                <el-select v-model="followUpForm.followUpWay" placeholder="跟进方式" style="width: 100%" @change="check">
                    <el-option v-for="item in followUpWays" :key="item.name"
                               :value="item.name"
                               :label="item.name"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="当前阶段" required>
                <el-select v-model="followUpForm.currentStage" placeholder="当前阶段" style="width: 100%" @change="check">
                    <el-option v-for="item in currentStages" :key="item.name"
                               :value="item.name"
                               :label="item.name"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="协同人">
                <el-select v-model="followUpForm.coordinator"
                           clearable multiple filterable placeholder="请选择协同人" style="width: 100%" @change="check">
                    <el-option
                            v-for="item in employees"
                            :key="item.unionid"
                            :label="item.name"
                            :value="item.unionid">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="沟通内容" required>
                <el-input
                        type="textarea"
                        :autosize="{ minRows: 4}"
                        placeholder="沟通内容"
                        v-model="followUpForm.visitContent" @input="check">
                </el-input>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" :disabled="!disable" @click="submit">跟进</el-button>
            </el-form-item>

        </el-form>
    </div>
</template>

<script>
    import XEUtils from 'xe-utils'
    import {GetBusinessFollowUpWays, GetCurrentStages, GetEmployees, InsertBusinessFollowUp} from "../../api";

    export default {
        name: "BusinessFollowUp",
        data() {
            return {
                loading: false,
                unionid: null,
                followUpWays: [],
                currentStages: [],
                employees: [],
                followUpForm: {
                    businessId: null,
                    followUpDate: XEUtils.toDateString(Date.now(), 'yyyy-MM-dd hh:mm'),
                    followUpWay: null,
                    currentStage: null,
                    coordinator: null,
                    visitContent: null,
                },
                disable: false
            }
        },
        props: {
            id: {
                type: Number,
                required: true
            }
        },
        methods: {
            check() {
                if (this.followUpForm.followUpWay == null
                    || this.followUpForm.currentStage == null
                    || this.followUpForm.visitContent == null || String(this.followUpForm.visitContent).trim() === '') {
                    this.disable = false
                } else {
                    this.disable = true
                }
            },
            submit() {
                this.loading = true
                InsertBusinessFollowUp(this.followUpForm, this.unionid).then((res) => {
                    this.loading = false
                    this.$emit('businessFollowUp')
                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')
            this.followUpForm.businessId = this.id

            GetCurrentStages().then((res) => {
                this.currentStages = res
            })

            GetBusinessFollowUpWays().then((res) => {
                this.followUpWays = res
            })

            GetEmployees('招商部', this.unionid).then((res) => {
                this.employees = res
            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

</style>
