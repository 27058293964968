<template>

    <div class="container" v-loading="loading" v-if="info != null">


        <el-descriptions title="客户信息"
                         labelClassName="label"
                         class="description"
                         :column="2"
                         size="medium"
                         border
                         style="margin-bottom: 20px">
            <el-descriptions-item label="客户名称" :span="2">{{info.user.clientName}}</el-descriptions-item>
            <el-descriptions-item label="所属项目">{{info.user.projName}}</el-descriptions-item>
            <el-descriptions-item label="客户编号">{{info.user.clientId}}</el-descriptions-item>
            <el-descriptions-item label="用户名称">{{info.userInfo.name}}</el-descriptions-item>
            <el-descriptions-item label="联系电话">{{info.userInfo.phone}}</el-descriptions-item>
            <el-descriptions-item label="微信绑定手机号">{{info.user.telephone}}</el-descriptions-item>
            <el-descriptions-item label="邮箱">{{info.userInfo.email}}</el-descriptions-item>
        </el-descriptions>

        <el-descriptions title="收款信息"
                         labelClassName="label"
                         class="description"
                         :column="2"
                         size="medium"
                         border
                         style="margin-bottom: 20px">
            <el-descriptions-item label="支付方式" :span="2">{{payType}}</el-descriptions-item>
            <el-descriptions-item label="金额">{{MoneyFormat(info.amount)}}</el-descriptions-item>
            <el-descriptions-item label="账单月份">{{billMonth}}</el-descriptions-item>
        </el-descriptions>

        <el-descriptions v-if="info.client.type === 1" title="开票信息"
                         labelClassName="label"
                         class="description"
                         :column="1"
                         border
                         style="margin-bottom: 20px">
            <el-descriptions-item label="公司名称">{{info.client.clientName}}</el-descriptions-item>
            <el-descriptions-item label="税号">{{info.client.taxNumber}}</el-descriptions-item>
            <el-descriptions-item label="开户行及账号">
                {{ (info.client.bank != null && info.client.bankAccount != null)?info.client.bank + ' ' +
                info.client.bankAccount:null}}
            </el-descriptions-item>
            <el-descriptions-item label="地址及电话">
                {{ (info.client.address != null && info.client.telephone != null)?info.client.address + ' ' +
                info.client.telephone:null}}
            </el-descriptions-item>
        </el-descriptions>

        <el-descriptions v-else title="开票信息"
                         labelClassName="label"
                         class="description"
                         :column="1"
                         border
                         style="margin-bottom: 20px">
            <el-descriptions-item label="姓名">{{info.client.clientName}}</el-descriptions-item>
            <el-descriptions-item label="身份证号">{{info.client.taxNumber}}</el-descriptions-item>
        </el-descriptions>

        <el-form :model="form" label-width="80px">

            <el-form-item label="开票内容">
                <el-input style="width: 100%"
                          type="textarea"
                          :rows="3"
                          placeholder="请输入开票内容"
                          v-model="form.content">
                </el-input>
            </el-form-item>

            <el-alert v-if="!disabled"
                      title="(1)用户邮箱为空  或  (2)开票信息未完善"
                      type="error"
                      :closable="false" style="margin-bottom: 10px">
            </el-alert>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" :disabled="!disabled" @click="submit">开票</el-button>
            </el-form-item>

        </el-form>


    </div>

</template>

<script>
    import {
        GetElecInvoiceInfo,
        InsertOperationRecord,
        OpenElecInvoice,
        TakeEffectElectronicElecInvoice
    } from "../../api";
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "MakeElecInvoice",
        props: {
            clientId: {
                required: true,
                type: String
            },
            billMonth: {
                required: true,
                type: String
            },
            payType: {
                required: true,
                type: String
            }
        },
        data() {
            return {
                unionid: null,
                loading: false,
                info: null,
                form: {
                    content: null
                },
                disabled: false
            }
        },
        methods: {
            MoneyFormat(value) {
                return formatMoney(value);
            },
            NewlineFormat(value) {
                return value.replace("\n", "<br/>")
            },
            submit() {

                if (this.form.content.indexOf('null') == -1 && this.form.content != '' && this.form.content != null) {

                    this.$alert('请确认开票内容无误！', '确认开票内容', {
                        confirmButtonText: '开票',
                        cancelButtonText: '取消',
                        cancelButtonClass: 'reject',
                        showCancelButton: true,
                        distinguishCancelAndClose: true,
                        type: 'warning',
                        callback: action => {
                            if (action === 'confirm') {
                                //开票
                                this.loading = true
                                OpenElecInvoice(this.clientId, this.info.amount, this.form.content, this.billMonth).then((res) => {
                                    TakeEffectElectronicElecInvoice(this.clientId, this.billMonth, res.fpqqlsh, this.info.amount, this.unionid).then((res) => {
                                        if (res === 1) {
                                            InsertOperationRecord(this.unionid, 'checkElecInvoice', this.clientId + ',' + this.billMonth).then((res) => {
                                                if (res === 1) {
                                                    this.loading = false
                                                    this.$emit('ElecInvoiceMake')
                                                }
                                            })
                                        }
                                    })
                                })
                            }
                            if (action === 'cancel') {

                            }

                        }
                    });

                } else {
                    this.$message.error('开票内容有误！');
                }
            }
        },
        created() {

            this.unionid = sessionStorage.getItem('unionid')

            GetElecInvoiceInfo(this.payType, this.clientId, this.billMonth).then((res) => {
                this.info = res
                this.form.content = res.content

                if (res.client.type === 1) {
                    //公司客户
                    if (res.client.taxNumber == null || res.client.taxNumber === ''
                        || res.client.bank == null || res.client.bank === ''
                        || res.client.bankAccount == null || res.client.bankAccount === ''
                        || res.client.address == null || res.client.address === ''
                        || res.client.telephone == null || res.client.telephone === ''
                        || res.userInfo.email == null || res.userInfo.email === '') {
                        this.disabled = false
                    } else {
                        this.disabled = true
                    }

                } else {
                    //个人客户
                    if (res.client.taxNumber == null || res.client.taxNumber === ''
                        || res.userInfo.email == null || res.userInfo.email === '') {
                        this.disabled = false
                    } else {
                        this.disabled = true
                    }
                }

            })

        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .description {
        margin-bottom: 20px;
    }

    .el-descriptions >>> .label {
        width: 150px;
    }

    .el-descriptions >>> .content {
        width: auto;
        white-space: pre-line
    }

</style>
