import { render, staticRenderFns } from "./WuyeBillSend.vue?vue&type=template&id=0b9e121b&scoped=true&"
import script from "./WuyeBillSend.vue?vue&type=script&lang=js&"
export * from "./WuyeBillSend.vue?vue&type=script&lang=js&"
import style0 from "./WuyeBillSend.vue?vue&type=style&index=0&id=0b9e121b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b9e121b",
  null
  
)

export default component.exports