<template>

    <el-container class="page-container">
        <el-header class="header-container">
            <TopNav @ChangeModule="ChangeModule($event)" :moduleName="module"></TopNav>
        </el-header>
        <el-container>
            <el-aside class="side-container">
                <SideNav :moduleName="module" :menuName="newTab" @ChangeMenu="ChangeMenu($event)"></SideNav>
            </el-aside>
            <el-main class="main-container">
                <Tab :newTab="newTab" @ChangeModule="ChangeModule($event)" @ChangeMenu="ChangeMenu($event)"></Tab>
            </el-main>
        </el-container>
    </el-container>

</template>

<script>
    import TopNav from '../components/TopNav.vue'
    import SideNav from "../components/SideNav";
    import Tab from "../components/Tab";
    import {Login} from "../api";

    export default {
        name: "Home",
        data() {
            return {
                module: 'assetsModule',
                newTab: 'houseManagement'
            };
        },
        methods: {
            /*模块变化*/
            ChangeModule(data) {
                this.module = data
            },
            /*菜单变化*/
            ChangeMenu(data) {
                this.newTab = data
            }

        },
        components: {TopNav, SideNav, Tab}
    }
</script>

<style scoped>
    .page-container {
        height: 100%;
        background-color: #F6F6F6;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
    }

    .header-container {
        height: 61px !important;
        background-color: #ffffff;
        border-bottom: solid 1px #e6e6e6;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        z-index: 999;
    }

    .side-container {
        width: auto !important;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
    }

    .main-container {
        padding: 10px;
    }


</style>
