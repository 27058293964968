<template>

    <div class="container" v-loading="loading">

        <el-form :model="addForm" ref="wuyeCollectionAddForm" :rules="rules" label-width="80px" size="medium">

            <el-form-item label="跟进时间" required prop="date">
                <el-date-picker style="width: 100%"
                                v-model="addForm.date"
                                type="date"
                                :clearable="false"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期时间">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="沟通情况" required prop="content">
                <el-input
                        type="textarea"
                        :autosize="{ minRows: 4}"
                        placeholder="沟通情况"
                        v-model="addForm.content">
                </el-input>
            </el-form-item>

            <el-form-item label="图片附件" required>
                <el-upload action="/api/uploadWuyeCollectionPic"
                           list-type="picture-card"
                           :auto-upload="false"
                           accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG"
                           :data="addForm"
                           :on-change="handleEditChange"
                           :on-exceed="handleExceed"
                           :on-success="handleSuccess"
                           :limit="5"
                           multiple
                           ref="fileupload2">
                    <i slot="default" class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{file}">

                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                        <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-preview"
                                      @click="handlePictureCardPreview(file)">
                                  <i class="el-icon-zoom-in"></i>
                                </span>

                                <span class="el-upload-list__item-delete"
                                      @click="handleRemove(file)">
                                  <i class="el-icon-delete"></i>
                                </span>

                            </span>
                    </div>
                </el-upload>
            </el-form-item>

            <el-form-item style="text-align: right">
                <el-button size="small" type="success" @click="submit">保 存</el-button>
            </el-form-item>

        </el-form>


        <el-dialog width="50%" :visible.sync="dialogVisible" :append-to-body="true">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>

    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {InsertWuyeCollectionRecord} from "../../api";

    export default {
        name: "WuyeCollectionAdd",
        props: {
            clientId: {
                type:String,
                required: true
            }
        },
        data() {
            return {
                unionid: null,
                loading: false,
                addForm: {
                    date: XEUtils.toDateString(Date.now(), 'yyyy-MM-dd'),
                    content: '',
                    clientId: ''
                },
                rules: {
                    date: [
                        {required: true, message: '不能为空', trigger: ['blur', 'change']}
                    ],
                    content: [
                        {required: true, message: '不能为空', trigger: ['blur', 'change']}
                    ],
                },
                dialogImageUrl: '',
                dialogVisible: false,
                uploadCount: 0
            }
        },
        methods:{
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleEditChange(file) {
                const isLt2M = file.size / 1024 / 1024 < 10;
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 10MB!');
                    this.handleRemove(file)
                }
            },
            handleExceed() {
                this.$message.error('最多5张！');
            },
            handleRemove(file) {
                let uploadFiles = this.$refs['fileupload2'].uploadFiles;
                let index = uploadFiles.indexOf(file);
                uploadFiles.splice(index, 1);
            },
            submit() {
                this.$refs.wuyeCollectionAddForm.validate((e) => {
                    if (this.$refs['fileupload2'].uploadFiles.length>0 && e) {
                        this.loading = true
                        this.$refs['fileupload2'].submit();
                    }else{
                        this.$message.error('有未填项！');
                    }
                })
            },
            handleSuccess(response, file, fileList) {
                this.uploadCount ++

                if (this.uploadCount == this.$refs['fileupload2'].uploadFiles.length) {
                    /* 所有文件上传完毕 */

                    let fileNameList = fileList.map(item => item.response)

                    InsertWuyeCollectionRecord(this.addForm,fileNameList,this.unionid).then((res) => {
                        if (res === 'success') {
                            this.$message.success('提交成功！');
                            this.$emit('AddWuyeCollection')
                            this.loading = false
                        }else{
                            this.$message.error('提交失败！');
                        }
                    })
                }


            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')
            this.addForm.clientId = this.clientId
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

</style>
