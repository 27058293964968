<template>

    <div>
        <div v-loading="loading" v-if="authority">

            <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
                <el-collapse-item name="collapseItem" class="form-container">
                    <template slot="title">
                        <el-tag type="success" style="width: 100%;padding-left: 15px">
                            <i class="el-icon-search" style="margin-right: 5px"></i>
                            <span v-if="searchObj.type === '待处理'">共查询到 {{invoicingList.length}} 条开票申请</span>
                            <span v-else>共查询到 {{invoicedList.length}} 条记录</span>
                        </el-tag>
                    </template>

                    <el-form :inline="true"
                             :model="searchObj"
                             class="form"
                             size="small"
                             label-width="72px">

                        <el-form-item>
                            <el-radio-group v-model="searchObj.type" size="small" @change="submit">
                                <el-radio-button label="待处理"></el-radio-button>
                                <el-radio-button label="已完成"></el-radio-button>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="所属项目:" style="margin-left: 10px">
                            <el-select v-model="searchObj.projName" placeholder="所属项目" @change="submit">
                                <el-option v-for="project in projects" :key="project.abbreviation"
                                           :value="project.projName"
                                           :label="project.projName"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="申请时间:" v-show="searchObj.type === '已完成'">
                            <el-date-picker style="width: 250px"
                                            v-model="searchObj.timeRange"
                                            type="daterange"
                                            range-separator="至"
                                            value-format="yyyy-MM-dd"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            @change="submit"
                                            :clearable="false">
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                </el-collapse-item>
            </el-collapse>


            <!-- 表格 -->
            <div :style="tableContainerStyle">
                <vxe-grid ref="invoicingParkOrderTable"
                          id="invoicingParkOrderTable"
                          class="invoicingParkOrderTable"
                          v-bind="invoicingParkOrderTable"
                          @toolbar-button-click="toolbarButtonClickEvent" v-if="searchObj.type === '待处理'">

                    <!-- 收款信息、开票信息、支付方式 -->
                    <template #collection="{ row }">
                        <el-tag v-if="row.collectionCode != null" type="success" size="small">已收款</el-tag>
                        <el-tag v-else type="danger" size="small">未收款</el-tag>
                    </template>

                    <template #paymentMethod="{ row }">
                        <el-tag v-if="row.paymentMethod == '1'" type="warning" size="small">江苏稻谷物业管理有限公司苏州分公司
                        </el-tag>
                        <el-tag v-if="row.paymentMethod == '3'" type="warning" size="small">苏州步步高物业管理有限公司</el-tag>
                    </template>

                    <template #operate="{ row }">
                        <el-button type="primary" size="mini" @click.stop="btnClick(row)" :disabled="row.collectionCode == null">开票</el-button>
                    </template>

                </vxe-grid>

                <vxe-grid ref="invoicedParkOrderTable"
                          id="invoicedParkOrderTable"
                          class="invoicedParkOrderTable"
                          v-bind="invoicedParkOrderTable"
                          @page-change="PageChange"
                          @toolbar-button-click="toolbarButtonClickEvent2" v-else>

                    <!-- 收款信息、开票信息、支付方式 -->
                    <template #collection="{ row }">
                        <el-tag v-if="row.collectionCode != null" type="success" size="small">已收款</el-tag>
                        <el-tag v-else type="danger" size="small">未收款</el-tag>
                    </template>

                    <template #paymentMethod="{ row }">
                        <el-tag v-if="row.paymentMethod == '1'" type="warning" size="small">江苏稻谷物业管理有限公司苏州分公司
                        </el-tag>
                        <el-tag v-if="row.paymentMethod == '3'" type="warning" size="small">苏州步步高物业管理有限公司</el-tag>
                    </template>

                    <template #operate="{ row }">
                        <el-button type="primary" size="mini" @click.stop="viewClick(row)">查看</el-button>
                    </template>
                </vxe-grid>
            </div>

            <!-- 开票 -->
            <el-drawer :visible.sync="openDrawer" size="80%" destroy-on-close :close-on-press-escape="false"
                       :wrapperClosable="false">
                <template slot="title">
                    <el-alert
                            title="开票"
                            type="warning"
                            :closable="false">
                    </el-alert>
                </template>

                <ParkInvoiceOpen :applicationId="applicationId" @OpenParkInvoice="OpenParkInvoice"></ParkInvoiceOpen>

            </el-drawer>

            <!-- 发票详情 -->
            <el-drawer :visible.sync="viewDrawer" size="50%" destroy-on-close>
                <template slot="title">
                    <el-alert
                            title="发票详情"
                            type="warning"
                            :closable="false">
                    </el-alert>
                </template>

                <div class="container" v-loading="loading2">

                    <el-image v-if="invoiceDetail != null"
                              style="width: 100%; height: 550px;margin-bottom: 10px"
                              :src="invoiceDetail.imgUrls"
                              fit="fill"
                              :preview-src-list="[invoiceDetail.imgUrls]">
                    </el-image>

                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div style="text-align: left;">
                                <a :href="invoiceDetail.pdfUrl" target="_blank">查看PDF文件</a>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div style="text-align: right" v-if="invoiceDetail.xmlUrl">
                                <a :href="invoiceDetail.xmlUrl" target="_blank">查看xml文件</a>
                            </div>
                        </el-col>
                    </el-row>

                    <el-divider content-position="left">关联账单</el-divider>
                    <vxe-grid ref="parkInvoiceBillListTable"
                              id="parkInvoiceBillListTable"
                              class="parkInvoiceBillListTable"
                              v-bind="parkInvoiceBillListTable">

                        <template #collection="{ row }">
                            <el-tag v-if="row.collectionCode != null" type="success" size="small">已收款</el-tag>
                            <el-tag v-else type="danger" size="small">未收款</el-tag>
                        </template>

                        <template #invoice="{ row }">
                            <el-tag v-if="row.paymentMethod == '2'" type="info" size="small">不可开票</el-tag>
                            <el-tag v-if="row.invoiceInfo == 'empty'" type="warning" size="small">无需开票</el-tag>
                            <el-tag v-if="row.paymentMethod != '2' && row.invoiceInfo != null && row.invoiceInfo != 'empty'"
                                    type="success" size="small">已开票
                            </el-tag>
                            <el-tag v-if="row.paymentMethod != '2' && row.invoiceInfo == null" type="danger"
                                    size="small">
                                未开票
                            </el-tag>
                        </template>

                        <template #paymentMethod="{ row }">
                            <el-tag v-if="(row.paymentMethod != '2') && row.collectionCode != null" size="small">对公
                            </el-tag>
                            <!--<el-tag v-if="row.paymentMethod == '1' && row.collectionCode == null" size="small">未支付</el-tag>-->
                            <el-tag v-if="row.paymentMethod == '2' && row.collectionCode != null" type="info"
                                    size="small">
                                现金
                            </el-tag>
                        </template>
                    </vxe-grid>
                </div>

            </el-drawer>

        </div>

        <div v-else>
            <el-result style="height: -webkit-calc(100vh - 250px)"
                       icon="warning" title="无权限"
                       subTitle="抱歉，无权限操作">
            </el-result>
        </div>

    </div>

</template>

<script>
    import XEUtils from 'xe-utils'
    import {
        GetInvoicedParkOrder,
        GetInvoicingParkOrder,
        GetProjectsWithoutAll,
        GetWuyeAuthority,
        GetWuyeBillsByInvoiceInfo, SearchFpqqlsh
    } from "../../api";
    import formatMoney from "../../utils/formatMoney";
    import ParkInvoiceOpen from "./ParkInvoiceOpen"

    export default {
        name: "ParkInvoiceApplicationManagement",
        components: {ParkInvoiceOpen},
        data() {
            return {
                unionid: null,
                loading: false,
                authority: true,
                bigHeight: null,
                tableContainerStyle: {
                    height: ''
                },
                projects: [],
                searchObj: {
                    type: '待处理',
                    projName: '',
                    timeRange: [
                        XEUtils.toDateString(XEUtils.getWhatDay(Date.now(), -30), 'yyyy-MM-dd'),
                        XEUtils.toDateString(XEUtils.getWhatDay(Date.now()), 'yyyy-MM-dd'),
                    ]
                },
                invoicingList: [],
                currentPage: 1,
                pageSize: 50,
                total: 0,
                invoicedList: [],
                viewDrawer: false,
                loading2: false,
                billList: [],
                invoiceDetail: {},
                openDrawer: false,
                applicationId: null
            }
        },
        computed: {
            invoicingParkOrderTable: function () {
                return {
                    height: 'auto',
                    size: 'small',
                    border: true,
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'}
                        ],
                        size: 'medium',
                        custom: true
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    scrollY: {
                        mode: 'wheel'
                    },
                    columnConfig: {
                        width: 'auto',
                        minWidth: '150'
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.invoicingList,
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {
                            resizable: true, title: '申请时间', field: 'applyTime', formatter: function ({cellValue}) {
                                return XEUtils.toDateString(cellValue, 'yyyy-MM-dd HH:mm:ss')
                            }
                        },
                        {
                            resizable: true, title: '账单信息', align: 'center', children: [
                                {resizable: true, title: '月份', field: 'billMonth'},
                                {resizable: true, title: '账单周期', field: 'chargeContent'},
                                {resizable: true, title: '收费项目', field: 'U8fee'},
                                {
                                    resizable: true,
                                    title: '实际收款',
                                    field: 'receivedAmount',
                                    formatter: 'FormatMoney',
                                    className: 'font-blue'
                                },
                                {resizable: true, title: '收款', field: 'collectionCode', slots: {default: 'collection'}},
                                {
                                    resizable: true,
                                    title: '收款公司',
                                    field: 'paymentMethod',
                                    slots: {default: 'paymentMethod'},
                                    width: '250'
                                }
                            ]
                        },
                        {
                            resizable: true, title: '订单信息', align: 'center', children: [
                                {resizable: true, title: '车牌号', field: 'carNum'},
                                {
                                    resizable: true,
                                    title: '开始日期',
                                    field: 'startDate',
                                    formatter: function ({cellValue}) {
                                        return XEUtils.toDateString(cellValue, 'yyyy-MM-dd HH:mm:ss')
                                    }
                                },
                                {
                                    resizable: true,
                                    title: '结束日期',
                                    field: 'endDate',
                                    formatter: function ({cellValue}) {
                                        return XEUtils.toDateString(cellValue, 'yyyy-MM-dd HH:mm:ss')
                                    }
                                },
                            ]
                        },
                        {
                            resizable: true, title: '用户信息', align: 'center', children: [
                                {resizable: true, title: '用户姓名', field: 'name'},
                                {resizable: true, title: '电话', field: 'phone'}
                            ]
                        },
                        {
                            title: '操作',
                            field: 'operation',
                            fixed: 'right',
                            align: 'center',
                            width: 80,
                            slots: {default: 'operate'}
                        }
                    ]
                }
            },
            invoicedParkOrderTable: function () {
                return {
                    height: 'auto',
                    size: 'small',
                    border: true,
                    toolbarConfig: {
                        buttons: [
                            {code: 'refresh', name: '刷 新', icon: 'vxe-icon--refresh'},
                            {code: 'export-plus', name: '导 出', icon: 'vxe-icon--download'},
                            {code: 'zoom', name: '最大化/还原', icon: 'vxe-icon--zoomout'}
                        ],
                        size: 'medium',
                        custom: true
                    },
                    customConfig: {
                        storage: true
                    },
                    rowConfig: {
                        isHover: true
                    },
                    scrollY: {
                        mode: 'wheel'
                    },
                    columnConfig: {
                        width: 'auto',
                        minWidth: '150'
                    },
                    exportConfig: {
                        remote: false,
                        types: ['xlsx'],
                        modes: ['current'],
                        useStyle: true
                    },
                    data: this.invoicedList,
                    pagerConfig: {
                        layouts: ['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Sizes', 'Total'],
                        pageSizes: [10, 20, 50, 100],
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        total: this.total
                    },
                    columns: [
                        {resizable: true, title: '序号', type: 'seq', width: '60'},
                        {resizable: true, title: '发票类型', field: 'type',formatter: function ({cellValue}) {
                                return cellValue === 'pc'?'数电普票(电子)':'数电专票(电子)'
                            }},
                        {resizable: true, title: '购方名称', field: 'buyerName', className: 'font-red'},
                        {
                            resizable: true, title: '申请时间', field: 'createTime', formatter: function ({cellValue}) {
                                return XEUtils.toDateString(cellValue, 'yyyy-MM-dd HH:mm:ss')
                            }
                        },
                        {resizable: true, title: '发票信息', field: 'invoiceInfo', className: 'font-blue'},
                        {
                            resizable: true, title: '完成时间', field: 'completionTime', formatter: function ({cellValue}) {
                                return XEUtils.toDateString(cellValue, 'yyyy-MM-dd HH:mm:ss')
                            }
                        },
                        {
                            resizable: true, title: '账单信息', align: 'center', children: [
                                {resizable: true, title: '月份', field: 'billMonth'},
                                {resizable: true, title: '账单周期', field: 'chargeContent'},
                                {resizable: true, title: '收费项目', field: 'U8fee'},
                                {
                                    resizable: true,
                                    title: '实际收款',
                                    field: 'receivedAmount',
                                    formatter: 'FormatMoney',
                                    className: 'font-blue'
                                },
                                {resizable: true, title: '收款', field: 'collectionCode', slots: {default: 'collection'}},
                                {
                                    resizable: true,
                                    title: '收款公司',
                                    field: 'paymentMethod',
                                    slots: {default: 'paymentMethod'},
                                    width: '250'
                                }
                            ]
                        },
                        {
                            resizable: true, title: '订单信息', align: 'center', children: [
                                {resizable: true, title: '车牌号', field: 'carNum'},
                                {
                                    resizable: true,
                                    title: '开始日期',
                                    field: 'startDate',
                                    formatter: function ({cellValue}) {
                                        return XEUtils.toDateString(cellValue, 'yyyy-MM-dd HH:mm:ss')
                                    }
                                },
                                {
                                    resizable: true,
                                    title: '结束日期',
                                    field: 'endDate',
                                    formatter: function ({cellValue}) {
                                        return XEUtils.toDateString(cellValue, 'yyyy-MM-dd HH:mm:ss')
                                    }
                                },
                            ]
                        },
                        {
                            resizable: true, title: '用户信息', align: 'center', children: [
                                {resizable: true, title: '用户姓名', field: 'name'},
                                {resizable: true, title: '电话', field: 'phone'}
                            ]
                        },
                        {
                            title: '操作',
                            field: 'operation',
                            fixed: 'right',
                            align: 'center',
                            width: 80,
                            slots: {default: 'operate'}
                        }
                    ]
                }
            },
            parkInvoiceBillListTable: function () {
                return {
                    height: '300',
                    showOverflow: true,
                    size: 'small',
                    rowConfig: {
                        isHover: true
                    },
                    data: this.billList,
                    columns: [
                        {resizable: true, title: '所属项目', field: 'projName', visible: false},
                        {resizable: true, title: '账单月份', field: 'billMonth'},
                        {resizable: true, title: '客户名称', field: 'clientName', className: 'font-blue'},
                        {resizable: true, title: '收费项目', field: 'u8fee'},
                        {resizable: true, title: '实际收款', field: 'receivedAmount', formatter: 'FormatMoney'},
                        {
                            resizable: true, title: '收款', field: 'collectionCode', slots: {default: 'collection'},

                        },
                        {
                            resizable: true, title: '结算方式', field: 'paymentMethod', slots: {default: 'paymentMethod'},

                        },
                        {
                            resizable: true, title: '发票信息', field: 'invoiceInfo', slots: {default: 'invoice'},
                        }
                    ],
                    showFooter: true,
                    footerRowClassName: 'footer-row',
                    footerMethod({columns, data}) {
                        return [
                            columns.map((column, columnIndex) => {
                                if (columnIndex === 0) {
                                    return '合计：'
                                }

                                if (['receivedAmount'].includes(column.property)) {

                                    let count = 0
                                    data.forEach(item => {
                                        count += Number(item[column.property])
                                    })
                                    return formatMoney(count.toFixed(2))
                                }

                                return ''
                            }),
                        ]
                    }
                }
            },
        },
        methods: {
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            submit() {
                if (this.searchObj.type === '待处理') {
                    this.loading = true
                    GetInvoicingParkOrder(this.searchObj.projName).then((res) => {
                        this.invoicingList = res
                        this.loading = false
                    })
                } else {
                    this.loading = true
                    GetInvoicedParkOrder(this.searchObj, this.pageSize, this.currentPage).then((res) => {
                        this.total = res.count
                        this.invoicedList = res.list
                        this.loading = false
                    })
                }
            },
            toolbarButtonClickEvent({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        this.$refs.invoicingParkOrderTable.openExport()
                        break
                    case 'zoom':
                        this.$refs.invoicingParkOrderTable.zoom()
                        break
                }
            },
            toolbarButtonClickEvent2({code}) {
                switch (code) {
                    case 'refresh':
                        this.submit()
                        break
                    case 'export-plus':
                        this.$refs.invoicedParkOrderTable.openExport()
                        break
                    case 'zoom':
                        this.$refs.invoicedParkOrderTable.zoom()
                        break
                }
            },
            PageChange({currentPage, pageSize}) {
                this.currentPage = currentPage
                this.pageSize = pageSize
                this.submit()
            },
            btnClick(row) {
                this.applicationId = row.applicationId
                this.openDrawer = true
            },
            OpenParkInvoice() {
                this.submit()
                this.openDrawer = false
            },
            viewClick(row) {
                this.viewDrawer = true
                this.loading2 = true
                var arr = row.invoiceInfo.split('-')
                GetWuyeBillsByInvoiceInfo(row.invoiceInfo).then((res) => {
                    this.billList = res
                    SearchFpqqlsh(row.paymentMethod, arr[3]).then((res) => {
                        this.invoiceDetail = res.result[0]
                        this.loading2 = false
                    })
                })
            }
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeAuthority(this.unionid).then((res) => {
                /* 权限控制 */
                if (res.authorities.indexOf('parkInvoice') != -1) {
                    this.authority = true
                } else {
                    this.authority = false
                }

                this.searchObj.projName = '稻谷国际中心'
                if (res.projName === 'ALL') {
                    GetProjectsWithoutAll().then((res) => {
                        this.projects = res
                    })
                } else {
                    this.projects = []
                    var arr = res.projName.split(',')

                    this.searchObj.projName = arr[0]

                    for (var i = 0; i <= arr.length - 1; i++) {
                        this.projects.push({
                            projName: arr[i]
                        })
                    }
                }

                this.submit()
            })
        },
        mounted() {
            this.$nextTick(function () {
                if (this.authority) {
                    this.bigHeight = this.$refs.collapse.$el.offsetHeight
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                }
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .invoicingParkOrderTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .invoicingParkOrderTable >>> .font-blue {
        color: #45aaf2;
    }

    .invoicingParkOrderTable >>> .font-red {
        color: #F76560;
    }

    .invoicingParkOrderTable >>> .footer-row {
        background-color: #F8F8F9;
    }

    .invoicedParkOrderTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .invoicedParkOrderTable >>> .font-blue {
        color: #45aaf2;
    }

    .invoicedParkOrderTable >>> .font-red {
        color: #F76560;
    }

    .invoicedParkOrderTable >>> .footer-row {
        background-color: #F8F8F9;
    }

    .parkInvoiceBillListTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .parkInvoiceBillListTable >>> .footer-row {
        background-color: #F8F8F9;
    }
    .container {
        width: 94%;
        margin-left: 3%;
    }

</style>
